import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { Viewer, ModelFile, SharedFileApi, SharedFile, ModelFileApi } from '../shared/sdk';
import { ViewerService } from '../services/viewer.service';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { BrowserComponent } from '../group/browser/browser.component';
import { SharedFolder } from '../share/share.component';
import { ActivatedRoute } from '@angular/router';
import { GlobalEventService } from '../services/global-event.service';
import { BrowserBreadCrumb, ShareBreadCrumb } from '../group/browser/browser-breadcrumb/browser-breadcrumb.component';

@Component({
    selector: 'app-share-with',
    templateUrl: './share-with.component.html',
    styleUrls: ['./share-with.component.scss']
})

export class ShareWithComponent implements AfterViewInit
{
    @ViewChild(BrowserComponent)
    private fileBrowser: BrowserComponent;


    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;

    activeViewer: Viewer

    folders: SharedFolder[] = [];
    files: ModelFile[] = [];
    viewMode: number;
    selectedFiles: ModelFile[] = [];
    sharedWith: Viewer;

    //Pagination
    private fileCount: number;
    private page: number = 1;
    pageSize: number = 75;

    //Sort defaults
    private sortCat: string = 'Name';
    private sortAsc: boolean = true;

    private viewerSubscription: Subscription;


    public onDisplayNativeFile (event)
    {
        this.viewerService.setShowNative(event).subscribe((viewer) =>
        {
            this.viewerService.changeViewer(viewer);
        });
    }

    constructor (private route: ActivatedRoute,
        private viewerService: ViewerService,
        private fileApi: ModelFileApi,
        private shareApi: SharedFileApi,
        private eventService: GlobalEventService) { }

    ngAfterViewInit ()
    {
        this.viewerSubscription = this.viewerService.currentViewer.subscribe((activeViewer: Viewer) =>
        {
            if (activeViewer.id)
            {
                setTimeout(() => { this.activeViewer = activeViewer; }, 0);
                setTimeout(() => { this.viewMode = activeViewer.ViewMode; }, 0);
                setTimeout(() => { this.fileBrowser.setViewer(activeViewer) }, 0);
                setTimeout(() =>
                {
                    this.route.params.subscribe(params =>
                    {
                        this.files = [];
                        this.folders = [];
                        if (params.owner)
                        {
                            
                            this.fileBrowser.setShareFolders([]);
                            this.viewerService.getViewerById(params.owner).subscribe((viewer: Viewer) =>
                            {
                                this.fileBrowser.resetShareCrumb();
                                this.sharedWith = viewer;
                                this.getPublishedFiles();
                                let crumb: ShareBreadCrumb = {
                                    name: 'SHARED_FILES',
                                    link: '/share/sharedWith'
                                }
                                this.fileBrowser.addShareCrumb(crumb);
                                crumb = {
                                    name: viewer.email,
                                    link: '/share/sharedWith/;owner=' + viewer.id
                                }
                                this.fileBrowser.addShareCrumb(crumb);
                            });
                        }
                        else
                        {
                            this.fileBrowser.resetShareCrumb();
                            let crumb: ShareBreadCrumb = {
                                name: 'SHARED_FILES',
                                link: '/share/sharedWith'
                            }
                            this.fileBrowser.addShareCrumb(crumb);
                            this.getFolder();
                        }
                    });
                });
            }
        });
    }

    ngOnInit ()
    {
        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
                this.pageSize = isMobile ? 20 : 75;
            }
        });
    }

    getFolder (searchString?: string)
    {
        this.folders = [];
        this.fileBrowser.setShareFolders([]);
        if (this.activeViewer && this.activeViewer.id)
        {
            this.viewerService.getSharedWith().subscribe((shares: SharedFile[]) =>
            {
                this.fileBrowser.setFiles([]);
                let folders = [];
                for (let i = 0; i < shares.length; i++)
                {
                    let sharedFolder: SharedFolder = {
                        id: shares[i].viewerId,
                        name: ''
                    };
                    if (this.folders.map((folder: SharedFolder) => { return folder.id; }).indexOf(shares[i].viewerId) === -1)
                    {
                        this.folders.push(sharedFolder);
                        this.viewerService.getViewerById(shares[i].viewerId).subscribe((viewer: Viewer) =>
                        {
                            sharedFolder.name = viewer.email;
                            if ((!searchString || viewer.email.indexOf(searchString) !== -1) &&
                                folders.map((folder: SharedFolder) => { return folder.id; }).indexOf(viewer.id) === -1)
                            {
                                folders.push(sharedFolder);
                                this.fileBrowser.setShareFolders(folders);
                            }
                        });
                    }
                }
            });
        }
    }

    ngOnDestroy ()
    {
        this.viewerSubscription.unsubscribe();
        this.layoutSubscription.unsubscribe();
    }

    getPublishedFiles (searchString?: string, sortCat?: string, sortAsc?: boolean)
    {
        this.files = [];
        this.folders = [];
        let filter = {
            sharedFrom: this.activeViewer.id,
            sharedWith: this.sharedWith.id,
            name: searchString
        }
        this.shareApi.countWithPageFilter(filter).subscribe((count) =>
        {
            this.fileCount = count.count;
            this.fileBrowser.setFileCount(this.fileCount);
            filter['skip'] = (this.page - 1) * this.pageSize;
            let asc: boolean = (sortAsc !== undefined) ? sortAsc : this.sortAsc;
            let cat = (sortCat ? sortCat : this.sortCat);
            filter['order'] = cat + ' ' + (asc ? 'ASC' : 'DESC');
            filter['limit'] = this.pageSize;
            this.shareApi.getFilesWithPageFilter(filter).subscribe((files) =>
            {
                files = files.files;
                this.files = files as ModelFile[];
                this.fileBrowser.setFiles(this.files);
            });
        });
    }

    setPage (page: number, searchString?: string, sortCat?: string, sortAsc?: boolean)
    {
        let maxPage = Math.ceil(this.fileCount / this.pageSize);
        if ((page > maxPage || page < 1) && maxPage !== 0)
        {
            return;
        }
        else
        {
            this.page = page;
            this.fileBrowser.setPage(page);
            this.getPublishedFiles(searchString, sortCat, sortAsc);
        }
    }

    setViewMode (viewMode)
    {
        this.viewerService.setViewMode(viewMode).subscribe((viewer) =>
        {
            this.viewerService.changeViewer(viewer);
        });
    }

    removeShares (searchString?: string, sortCat?: string, sortAsc?: boolean)
    {
        this.selectedFiles = this.fileBrowser.getSelection();
        for (let i = 0; i < this.selectedFiles.length; i++)
        {
            let share = {
                where: {
                    viewerId: this.sharedWith.id,
                    modelFileId: this.selectedFiles[i].id
                }
            };
            this.shareApi.find(share).subscribe((shareToDelete: SharedFile[]) =>
            {
                this.shareApi.deleteById(shareToDelete[0].id).subscribe(res =>
                {
                    this.setPage(1, searchString, sortCat, sortAsc);
                })
            });
        }
    }

    removeAllShares (searchString?: string, sortCat?: string, sortAsc?: boolean)
    {
        let filter = {
            sharedFrom: this.activeViewer.id,
            sharedWith: this.sharedWith.id,
            skip: 0,
            limit: 0,
            order: 'Name ASC'
        }
        this.shareApi.getFilesWithPageFilter(filter).subscribe((files) =>
        {
            files = files.files;
            let calls: Observable<any>[] = [];
            for (let i = 0; i < files.length; i++)
            {
                calls.push(this.fileApi.deleteSharedWith(files[i].id));
            }
            forkJoin(calls).subscribe((res) =>
            {
                this.setPage(1, searchString, sortCat, sortAsc);
            });
        });

    }

    OnBrowserEmit (event)
    {
        if (event.Page)
        {
            if (this.folders.length === 0)
            {
                this.setPage(event.Page, event.SearchText, event.SortCat, event.SortAsc);
            }
            else
            {
                this.getFolder(event.SearchText);
            }
        }
        else if (event.Type && event.Type === 'RemoveShare')
        {
            this.removeShares(event.SearchText, event.SortCat, event.SortAsc);
        }
        else if (event.Type && event.Type === 'RemoveAllSharesForUser')
        {
            this.removeAllShares(event.SearchText, event.SortCat, event.SortAsc);
        }
    }
}
