import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { GroupFolder, GroupFolderApi, GroupApi, Group } from 'src/app/shared/sdk';

@Component({
    selector: 'app-browser-breadcrumb',
    templateUrl: './browser-breadcrumb.component.html',
    styleUrls: ['./browser-breadcrumb.component.scss']
})
export class BrowserBreadcrumbComponent implements AfterViewInit
{
    @ViewChild('crumbBar')
    private crumbBar: ElementRef;
    @Input()
    private rightBrowser: boolean;
    @Input()
    isMobile: boolean;
    @Input()
    private isShare: boolean = false;
    path: BrowserBreadCrumb[] = [];
    sharePath: ShareBreadCrumb[] = [];
    rootCrumb: BrowserBreadCrumb;

    private root: BrowserBreadCrumb;
    //Emitter
    @Output()
    private emitter = new EventEmitter<any>();

    constructor (
        private folderApi: GroupFolderApi,
        private groupApi: GroupApi) { }

    ngAfterViewInit ()
    {
        if (this.crumbBar)
        {
            this.crumbBar.nativeElement.scrollIntoView(false);
        }
    }

    goToHome ()
    {
        this.emitter.emit('home');
    }

    getPath ()
    {
        return this.path;
    }

    addCrumb (crumb: BrowserBreadCrumb)
    {
        if (crumb.rootFolderId)
        {
            this.rootCrumb = crumb;
        }
        else if (crumb.isGroup)
        {
            this.root = crumb;
            crumb.index = this.path.length;
            let i = this.path.map(item => { return item.id; }).indexOf(crumb.id);
            if (i === -1)
            {
                this.path.push(crumb)
            }
            else
            {
                if (crumb.isGroup)
                {
                    this.jumpToIndex(0);
                }
                else
                {
                    this.jumpToIndex(i);
                }
            }
        }
        else if (!this.root)
        {
            this.resolvePath(crumb.id);
        }
        else
        {
            crumb.index = this.path.length;
            let i = this.path.map(item => { return item.id; }).indexOf(crumb.id);
            if (i === -1 || (!crumb.isGroup && i === 0))
            {
                this.path.push(crumb)
            }
            else
            {
                if (crumb.isGroup)
                {
                    this.jumpToIndex(0);
                }
                else
                {
                    this.jumpToIndex(i);
                }
            }
        }
        if (this.crumbBar)
        {
            setTimeout(() =>
            {
                this.crumbBar.nativeElement.scrollLeft = this.crumbBar.nativeElement.scrollWidth;
            }, 0);
        }
    }

    resetShareCrumb ()
    {
        this.sharePath = [];
    }

    addShareCrumb (crumb: ShareBreadCrumb)
    {
        this.sharePath.push(crumb);
        if (this.crumbBar)
        {
            setTimeout(() =>
            {
                this.crumbBar.nativeElement.scrollLeft = this.crumbBar.nativeElement.scrollWidth;
            }, 0);
        }
    }

    resolvePath (folderId: number)
    {
        this.folderApi.findById(folderId).subscribe((folder: GroupFolder) =>
        {
            let crumb = {
                name: folder.Name,
                id: folder.id,
                isGroup: false,
                index: undefined,
                isAssembly: folder.IsParent
            };
            this.path.unshift(crumb);
            if (folder.childFolderType === 'GroupFolder')
            {
                this.resolvePath(folder.childFolderId);
            }
            else
            {
                this.groupApi.findById(folder.childFolderId).subscribe((group: Group) =>
                {
                    let rootCrumb = {
                        name: group.Name,
                        id: group.id,
                        isGroup: true,
                        index: undefined,
                        isAssembly: false
                    };
                    this.path.unshift(rootCrumb);
                    let index = 0;
                    this.path = this.path.map(item =>
                    {
                        item.index = index;
                        index++;
                        return item;
                    });
                    this.root = rootCrumb;
                    if (this.crumbBar)
                    {
                        setTimeout(() =>
                        {
                            this.crumbBar.nativeElement.scrollLeft = this.crumbBar.nativeElement.scrollWidth;
                        }, 0);
                    }
                });
            }
        });
    }

    jumpToIndex (index: number)
    {
        this.path = this.path.slice(0, index + 1);
    }

    route (crumb)
    {
        this.jumpToIndex(crumb.index);
        this.emitter.emit({ isGroup: crumb.isGroup, id: crumb.id, root: this.root });
    }

}

export interface BrowserBreadCrumb
{
    name: string,
    id: number,
    isGroup: boolean,
    index: number,
    isAssembly: boolean
    rootFolderId?: number
};


export interface ShareBreadCrumb
{
    name: string,
    link: string
};

