import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SettingsService } from '../services/settings.service';
import { SettingsInterface, Settings, Viewer, SourceApi, Theme, ThemeApi, ThemeInterface } from '../shared/sdk';
import { Subscription } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ViewerService } from '../services/viewer.service';
import { ThrowStmt } from '@angular/compiler';
import { SimpleDialogComponent } from '../widgets/simple-dialog/simple-dialog.component';
import { GlobalEventService, VisKeyboardEvent } from '../services/global-event.service';

@Component({
    selector: 'app-color-selection',
    templateUrl: './color-selection.component.html',
    styleUrls: ['./color-selection.component.scss']
})
export class ColorSelectionComponent implements OnInit
{
    @ViewChild(SimpleDialogComponent)
    private simpleDialog: SimpleDialogComponent;
    //Inputs
    color1 = new FormControl();
    color2 = new FormControl();
    color3 = new FormControl();
    color1String = new FormControl();
    color2String = new FormControl();
    color3String = new FormControl();
    banner = new FormControl();
    banners: string[] = [];
    logo = new FormControl();
    logos: string[] = [];
    themes: Theme[] = [];
    theme = new FormControl();
    activeTheme: Theme;

    //Defaults
    private defaultColor1: string = 'd67c1c';
    private defaultColor2: string = 'ffc662';
    private defaultColor3: string = 'fcd797';
    private defaultBanner: string = 'Kisters_banner.svg';
    private defaultLogo: string = 'Kisters_logo.svg';

    private root: HTMLElement = document.documentElement;
    selection: number = 0;

    //Viewer
    private activeViewer: Viewer;
    private viewerSubscription: Subscription;
    //Settings
    private settings: Settings;
    private settingsSubscription: Subscription;
    private globalEventSubscription: Subscription;

    constructor (private settingsService: SettingsService,
        private viewerService: ViewerService,
        private http: HttpClient,
        private sourceApi: SourceApi,
        private themeApi: ThemeApi,
        private eventService: GlobalEventService)
    {
    }

    ngOnInit ()
    {
        this.settingsSubscription = this.settingsService.settings.subscribe((settings: Settings) =>
        {
            if (!this.settings || !this.settings.Color1)
            {

                this.settings = settings;
                this.root.style.setProperty('--tmp-app-color1', this.settings.Color1 ? this.parseToHex(this.settings.Color1) : '#' + this.defaultColor1);
                this.root.style.setProperty('--tmp-app-color2', this.settings.Color2 ? this.parseToHex(this.settings.Color2) : '#' + this.defaultColor2);
                this.root.style.setProperty('--tmp-app-color3', this.settings.Color3 ? this.parseToHex(this.settings.Color3) : '#' + this.defaultColor3);
                this.color1String.setValue(this.settings.Color1 ? this.parseToHex(this.settings.Color1) : '#' + this.defaultColor1, { emitEvent: false });
                this.color1.setValue(this.settings.Color1 ? this.parseToHex(this.settings.Color1) : '#' + this.defaultColor1, { emitEvent: false });
                this.color2String.setValue(this.settings.Color2 ? this.parseToHex(this.settings.Color2) : '#' + this.defaultColor2, { emitEvent: false });
                this.color2.setValue(this.settings.Color2 ? this.parseToHex(this.settings.Color2) : '#' + this.defaultColor2, { emitEvent: false });
                this.color3String.setValue(this.settings.Color3 ? this.parseToHex(this.settings.Color3) : '#' + this.defaultColor3, { emitEvent: false });
                this.color3.setValue(this.settings.Color3 ? this.parseToHex(this.settings.Color3) : '#' + this.defaultColor3, { emitEvent: false });
                this.banner.setValue(this.settings.Banner ? this.settings.Banner : this.defaultBanner, { emitEvent: false });
                this.logo.setValue(this.settings.Logo ? this.settings.Logo : this.defaultLogo, { emitEvent: false });
            }
        });

        this.viewerSubscription = this.viewerService.currentViewer.subscribe((viewer: Viewer) =>
        {
            if (viewer.id && ((this.activeViewer && this.activeViewer.id !== viewer.id) || !this.activeViewer))
            {
                this.getBanners();
                this.getLogos();
                this.getThemes();
            }
            this.activeViewer = viewer;
        });

        //Map color inputs to string inputs
        this.color1.valueChanges.subscribe(val =>
        {
            this.root.style.setProperty('--tmp-app-color1', val);
            this.color1String.setValue(val, { emitEvent: false });
            this.resetTheme();
        });
        this.color2.valueChanges.subscribe(val =>
        {
            this.root.style.setProperty('--tmp-app-color2', val);
            this.color2String.setValue(val, { emitEvent: false });
            this.resetTheme();
        });
        this.color3.valueChanges.subscribe(val =>
        {
            this.root.style.setProperty('--tmp-app-color3', val);
            this.color3String.setValue(val, { emitEvent: false });
            this.resetTheme();
        });
        //Map string inputs to color inputs
        this.color1String.valueChanges.subscribe(val =>
        {
            let hexVal = this.parseToHex(val);
            this.root.style.setProperty('--tmp-app-color1', hexVal);
            this.color1.setValue(hexVal, { emitEvent: false });
            this.resetTheme();
        });
        this.color2String.valueChanges.subscribe(val =>
        {
            let hexVal = this.parseToHex(val);
            this.root.style.setProperty('--tmp-app-color2', hexVal);
            this.color2.setValue(hexVal, { emitEvent: false });
            this.resetTheme();
        });
        this.color3String.valueChanges.subscribe(val =>
        {
            let hexVal = this.parseToHex(val);
            this.root.style.setProperty('--tmp-app-color3', hexVal);
            this.color3.setValue(hexVal, { emitEvent: false });
            this.resetTheme();
        });
        //Map banner and logo
        this.banner.valueChanges.subscribe(val =>
        {
            this.resetTheme();
        });
        this.logo.valueChanges.subscribe(val =>
        {
            this.resetTheme();
        });
        //Map themes
        this.theme.valueChanges.subscribe((theme: Theme) =>
        {
            this.color1String.setValue(theme.Color1);
            this.color2String.setValue(theme.Color2);
            this.color3String.setValue(theme.Color3);
            this.banner.setValue(theme.Banner);
            this.logo.setValue(theme.Logo);
            this.setTheme(theme);
        });

        //Keyboard listener
        this.globalEventSubscription = this.eventService.keyboardEvent.subscribe((key: VisKeyboardEvent) =>
        {
            if (key === VisKeyboardEvent.ESC)
            {
                this.simpleDialog.close();
            }
        });

    }

    ngOnDestroy ()
    {
        this.viewerSubscription.unsubscribe();
        this.settingsSubscription.unsubscribe();
        this.globalEventSubscription.unsubscribe();
    }

    parseToHex (val: string)
    {
        if (val.startsWith('#'))
        {
            return val;
        }
        else
        {
            val = val.trim().toLowerCase().replace('rgb(', ')').replace(')', '');
            let rgbVals = val.split(',');
            return "#" + ((1 << 24) + (parseInt(rgbVals[0]) << 16) + (parseInt(rgbVals[1]) << 8) + parseInt(rgbVals[2])).toString(16).slice(1);
        }
    }

    getBanners ()
    {
        this.viewerService.getBanners().subscribe((res: any) =>
        {
            this.banners = res.Banners;
        });
    }

    getLogos ()
    {
        this.viewerService.getLogos().subscribe((res: any) =>
        {
            this.logos = res.Logos;
        });
    }

    getThemes ()
    {
        this.themeApi.find().subscribe((themes: Theme[]) =>
        {
            this.themes = themes;
            let hasActiveTheme: boolean = false;
            themes.forEach((theme: Theme) =>
            {
                if (theme.IsActive)
                {
                    hasActiveTheme = true;
                    this.setTheme(theme);
                }
            });
            let noTheme: Theme = {
                Banner: this.defaultBanner,
                Logo: this.defaultLogo,
                Color1: '#' + this.defaultColor1,
                Color2: '#' + this.defaultColor2,
                Color3: '#' + this.defaultColor3,
                IsActive: !hasActiveTheme,
                Name: '-',
                id: -1
            };
            this.themes.unshift(noTheme);
            if (!hasActiveTheme)
            {
                this.setTheme(noTheme, false);
            }
        });
    }

    startThemeSaving ()
    {
        this.simpleDialog.open('SAVE_THEME', 'THEME_NAME', 'ThemeCreation');
    }

    saveAsTheme (name: string)
    {
        let newTheme: ThemeInterface = {
            IsActive: true,
            Color1: this.color1.value,
            Color2: this.color2.value,
            Color3: this.color3.value,
            Banner: this.banner.value,
            Logo: this.logo.value,
            Name: name
        };
        this.applyTheme(newTheme as Theme, true);
    }

    applyTheme (newActiveTheme: Theme, isNew: boolean = false)
    {
        this.themeApi.updateAll({ where: { IsActive: true } }, { IsActive: false }).subscribe(() =>
        {
            this.themes.forEach((theme: Theme) =>
            {
                if (theme.IsActive)
                {
                    if (theme.id !== newActiveTheme.id && theme.id !== -1)
                    {
                        theme.IsActive = false;
                    }
                }
            });
            this.patchOrCreateActiveTheme(newActiveTheme, isNew);
        });
    }

    patchOrCreateActiveTheme (newActiveTheme: Theme, isNew: boolean)
    {
        if (isNew)
        {
            this.themeApi.create(newActiveTheme).subscribe(() =>
            {
                this.changeSettingsFromTheme();
            });
        }
        else if (newActiveTheme.id === -1)
        {
            newActiveTheme.IsActive = true;
            this.changeSettingsFromTheme();
        }
        else
        {
            this.themeApi.patchAttributes(newActiveTheme.id, { IsActive: true }).subscribe(() =>
            {
                newActiveTheme.IsActive = true;
                this.changeSettingsFromTheme();
            });
        }
    }

    changeSettingsFromTheme ()
    {
        let themeSettings: SettingsInterface = {
            Color1: this.color1String.value,
            Color2: this.color2String.value,
            Color3: this.color3String.value,
            Banner: this.banner.value,
            Logo: this.logo.value
        };
        this.settingsService.setSettings(themeSettings);
        this.getThemes();
        this.root.style.setProperty('--app-color1', themeSettings.Color1);
        this.root.style.setProperty('--app-color2', themeSettings.Color2);
        this.root.style.setProperty('--app-color3', themeSettings.Color3);
    }

    deleteTheme ()
    {
        this.themeApi.deleteById(this.activeTheme.id).subscribe(() =>
        {
            this.getThemes();
        });
    }

    setTheme (theme: Theme, update: boolean = true)
    {
        if (update)
        {
            if (theme.id === -1)
            {
                theme.Color1 = this.settings.Color1;
                theme.Color2 = this.settings.Color2;
                theme.Color3 = this.settings.Color3;
                theme.Logo = this.settings.Logo;
                theme.Banner = this.settings.Banner;
            }
            this.color1String.setValue(theme.Color1);
            this.color2String.setValue(theme.Color2);
            this.color3String.setValue(theme.Color3);
            this.banner.setValue(theme.Banner, { emitEvent: false });
            this.logo.setValue(theme.Logo, { emitEvent: false });
        }
        this.activeTheme = theme;
        this.theme.setValue(theme, { emitEvent: false });
    }

    resetTheme (updateServer: boolean = false)
    {
        this.themes.forEach((theme: Theme) =>
        {
            if (theme.id !== -1 && theme.IsActive)
            {
                theme.IsActive = false;
            }
        });
        if (updateServer)
        {
            this.inActivateThemesOnServer();
        }
        this.setTheme(this.themes[0], false);
    }

    inActivateThemesOnServer ()
    {
        this.themeApi.find({ where: { IsActive: true } }).subscribe((activeThemes: Theme[]) =>
        {
            activeThemes.forEach((activeTheme: Theme) =>
            {
                this.themeApi.patchAttributes(activeTheme.id, { IsActive: false }).subscribe();
            });
        });
    }

    changeColors ()
    {
        let colorSettings: SettingsInterface = {
            Color1: this.color1String.value,
            Color2: this.color2String.value,
            Color3: this.color3String.value
        };
        this.settingsService.setSettings(colorSettings);
        let root = document.documentElement;
        root.style.setProperty('--app-color1', colorSettings.Color1);
        root.style.setProperty('--app-color2', colorSettings.Color2);
        root.style.setProperty('--app-color3', colorSettings.Color3);
        this.resetTheme(true);
    }

    resetColors ()
    {
        this.color1String.setValue('#' + this.defaultColor1);
        this.color2String.setValue('#' + this.defaultColor2);
        this.color3String.setValue('#' + this.defaultColor3);
        let colorSettings: SettingsInterface = {
            Color1: '#' + this.defaultColor1,
            Color2: '#' + this.defaultColor2,
            Color3: '#' + this.defaultColor3
        };
        this.settingsService.setSettings(colorSettings);
        let root = document.documentElement;
        root.style.setProperty('--app-color1', colorSettings.Color1);
        root.style.setProperty('--app-color2', colorSettings.Color2);
        root.style.setProperty('--app-color3', colorSettings.Color3);
        this.resetTheme(true);
    }

    uploadBanner (event)
    {
        let file = event.target.files[0];
        let formData = new FormData();
        formData.append('file', file, file.name);
        let headers = new HttpHeaders({ "Authorization": this.viewerService.getAuthToken().id });
        let uid = this.create_UUID();
        this.sourceApi.createContainer({ name: 'vis-banner-' + uid }).subscribe(() =>
        {
            let call = this.http.post(this.settings.ServerUrl + '/api/Sources/vis-banner-' + uid + '/upload', formData, { headers }).subscribe((res: any) =>
            {
                this.banner.setValue(file.name, { emitEvent: false });
                this.getBanners();
            });
        });
    }

    uploadLogo (event)
    {
        let file = event.target.files[0];
        let formData = new FormData();
        formData.append('file', file, file.name);
        let headers = new HttpHeaders({ "Authorization": this.viewerService.getAuthToken().id });
        let uid = this.create_UUID();
        this.sourceApi.createContainer({ name: 'vis-logo-' + uid }).subscribe(() =>
        {
            let call = this.http.post(this.settings.ServerUrl + '/api/Sources/vis-logo-' + uid + '/upload', formData, { headers }).subscribe((res: any) =>
            {
                this.logo.setValue(file.name, { emitEvent: false });
                this.getLogos();
            });
        });
    }

    changeImages ()
    {
        let imageSettings: SettingsInterface = {
            Banner: this.banner.value,
            Logo: this.logo.value
        };
        this.settingsService.setSettings(imageSettings);
        this.viewerService.setBanner(this.banner.value).subscribe();
        this.viewerService.setLogo(this.logo.value).subscribe();
        this.resetTheme(true);
    }

    resetImages ()
    {
        let imageSettings: SettingsInterface = {
            Banner: this.defaultBanner,
            Logo: this.defaultLogo
        };
        this.banner.setValue(this.defaultBanner);
        this.logo.setValue(this.defaultLogo);
        this.settingsService.setSettings(imageSettings);
        this.viewerService.setBanner(this.defaultBanner).subscribe();
        this.viewerService.setLogo(this.defaultLogo).subscribe();
        this.resetTheme(true);
    }

    create_UUID ()
    {
        var dt = Date.now();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) =>
        {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    OnDialogInput (event)
    {
        if (event)
        {
            let name = event.value;
            this.saveAsTheme(name);
        }
    }

}
