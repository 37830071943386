/* tslint:disable */
import {
  Chat,
  ChatImage,
  ChatFileView
} from '../index';

declare var Object: any;
export interface ChatEntryInterface {
  "CreationTime"?: number;
  "IsEdited"?: boolean;
  "IsDeleted"?: boolean;
  "Message": string;
  "Author"?: string;
  "HasView"?: boolean;
  "id"?: number;
  "chatId"?: number;
  chat?: Chat;
  images?: ChatImage[];
  view?: ChatFileView;
}

export class ChatEntry implements ChatEntryInterface {
  "CreationTime": number;
  "IsEdited": boolean;
  "IsDeleted": boolean;
  "Message": string;
  "Author": string;
  "HasView": boolean;
  "id": number;
  "chatId": number;
  chat: Chat;
  images: ChatImage[];
  view: ChatFileView;
  constructor(data?: ChatEntryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ChatEntry`.
   */
  public static getModelName() {
    return "ChatEntry";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ChatEntry for dynamic purposes.
  **/
  public static factory(data: ChatEntryInterface): ChatEntry{
    return new ChatEntry(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ChatEntry',
      plural: 'ChatEntries',
      path: 'ChatEntries',
      idName: 'id',
      properties: {
        "CreationTime": {
          name: 'CreationTime',
          type: 'number',
          default: 0
        },
        "IsEdited": {
          name: 'IsEdited',
          type: 'boolean',
          default: false
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean',
          default: false
        },
        "Message": {
          name: 'Message',
          type: 'string'
        },
        "Author": {
          name: 'Author',
          type: 'string'
        },
        "HasView": {
          name: 'HasView',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "chatId": {
          name: 'chatId',
          type: 'number'
        },
      },
      relations: {
        chat: {
          name: 'chat',
          type: 'Chat',
          model: 'Chat',
          relationType: 'belongsTo',
                  keyFrom: 'chatId',
          keyTo: 'id'
        },
        images: {
          name: 'images',
          type: 'ChatImage[]',
          model: 'ChatImage',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'chatEntryId'
        },
        view: {
          name: 'view',
          type: 'ChatFileView',
          model: 'ChatFileView',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'chatEntryId'
        },
      }
    }
  }
}
