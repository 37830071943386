/* tslint:disable */
import {
  Viewer,
  Group
} from '../index';

declare var Object: any;
export interface GroupViewerMappingInterface {
  "writeAccess": boolean;
  "adminRights"?: boolean;
  "id"?: number;
  "viewerId"?: number;
  "groupId"?: number;
  viewer?: Viewer;
  group?: Group;
}

export class GroupViewerMapping implements GroupViewerMappingInterface {
  "writeAccess": boolean;
  "adminRights": boolean;
  "id": number;
  "viewerId": number;
  "groupId": number;
  viewer: Viewer;
  group: Group;
  constructor(data?: GroupViewerMappingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GroupViewerMapping`.
   */
  public static getModelName() {
    return "GroupViewerMapping";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GroupViewerMapping for dynamic purposes.
  **/
  public static factory(data: GroupViewerMappingInterface): GroupViewerMapping{
    return new GroupViewerMapping(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GroupViewerMapping',
      plural: 'GroupViewerMappings',
      path: 'GroupViewerMappings',
      idName: 'id',
      properties: {
        "writeAccess": {
          name: 'writeAccess',
          type: 'boolean',
          default: false
        },
        "adminRights": {
          name: 'adminRights',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "viewerId": {
          name: 'viewerId',
          type: 'number'
        },
        "groupId": {
          name: 'groupId',
          type: 'number'
        },
      },
      relations: {
        viewer: {
          name: 'viewer',
          type: 'Viewer',
          model: 'Viewer',
          relationType: 'belongsTo',
                  keyFrom: 'viewerId',
          keyTo: 'id'
        },
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'groupId',
          keyTo: 'id'
        },
      }
    }
  }
}
