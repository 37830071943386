/* tslint:disable */

declare var Object: any;
export interface ThemeInterface {
  "Color1": string;
  "Color2": string;
  "Color3": string;
  "Banner": string;
  "Logo": string;
  "Name": string;
  "IsActive"?: boolean;
  "id"?: number;
}

export class Theme implements ThemeInterface {
  "Color1": string;
  "Color2": string;
  "Color3": string;
  "Banner": string;
  "Logo": string;
  "Name": string;
  "IsActive": boolean;
  "id": number;
  constructor(data?: ThemeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Theme`.
   */
  public static getModelName() {
    return "Theme";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Theme for dynamic purposes.
  **/
  public static factory(data: ThemeInterface): Theme{
    return new Theme(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Theme',
      plural: 'Themes',
      path: 'Themes',
      idName: 'id',
      properties: {
        "Color1": {
          name: 'Color1',
          type: 'string'
        },
        "Color2": {
          name: 'Color2',
          type: 'string'
        },
        "Color3": {
          name: 'Color3',
          type: 'string'
        },
        "Banner": {
          name: 'Banner',
          type: 'string'
        },
        "Logo": {
          name: 'Logo',
          type: 'string'
        },
        "Name": {
          name: 'Name',
          type: 'string',
          default: 'Custom Theme'
        },
        "IsActive": {
          name: 'IsActive',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
