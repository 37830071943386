import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { FileService } from 'src/app/services/file.service';
import { PortalError, ProgressService } from 'src/app/services/progress.service';
import { UploadItem, UploadService } from 'src/app/services/upload.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { Attachment, AttachmentInterface, GroupFolder, GroupFolderApi, ModelFile, ModelFileApi, ModelFileInterface, Viewer, ViewerApi } from 'src/app/shared/sdk';
import { ConfirmModalDialogComponent } from 'src/app/widgets/confirm-modal-dialog/confirm-modal-dialog.component';
import { SimpleDialogComponent } from 'src/app/widgets/simple-dialog/simple-dialog.component';
declare var VSShareBackendUrl: any;

@Component({
    selector: 'app-file-properties-modal',
    templateUrl: './file-properties-modal.component.html',
    styleUrls: ['./file-properties-modal.component.scss']
})
export class FilePropertiesModalComponent implements OnInit
{
    @Input('writeAccess')
    private writeAccess: boolean = false;

    //Layout variables
    @Input('isMobile')
    private isMobile: boolean = false;

    @ViewChild("inputVal")
    private inputVal: ElementRef;

    @ViewChild(ConfirmModalDialogComponent)
    private confirmModal: ConfirmModalDialogComponent;

    @ViewChild(SimpleDialogComponent)
    private simpleDialog: SimpleDialogComponent;

    isOpen: boolean = false;
    selectedTab: number = 0;

    item: any;
    attachments: Attachment[] = [];
    uploadPlaceholder: UploadItem[] = [];

    projectId: number;

    //iOs detection
    isiOs: boolean = false;

    //Selection variable
    allSelected: boolean = false;
    selection: any[] = [];
    attachmentInEdit: Attachment;

    //Sorting
    sortCat: string = 'Name';
    sortAsc: boolean = true;
    wasNavigation: boolean = false;

    constructor (private fileApi: ModelFileApi,
        private folderApi: GroupFolderApi,
        private viewerService: ViewerService,
        private http: HttpClient,
        private progressService: ProgressService,
        private viewerApi: ViewerApi,
        private fileService: FileService,
        private uploadService: UploadService) { }

    ngOnInit ()
    {
        this.isiOs = /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    }

    open(item: any, showAttachments: boolean = false, projectId: number, selectedAttachmentId?: number)
    {
        this.attachments = [];
        this.projectId = projectId;
        //File was clicked
        if (item.UID)
        {
            let ownerMail: string = '-';
            this.item = item as ModelFile;
            if (showAttachments)
            {
                this.selectedTab = 1;
            }
            this.getAttachments(selectedAttachmentId);
        }
        //Folder was clicked
        else
        {
            this.selectedTab = 0;
            this.item = item as GroupFolder;
        }
        //Set owner mail
        if (item.ownerId)
        {
            this.viewerApi.findById(item.ownerId).subscribe((viewer: Viewer) =>
            {
                this.item['ownerMail'] = viewer.email;
            });
        }
        else
        {
            this.item['ownerMail'] = '-';
        }
        this.isOpen = true;
    }

    getSearchURL()
    {
        let link = location.origin + '/all/project/';
        link += this.projectId !== undefined ? this.projectId + ';folder=' + this.item.childFileId : this.item.childFileId;
        link += '?filter=' + this.item.Name;
        return link;
    }

    close ()
    {
        if (this.confirmModal)
        {
            this.confirmModal.close();
        }
        if (this.simpleDialog)
        {
            this.simpleDialog.close();
        }
        this.isOpen = false;
    }

    getAttachments (preselectedAttachmentId?: number)
    {
        this.selection = [];
        this.allSelected = false;
        this.fileApi.getAttachments(this.item.id).subscribe((attachments: Attachment[]) =>
        {
            this.sort(attachments);
            if (preselectedAttachmentId)
            {
                let index = this.attachments.map((a: Attachment) => { return a.id }).indexOf(preselectedAttachmentId);
                if (index !== -1)
                {
                    this.selection = [this.attachments[index]];
                }
            }
        });
    }

    updateNote (event)
    {
        let api = this.item.UID ? this.fileApi : this.folderApi;
        api.patchAttributes(this.item.id, { Comment: event.target.value }).subscribe();
    }

    editAttachmentNote (attachment: Attachment)
    {
        this.attachmentInEdit = attachment;
        this.simpleDialog.open('NOTE', 'EDIT_NOTE', 'EditNote', attachment.Comment);
    }

    selectAttachment (attachment: Attachment)
    {
        if (this.selection.indexOf(attachment) === -1)
        {
            this.selection.push(attachment);
        }
        else
        {
            this.allSelected = false;
            this.selection.splice(this.selection.indexOf(attachment), 1);
        }
    }

    selectAll ()
    {
        if (!this.allSelected)
        {
            this.selection = this.attachments.slice();
        }
        else
        {
            this.selection = [];
        }
        this.allSelected = !this.allSelected
    }

    sortTable (category: string)
    {
        this.sortAsc = this.sortCat === category ? !this.sortAsc : false;
        this.sortCat = category;
        this.sort(this.attachments);
    }

    sort (attachmentsToSort: Attachment[])
    {
        this.attachments = attachmentsToSort.sort((a1: Attachment, a2: Attachment) =>
        {
            let val1, val2;
            if (this.sortCat === 'Size')
            {
                val1 = parseInt(a1[this.sortCat].toString());
                val2 = parseInt(a2[this.sortCat].toString());
            }
            else if (this.sortCat === 'Name' || this.sortCat === 'Comment')
            {
                val2 = a1[this.sortCat].toLowerCase().trim();
                val1 = a2[this.sortCat].toLowerCase().trim();
            }
            else
            {
                val1 = a1[this.sortCat];
                val2 = a2[this.sortCat];
            }
            if (val1 > val2)
            {
                return this.sortAsc ? 1 : -1;
            }
            else
            {
                return this.sortAsc ? -1 : 1;
            }
        });
    }

    uploadAttachment (event)
    {
        let attachemts = event.target.files;
        for (let i = 0; i < attachemts.length; i++)
        {
            this.singleUpload(attachemts[i]);
        }
        let target = event.target as HTMLInputElement;
        target.value = '';
    }

    singleUpload (file)
    {
        let name = file.name;
        let attachmentObj: AttachmentInterface = {
            Name: name,
            UID: undefined,
            Size: undefined
        };
        let formData = new FormData();
        //Check file etxentions for numeric endings and remove them (Creo)
        let uploadName: string;
        let splittedNameArr = file.name.split('.');
        let ext = splittedNameArr.pop();
        uploadName = !isNaN(ext) ? splittedNameArr.join('.') : file.name;
        formData.append('file', file, uploadName);
        let tempFile: UploadItem;
        let headers = new HttpHeaders({ "Authorization": this.viewerService.getAuthToken().id });
        let call = this.http.post(VSShareBackendUrl + '/api/Sources/temp/upload', formData, { headers }).subscribe((res: any) =>
        {
            this.removeTempFile(tempFile);
            //Create virtual file
            let savedFile = res.result.files.file[0];
            attachmentObj.UID = savedFile.name;
            attachmentObj.Size = savedFile.size;
            this.fileApi.createAttachments(this.item.id, attachmentObj).subscribe(resp =>
            {
                this.getAttachments();
            });
        }, err =>
        {
            //Remove placeholder file
            let visError: PortalError = {
                code: err.status,
                message: err.error.error.message,
                name: err.error.error.name
            };
            this.progressService.addError(visError);
        });
        //Add placeholder
        let timestamp = Date.now();
        tempFile = this.addTempFile({ name: file.name, uploadTime: timestamp, call: call });
        this.uploadPlaceholder.push(tempFile);
    }

    addTempFile (file: UploadItem): UploadItem
    {
        let id = this.uploadService.addItem(file);
        file.id = id;
        return file;
    }

    removeTempFile (file: UploadItem)
    {
        this.uploadPlaceholder = this.uploadPlaceholder.filter((item: UploadItem) =>
        {
            return item.id !== file.id;
        });
        this.uploadService.removeItem(file.id);
    }

    abortUpload (file: UploadItem)
    {
        file.call.unsubscribe();
        this.removeTempFile(file);
    }

    deleteAttachments ()
    {
        this.confirmModal.open('ATTACHMENT_DELETION','DELETE_ATTACHMENTS', 'DELETE');
    }

    setRights (newState: boolean, property: string)
    {
        let data = {};
        data[property] = newState;
        this.selection.forEach((attachment: Attachment) =>
        {
            this.fileApi.updateByIdAttachments(this.item.id, attachment.id, data).subscribe((a: Attachment) =>
            {
                attachment[property] = a[property];
            });
        });
    }

    openInViewer (attachment: Attachment)
    {
        let fileObj: ModelFileInterface = {
            id: this.item.id,
            Container: "repo",
            IsNative: this.item.IsNative,
            Name: attachment.Name,
            Path: " ",
            UID: attachment.UID,
            childFileId: this.item.childFileId,
            childFileType: this.item.childFileType
        };
        this.viewerService.openFile(fileObj).subscribe(token =>
        {
            this.fileService.addTab(fileObj, token.token);
        });
    }

    downloadAttachment (attachment: Attachment)
    {
        let headers = new HttpHeaders({
            "Authorization": this.viewerService.getAuthToken().id,
        });
        this.http.get(VSShareBackendUrl + '/api/Sources/repo/download/' + attachment.UID + '?isAttachment=true&attachmentId=' + attachment.id,
            { headers, responseType: 'blob' }).subscribe((res: any) =>
            {
                let link = document.createElement('a');
                link.href = URL.createObjectURL(res);
                link.download = attachment.Name;
                document.body.appendChild(link);
                link.click();
                link.remove();
                URL.revokeObjectURL(res);
            });
    }

    archiveAttachments ()
    {
        let headers = new HttpHeaders({
            "Authorization": this.viewerService.getAuthToken().id,
        });
        let secret = this.create_UUID();
        this.progressService.startLoadingScreen('START_ZIPPING');
        this.fileApi.archiveAttachments(this.item.id, { secret: secret }).subscribe(res =>
        {
            this.progressService.endLoadingScreen();
            let queryParams = '?id=' + this.item.id + '&secret=' + secret + '&hash=' + res.Response.Hash +'&isAttachment=true';
            this.http.get(VSShareBackendUrl + '/api/Sources/downloads/download/' + res.Response.Path + queryParams,
                { headers, responseType: 'blob' }).subscribe((res: any) =>
                {
                    let link = document.createElement('a');
                    link.href = URL.createObjectURL(res);
                    link.download = 'Attachments_' + this.item.Name + '.zip';
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    URL.revokeObjectURL(res);
                });
        });
    }

    create_UUID ()
    {
        var dt = Date.now();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) =>
        {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    OnDialogInput (event)
    {
        if (event.id === 'EditNote')
        {
            let comment = {
                Comment: event.value
            };
            this.fileApi.updateByIdAttachments(this.item.id, this.attachmentInEdit.id, comment).subscribe((attachment: Attachment) =>
            {
                this.attachmentInEdit.Comment = attachment.Comment;
            });
        }
    }

    OnConfirm (event: string)
    {
        if (event)
        {
            let calls: Observable<any>[] = [];
            this.selection.forEach((attachment: Attachment) =>
            {
               calls.push(this.fileApi.destroyByIdAttachments(this.item.id, attachment.id));
            });
            forkJoin(calls).subscribe(res =>
            {
                this.getAttachments();
            });
        }
    }

}
