import { Component, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { Viewer, Group, GroupApi, GroupViewerMappingApi, GroupViewerMapping } from 'src/app/shared/sdk';
import { FormControl } from '@angular/forms';
import { ViewerService } from 'src/app/services/viewer.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'app-group-admin-modal',
    templateUrl: './group-admin-modal.component.html',
    styleUrls: ['./group-admin-modal.component.scss']
})
export class GroupAdminModalComponent implements AfterViewInit
{

    @Input() activeViewer: Viewer;
    @Output() emitter = new EventEmitter<any>();

    isOpen: boolean = false;
    //Selection arrays
    private selectionRemaining: Viewer[] = [];
    private selectionDone: Viewer[] = [];

    private searchTextInGroup = new FormControl();
    private searchTextRemaining = new FormControl();
    private groupUserFilterString: string = '';
    private remainingUserFilterString: string = '';

    private selectedGroup: Group = undefined;

    //Administration variable
    private inAdminArr: Viewer[] = [];
    private unfilteredInAdminArr: Viewer[] = [];
    private noAdminArr: Viewer[] = [];
    private unfilteredNoAdminArr: Viewer[] = [];
    private removeAdminCallbackArr: Viewer[] = [];
    private addAdminCallbackArr: Viewer[] = [];

    constructor (private groupApi: GroupApi,
        private viewerService: ViewerService,
        private groupViewerApi: GroupViewerMappingApi) { }

    ngAfterViewInit ()
    {
        //Register onchange listener for the group search field
        this.searchTextInGroup.valueChanges.subscribe(val =>
        {
            this.groupUserFilterString = val;
            this.updateUIArray();
        });

        //Register onchange listener for the remaining search field
        this.searchTextRemaining.valueChanges.subscribe(val =>
        {
            this.remainingUserFilterString = val;
            this.updateUIArray();
        });
    }

    open (useTenants: boolean, group?: Group)
    {
        this.selectedGroup = group;
        let filter = {
            where: {
                IsDeleted: false
            }
        };
        let tenantId = undefined;
        if (useTenants)
        {
            tenantId = group.tenantId;
        }
        this.viewerService.getUserForGroup(tenantId, (group.IsPublic || group.IsShare), filter).subscribe((viewers: Viewer[]) =>
        {
            this.unfilteredNoAdminArr = viewers;
            this.getAdmins();
        });
        this.isOpen = true;
    }

    updateUIArray ()
    {
        this.inAdminArr = this.unfilteredInAdminArr.filter(item =>
        {
            let regEx = new RegExp(".*(" + this.groupUserFilterString.toLowerCase() + ").*");
            return regEx.test(item.email.toLowerCase());
        });

        this.noAdminArr = this.unfilteredNoAdminArr.filter(item =>
        {
            let regEx = new RegExp(".*(" + this.remainingUserFilterString.toLowerCase() + ").*");
            return regEx.test(item.email.toLowerCase());
        });
    }

    resetInGroupSearchString ()
    {
        this.searchTextInGroup.setValue('');
    }

    resetRemainingSearchString ()
    {
        this.searchTextRemaining.setValue('');
    }

    selectRemaining (e: MouseEvent, viewer: Viewer)
    {
        if (e.shiftKey)
        {
            this.applyShiftKeySelection(this.noAdminArr, this.selectionRemaining, viewer, e.ctrlKey);
        }
        else if (e.ctrlKey)
        {
            this.select(viewer, this.selectionRemaining)
        }
        else
        {
            this.selectionRemaining = [viewer];
        }
    }

    selectInGroup (e: MouseEvent, viewer: Viewer)
    {
        if (e.shiftKey)
        {
            this.applyShiftKeySelection( this.inAdminArr, this.selectionDone, viewer, e.ctrlKey);
        }
        else if (e.ctrlKey)
        {
            this.select(viewer, this.selectionDone)
        }
        else
        {
            this.selectionDone = [viewer];
        }
    }

    select (viewer: Viewer, selection: Viewer[])
    {
        if (selection.indexOf(viewer) === -1)
        {
            selection.push(viewer);
        }
        else
        {
            selection.splice(selection.indexOf(viewer), 1);
        }
    }

    keyDownInAdmin (e: KeyboardEvent)
    {
        if (e.keyCode === 39)
        {
            this.removeFromAdmin();
        }
        else if (e.keyCode === 40)
        {
            let lastSelectedElement = this.selectionDone.length === 0 ? this.inAdminArr[0] : this.selectionDone[this.selectionDone.length - 1];
            let index = this.inAdminArr.map((admin: Viewer) => { return admin.id }).indexOf(lastSelectedElement.id);
            if (index < this.inAdminArr.length - 1)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionDone.push(this.inAdminArr[index + 1]);
                }
                else
                {
                    this.selectionDone = [this.inAdminArr[index + 1]];
                }
            }
        }
        else if (e.keyCode === 38)
        {
            let lastSelectedElement = this.selectionDone.length === 0 ? this.inAdminArr[0] : this.selectionDone[this.selectionDone.length - 1];
            let index = this.inAdminArr.map((admin: Viewer) => { return admin.id }).indexOf(lastSelectedElement.id);
            let lastSelectableIndex = this.inAdminArr.length - this.inAdminArr.filter((admin: Viewer) =>
            {
                return !this.isOwner(admin) && admin.id !== this.activeViewer.id;
            }).length;
            if (index > lastSelectableIndex)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionDone.push(this.inAdminArr[index - 1]);
                }
                else
                {
                    this.selectionDone = [this.inAdminArr[index - 1]];
                }
            }
        }
        else if (e.ctrlKey && e.keyCode === 65)
        {
            e.preventDefault();
            this.selectAllAdmins();
        }
    }

    keyDownInRemaining (e?: KeyboardEvent)
    {
        if (e.keyCode === 37)
        {
            this.addToAdmin();
        }
        else if (e.keyCode === 40)
        {
            let lastSelectedElement = this.selectionRemaining.length === 0 ? this.noAdminArr[0] : this.selectionRemaining[this.selectionRemaining.length - 1];
            let index = this.noAdminArr.map((admin: Viewer) => { return admin.id }).indexOf(lastSelectedElement.id);
            if (index < this.noAdminArr.length - 1)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionRemaining.push(this.noAdminArr[index + 1]);
                }
                else
                {
                    this.selectionRemaining = [this.noAdminArr[index + 1]];
                }
            }
        }
        else if (e.keyCode === 38)
        {
            let lastSelectedElement = this.selectionRemaining.length === 0 ? this.noAdminArr[0] : this.selectionRemaining[this.selectionRemaining.length - 1];
            let index = this.noAdminArr.map((admin: Viewer) => { return admin.id }).indexOf(lastSelectedElement.id);
            if (index > 0)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionRemaining.push(this.noAdminArr[index - 1]);
                }
                else
                {
                    this.selectionRemaining = [this.noAdminArr[index - 1]];
                }
            }
        }
        else if (e.ctrlKey && e.keyCode === 65)
        {
            e.preventDefault();
            this.selectAllNonAdmins();
        }
    }

    selectAllAdmins ()
    {
        this.selectionDone = this.inAdminArr.filter((admin: Viewer) =>
        {
            return !this.isOwner(admin) && admin.id !== this.activeViewer.id;
        });
    }

    selectAllNonAdmins ()
    {
        this.selectionRemaining = this.noAdminArr;
    }

    applyShiftKeySelection (arr: Viewer[], selection: Viewer[] ,viewer: Viewer, ctrlKeyClicked: boolean)
    {
        let selectionLength = selection.length;
        let lastSelectedElement = selection[selectionLength - 1];
        if (!ctrlKeyClicked && selectionLength > 1)
        {
            selection.splice(0, selectionLength - 2);
        }
        //Get start index
        let startIndex: number;
        for (let i = 0; i < arr.length; i++)
        {
            if (arr[i].id === lastSelectedElement.id)
            {
                startIndex = i;
            }
        }
        //Get end index
        let endIndex: number;
        for (let j = 0; j < arr.length; j++)
        {
            if (arr[j].id === viewer.id)
            {
                endIndex = j;
            }
        }
        //Select forward
        //Case group is first and last selection
        if (startIndex <= endIndex)
        {
            //Select groups till endindex
            for (let i = startIndex + 1; i < endIndex + 1; i++)
            {
                this.select(arr[i], selection);
            }
        }
        //Select backwards
        //Case group is first and last selection
        else if (endIndex < startIndex)
        {
            //Select groups
            for (let i = startIndex - 1; i >= endIndex; i--)
            {
                this.select(arr[i], selection);
            }
        }
    }

    close (update: boolean)
    {
        if (this.isOpen)
        {
            this.emitter.emit(update);
            this.isOpen = false;
        }
    }

    submitgroup ()
    {
        this.updateAdminRights();
        this.close(false);
    }

    //Administration
    isOwner (viewer: Viewer)
    {
        if (this.selectedGroup)
        {
            return this.selectedGroup.viewerId === viewer.id;
        }
        else
        {
            return false;
        }
    }

    isAdmin (viewer: Viewer)
    {
        return this.unfilteredInAdminArr.map(item => { return item.id }).indexOf(viewer.id) !== -1 && !this.isOwner(viewer);
    }

    getAdmins ()
    {
        this.unfilteredInAdminArr = [];
        let tmpArr = this.unfilteredNoAdminArr.slice();
        this.unfilteredNoAdminArr = [];
        let filter = {
            where: {
                groupId: this.selectedGroup.id,
                adminRights: true
            }
        };
        this.groupViewerApi.find(filter).subscribe((mappings: GroupViewerMapping[]) =>
        {
            for (let i = 0; i < tmpArr.length; i++)
            {
                let state = mappings.map(item => { return item.viewerId }).indexOf(tmpArr[i].id);
                if (state === -1)
                {
                    if (this.isOwner(tmpArr[i]))
                    {
                        this.unfilteredInAdminArr.unshift(tmpArr[i]);
                    }
                    else
                    {
                        this.unfilteredNoAdminArr.push(tmpArr[i]);
                    }
                }
                else
                {
                    this.unfilteredInAdminArr.push(tmpArr[i]);
                }
            }
            this.noAdminArr = this.unfilteredNoAdminArr.slice();
            this.inAdminArr = this.unfilteredInAdminArr.slice();
        });
    }

    addToAdmin ()
    {
        this.unfilteredInAdminArr = this.unfilteredInAdminArr.concat(this.selectionRemaining);
        for (let i = 0; i < this.selectionRemaining.length; i++)
        {
            let addEl = this.unfilteredNoAdminArr.splice(this.unfilteredNoAdminArr.indexOf(this.selectionRemaining[i]), 1)[0];
            this.addAdminCallbackArr.push(addEl);
            if (this.removeAdminCallbackArr.indexOf(addEl) !== -1)
            {
                this.removeAdminCallbackArr.splice(this.removeAdminCallbackArr.indexOf(addEl));
            }
        }
        this.selectionRemaining = [];
        this.updateUIArray();
    }

    removeFromAdmin ()
    {
        this.unfilteredNoAdminArr = this.unfilteredNoAdminArr.concat(this.selectionDone);
        for (let i = 0; i < this.selectionDone.length; i++)
        {
            let remEl = this.unfilteredInAdminArr.splice(this.unfilteredInAdminArr.indexOf(this.selectionDone[i]), 1)[0];
            this.removeAdminCallbackArr.push(remEl);
            if (this.addAdminCallbackArr.indexOf(remEl) !== -1)
            {
                this.addAdminCallbackArr.splice(this.addAdminCallbackArr.indexOf(remEl));
            }
        }
        this.selectionDone = [];
        this.updateUIArray();
    }

    updateAdminRights ()
    {
        let calls: Observable<any>[] = [];
        for (let i = 0; i < this.addAdminCallbackArr.length; i++)
        {
            let attribute = {
                adminRights: true,
                writeAccess: true,
                viewerId: this.addAdminCallbackArr[i].id,
                groupId: this.selectedGroup.id
            };
            let filter = {
                viewerId: this.addAdminCallbackArr[i].id,
                groupId: this.selectedGroup.id
            };
            calls.push(this.groupViewerApi.upsertWithWhere(filter, attribute));
        }
        for (let j = 0; j < this.removeAdminCallbackArr.length; j++)
        {
            let attribute = {
                adminRights: false
            };
            let filter = {
                viewerId: this.removeAdminCallbackArr[j].id,
                groupId: this.selectedGroup.id
            };
            calls.push(this.groupViewerApi.upsertWithWhere(filter, attribute));
        }
        forkJoin(calls).subscribe();
    }

    promoteToOwner ()
    {
        let attribute = {
            adminRights: true,
            writeAccess: true,
            viewerId: this.activeViewer.id,
            groupId: this.selectedGroup.id
        };
        let filter = {
            viewerId: this.activeViewer.id,
            groupId: this.selectedGroup.id
        };
        this.groupViewerApi.upsertWithWhere(filter, attribute).subscribe(res =>
        {
            this.groupApi.patchAttributes(this.selectedGroup.id, { viewerId: this.selectionDone[0].id }).subscribe(() =>
            {
                this.close(true);
            });
        });
    }
}
