/* tslint:disable */
import { Injectable } from '@angular/core';
import { User } from '../../models/User';
import { RoleMapping } from '../../models/RoleMapping';
import { Role } from '../../models/Role';
import { Group } from '../../models/Group';
import { AppRole } from '../../models/AppRole';
import { ModelFile } from '../../models/ModelFile';
import { Viewer } from '../../models/Viewer';
import { Source } from '../../models/Source';
import { GroupViewerMapping } from '../../models/GroupViewerMapping';
import { GroupFolder } from '../../models/GroupFolder';
import { Settings } from '../../models/Settings';
import { SharedFile } from '../../models/SharedFile';
import { ImageData } from '../../models/ImageData';
import { Email } from '../../models/Email';
import { ViewerVerification } from '../../models/ViewerVerification';
import { RemoteShare } from '../../models/RemoteShare';
import { RootFolder } from '../../models/RootFolder';
import { RemoteInvite } from '../../models/RemoteInvite';
import { ConvService } from '../../models/ConvService';
import { ConvJob } from '../../models/ConvJob';
import { SearchSettings } from '../../models/SearchSettings';
import { Tenant } from '../../models/Tenant';
import { TenantViewerMapping } from '../../models/TenantViewerMapping';
import { GroupSync } from '../../models/GroupSync';
import { Theme } from '../../models/Theme';
import { Chat } from '../../models/Chat';
import { ChatEntry } from '../../models/ChatEntry';
import { LogEvent } from '../../models/LogEvent';
import { ChatView } from '../../models/ChatView';
import { ChatImage } from '../../models/ChatImage';
import { Attachment } from '../../models/Attachment';
import { ChatFileView } from '../../models/ChatFileView';
import { ZipJob } from '../../models/ZipJob';
import { AssemblyMap } from '../../models/AssemblyMap';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    User: User,
    RoleMapping: RoleMapping,
    Role: Role,
    Group: Group,
    AppRole: AppRole,
    ModelFile: ModelFile,
    Viewer: Viewer,
    Source: Source,
    GroupViewerMapping: GroupViewerMapping,
    GroupFolder: GroupFolder,
    Settings: Settings,
    SharedFile: SharedFile,
    ImageData: ImageData,
    Email: Email,
    ViewerVerification: ViewerVerification,
    RemoteShare: RemoteShare,
    RootFolder: RootFolder,
    RemoteInvite: RemoteInvite,
    ConvService: ConvService,
    ConvJob: ConvJob,
    SearchSettings: SearchSettings,
    Tenant: Tenant,
    TenantViewerMapping: TenantViewerMapping,
    GroupSync: GroupSync,
    Theme: Theme,
    Chat: Chat,
    ChatEntry: ChatEntry,
    LogEvent: LogEvent,
    ChatView: ChatView,
    ChatImage: ChatImage,
    Attachment: Attachment,
    ChatFileView: ChatFileView,
    ZipJob: ZipJob,
    AssemblyMap: AssemblyMap,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
