import { Component, OnInit, ViewChild, NgZone, HostListener } from '@angular/core';
import { Group, GroupApi, GroupViewerMapping, Viewer, RootFolderApi, RootFolder, Settings, GroupViewerMappingApi, Tenant } from '../shared/sdk';
import { ViewerService } from '../services/viewer.service';
import { GroupModalComponent } from './group-modal/group-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { GalleryContextMenuComponent } from './gallery-context-menu/gallery-context-menu.component';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { UploadBarComponent } from '../widgets/upload-bar/upload-bar.component';
import { SimpleDialogComponent } from '../widgets/simple-dialog/simple-dialog.component';
import { RemoteModalComponent } from './remote-modal/remote-modal.component';
import { FormControl } from '@angular/forms';
import { GroupAdminModalComponent } from './group-admin-modal/group-admin-modal.component';
import { ConfirmModalDialogComponent } from '../widgets/confirm-modal-dialog/confirm-modal-dialog.component';
import { SettingsService } from '../services/settings.service';
import { GlobalEventService, VisKeyboardEvent } from '../services/global-event.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProgressService, PortalError } from '../services/progress.service';
declare var HidePrivate: boolean;
declare var HideMailLinks: boolean;
declare var VSShareBackendUrl: any;
declare var OrderProjectsAlphabetic: boolean;

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit
{
    @ViewChild(GalleryContextMenuComponent)
    private ctxMenu: GalleryContextMenuComponent;

    @ViewChild(GroupModalComponent)
    private groupModal: GroupModalComponent;

    @ViewChild(GroupAdminModalComponent)
    private groupAdminModal: GroupAdminModalComponent;

    @ViewChild(UploadBarComponent)
    private uploadBar: UploadBarComponent;

    @ViewChild(SimpleDialogComponent)
    private simpleDialog: SimpleDialogComponent;

    @ViewChild(RemoteModalComponent)
    private remoteModal: RemoteModalComponent;

    @ViewChild(ConfirmModalDialogComponent)
    private confirmModal: ConfirmModalDialogComponent;


    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;
    showMobileSearch: boolean = false;
    //Search bar
    searchText = new FormControl();
    private searchString = "";

    activeViewer: Viewer
    rootFolders: RootFolder[] = [];
    groups: Group[] = [];
    showTeams: boolean = false;
    showPersonal: boolean = false;
    showAll: boolean = false;
    groupsLoaded: boolean = false;
    private privateGroup: Group;
    rootFolderId: number;
    activeRootFolder: RootFolder;
    private tenants: Tenant[];

    //UI visibility
    hidePrivate: boolean = false;
    hideLinks: boolean = false;

    //iOs detection
    isiOs: boolean = false;

    //Selection
    selection: any[] = [];
    groupsInEdit: Group[] = [];
    hoveredGroup: Group;
    hoveredFolder: RootFolder;

    //Admin groups
    adminGroups: Group[] = [];

    private wrapper: HTMLElement;
    selectedFolder: RootFolder;
    ownedFolders: RootFolder[] = [];

    //Settings
    settings: Settings;
    private settingsSubscription: Subscription;

    private viewerSubscription: Subscription;
    private globalEventSubscription: Subscription;

    @HostListener('window:keydown', ['$event'])
    onKeyDown (e: KeyboardEvent)
    {
        if (this.groupAdminModal.isOpen)
        {
            return;
        }
        if (e.ctrlKey && e.keyCode === 65 && !this.groupModal.isOpen && !this.groupAdminModal.isOpen)
        {
            e.preventDefault();
            this.selection = this.groups.slice();
            this.ownedFolders.forEach((folder: RootFolder) =>
            {
                this.selection.push(folder);
            });
        }
    }

    constructor (private route: ActivatedRoute,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private viewerService: ViewerService,
        private groupApi: GroupApi,
        private ngZone: NgZone,
        private rootFolderApi: RootFolderApi,
        private settingsService: SettingsService,
        private eventService: GlobalEventService,
        private http: HttpClient,
        private progressService: ProgressService,
        private groupViewerApi: GroupViewerMappingApi)
    {
    }

    ngOnInit ()
    {
        try
        {
            this.hidePrivate = HidePrivate;
        }
        catch (e) { }
        try
        {
            this.hideLinks = HideMailLinks;
        }
        catch (e) { }
        this.selection = [];
        this.groupsInEdit = [];
        this.isiOs = /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        this.route.params.subscribe(params =>
        {
            let category = params.category ? params.category : this.route.routeConfig.path;
            switch (category)
            {
                case 'projects':
                    this.showTeams = true;
                    break;
                case 'private':
                    this.showPersonal = true;
                    break;
                case 'all':
                    this.showAll = true;
                    break;
            }
            if (params.rootFolderId)
            {
                this.rootFolderId = parseInt(params.rootFolderId);
                this.rootFolderApi.findById(this.rootFolderId).subscribe((rf: RootFolder) =>
                {
                    this.activeRootFolder = rf;
                });
            }
            else
            {
                this.rootFolderId = undefined;
                this.activeRootFolder = undefined;
            }
        });

        this.viewerSubscription = this.viewerService.currentViewer.subscribe((viewer: Viewer) =>
        {
            if (viewer.id && ((this.activeViewer && this.activeViewer.id !== viewer.id) || !this.activeViewer))
            {
                this.getGroups();
                if (this.isMobile)
                {
                    this.viewerService.getTenants().subscribe((tenants: Tenant[]) =>
                    {
                        this.tenants = tenants;
                    });
                }
            }
            this.activeViewer = viewer;
        });

        this.settingsSubscription = this.settingsService.settings.subscribe((settings: Settings) =>
        {
            this.settings = settings;
        });

        this.globalEventSubscription = this.eventService.keyboardEvent.subscribe((key: VisKeyboardEvent) =>
        {
            if (key === VisKeyboardEvent.ESC)
            {
                this.groupModal.close();
                this.remoteModal.close();
                this.groupAdminModal.close(false);
                this.simpleDialog.close();
            }
        });

        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });

        this.wrapper = document.getElementById('gallery-wrapper');
        this.wrapper.oncontextmenu = e =>
        {
            this.openContextMenu(e);
        }

        this.ngZone.runOutsideAngular(() =>
        {
            this.wrapper.addEventListener('dragover', (e) =>
            {
                if ($(e.target).hasClass('root-folder'))
                {
                    e.preventDefault();
                }
            })
        });

        //Register onchange listener for the search field
        this.searchText.valueChanges.subscribe(val =>
        {
            this.searchString = val;
            this.getGroups();
        });
    }

    ngOnDestroy ()
    {
        this.viewerSubscription.unsubscribe();
        this.settingsSubscription.unsubscribe();
        this.globalEventSubscription.unsubscribe();
        this.layoutSubscription.unsubscribe();
    }

    openContextMenu (event)
    {
        if (!this.isModalOpen())
        {
            event.preventDefault();
            event.stopPropagation();
            if (this.activeViewer && this.activeViewer['Verified'])
            {
                let hideLeave: boolean = false;
                if (this.selection.length < 2 && (this.hoveredFolder || this.hoveredGroup))
                {
                    if (this.hoveredGroup)
                    {
                        this.selection = [this.hoveredGroup];
                    }
                    else
                    {
                        this.selection = [this.hoveredFolder];
                    }
                }
                this.selection.forEach(item =>
                {
                    if (item.IsPublic || item.IsShare)
                    {
                        hideLeave = true;
                    }
                });
                this.ctxMenu.showContextMenu(this.selection, hideLeave, this.rootFolderId ? true : false, this.isMobile ? undefined : event);
                this.groupsInEdit = this.selection.slice();
            }
        }
    }

    isModalOpen (): boolean
    {
        return this.groupModal.isOpen || this.remoteModal.isOpen || this.confirmModal.isOpen || this.groupAdminModal.isOpen || this.simpleDialog.isOpen;
    }

    resetSearchTextString ()
    {
        this.searchText.setValue('');
    }

    getGroups ()
    {
        this.groups = [];
        let filter = {
            where: {
                Name: {
                    ilike: '%' + this.searchString + '%'
                }
            }
        };
        try
        {
            if (OrderProjectsAlphabetic)
            {
                filter['order'] = 'Name ASC';
            }
        }
        catch (e)
        {
        }
        //Get root folder
        if (!this.rootFolderId)
        {
            this.rootFolderApi.find(filter).subscribe((folders: RootFolder[]) =>
            {
                this.rootFolders = folders.filter(item =>
                {
                    let check = item.ViewerId === this.activeViewer.id;
                    if (check)
                    {
                        this.ownedFolders.push(item);
                    }
                    return check;
                });
                this.checkGroups(folders, filter);
            })
        }
        else
        {
            this.checkGroups([], filter);
        }
    }

    checkGroups (folders: RootFolder[], filter)
    {
        //Get groups
        this.groupApi.find(filter).subscribe((groups: Group[]) =>
        {
            this.groups = [];
            let publicGroups = [];
            for (let i = 0; i < groups.length; i++)
            {
                let group = groups[i];
                if ((!group.IsPrivate) && (this.showTeams || this.showAll))
                {
                    if (group.rootFolderId && group.rootFolderId > 0 && !this.rootFolderId)
                    {
                        this.checkFolder(folders, group);
                    }
                    else
                    {
                        if (this.rootFolderId && group.rootFolderId !== this.rootFolderId)
                        {
                            continue;
                        }
                        else
                        {
                            if (group.IsPublic)
                            {
                                publicGroups.unshift(group);
                            }
                            else if (group.IsShare)
                            {
                                publicGroups.push(group);
                            }
                            else
                            {
                                let accessFilter =
                                {
                                    where:
                                    {
                                        viewerId: this.activeViewer.id,
                                        groupId: group.id
                                    }
                                };
                                this.groupViewerApi.find(accessFilter).subscribe((map: GroupViewerMapping[]) =>
                                {
                                    if (map && map.length > 0 && map[0].writeAccess)
                                    {
                                        group['hasWriteAccess'] = true;
                                    }
                                })
                                this.groups.push(group);
                            }
                        }
                    }
                }
                else if (group.IsPrivate)
                {
                    this.privateGroup = group;
                }
            }
            this.groups = publicGroups.concat(this.groups);
            //Jump into a group if only 1 exists
            if (this.showTeams && this.groups.length === 1 && !this.rootFolderId)
            {
                this.router.navigate(['projects', 'project', this.groups[0].id]);
            }
            this.groupsLoaded = true;
        });

        //Get administrable groups
        forkJoin(this.viewerService.getAdministrableGroups()).subscribe((groups: [Group[], GroupViewerMapping[]]) =>
        {
            this.adminGroups = groups[0];
            this.adminGroups.forEach((group: Group) =>
            {
                this.setAdminFlag(group);
            });
            for (let i = 0; i < groups[1].length; i++)
            {
                this.groupApi.findById(groups[1][i].groupId).subscribe((group: Group) =>
                {
                    this.adminGroups.push(group);
                    this.setAdminFlag(group);
                });
            }
        });
    }

    setAdminFlag (group: Group)
    {
        let index = this.groups.map((g: Group) =>
        {
            return g.id;
        }).indexOf(group.id);
        if (index !== -1)
        {
            this.groups[index]['isAdmin'] = true;
        }
    }

    checkFolder (folders: RootFolder[], group: Group)
    {
        //Add the folder if a group is inside
        let newFolder = folders.filter(item =>
        {
            return item.id === group.rootFolderId && this.rootFolders.map(f => { return f.id; }).indexOf(item.id) === -1;
        })[0];
        if (newFolder)
        {
            this.rootFolders.push(newFolder);
        }
    }

    createGroup ()
    {
        this.groupModal.open(this.settings.UseTenants);
    }

    duplicateProject()
    {
        let count = this.groupsInEdit.length;
        for (let i = 0; i < this.groupsInEdit.length; i++)
        {
            this.groupApi.duplicateProject(this.groupsInEdit[i].id).subscribe(res =>
            {
                count--;
                if (count === 0)
                {
                    this.getGroups();
                }
            });
        }
    }

    openAdminModal ()
    {
        this.groupAdminModal.open(this.settings.UseTenants, this.groupsInEdit[0]);
    }

    manageGroup ()
    {
        this.groupModal.open(this.settings.UseTenants, this.groupsInEdit[0]);
    }

    deleteGroup ()
    {
        let calls: Observable<any>[] = [];
        for (let i = 0; i < this.groupsInEdit.length; i++)
        {
            calls.push(this.groupApi.deleteById(this.groupsInEdit[i].id));
        }
        forkJoin(calls).subscribe(res =>
        {
            this.getGroups();
        });
    }

    leaveGroup ()
    {
        let calls: Observable<any>[] = [];
        for (let i = 0; i < this.groupsInEdit.length; i++)
        {
            calls.push(this.viewerService.leaveGroup(this.groupsInEdit[i]));
        }
        forkJoin(calls).subscribe(res =>
        {
            this.getGroups();
        });
    }

    createFolder ()
    {
        this.simpleDialog.open('ADD_PROJECT_FOLDER', 'NAME', 'FolderCreation');
    }

    createMobileGroup ()
    {
        this.simpleDialog.open('ADD_PROJECT', 'NAME', 'GroupCreation');
    }

    renameFolder ()
    {
        this.simpleDialog.open('RENAME_FOLDER', 'NAME', 'FolderRename', this.selection[0].Name);
    }

    dissolveFolder ()
    {
        let selectedFolder = this.selection.filter(item =>
        {
            return item.IsPrivate === undefined;
        });
        let calls: Observable<any>[] = [];
        for (let i = 0; i < selectedFolder.length; i++)
        {
            let id = selectedFolder[i].id;
            calls.push(this.rootFolderApi.deleteById(id));
        }
        forkJoin(calls).subscribe(res =>
        {
            this.getGroups();
        });
    }

    removeFromFolder ()
    {
        let calls: Observable<any>[] = [];
        for (let i = 0; i < this.selection.length; i++)
        {
            calls.push(this.groupApi.patchAttributes(this.selection[i].id, { rootFolderId: null }));
        }
        forkJoin(calls).subscribe(res =>
        {
            this.getGroups();
        });
    }

    changeProjectType (type: number)
    {
        let attribute = {
            IsPublic: type === 0,
            IsShare: type === 1
        };
        let calls: Observable<any>[] = [];
        for (let i = 0; i < this.selection.length; i++)
        {
            calls.push(this.groupApi.patchAttributes(this.selection[i].id, attribute));
        }
        forkJoin(calls).subscribe(res =>
        {
            this.getGroups();
        });
    }

    //Dragging
    dragStart (event: DragEvent, group)
    {
        if (group)
        {
            let index = this.selection.indexOf(group);
            if (index === -1)
            {
                this.selection.push(group);
            }
        }
        $(event.target).addClass('group-in-drag');
    }

    dragEnd (event: DragEvent)
    {
        $(event.target).removeClass('group-in-drag');
    }

    dragEnter (event: DragEvent)
    {
        $(event.target).addClass('drag-over');
    }

    dragLeave (event: DragEvent)
    {
        $(event.target).removeClass('drag-over');
    }

    onFolderDrop (event, folder: RootFolder)
    {
        $(event.target).removeClass('drag-over');
        let calls: Observable<any>[] = [];
        let noRights: boolean = false;
        for (let i = 0; i < this.selection.length; i++)
        {
            if (this.selection[i].IsPrivate)
            {
                continue;
            }
            if (this.adminGroups.map((g: Group) => { return g.id }).indexOf(this.selection[i].id) === -1)
            {
                noRights = true;
            }
            else
            {
                calls.push(this.groupApi.patchAttributes(this.selection[i].id, { rootFolderId: folder.id }));
            }
        }
        forkJoin(calls).subscribe(res =>
        {
            this.getGroups();
        });
        if (noRights)
        {
            let error: PortalError = {
                code: -1,
                message: 'NO_ACCESS_FOR_FOLDER_MOVE',
                name: 'AccessDenied'
            };
            this.progressService.addError(error);
        }
    }

    //Selection
    groupClicked (group: Group, e: MouseEvent)
    {
        if (this.ctxMenu.isOpen)
        {
            return;
        }
        if (e.ctrlKey)
        {
            this.select(group, e);
        }
        else if (e.shiftKey)
        {
            //No previous selection
            if (this.selection.length === 0)
            {
                this.select(group, e);
            }
            this.applyShiftKeySelection(this.groups, group, e);
        }
        else
        {
            this.router.navigate(['project', group.id], { relativeTo: this.activatedRoute });
        }
    }

    folderClicked (folder: RootFolder, e: MouseEvent)
    {
        e.preventDefault();
        e.stopPropagation();
        if (e.ctrlKey)
        {
            this.select(folder, e);
        }
        else if (e.shiftKey)
        {
            //No previous selection
            if (this.selection.length === 0)
            {
                this.select(folder, e);
            }
            this.applyShiftKeySelection(this.rootFolders, folder, e);

        }
        else
        {
            this.router.navigate(['folder', folder.id], { relativeTo: this.activatedRoute });
        }
    }

    applyShiftKeySelection (arr: any[], obj: any, e: MouseEvent)
    {
        let selectionLength = this.selection.length;
        let lastSelectedElement = this.selection[selectionLength - 1];
        let wasFolderSelected = lastSelectedElement.IsPrivate === undefined;
        let isFolderSelected = obj.IsPrivate === undefined;
        //Get start index
        let startIndex: number;
        let searchArray = wasFolderSelected ? this.rootFolders : this.groups;
        for (let i = 0; i < searchArray.length; i++)
        {
            if (searchArray[i].id === lastSelectedElement.id)
            {
                startIndex = i;
            }
        }
        //Get end index
        let endIndex: number;
        for (let j = 0; j < arr.length; j++)
        {
            if (arr[j].id === obj.id)
            {
                endIndex = j;
            }
        }
        //Select forward
        //Case group is first and last selection
        if (startIndex <= endIndex && !wasFolderSelected && !isFolderSelected)
        {
            //Select groups till endindex
            for (let g = startIndex + 1; g < endIndex + 1; g++)
            {
                this.select(this.groups[g], e);
            }
        }
        //Case group is first and folder last selection
        else if (!wasFolderSelected && isFolderSelected)
        {
            //Select all remaining groups
            for (let g = startIndex + 1; g < this.groups.length; g++)
            {
                this.select(this.groups[g], e);
            }
            //Select folders
            for (let f = 0; f < endIndex + 1; f++)
            {
                this.select(this.rootFolders[f], e);
            }
        }
        //Case first and last selection are folder
        else if (startIndex <= endIndex && wasFolderSelected && isFolderSelected)
        {


            //Select folders
            for (let f = startIndex + 1; f < endIndex + 1; f++)
            {
                this.select(this.rootFolders[f], e);
            }
        }
        //Select backwards
        //Case group is first and last selection
        else if (endIndex < startIndex && !wasFolderSelected && !isFolderSelected)
        {
            //Select groups
            for (let g = startIndex - 1; g >= endIndex; g--)
            {
                this.select(this.groups[g], e);
            }
        }
        //Case folder is first and group last selection
        else if (wasFolderSelected && !isFolderSelected)
        {
            //Select all remaining folders
            for (let f = startIndex - 1; f >= 0; f--)
            {
                this.select(this.rootFolders[f], e);
            }
            //Select groups
            for (let g = this.groups.length - 1; g >= endIndex; g--)
            {
                this.select(this.groups[g], e);
            }
        }
        //Case folder is first and last selection
        else if (endIndex <= startIndex && wasFolderSelected && isFolderSelected)
        {
            for (let f = startIndex - 1; f >= endIndex; f--)
            {
                this.select(this.rootFolders[f], e);
            }
        }
    }

    select (obj, e?: MouseEvent)
    {
        if (e)
        {
            e.preventDefault();
            e.stopPropagation();
        }
        let index = this.selection.indexOf(obj);
        if (index === -1)
        {
            this.selection.push(obj);
        }
        else
        {
            this.selection.splice(index, 1);
        }
    }

    resetSelection (e: MouseEvent)
    {
        this.selection = [];
    }

    download (includeAttachments: boolean = false)
    {
        let headers = new HttpHeaders({
            "Authorization": this.viewerService.getAuthToken().id,
        });
        let secret = this.create_UUID();
        this.progressService.startLoadingScreen('START_ZIPPING');
        let project = this.selection[0];
        this.viewerService.downloadProject(project.id, secret, includeAttachments).subscribe(res =>
        {
            this.progressService.endLoadingScreen();
            let queryParams = '?project=' + project.id + '&id=' + project.id + '&secret=' + secret + '&hash=' + res.Response.Hash;
            this.http.get(VSShareBackendUrl + '/api/Sources/downloads/download/' + res.Response.Path + queryParams,
                { headers, responseType: 'blob' }).subscribe((res: any) =>
                {
                    let link = document.createElement('a');
                    link.href = URL.createObjectURL(res);
                    link.download = project.Name + '.zip';
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    URL.revokeObjectURL(res);
                });
        });
    }

    create_UUID ()
    {
        var dt = Date.now();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) =>
        {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    OnClose (event: Group)
    {
        if (this.rootFolderId)
        {
            let group = event;
            this.groupApi.patchAttributes(group.id, { rootFolderId: this.rootFolderId }).subscribe(res =>
            {
                this.getGroups();
            });
        }
        else
        {
            this.getGroups();
        }
    }

    OnAdminClose (event)
    {
        if (event && event === true)
        {
            this.getGroups();
        }
    }

    OnContextSelection (contextOption: any)
    {
        switch (contextOption)
        {
            case 'ManageGroup':
                this.manageGroup();
                break;
            case 'ManageAdmins':
                this.openAdminModal();
                break;
            case 'AddGroup':
                this.createGroup();
                break;
            case 'DuplicateProject':
                this.duplicateProject();
                break;
            case 'AddMobileGroup':
                if (this.tenants && this.tenants.length === 0 && this.settings && this.settings.UseTenants)
                {
                    let error: PortalError = {
                        code: -1,
                        message: 'PROJECT_CREATION_WITHOUT_USER_POOL',
                        name: 'MissingUserPoolError'
                    };
                    this.progressService.addError(error);
                }
                else
                {
                    this.createMobileGroup();
                }
                break;
            case 'DeleteGroup':
                if (this.groupsInEdit.length > 1)
                {
                    this.confirmModal.open("DELETE_PROJECT", "MULTIPLE_PROJECT_DELETION_CONFIRM", "DELETE");
                }
                else
                {
                    this.confirmModal.open("DELETE_PROJECT", "SINGLE_PROJECT_DELETION_CONFIRM", "DELETE");
                }
                break;
            case 'LeaveGroup':
                this.leaveGroup();
                break;
            case 'RenameFolder':
                this.renameFolder();
                break;
            case 'DissolveFolder':
                this.dissolveFolder();
                break;
            case 'RemoveFromFolder':
                this.removeFromFolder();
                break;
            case 'CreateFolder':
                this.createFolder();
                break;
            case 'InviteRemote':
                this.remoteModal.open(this.groupsInEdit[0]);
                break;
            case 'SetPublic':
                this.changeProjectType(0);
                break;
            case 'SetShowcase':
                this.changeProjectType(1);
                break;
            case 'SetRestricted':
                this.changeProjectType(2);
                break;
            case 'Download':
                this.download(false);
                break;
            case 'DownloadWithAttachments':
                this.download(true);
                break;
        }
    }

    OnDialogInput (event)
    {
        if (event.id === 'FolderCreation')
        {
            let name = event.value;
            this.rootFolderApi.create({ Name: name }).subscribe((folder: RootFolder) =>
            {
                this.getGroups();
            });
        }
        else if (event.id === 'FolderRename')
        {
            let name = {
                Name: event.value
            };
            this.rootFolderApi.patchAttributes(this.selection[0].id, name).subscribe(() =>
            {
                this.getGroups();
            });
        }
        else if (event.id === 'GroupCreation')
        {
            let name = event.value;
            let group = {
                Image: "",
                Name: name,
                IsPrivate: false,
                IsPublic: false,
                IsShare: false,
                tenantId: (this.tenants && this.tenants.length !== 0) ? this.tenants[0].id : -1
            };
            this.viewerService.createGroup(group).subscribe((newGroup: Group) =>
            {
                this.groupsInEdit = [newGroup];
                this.manageGroup();
            });
        }
    }

    OnConfirm (event: boolean)
    {
        if (event)
        {
            this.deleteGroup();
        }
    }
}
