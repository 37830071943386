import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewerApi } from '../shared/sdk';

@Component({
  selector: 'app-login-or-register',
  templateUrl: './login-or-register.component.html',
  styleUrls: ['./login-or-register.component.scss']
})
export class LoginOrRegisterComponent implements AfterViewInit {

    constructor(private route: ActivatedRoute, private viewerApi: ViewerApi, private router: Router) { }

    ngAfterViewInit()
    {
        this.viewerApi.getCurrent().subscribe(user =>
        {
            this.router.navigate(['/all']);
        }, err =>
        {
            let mail: string = this.route.snapshot.queryParams['mail'];
            if (mail)
            {
                this.viewerApi.login({ email: mail.toLowerCase(), password: '' }).subscribe((token: any) =>
                {
                    //Will never get reached
                }, err =>
                {
                    if (err.code === "EMAIL_UNKNOWN")
                    {
                        this.router.navigate(['/register'], {queryParamsHandling: 'preserve'});
                    }
                    else
                    {
                        this.router.navigate(['/login'], { queryParamsHandling: 'preserve'});
                    }
                });
            }
            else
            {
                console.log('No mail provided');
            }
        });
    }

}
