import { Injectable } from "@angular/core";
import { ModelFile, ModelFileInterface, Settings, Viewer } from "../shared/sdk";
import { ProgressService, PortalError } from "./progress.service";
import { SettingsService } from "./settings.service";
declare var WebViewerClientUrlOverride;

export interface ModelMapping
{
    token: string,
    tab: Window,
    model: string
}

@Injectable()
export class FileService
{

  
    private modelMap: ModelMapping[] = []; 
    private clipboard: ModelFile[] = [];
    private cut: boolean = false;
    private settings: Settings;
    private activeViewer: Viewer;
    private remoteUser: boolean = false;
    constructor (
        private settingsService: SettingsService,
        private progressService: ProgressService
    )
    {
        this.settingsService.settings.subscribe((settings: Settings) =>
        {
            this.settings = settings;
        });
    }
  
    addTab (file: ModelFile | ModelFileInterface, token: string)
    {
        let url: string = this.settings.WebViewerClientUrl;
        try
        {
            if (WebViewerClientUrlOverride && WebViewerClientUrlOverride !== '' && WebViewerClientUrlOverride !== null)
            {
                url = WebViewerClientUrlOverride;
            }
        }
        catch(e){}
        let email = (!this.remoteUser && this.activeViewer.email) ? '#licenseInfo=' + encodeURI(unescape(window.btoa(this.activeViewer.email))) : '';
        let win: Window = window.open(url + '/index.html?token=' + token + email);
        if (typeof(win)=='undefined' || win === null)
        {
            let isIos = /iPad|iPhone|iPod/.test(navigator.platform)
                || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
            let warning: PortalError = {
                code: 0,
                message: isIos ? '"Block pop-ups" is enabled in your Safari.\n Please disable it in "Safari / Settings" to view the model.' : 'Please enable pop-ups to view models.',
                name: 'PopUpWarning'
            };
            this.progressService.addError(warning);
        }
        else
        {
            win.onbeforeunload = e =>
            {
                for (let i = 0; i < 0; i++)
                {
                    if (this.modelMap[i].tab === win)
                    {
                        this.modelMap.splice(i, 1);
                    }
                }
            }
            let modelMapping: ModelMapping = {
                token: token,
                tab: win,
                model: file.Name
            };
            this.modelMap.push(modelMapping);
        }
    }

    addEntry (tokenMap: ModelMapping)
    {
        this.modelMap.push(tokenMap)
    }

    getMap ()
    {
        return this.modelMap;
    }

    clearMap ()
    {
        this.modelMap = [];
    }

    getToken ()
    {
        return this.modelMap.length > 0 ? this.modelMap[this.modelMap.length - 1].token : undefined;
    }

    isClipboardCut ()
    {
        return this.cut;
    }

    setClipboard (files: ModelFile[], cut?: boolean)
    {
        if (cut)
        {
            this.cut = true;
        }
        this.clipboard = files;
    }

    updateMap (token: string, filename: string)
    {
        for (let i = 0; i < this.modelMap.length; i++)
        {
            if (this.modelMap[i].token === token)
            {
                this.modelMap[i].model = filename;
                return;
            }
        }
    }

    getClipboard (skipCut: boolean = false)
    {
        let data = this.clipboard.slice();
        if (this.cut && !skipCut)
        {
            this.clipboard = [];
            this.cut = false;
        }
        return data;
    }

    changeViewer(viewer: Viewer, isRemote: boolean)
    {
        this.activeViewer = viewer;
        this.remoteUser = isRemote;
    }

}