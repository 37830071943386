import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModelFile, Settings, ModelFileApi } from 'src/app/shared/sdk';
import { RemoteShare, RemoteShareInterface } from 'src/app/shared/sdk/models/RemoteShare';
import { RemoteShareApi } from 'src/app/shared/sdk/services/custom/RemoteShare';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';
import { ProgressService, PortalToast, PortalError } from 'src/app/services/progress.service';

@Component({
    selector: 'app-remote-share-modal',
    templateUrl: './remote-share-modal.component.html',
    styleUrls: ['./remote-share-modal.component.scss']
})
export class RemoteShareModalComponent implements OnInit
{
   //Layout variables
   @Input('isMobile')
    private isMobile: boolean = false;

    @Input('files')
    files: ModelFile[];
    //Controls
    private emails = new FormControl();
    private from = new FormControl();
    private until = new FormControl();
    private limit = new FormControl();
    private comment = new FormControl();
    //Init variables
    isOpen: boolean = false;
    hasInitShare: boolean = false;
    multiSelection: boolean = false;
    mailsUpdate: boolean = false;
    fromUpdate: boolean = false;
    untilUpdate: boolean = false;
    limitUpdate: boolean = false;
    commentUpdate: boolean = false;

    //Update Obj
    private updateObj = {};

    //Primitive values for controls
    private fromDate: number = 0;
    private untilDate: number = 0;
    private unparsedMails: string = "";
    private limitVal: number = 0;
    private commentVal: string = ""
    //Flags
    useFrom: boolean = false;
    useUntil: boolean = false;
    useLimit: boolean = false;
    //Mail variables
    mailtoLink: string;
    //Links
    links: RemoteShare[] = [];

    //Settings
    private settings: Settings;
    private settingsSubscription: Subscription;

    //Emitter
    @Output()
    private emitter = new EventEmitter<any>();
    constructor (private remoteShareApi: RemoteShareApi,
        private settingsService: SettingsService,
        private fileApi: ModelFileApi,
        private progressService: ProgressService) { }

    ngOnInit ()
    {
        this.from.valueChanges.subscribe(val =>
        {
            this.updateFrom();
        });

        this.until.valueChanges.subscribe(val =>
        {
            this.updateUntil();
        });

        this.emails.valueChanges.subscribe(val =>
        {
            this.updateMails();
        });

        this.comment.valueChanges.subscribe(val =>
        {
            this.updateComment();
        });

        this.limit.valueChanges.subscribe(val =>
        {
            this.updateLimit();
        });

        this.settingsSubscription = this.settingsService.settings.subscribe((settings: Settings) =>
        {
            this.settings = settings;
        });

    }

    ngOnDestroy ()
    {
        this.settingsSubscription.unsubscribe();
    }

    open (initShares?: RemoteShare[])
    {
        this.isOpen = true;
        this.links = [];
        if (initShares && initShares.length === 1)
        {
            let share = initShares[0];
            this.multiSelection = false;
            this.hasInitShare = true;
            this.mailsUpdate = true;
            this.fromUpdate = true;
            this.untilUpdate = true;
            this.limitUpdate = true;
            this.commentUpdate = true;
            this.setDisplayValues(share);
            this.fileApi.findById(share.FileId).subscribe((file: ModelFile) =>
            {
                this.files.push(file);
                share['fileName'] = file.Name;
                share['fullURL'] = this.settings.ConfirmationRedirectUrl + '/file-link/' + share.Token;
                this.links.push(share);
                this.generateLinkString();
            });
        }
        else if(!initShares)
        {
            this.multiSelection = false;
            this.mailsUpdate = true;
            this.fromUpdate = true;
            this.untilUpdate = true;
            this.limitUpdate = true;
            this.hasInitShare = false;
            this.commentUpdate = true;
            let calls: Observable<any>[] = [];
            for (let i = 0; i < this.files.length; i++)
            {
                let file = this.files[i];
                if (file.UID)
                {
                    let remoteShare: RemoteShareInterface = {
                        Email: this.unparsedMails,
                        From: this.useFrom ? this.fromDate : 0,
                        Until: this.useUntil ? this.untilDate : 0,
                        Limit: this.useLimit ? this.limitVal : 0,
                        FileId: file.id,
                        ViewCount: 0,
                        Comment: file.Comment
                    };
                    let now: number = Date.now();
                    let monthLater: number = now + 2629800000;
                    this.from.setValue(new Date(now).toISOString().slice(0, 10), { emitEvent: false });
                    this.until.setValue(new Date(monthLater).toISOString().slice(0, 10), { emitEvent: false });
                    calls.push(this.remoteShareApi.create(remoteShare));
                }
            }
            forkJoin(calls).subscribe((rs: RemoteShare[]) =>
            {
                let onlyFiles = this.files.filter((item: ModelFile) =>
                {
                    return item.UID;
                });
                for (let i = 0; i < rs.length; i++)
                {
                    rs[i]['fileName'] = onlyFiles[i].Name;
                    rs[i]['fullURL'] = this.settings.ConfirmationRedirectUrl + '/file-link/' + rs[i].Token;
                    this.links.push(rs[i]);
                    if (rs.length === 1)
                    {
                        this.comment.setValue(rs[i].Comment, { emitEvent: false });
                    }
                }
                let toast: PortalToast = {
                    message: 'LINKS_CREATED',
                    type: 2
                };
                this.progressService.showToast(toast);
                this.updateFrom();
                this.updateUntil();
            }, (err) =>
            {
                let visError: PortalError = {
                    code: err.statusCode,
                    message: err.message,
                    name: err.name
                };
                this.progressService.addError(visError);
                this.close();
            });
        }
        else
        {
            this.multiSelection = true;
            this.hasInitShare = true;
            this.mailsUpdate = false;
            this.fromUpdate = false;
            this.untilUpdate = false;
            this.limitUpdate = false;
            this.commentUpdate = false;
            this.setDisplayValues(initShares[0]);
            for (let i = 0; i < initShares.length; i++)
            {
                let share = initShares[i];
                this.fileApi.findById(share.FileId).subscribe((file: ModelFile) =>
                {
                    this.files.push(file);
                    share['fileName'] = file.Name;
                    share['fullURL'] = this.settings.ConfirmationRedirectUrl + '/file-link/' + share.Token;
                    this.links.push(share);
                    this.generateLinkString();
                });
            }
        }
    }

    close ()
    {
        this.emitter.emit('cancel');
        this.isOpen = false;
    }

    updateMails ()
    {
        this.unparsedMails = this.emails.value;
        this.generateLinkString();
        this.updateObj['Email'] = this.unparsedMails;
    }

    updateComment()
    {
        this.commentVal = this.comment.value;
        this.updateObj['Comment'] = this.commentVal;
    }

    updateFrom ()
    {
        this.fromDate = new Date(this.from.value).getTime();
        this.generateLinkString();
        this.updateObj['From'] = this.fromDate;
    }

    updateUntil ()
    {
        this.untilDate = new Date(this.until.value).getTime();
        this.generateLinkString();
        this.updateObj['Until'] = this.untilDate;
    }

    updateLimit ()
    {
        this.limitVal = this.limit.value;
        this.generateLinkString();
        this.updateObj['Limit'] = this.limitVal;
    }

    setDisplayValues (share: RemoteShare)
    {
        let floatFrom: any;
        floatFrom = share.From;
        let floatUntil: any;
        floatUntil = share.Until;
        if (share.From && floatFrom !== 0 && floatFrom !== '0')
        {
            this.useFrom = true;
            let ms = share.From.toString();
            this.fromDate = share.From;
            this.from.setValue(new Date(parseInt(ms)).toISOString().slice(0, 10), { emitEvent: false});
        }
        else
        {
            this.useFrom = false;
            this.fromDate = 0;
            this.from.setValue(undefined, { emitEvent: false});
        }
        if (share.Until && share.Until !== 0 && floatUntil !== '0')
        {
            this.useUntil = true;
            let ms = share.Until.toString();
            this.untilDate = share.Until;
            this.until.setValue(new Date(parseInt(ms)).toISOString().slice(0, 10), { emitEvent: false});
        }
        else
        {
            this.useUntil = false;
            this.untilDate = 0;
            this.until.setValue(undefined, { emitEvent: false});
        }
        if (share.Limit && share.Limit > 0)
        {
            this.useLimit = true;
            this.limitVal = share.Limit;
            this.limit.setValue(share.Limit, { emitEvent: false});
        }
        else
        {
            this.useLimit = false;
            this.limitVal = 0;
            this.limit.setValue(0, { emitEvent: false});
        }
        this.emails.setValue(share.Email, { emitEvent: false });
        this.comment.setValue(share.Comment, { emitEvent: false });
    }

    copyToClipBoard (link?: RemoteShare)
    {
        let text = "";
        if (!link)
        {
            for (let i = 0; i < this.links.length; i++)
            {
                text += this.links[i]['fileName'] + ':\n';
                text += this.links[i]['fullURL'] + '\n';
            }
        }
        else
        {
            text = link['fullURL'];
        }
        let hiddenText = document.createElement('textarea');
        hiddenText.style.position = 'fixed';
        hiddenText.style.top = '0';
        hiddenText.style.left = '0';
        hiddenText.style.width = '2em';
        hiddenText.style.height = '2em';
        hiddenText.style.padding = '0';
        hiddenText.style.border = 'none';
        hiddenText.style.outline = 'none';
        hiddenText.style.boxShadow = 'none';
        hiddenText.style.background = 'transparent';
        hiddenText.value = text;
        document.body.appendChild(hiddenText);
        hiddenText.focus();
        hiddenText.select();

        try
        {
            document.execCommand('copy');
        } catch (err)
        {
        }
        document.body.removeChild(hiddenText);
    }

    updateShares (send: boolean = false)
    {
        let calls: Observable<any>[] = [];
        this.links.forEach((share: RemoteShare) =>
        {
            if (this.mailsUpdate && this.updateObj['Email'])
            {
                calls.push(this.patchShare('Email', this.updateObj['Email'], share.id));
            }
            if (this.fromUpdate && (this.updateObj['From'] || !this.useFrom))
            {
                calls.push(this.patchShare('From', this.useFrom ? this.updateObj['From'] : 0, share.id));
            }
            if (this.untilUpdate && (this.updateObj['Until'] || !this.useUntil))
            {
                calls.push(this.patchShare('Until', this.useUntil ? this.updateObj['Until'] : 0, share.id));
            }
            if (this.limitUpdate && (this.updateObj['Limit'] || !this.useLimit))
            {
                calls.push(this.patchShare('Limit', this.useLimit ? this.updateObj['Limit'] : 0, share.id));
            }
            if (this.commentUpdate && this.updateObj['Comment'])
            {
                calls.push(this.patchShare('Comment', this.updateObj['Comment'], share.id));
            }
        });
        if (!send)
        {
            if (calls.length === 0)
            {
                if (!this.hasInitShare)
                {
                    this.emitter.emit('close');
                }
                else
                {
                    this.emitter.emit('cancel');
                }
                this.isOpen = false;
            }
            else
            {
                forkJoin(calls).subscribe(res =>
                {
                    this.emitter.emit('close');
                    this.isOpen = false;
                });
            }
        }
        else
        {
            if (calls.length === 0)
            {
                this.mail();
            }
            else
            {
                forkJoin(calls).subscribe(res =>
                {
                    this.mail();
                });
            }
        }
    }

    patchShare (attr: string, val, shareId)
    {
        let obj = {};
        obj[attr] = val;
        return this.remoteShareApi.patchAttributes(shareId, obj);
    }

    sendMails ()
    {
        this.updateShares(true);
    }

    mail ()
    {
        for (let i = 0; i < this.links.length; i++)
        {
            this.remoteShareApi.sendMails(this.links[i].id).subscribe();
        }
        let toast: PortalToast = {
            message: 'MAILS_SEND',
            type: 0
        };
        this.progressService.showToast(toast);
    }

    generateLinkString ()
    {
        let shareType = this.useLimit ? 'Limited share (' + this.limitVal + ' visits) for ' : 'Share for '
        let from = this.useFrom ? ' from ' + new Date(this.fromDate).toLocaleString() : '';
        let until = this.useUntil ? ' until ' + new Date(this.untilDate).toLocaleString() : '';
        let subject = this.links.length > 1 ? 'Share for files' : 'Share for file ' + this.links[0]['fileName'];
        let linkString = '\n';
        for (let i = 0; i < this.links.length; i++)
        {
            linkString += this.links[i]['fileName'] + ':\n';
            linkString += this.links[i]['fullURL'] + '\n';
        }
        let bodyString = shareType + '\n' + linkString + '\n' + from + until;
        this.mailtoLink = "mailto:" + this.unparsedMails + '?subject=' + subject + '&body=' + escape(bodyString);
    }

}
