import { Component, OnInit, AfterViewInit, EventEmitter, Output, Input } from '@angular/core';
import { Viewer, Tenant, TenantViewerMapping, TenantViewerMappingInterface, TenantViewerMappingApi, TenantInterface, TenantApi } from '../../shared/sdk';
import { FormControl } from '@angular/forms';
import { ViewerService } from '../../services/viewer.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-tenant-modal',
  templateUrl: './tenant-modal.component.html',
  styleUrls: ['./tenant-modal.component.scss']
})
export class TenantModalComponent implements AfterViewInit {

    @Output() emitter = new EventEmitter<any>();
    @Input() activeViewer: Viewer;

    //Layout variables
    @Input('isMobile')
    private isMobile: boolean = false;
    userMobile: Viewer[] = [];
    private unfilteredUserMobile: Viewer[] = [];
    private mobileUpdate: boolean = false;

    isOpen: boolean = false;
    //Selection arrays
    private selectionRemaining: Viewer[] = [];
    private selectionDone: Viewer[] = [];

    private searchTextInGroup = new FormControl();
    private searchTextRemaining = new FormControl();
    private groupUserFilterString: string = '';
    private remainingUserFilterString: string = '';

    //Tenant variable
    private inTenantArr: Viewer[] = [];
    private unfilteredInTenantArr: Viewer[] = [];
    private noTenantArr: Viewer[] = [];
    private unfilteredNoTenantArr: Viewer[] = [];
    private removeTenantCallbackArr: Viewer[] = [];
    private addTenantCallbackArr: Viewer[] = [];
    private exisitingMappings: TenantViewerMapping[] = [];
    private name = new FormControl();

    selectedTenant: Tenant;

    constructor (private viewerService: ViewerService,
        private tenantMappingApi: TenantViewerMappingApi,
        private tenantApi: TenantApi) { }

    ngAfterViewInit ()
    {
        //Register onchange listener for the group search field
        this.searchTextInGroup.valueChanges.subscribe(val =>
        {
            this.groupUserFilterString = val;
            this.updateUIArray();
        });

        //Register onchange listener for the remaining search field
        this.searchTextRemaining.valueChanges.subscribe(val =>
        {
            this.remainingUserFilterString = val;
            this.updateUIArray();
        });
    }

    open (tenant?: Tenant)
    {
        this.unfilteredInTenantArr = [];
        this.inTenantArr = [];
        this.unfilteredNoTenantArr = [];
        this.noTenantArr = [];
        this.selectionDone = [];
        this.selectionRemaining = [];
        this.addTenantCallbackArr = [];
        this.removeTenantCallbackArr = [];

        this.mobileUpdate = false;
        this.userMobile = [];
        this.unfilteredUserMobile = [];
        let filter = {
            where: {
                IsDeleted: false
            }
        };
        if (tenant)
        {
            this.selectedTenant = tenant;
            this.name.setValue(tenant.Name);
        }
        else
        {
            this.selectedTenant = undefined;
            this.name.setValue("");
        }
        this.viewerService.getAllUser(filter).subscribe((viewers: Viewer[]) =>
        {
            if (!this.isMobile)
            {
                this.unfilteredNoTenantArr = viewers;
                if (tenant)
                {
                    this.tenantMappingApi.find({ where: { tenantId: tenant.id } }).subscribe((map: TenantViewerMapping[]) =>
                    {
                        this.exisitingMappings = map;
                        map.forEach((item: TenantViewerMapping) =>
                        {
                            this.unfilteredNoTenantArr = this.unfilteredNoTenantArr.filter((viewer: Viewer) =>
                            {
                                let hasMapping = viewer.id === item.viewerId;
                                if (hasMapping)
                                {
                                    this.unfilteredInTenantArr.push(viewer);
                                }
                                return !hasMapping;
                            });
                        });
                        this.noTenantArr = this.unfilteredNoTenantArr.slice();
                        this.inTenantArr = this.unfilteredInTenantArr.slice();
                    });
                }
                else
                {
                    this.noTenantArr = this.unfilteredNoTenantArr.slice();
                    this.inTenantArr = this.unfilteredInTenantArr.slice();
                }
            }
            else
            {
                this.unfilteredUserMobile = viewers;
                if (tenant)
                {
                    this.tenantMappingApi.find({ where: { tenantId: tenant.id } }).subscribe((map: TenantViewerMapping[]) =>
                    {
                        this.exisitingMappings = map;
                        this.unfilteredUserMobile.forEach((viewer: Viewer) =>
                        {
                            viewer['member'] = map.map((item: TenantViewerMapping) => { return item.viewerId }).indexOf(viewer.id) !== -1;
                        });
                        this.userMobile = this.unfilteredUserMobile.slice();
                        this.updateUIArray();
                    });
                }
                else
                {
                    this.userMobile = this.unfilteredUserMobile.slice();
                    this.updateUIArray();
                }
            }
        });
        this.isOpen = true;
    }

    updateUIArray ()
    {
        if (!this.isMobile)
        {
            this.inTenantArr = this.unfilteredInTenantArr.filter(item =>
            {
                let regEx = new RegExp(".*(" + this.groupUserFilterString.toLowerCase() + ").*");
                return regEx.test(item.email.toLowerCase());
            });

            this.noTenantArr = this.unfilteredNoTenantArr.filter(item =>
            {
                let regEx = new RegExp(".*(" + this.remainingUserFilterString.toLowerCase() + ").*");
                return regEx.test(item.email.toLowerCase());
            });
        }
        else
        {
            this.userMobile = this.unfilteredUserMobile.filter(item =>
            {
                let regEx = new RegExp(".*(" + this.groupUserFilterString.toLowerCase() + ").*");
                return regEx.test(item.email.toLowerCase());
            });
        }
    }

    resetInGroupSearchString ()
    {
        this.searchTextInGroup.setValue('');
    }

    resetRemainingSearchString ()
    {
        this.searchTextRemaining.setValue('');
    }

    selectRemaining (e: MouseEvent, viewer: Viewer)
    {
        if (e.shiftKey)
        {
            this.applyShiftKeySelection(this.noTenantArr, this.selectionRemaining, viewer, e.ctrlKey);
        }
        else if (e.ctrlKey)
        {
            this.select(viewer, this.selectionRemaining)
        }
        else
        {
            this.selectionRemaining = [viewer];
        }
    }

    selectInGroup (e: MouseEvent, viewer: Viewer)
    {
        if (e.shiftKey)
        {
            this.applyShiftKeySelection( this.inTenantArr, this.selectionDone, viewer, e.ctrlKey);
        }
        else if (e.ctrlKey)
        {
            this.select(viewer, this.selectionDone)
        }
        else
        {
            this.selectionDone = [viewer];
        }
    }

    select (viewer: Viewer, selection: Viewer[])
    {
        if (selection.indexOf(viewer) === -1)
        {
            selection.push(viewer);
        }
        else
        {
            selection.splice(selection.indexOf(viewer), 1);
        }
    }

    keyDownInTenant (e: KeyboardEvent)
    {
        if (e.keyCode === 39)
        {
            this.removeFromTenant();
        }
        else if (e.keyCode === 40)
        {
            let lastSelectedElement = this.selectionDone.length === 0 ? this.inTenantArr[0] : this.selectionDone[this.selectionDone.length - 1];
            let index = this.inTenantArr.map((tenant: Viewer) => { return tenant.id }).indexOf(lastSelectedElement.id);
            if (index < this.inTenantArr.length - 1)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionDone.push(this.inTenantArr[index + 1]);
                }
                else
                {
                    this.selectionDone = [this.inTenantArr[index + 1]];
                }
            }
        }
        else if (e.keyCode === 38)
        {
            let lastSelectedElement = this.selectionDone.length === 0 ? this.inTenantArr[0] : this.selectionDone[this.selectionDone.length - 1];
            let index = this.inTenantArr.map((viewer: Viewer) => { return viewer.id }).indexOf(lastSelectedElement.id);
            let lastSelectableIndex = this.inTenantArr.length - this.inTenantArr.filter((viewer: Viewer) =>
            {
                return viewer.id !== this.activeViewer.id;
            }).length;
            if (index > lastSelectableIndex)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionDone.push(this.inTenantArr[index - 1]);
                }
                else
                {
                    this.selectionDone = [this.inTenantArr[index - 1]];
                }
            }
        }
        else if (e.ctrlKey && e.keyCode === 65)
        {
            e.preventDefault();
            this.selectAllTenants();
        }
    }

    keyDownInRemaining (e?: KeyboardEvent)
    {
        if (e.keyCode === 37)
        {
            this.addToTenant();
        }
        else if (e.keyCode === 40)
        {
            let lastSelectedElement = this.selectionRemaining.length === 0 ? this.noTenantArr[0] : this.selectionRemaining[this.selectionRemaining.length - 1];
            let index = this.noTenantArr.map((viewer: Viewer) => { return viewer.id }).indexOf(lastSelectedElement.id);
            if (index < this.noTenantArr.length - 1)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionRemaining.push(this.noTenantArr[index + 1]);
                }
                else
                {
                    this.selectionRemaining = [this.noTenantArr[index + 1]];
                }
            }
        }
        else if (e.keyCode === 38)
        {
            let lastSelectedElement = this.selectionRemaining.length === 0 ? this.noTenantArr[0] : this.selectionRemaining[this.selectionRemaining.length - 1];
            let index = this.noTenantArr.map((viewer: Viewer) => { return viewer.id }).indexOf(lastSelectedElement.id);
            if (index > 0)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionRemaining.push(this.noTenantArr[index - 1]);
                }
                else
                {
                    this.selectionRemaining = [this.noTenantArr[index - 1]];
                }
            }
        }
        else if (e.ctrlKey && e.keyCode === 65)
        {
            e.preventDefault();
            this.selectAllNonTenants();
        }
    }

    selectAllTenants ()
    {
        this.selectionDone = this.inTenantArr.filter((viewer: Viewer) =>
        {
            return viewer.id !== this.activeViewer.id;
        });
    }

    selectAllNonTenants ()
    {
        this.selectionRemaining = this.noTenantArr;
    }

    applyShiftKeySelection (arr: Viewer[], selection: Viewer[] ,viewer: Viewer, ctrlKeyClicked: boolean)
    {
        let selectionLength = selection.length;
        let lastSelectedElement = selection[selectionLength - 1];
        if (!ctrlKeyClicked && selectionLength > 1)
        {
            selection.splice(0, selectionLength - 2);
        }
        //Get start index
        let startIndex: number;
        for (let i = 0; i < arr.length; i++)
        {
            if (arr[i].id === lastSelectedElement.id)
            {
                startIndex = i;
            }
        }
        //Get end index
        let endIndex: number;
        for (let j = 0; j < arr.length; j++)
        {
            if (arr[j].id === viewer.id)
            {
                endIndex = j;
            }
        }
        //Select forward
        //Case group is first and last selection
        if (startIndex <= endIndex)
        {
            //Select groups till endindex
            for (let i = startIndex + 1; i < endIndex + 1; i++)
            {
                this.select(arr[i], selection);
            }
        }
        //Select backwards
        //Case group is first and last selection
        else if (endIndex < startIndex)
        {
            //Select groups
            for (let i = startIndex - 1; i >= endIndex; i--)
            {
                this.select(arr[i], selection);
            }
        }
    }

    close (update: boolean)
    {
        this.emitter.emit(update || this.mobileUpdate);
        this.isOpen = false;
    }

    addToTenant ()
    {
        this.unfilteredInTenantArr = this.unfilteredInTenantArr.concat(this.selectionRemaining);
        for (let i = 0; i < this.selectionRemaining.length; i++)
        {
            let addEl = this.unfilteredNoTenantArr.splice(this.unfilteredNoTenantArr.indexOf(this.selectionRemaining[i]), 1)[0];
            this.addTenantCallbackArr.push(addEl);
            if (this.removeTenantCallbackArr.indexOf(addEl) !== -1)
            {
                this.removeTenantCallbackArr.splice(this.removeTenantCallbackArr.indexOf(addEl));
            }
        }
        this.selectionRemaining = [];
        this.updateUIArray();
    }

    removeFromTenant ()
    {
        this.unfilteredNoTenantArr = this.unfilteredNoTenantArr.concat(this.selectionDone);
        for (let i = 0; i < this.selectionDone.length; i++)
        {
            let remEl = this.unfilteredInTenantArr.splice(this.unfilteredInTenantArr.indexOf(this.selectionDone[i]), 1)[0];
            this.removeTenantCallbackArr.push(remEl);
            if (this.addTenantCallbackArr.indexOf(remEl) !== -1)
            {
                this.addTenantCallbackArr.splice(this.addTenantCallbackArr.indexOf(remEl));
            }
        }
        this.selectionDone = [];
        this.updateUIArray();
    }

    changeTenantName (event)
    {
        if (this.name.value !== this.selectedTenant.Name)
        {
            this.mobileUpdate = true;
            this.tenantApi.patchAttributes(this.selectedTenant.id, { Name: this.name.value }).subscribe();
        }
    }

    setMemberState (viewer: Viewer)
    {
        let newMapping: TenantViewerMappingInterface = {
            tenantId: this.selectedTenant.id,
            viewerId: viewer.id
        };
        if (!viewer['member'])
        {
            this.tenantMappingApi.create(newMapping).subscribe((mapping: TenantViewerMapping) =>
            {
                this.exisitingMappings.push(mapping)
            });
        }
        else
        {
            let index = this.exisitingMappings.map((item: TenantViewerMapping) => { return item.viewerId; }).indexOf(viewer.id);
            let mappingToDelete = this.exisitingMappings[index];
            if (mappingToDelete)
            {
                this.tenantMappingApi.deleteById(mappingToDelete.id).subscribe(res =>
                {
                    this.exisitingMappings.splice(index, 1);
                });
            }
        }
        this.mobileUpdate = true;
        viewer['member'] = !viewer['member'];
    }

    finish ()
    {
        if (!this.selectedTenant)
        {
            let newTenant: TenantInterface = {
                Name: this.name.value
            };
            this.tenantApi.create(newTenant).subscribe((tenant: Tenant) =>
            {
                this.selectedTenant = tenant;
                this.updateTenant();
            });
        }
        else
        {
            if (this.name.value !== this.selectedTenant.Name)
            {
                this.tenantApi.patchAttributes(this.selectedTenant.id, { Name: this.name.value }).subscribe(res =>
                {
                    this.updateTenant();
                });
            }
            else
            {
                this.updateTenant();
            }
        }
    }

    updateTenant ()
    {
        let calls: Observable<any>[] = [];
        for (let i = 0; i < this.addTenantCallbackArr.length; i++)
        {
            let mapping: TenantViewerMappingInterface = {
                tenantId: this.selectedTenant.id,
                viewerId: this.addTenantCallbackArr[i].id
            };
            calls.push(this.tenantMappingApi.create(mapping));
        }
        for (let j = 0; j < this.removeTenantCallbackArr.length; j++)
        {
            let mappingToDelete = this.exisitingMappings.filter((m: TenantViewerMapping) =>
            {
                return m.viewerId === this.removeTenantCallbackArr[j].id;
            });
            if (mappingToDelete && mappingToDelete.length > 0)
            {
                calls.push(this.tenantMappingApi.deleteById(mappingToDelete[0].id));
            }
        }
        if (calls.length > 0)
        {
            forkJoin(calls).subscribe(res =>
            {
                this.close(true);
            });
        }
        else
        {
            this.close(true);
        }
    }

}
