import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViewerApi } from '../shared/sdk';
import { ProgressService, PortalError } from '../services/progress.service';
import { GlobalEventService } from '../services/global-event.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-resend-mail',
    templateUrl: './resend-mail.component.html',
    styleUrls: ['./resend-mail.component.scss']
})
export class ResendMailComponent implements OnInit
{
    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;

    mail: FormGroup;
    constructor (private formBuilder: FormBuilder,
        private viewerApi: ViewerApi,
        private progressService: ProgressService,
        private eventService: GlobalEventService) { }

    ngOnInit ()
    {
        this.mail = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });

        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof (isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });
    }

    ngOnDestroy ()
    {
        this.layoutSubscription.unsubscribe();
    }

    resendMail ()
    {
        this.viewerApi.resendMail({ email: this.mail.value.email }).subscribe((success: boolean) =>
        {
            if (success)
            {
                let error: PortalError = {
                    code: 0,
                    message: 'VERIFY_MAIL_RESEND',
                    name: 'MailResend'
                }
                this.progressService.addError(error);
            }
        }, err =>
        {
            let visError: PortalError = {
                code: err['statusCode'],
                message: err.message,
                name: 'Server Error'
            };
            this.progressService.addError(visError);
        });
    }

}
