import { Component, Input, Output, EventEmitter, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { Viewer, GroupApi, Group, GroupViewerMappingApi, GroupViewerMapping, Tenant } from 'src/app/shared/sdk';
import { FormControl } from '@angular/forms';
import { ViewerService } from 'src/app/services/viewer.service';
import { Observable, forkJoin } from 'rxjs';
import { PortalError, ProgressService } from 'src/app/services/progress.service';
import { SwitchComponent } from 'src/app/widgets/switch/switch.component';

@Component({
    selector: 'app-group-modal',
    templateUrl: './group-modal.component.html',
    styleUrls: ['./group-modal.component.scss']
})
export class GroupModalComponent implements AfterViewInit
{

    @Input()
    activeViewer: Viewer;
    @Output()
    emitter = new EventEmitter<any>();

    //Direct save
    @ViewChildren(SwitchComponent)
    private switches: QueryList<SwitchComponent>;
    private allowDirectSave: boolean = false;

    //Download
    @Input()
    showDownload: boolean = false;
    private allowDownload: boolean = false;
    //Layout variables
    @Input('isMobile')
    private isMobile: boolean = false;
    private unfilteredUserMobile: Viewer[] = [];
    userMobile: Viewer[] = [];

    isOpen: boolean = false;
    private inReadOnlyArr: Viewer[] = [];
    private inWriteAccessArr: Viewer[] = [];
    private notInGroupArr: Viewer[] = [];
    private unfilteredInReadOnlyArr: Viewer[] = [];
    private unfilteredInWriteAccessArr: Viewer[] = [];
    private unfilteredNotInGroupArr: Viewer[] = [];
    private removeCallbackArr: Viewer[] = [];
    private addCallbackArr: Viewer[] = [];
    adminArr: Viewer[] = [];
    unfilteredAdminArr: Viewer[] = [];

    private selectionRemaining: Viewer[] = [];
    private selectionDone: Viewer[] = [];


    private name = new FormControl();
    private searchTextInGroup = new FormControl();
    private searchTextRemaining = new FormControl();
    private groupUserFilterString: string = '';
    private remainingUserFilterString: string = '';


    private selectedGroup: Group = undefined;
    isPublic: boolean = false;

    //Tenant handling
    private tenant = new FormControl();
    useTenants: boolean = false;
    viewerTenants: Tenant[] = [];
    private activeTenantId: number;

    //Only for calls from admin cockpit
    dirSize: number = 0;
    private adminRead = new FormControl();
    private adminWrite = new FormControl();

    constructor (private groupApi: GroupApi,
        private viewerService: ViewerService,
        private groupViewerApi: GroupViewerMappingApi,
        private progressService: ProgressService) { }

    ngAfterViewInit ()
    {
        //Register onchange listener for the group search field
        this.searchTextInGroup.valueChanges.subscribe(val =>
        {
            this.groupUserFilterString = val;
            this.updateUIArray();
        });

        //Register onchange listener for the remaining search field
        this.searchTextRemaining.valueChanges.subscribe(val =>
        {
            this.remainingUserFilterString = val;
            this.updateUIArray();
        });

        this.tenant.valueChanges.subscribe(value =>
        {
            this.activeTenantId = parseFloat(value);
            this.getUser(this.selectedGroup);
            if (this.isMobile)
            {
                this.groupApi.patchAttributes(this.selectedGroup.id, { tenantId: this.activeTenantId }).subscribe();
            }
        });
    }

    setDirSize (size: number)
    {
        this.dirSize = size;
    }

    open (useTenants: boolean, group?: Group)
    {
        this.useTenants = useTenants;
        if (!group)
        {
            this.name.setValue("");
        }
        if (useTenants)
        {
            this.viewerService.getTenants().subscribe((tenants: Tenant[]) =>
            {
                this.viewerTenants = tenants;
                if (this.viewerTenants.length === 0)
                {
                    let error: PortalError = {
                        code: -1,
                        message: 'PROJECT_CREATION_WITHOUT_USER_POOL',
                        name: 'MissingUserPoolError'
                    };
                    this.progressService.addError(error);
                    return;
                }
                if (this.viewerTenants.length > 0 && !group)
                {
                    this.tenant.patchValue(this.viewerTenants[0].id, {emitEvent: false});
                    this.activeTenantId = this.viewerTenants[0].id;
                }
                else
                {
                    this.tenant.patchValue(group.tenantId, { emitEvent: false });
                    this.activeTenantId = group.tenantId;
                }
                this.getUser(group);
            });
        }
        else
        {
            this.getUser(group);
        }
    }

    getUser (group: Group)
    {
        if (this.isMobile)
        {
            this.getUserMobile(group);
            return;
        }
        this.inReadOnlyArr = [];
        this.inWriteAccessArr = [];
        this.notInGroupArr = [];
        this.unfilteredInReadOnlyArr = [];
        this.unfilteredInWriteAccessArr = [];
        this.unfilteredNotInGroupArr = [];
        this.removeCallbackArr = [];
        this.addCallbackArr = [];
        this.adminArr = [];
        this.unfilteredAdminArr = [];
        let isPublic = group ? (group.IsPublic|| group.IsShare) : false;
        let filter = {
            where: {
                IsDeleted: false
            }
        };
        this.viewerService.getUserForGroup(this.activeTenantId ? this.activeTenantId : undefined, isPublic, filter).subscribe((viewers:any[]) =>
        {
            let groupOwnerId: number;
            this.unfilteredAdminArr.push(this.activeViewer);
            for (let i = 0; i < viewers.length; i++)
            {
                if (viewers[i].id !== this.activeViewer.id)
                {
                    //Insert group owner at the beginning if you are not the owner
                    if (group && group.id && viewers[i].id === group.viewerId)
                    {
                        groupOwnerId = viewers[i].id;
                        this.unfilteredAdminArr.unshift(viewers[i]);
                    }
                    else
                    {
                        this.unfilteredNotInGroupArr.push(viewers[i]);
                    }
                }
            }
            if (group && group.id)
            {
                this.selectedGroup = group;
                this.isPublic = this.selectedGroup.IsPublic || this.selectedGroup.IsShare;
                let filter = {
                    where: {
                        groupId: group.id
                    }
                };
                this.name.setValue(group.Name);
                this.groupViewerApi.find(filter).subscribe((map: GroupViewerMapping[]) =>
                {
                    for (let i = 0; i < map.length; i++)
                    {
                        if (map[i].viewerId !== this.activeViewer.id)
                        {
                            if (this.unfilteredNotInGroupArr.map((item: Viewer) => { return item.id }).indexOf(map[i].viewerId) === -1)
                            {
                                this.groupViewerApi.findByIdGroupViewers(map[i].id, map[i].viewerId).subscribe((viewer: Viewer) =>
                                {
                                    this.addMapingEntryToArray(map[i], viewer);
                                    this.updateUIArray();
                                });
                            }
                            else
                            {
                                this.unfilteredNotInGroupArr = this.unfilteredNotInGroupArr.filter((item) =>
                                {
                                    if (item.id !== map[i].viewerId)
                                    {
                                        if (groupOwnerId && groupOwnerId === item.id)
                                        {
                                            return false;
                                        }
                                        return true;
                                    }
                                    else
                                    {
                                        this.addMapingEntryToArray(map[i], item);
                                        return false;
                                    }
                                });
                                this.updateUIArray();
                            }
                        }
                    }
                    this.notInGroupArr = this.unfilteredNotInGroupArr.slice();
                    this.inReadOnlyArr = this.unfilteredInReadOnlyArr.slice();
                    this.inWriteAccessArr = this.unfilteredInWriteAccessArr.slice();
                    this.adminArr = this.unfilteredAdminArr.slice();
                    this.isOpen = true;
                    if (this.showDownload)
                    {
                        setTimeout(() =>
                        {
                            this.switches.last.setState(group.AllowDownload, true);
                        }, 0);
                    }
                    setTimeout(() =>
                    {
                        this.switches.first.setState(group.AllowDirectSave, true);
                    }, 0);
                    this.updateUIArray();
                });
            }
            else
            {
                this.notInGroupArr = this.unfilteredNotInGroupArr.slice();
                this.selectedGroup = new Group();
                this.selectedGroup.IsPublic = false;
                this.selectedGroup.IsPrivate = false;
                this.selectedGroup.IsShare = false;
                this.selectedGroup.viewerId = this.activeViewer.id;
                this.isPublic = false;
                this.isOpen = true;
                if (this.showDownload)
                {
                    setTimeout(() =>
                    {
                        this.switches.last.setState(false, true);
                    }, 0);
                }
                this.switches.first.setState(false, true);
                this.updateUIArray();
            }
        });
    }

    addMapingEntryToArray (map: GroupViewerMapping, viewer: Viewer)
    {
        if (!this.isMobile)
        {
            if (map.adminRights)
            {
                if (this.unfilteredAdminArr.map((unfilteredItem) => { return unfilteredItem.id }).indexOf(viewer.id) === -1)
                {
                    this.unfilteredAdminArr.push(viewer);
                }
            }
            else if (map.writeAccess)
            {
                this.unfilteredInWriteAccessArr.push(viewer);
            }
            else
            {
                this.unfilteredInReadOnlyArr.push(viewer);
            }
        }
        else
        {
            viewer['member'] = true;
            if (map.adminRights)
            {
                viewer['writeAccess'] = true;
                viewer['admin'] = true;
            }
            else if (map.writeAccess)
            {
                viewer['writeAccess'] = true;
            }
        }
    }

    updateUIArray ()
    {
        if (!this.isMobile)
        {
            this.notInGroupArr = this.unfilteredNotInGroupArr.filter(item =>
            {
                let regEx = new RegExp(".*(" + this.remainingUserFilterString.toLowerCase() + ").*");
                return regEx.test(item.email.toLowerCase());
            });

            this.inReadOnlyArr = this.unfilteredInReadOnlyArr.filter(item =>
            {
                let regEx = new RegExp(".*(" + this.groupUserFilterString.toLowerCase() + ").*");
                return regEx.test(item.email.toLowerCase());
            });

            this.inWriteAccessArr = this.unfilteredInWriteAccessArr.filter(item =>
            {
                let regEx = new RegExp(".*(" + this.groupUserFilterString.toLowerCase() + ").*");
                return regEx.test(item.email.toLowerCase());
            });

            this.adminArr = this.unfilteredAdminArr.filter(item =>
            {
                let regEx = new RegExp(".*(" + this.groupUserFilterString.toLowerCase() + ").*");
                return regEx.test(item.email.toLowerCase());
            });
        }
        else
        {
            this.userMobile = this.unfilteredUserMobile.filter(item =>
            {
                let regEx = new RegExp(".*(" + this.groupUserFilterString.toLowerCase() + ").*");
                return regEx.test(item.email.toLowerCase());
            });
        }
    }

    resetInGroupSearchString ()
    {
        this.searchTextInGroup.setValue('');
    }

    resetRemainingSearchString ()
    {
        this.searchTextRemaining.setValue('');
    }

    selectRemaining (e: MouseEvent, viewer: Viewer)
    {
        if (e.shiftKey)
        {
            this.applyShiftKeySelection(this.notInGroupArr, this.selectionRemaining, viewer, e.ctrlKey);
        }
        else if (e.ctrlKey)
        {
            this.select(viewer, this.selectionRemaining)
        }
        else
        {
            this.selectionRemaining = [viewer];
        }
    }

    selectInGroup (e: MouseEvent, viewer: Viewer)
    {
        if (e.shiftKey)
        {
            let arr = this.inReadOnlyArr.map((item: Viewer) => { return item.id; }).indexOf(viewer.id) !== -1 ? this.inReadOnlyArr : this.inWriteAccessArr;
            this.applyShiftKeySelection( arr, this.selectionDone, viewer, e.ctrlKey);
        }
        else if (e.ctrlKey)
        {
            this.select(viewer, this.selectionDone)
        }
        else
        {
            this.selectionDone = [viewer];
        }
    }

    select (viewer: Viewer, selection: Viewer[])
    {
        if (selection.indexOf(viewer) === -1)
        {
            selection.push(viewer);
        }
        else
        {
            selection.splice(selection.indexOf(viewer), 1);
        }
    }

    keyDownInWrite (e: KeyboardEvent)
    {
        if (e.keyCode === 39)
        {
            this.removeFromGroup();
        }
        else if (e.keyCode === 40)
        {
            let lastSelectedElement = this.selectionDone.length === 0 ? this.inWriteAccessArr[0] : this.selectionDone[this.selectionDone.length - 1];
            let index = this.inWriteAccessArr.map((viewer: Viewer) => { return viewer.id }).indexOf(lastSelectedElement.id);
            if (index < this.inWriteAccessArr.length - 1)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionDone.push(this.inWriteAccessArr[index + 1]);
                }
                else
                {
                    this.selectionDone = [this.inWriteAccessArr[index + 1]];
                }
            }
        }
        else if (e.keyCode === 38)
        {
            let lastSelectedElement = this.selectionDone.length === 0 ? this.inWriteAccessArr[0] : this.selectionDone[this.selectionDone.length - 1];
            let index = this.inWriteAccessArr.map((viewer: Viewer) => { return viewer.id }).indexOf(lastSelectedElement.id);
            let lastSelectableIndex = this.inWriteAccessArr.length - this.inWriteAccessArr.filter((viewer: Viewer) =>
            {
                return viewer.id !== this.activeViewer.id;
            }).length;
            if (index > lastSelectableIndex)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionDone.push(this.inWriteAccessArr[index - 1]);
                }
                else
                {
                    this.selectionDone = [this.inWriteAccessArr[index - 1]];
                }
            }
        }
        else if (e.ctrlKey && e.keyCode === 65)
        {
            e.preventDefault();
            this.selectAllInWrite();
        }
    }

    keyDownInReadOnly (e: KeyboardEvent)
    {
        if (e.keyCode === 39)
        {
            this.removeFromGroup();
        }
        else if (e.keyCode === 40)
        {
            let lastSelectedElement = this.selectionDone.length === 0 ? this.inReadOnlyArr[0] : this.selectionDone[this.selectionDone.length - 1];
            let index = this.inReadOnlyArr.map((viewer: Viewer) => { return viewer.id }).indexOf(lastSelectedElement.id);
            if (index < this.inReadOnlyArr.length - 1)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionDone.push(this.inReadOnlyArr[index + 1]);
                }
                else
                {
                    this.selectionDone = [this.inReadOnlyArr[index + 1]];
                }
            }
        }
        else if (e.keyCode === 38)
        {
            let lastSelectedElement = this.selectionDone.length === 0 ? this.inReadOnlyArr[0] : this.selectionDone[this.selectionDone.length - 1];
            let index = this.inReadOnlyArr.map((viewer: Viewer) => { return viewer.id }).indexOf(lastSelectedElement.id);
            let lastSelectableIndex = this.inReadOnlyArr.length - this.inReadOnlyArr.filter((viewer: Viewer) =>
            {
                return viewer.id !== this.activeViewer.id;
            }).length;
            if (index > lastSelectableIndex)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionDone.push(this.inReadOnlyArr[index - 1]);
                }
                else
                {
                    this.selectionDone = [this.inReadOnlyArr[index - 1]];
                }
            }
        }
        else if (e.ctrlKey && e.keyCode === 65)
        {
            e.preventDefault();
            this.selectAllInReadOnly();
        }
    }

    keyDownInNotInGroup (e?: KeyboardEvent)
    {
        if (e.keyCode === 37)
        {
            this.addToWriteAccessGroup();
        }
        else if (e.keyCode === 40)
        {
            let lastSelectedElement = this.selectionRemaining.length === 0 ? this.notInGroupArr[0] : this.selectionRemaining[this.selectionRemaining.length - 1];
            let index = this.notInGroupArr.map((admin: Viewer) => { return admin.id }).indexOf(lastSelectedElement.id);
            if (index < this.notInGroupArr.length - 1)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionRemaining.push(this.notInGroupArr[index + 1]);
                }
                else
                {
                    this.selectionRemaining = [this.notInGroupArr[index + 1]];
                }
            }
        }
        else if (e.keyCode === 38)
        {
            let lastSelectedElement = this.selectionRemaining.length === 0 ? this.notInGroupArr[0] : this.selectionRemaining[this.selectionRemaining.length - 1];
            let index = this.notInGroupArr.map((admin: Viewer) => { return admin.id }).indexOf(lastSelectedElement.id);
            if (index > 0)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionRemaining.push(this.notInGroupArr[index - 1]);
                }
                else
                {
                    this.selectionRemaining = [this.notInGroupArr[index - 1]];
                }
            }
        }
        else if (e.ctrlKey && e.keyCode === 65)
        {
            e.preventDefault();
            this.selectAllNotInGroup();
        }
    }

    selectAllInWrite ()
    {
        this.selectionDone = this.inWriteAccessArr.filter((viewer: Viewer) =>
        {
            return viewer.id !== this.activeViewer.id;
        });
    }

    selectAllInReadOnly ()
    {
        this.selectionDone = this.inReadOnlyArr.filter((viewer: Viewer) =>
        {
            return viewer.id !== this.activeViewer.id;
        });
    }

    selectAllNotInGroup ()
    {
        this.selectionRemaining = this.notInGroupArr;
    }

    applyShiftKeySelection (arr: Viewer[], selection: Viewer[] ,viewer: Viewer, ctrlKeyClicked: boolean)
    {
        let selectionLength = selection.length;
        let lastSelectedElement = selection[selectionLength - 1];
        if (!ctrlKeyClicked && selectionLength > 1)
        {
            selection.splice(0, selectionLength - 2);
        }
        //Get start index
        let startIndex: number;
        for (let i = 0; i < arr.length; i++)
        {
            if (arr[i].id === lastSelectedElement.id)
            {
                startIndex = i;
            }
        }
        //Get end index
        let endIndex: number;
        for (let j = 0; j < arr.length; j++)
        {
            if (arr[j].id === viewer.id)
            {
                endIndex = j;
            }
        }
        //Select forward
        //Case group is first and last selection
        if (startIndex <= endIndex)
        {
            //Select groups till endindex
            for (let i = startIndex + 1; i < endIndex + 1; i++)
            {
                this.select(arr[i], selection);
            }
        }
        //Select backwards
        //Case group is first and last selection
        else if (endIndex < startIndex)
        {
            //Select groups
            for (let i = startIndex - 1; i >= endIndex; i--)
            {
                this.select(arr[i], selection);
            }
        }
    }

    addToReadOnlyGroup ()
    {
        this.unfilteredInReadOnlyArr = this.unfilteredInReadOnlyArr.concat(this.selectionRemaining);
        for (let i = 0; i < this.selectionRemaining.length; i++)
        {
            let addEl = this.unfilteredNotInGroupArr.splice(this.unfilteredNotInGroupArr.indexOf(this.selectionRemaining[i]), 1)[0];
            this.addCallbackArr.push(addEl);
            if (this.removeCallbackArr.indexOf(addEl) !== -1)
            {
                this.removeCallbackArr.splice(this.removeCallbackArr.indexOf(addEl));
            }
        }
        this.selectionRemaining = [];
        this.updateUIArray();
    }

    addToWriteAccessGroup ()
    {
        this.unfilteredInWriteAccessArr = this.unfilteredInWriteAccessArr.concat(this.selectionRemaining);
        for (let i = 0; i < this.selectionRemaining.length; i++)
        {
            let addEl = this.unfilteredNotInGroupArr.splice(this.unfilteredNotInGroupArr.indexOf(this.selectionRemaining[i]), 1)[0];
            this.addCallbackArr.push(addEl);
            if (this.removeCallbackArr.indexOf(addEl) !== -1)
            {
                this.removeCallbackArr.splice(this.removeCallbackArr.indexOf(addEl));
            }
        }
        this.selectionRemaining = [];
        this.updateUIArray();
    }

    removeFromGroup ()
    {
        this.unfilteredNotInGroupArr = this.unfilteredNotInGroupArr.concat(this.selectionDone);
        for (let i = 0; i < this.selectionDone.length; i++)
        {
            let remEl: Viewer;
            if (this.unfilteredInReadOnlyArr.indexOf(this.selectionDone[i]) !== -1)
            {
                remEl = this.unfilteredInReadOnlyArr.splice(this.unfilteredInReadOnlyArr.indexOf(this.selectionDone[i]), 1)[0];
            }
            else
            {
                remEl = this.unfilteredInWriteAccessArr.splice(this.unfilteredInWriteAccessArr.indexOf(this.selectionDone[i]), 1)[0];
            }
            this.removeCallbackArr.push(remEl);
            if (this.addCallbackArr.indexOf(remEl) !== -1)
            {
                this.addCallbackArr.splice(this.addCallbackArr.indexOf(remEl));
            }
        }
        this.selectionDone = [];
        this.updateUIArray();
    }

    close (emitEvent: boolean = true)
    {
        if (this.isOpen)
        {
            if (emitEvent || this.isMobile)
            {
                this.emitter.emit(this.selectedGroup);
            }
            this.isOpen = false;
        }
    }

    submitgroup ()
    {
        let group = {
            Image: "",
            Name: this.name.value,
            IsPrivate: false,
            IsPublic: false,
            IsShare: false,
            tenantId: this.activeTenantId,
            AllowDownload: this.allowDownload,
            AllowDirectSave: this.allowDirectSave
        };

        if (!this.selectedGroup.id)
        {
            this.viewerService.createGroup(group).subscribe(newGroup =>
            {
                this.selectedGroup = newGroup;
                this.updateMapping(newGroup);
            });
        }
        else
        {
            let needUpdate: boolean = false;
            let newAttributes = {};
            if (this.selectedGroup.Name !== this.name.value)
            {
                needUpdate = true;
                newAttributes['Name'] = this.name.value;
            }
            if (this.selectedGroup.AllowDownload !== this.allowDownload)
            {
                needUpdate = true;
                newAttributes['AllowDownload'] = this.allowDownload;
            }
            if (this.selectedGroup.AllowDirectSave !== this.allowDirectSave)
            {
                needUpdate = true;
                newAttributes['AllowDirectSave'] = this.allowDirectSave;
            }
            if (needUpdate)
            {
                this.groupApi.patchAttributes(this.selectedGroup.id, newAttributes).subscribe(res =>
                {
                    this.updateMapping(this.selectedGroup);
                });
            }
            else
            {
                this.updateMapping(this.selectedGroup);
            }
        }
    }

    updateMapping (group)
    {
        if ((this.addCallbackArr.length > 0 || this.removeCallbackArr.length > 0))
        {
            let calls: Observable<any>[] = [];
            for (let i = 0; i < this.addCallbackArr.length; i++)
            {
                let writeAccess = this.unfilteredInWriteAccessArr.map(item => { return item.id }).indexOf(this.addCallbackArr[i].id) !== -1;
                let mapping = {
                    writeAccess: writeAccess,
                    groupId: group.id,
                    viewerId: this.addCallbackArr[i].id
                };
                let filter = {
                    viewerId: this.addCallbackArr[i].id,
                    groupId: group.id
                };
                calls.push(this.groupViewerApi.upsertWithWhere(filter, mapping));
            }
            for (let r = 0; r < this.removeCallbackArr.length; r++)
            {
                calls.push(this.groupApi.unlinkViewers(this.selectedGroup.id, this.removeCallbackArr[r].id));
            }
            if (this.useTenants && this.selectedGroup.tenantId !== this.activeTenantId)
            {
                calls.push(this.groupApi.patchAttributes(this.selectedGroup.id, { tenantId: this.activeTenantId }));
            }
            forkJoin(calls).subscribe(results =>
            {
                this.close();
            });
        }
        else if (this.useTenants && this.selectedGroup.tenantId !== this.activeTenantId)
        {
            this.groupApi.patchAttributes(this.selectedGroup.id, { tenantId: this.activeTenantId }).subscribe(res =>
            {
                this.close();
            });
        }
        else
        {
            this.close();
        }
    }

    OnDownloadToggle (state: boolean)
    {
        this.allowDownload = state;
    }

    OnDirectSaveToggle(state: boolean)
    {
        this.allowDirectSave = state;
    }

    //Admin directory import
    adminAdd (hasWriteAccses: boolean)
    {
        if (hasWriteAccses)
        {
            let users = this.adminWrite.value.split(',');
            for (let i = 0; i < users.length; i++)
            {
                let userIndex = this.unfilteredNotInGroupArr.map(item => { return item.email }).indexOf(users[i].trim());
                if (userIndex !== -1)
                {
                    this.selectionRemaining.push(this.unfilteredNotInGroupArr[userIndex]);
                }
            }
            this.addToWriteAccessGroup();
        }
        else
        {
            let users = this.adminRead.value.trim().split(',');
            for (let i = 0; i < users.length; i++)
            {
                let userIndex = this.unfilteredNotInGroupArr.map(item => { return item.email }).indexOf(users[i].trim());
                if (userIndex !== -1)
                {
                    this.selectionRemaining.push(this.unfilteredNotInGroupArr[userIndex]);
                }
            }
            this.addToReadOnlyGroup();
        }
    }

    //---------------------- Mobile methods ----------------------------------
    getUserMobile (group: Group)
    {
        this.unfilteredUserMobile = [];
        let isPublic = group ? (group.IsPublic|| group.IsShare) : false;
        let filter = {
            where: {
                IsDeleted: false
            }
        };
        this.viewerService.getUserForGroup(this.activeTenantId ? this.activeTenantId : undefined, isPublic, filter).subscribe((viewers:any[]) =>
        {
            for (let i = 0; i < viewers.length; i++)
            {
                //Insert group owner at the beginning if you are not the owner
                if (group && group.id && viewers[i].id === group.viewerId)
                {
                    viewers[i].writeAccess = true;
                    viewers[i].member = true;
                    viewers[i].admin = true;
                    viewers[i].isOwner = true;
                    this.unfilteredUserMobile.unshift(viewers[i]);
                }
                else
                {
                    this.unfilteredUserMobile.push(viewers[i]);
                }
            }
            this.selectedGroup = group;
            this.isPublic = this.selectedGroup.IsPublic || this.selectedGroup.IsShare;
            let filter = {
                where: {
                    groupId: group.id
                }
            };
            this.name.setValue(group.Name);
            this.groupViewerApi.find(filter).subscribe((map: GroupViewerMapping[]) =>
            {
                for (let i = 0; i < map.length; i++)
                {
                    let index = this.unfilteredUserMobile.map((item: Viewer) => { return item.id }).indexOf(map[i].viewerId);
                    if (index !== -1)
                    {
                        this.addMapingEntryToArray(map[i], this.unfilteredUserMobile[index]);
                        this.updateUIArray();
                    }
                    else
                    {
                        this.groupViewerApi.findByIdGroupViewers(map[i].id, map[i].viewerId).subscribe((viewer: Viewer) =>
                        {
                            this.unfilteredUserMobile.push(viewer);
                            this.addMapingEntryToArray(map[i], viewer);
                            this.updateUIArray();
                        });
                    }
                }
                this.isOpen = true;
                this.updateUIArray();
            });
        });
    }

    setMemberState (user: Viewer)
    {
        user['member'] = !user['member'];
        if (!user['member'])
        {
            user['admin'] = false;
            user['writeAccess'] = false;
            this.groupApi.unlinkViewers(this.selectedGroup.id, user.id).subscribe();
        }
        else
        {
            let mapping = {
                writeAccess: false,
                groupId: this.selectedGroup.id,
                viewerId: user.id
            };
            let filter = {
                viewerId: user.id,
                groupId: this.selectedGroup.id
            };
            this.groupViewerApi.upsertWithWhere(filter, mapping).subscribe();
        }
    }

    setWriteAccess (user: Viewer)
    {
        user['writeAccess'] = !user['writeAccess'];
        if (user['writeAccess'])
        {
            user['member'] = true;
            let mapping = {
                writeAccess: true,
                groupId: this.selectedGroup.id,
                viewerId: user.id
            };
            let filter = {
                viewerId: user.id,
                groupId: this.selectedGroup.id
            };
            this.groupViewerApi.upsertWithWhere(filter, mapping).subscribe();
        }
        else
        {
            user['admin'] = false;
            this.groupApi.unlinkViewers(this.selectedGroup.id, user.id).subscribe();
        }

    }

    setAdmin (user: Viewer)
    {
        user['admin'] = !user['admin'];
        if (user['admin'])
        {
            user['member'] = true;
            user['writeAccess'] = true;
        }
        let mapping = {
            writeAccess: true,
            adminRights: user['admin'],
            groupId: this.selectedGroup.id,
            viewerId: user.id
        };
        let filter = {
            viewerId: user.id,
            groupId: this.selectedGroup.id
        };
        this.groupViewerApi.upsertWithWhere(filter, mapping).subscribe();
    }
}
