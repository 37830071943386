import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewerApi, Viewer } from '../shared/sdk';
import { ViewerService } from '../services/viewer.service';
import { ProgressService, PortalError } from '../services/progress.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

    private tempUser;

    constructor (private router: Router,
        private progressService: ProgressService,
        private viewerService: ViewerService,
        private viewerApi: ViewerApi)
    {
    }

    ngOnInit ()
    {
        this.tempUser = this.viewerService.getTempUser();
    }

    register ()
    {
        this.viewerApi.create({ email: this.tempUser.mail.toLowerCase(), password: this.tempUser.password }).subscribe((viewer: Viewer) =>
        {
            this.viewerService.deleteTempUser();
            this.router.navigateByUrl('/login').then(() =>
            {
                let error: PortalError = {
                    code: 0,
                    message: 'VERIFY_MAIL_SEND',
                    name: 'VerificationWarning'
                }
                this.progressService.addError(error);
            });
        }, error =>
            {
                this.progressService.addError({ name: error.name, message: error.message, code: error.statusCode });
            });
    }
}
