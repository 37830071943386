import { Component, OnInit } from '@angular/core';
import { ViewerApi } from '../shared/sdk/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Viewer } from '../shared/sdk';
import { ProgressService, PortalError } from '../services/progress.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewerService } from '../services/viewer.service';
import { Subscription } from 'rxjs';
import { GlobalEventService } from '../services/global-event.service';
declare var UseAdvancedRegister: boolean;
declare var ShowTermsAfterRegister: boolean;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit
{
    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;

    newViewer: FormGroup
    lockMail: boolean = false;
    failedTry: boolean = false;
    constructor (private formBuilder: FormBuilder,
        private viewerApi: ViewerApi,
        private router: Router,
        private progressService: ProgressService,
        private route: ActivatedRoute,
        private viewerService: ViewerService,
        private eventService: GlobalEventService)
    {
        this.newViewer = this.formBuilder.group({
            name: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
            confirmPassword: ['', [Validators.required]]
        }, {
            validator: this.checkPasswords
        });
    }

    ngOnInit ()
    {
        this.failedTry = false;
        if (this.route.snapshot.queryParams['mail'])
        {
            this.newViewer.controls['name'].setValue(this.route.snapshot.queryParams['mail']);
        }
    
        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });
    }

    ngOnDestroy ()
    {
        this.layoutSubscription.unsubscribe();
    }

    signUp ()
    {
        if (this.newViewer.status === 'INVALID')
        {
            this.failedTry = true;
            return;
        }
        let routeToAdvancedRegister = false;
        try
        {
            routeToAdvancedRegister = UseAdvancedRegister;
        }
        catch (e)
        {
        }
        let routeToTerms = false;
        try
        {
            routeToTerms = ShowTermsAfterRegister;
        }
        catch (e)
        {

        }
        if (routeToAdvancedRegister)
        {
            this.viewerService.saveTempUser(this.newViewer.value.name.toLowerCase(), this.newViewer.value.password);
            this.router.navigateByUrl('/advanced-register');
        }
        else if (routeToTerms)
        {
            this.viewerService.saveTempUser(this.newViewer.value.name.toLowerCase(), this.newViewer.value.password);
            this.router.navigateByUrl('/terms-and-conditions');
        }
        else
        {
            this.viewerApi.create({ email: this.newViewer.value.name.toLowerCase(), password: this.newViewer.value.password }).subscribe((viewer: Viewer) =>
            {
                this.router.navigateByUrl('/login').then(() =>
                {
                    let error: PortalError = {
                        code: 0,
                        message: 'VERIFY_MAIL_SEND',
                        name: 'VerificationWarning'
                    }
                    this.progressService.addError(error);
                });
            }, error =>
            {
                this.progressService.addError({ name: error.name, message: error.message, code: error.statusCode });
            });
        }
    };

    checkPasswords (group: FormGroup)
    {
        let pass = group.controls.password.value;
        let confirmPass = group.controls.confirmPassword.value;
        return pass.startsWith(confirmPass) ? null : { notSame: true }
    }

}

