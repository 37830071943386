/* tslint:disable */

declare var Object: any;
export interface TenantInterface {
  "Name"?: string;
  "id"?: number;
}

export class Tenant implements TenantInterface {
  "Name": string;
  "id": number;
  constructor(data?: TenantInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Tenant`.
   */
  public static getModelName() {
    return "Tenant";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Tenant for dynamic purposes.
  **/
  public static factory(data: TenantInterface): Tenant{
    return new Tenant(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Tenant',
      plural: 'Tenants',
      path: 'Tenants',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string',
          default: 'Tenant'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
