import { Component, OnInit, EventEmitter, ElementRef, Output, Input } from '@angular/core';

@Component({
  selector: 'app-confirm-modal-dialog',
  templateUrl: './confirm-modal-dialog.component.html',
  styleUrls: ['./confirm-modal-dialog.component.scss']
})
export class ConfirmModalDialogComponent implements OnInit {

    @Input("isMobile")
    private isMobile: boolean;

    title: string;
    text: string;
    isOpen: boolean = false;
    confirmText: string;
    private isLocked: boolean = false;
    isLoading: boolean = false;

    //Emitter
    @Output()
    private emitter = new EventEmitter<any>();

    constructor () { }

    ngOnInit ()
    {
    }

    open (title: string, text: string, confirmText: string)
    {
        this.title = title;
        this.text = text;
        this.confirmText = confirmText;
        this.isOpen = true;
    }

    setLock (isLocked: boolean)
    {
        this.isLocked = isLocked;
    }

    setNewText (title: string, text: string, confirmText: string)
    {
        this.title = title;
        this.text = text;
        this.confirmText = confirmText;
    }

    startLoading ()
    {
        this.isLoading = true;
    }

    stopLoading ()
    {
        this.isLoading = false;
    }

    callback (value: boolean)
    {
        this.emitter.emit(this.isLocked && value ? 'LockStep' : value);
        if (!this.isLocked || !value)
        {
            this.isOpen = false;
        }
    }

    close ()
    {
        this.isOpen = false;
    }


}
