import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isPrimitive } from 'util';


export enum VisKeyboardEvent {
    ESC,
    F1
}


@Injectable({
  providedIn: 'root'
})
export class GlobalEventService {

    private keyboardSource = new BehaviorSubject({});
    keyboardEvent = this.keyboardSource.asObservable();

    private layoutSource = new BehaviorSubject({});
    layoutChange = this.layoutSource.asObservable();

    constructor () { }

    emitEvent (value: VisKeyboardEvent)
    {
        this.keyboardSource.next(value);
    }

    changeLayout (isMobile: boolean)
    {
        this.layoutSource.next(isMobile);
    }

}
