import { Component, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { ViewerService } from 'src/app/services/viewer.service';
import { RemoteShare, Viewer, RemoteShareApi } from 'src/app/shared/sdk';
import { FormControl } from '@angular/forms';
import { RemoteShareModalComponent } from 'src/app/group/browser/remote-share-modal/remote-share-modal.component';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { GlobalEventService, VisKeyboardEvent } from 'src/app/services/global-event.service';
import { ConfirmModalDialogComponent } from 'src/app/widgets/confirm-modal-dialog/confirm-modal-dialog.component';
import { SimpleDialogComponent } from 'src/app/widgets/simple-dialog/simple-dialog.component';
import { ShareContextMenuComponent } from './share-context-menu/share-context-menu.component';

@Component({
    selector: 'app-share-list',
    templateUrl: './share-list.component.html',
    styleUrls: ['./share-list.component.scss']
})
export class ShareListComponent implements AfterViewInit
{

    @ViewChild(ConfirmModalDialogComponent)
    private confirmModal: ConfirmModalDialogComponent;

    @ViewChild(RemoteShareModalComponent)
    private remoteShareModal: RemoteShareModalComponent;

    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;
    selectedUsers: Viewer[] = [];
    dateNow: number;
    @ViewChild(ShareContextMenuComponent)
    private ctxMenu: ShareContextMenuComponent;

    @ViewChild(SimpleDialogComponent)
    private simpleDialog: SimpleDialogComponent;

    shares: RemoteShare[] = [];
    selection: RemoteShare[] = [];
    allSelected: boolean = false;
    hoveredShare: RemoteShare;

    wrapper: HTMLElement

    //Search bar
    searchText = new FormControl();
    private searchString: string = '';

    //iOs detection
    isiOs: boolean = false;

    //Pagination
    shareCount: number;
    page: number = 1;
    pageSize: number = 75;

    //Sorting
    sortCat: string = 'id';
    sortAsc: boolean = false;

    //Sort display statets for angular build
    showEmailSort: boolean = false;
    showNameSort: boolean = false;
    showFromSort: boolean = false;
    showUntilSort: boolean = false;
    showViewCountSort: boolean = false;
    showLimitSort: boolean = false;
    showCommentSort: boolean = false;
    showIdSort: boolean = true;

    //Call filtering
    private callTimestamp: number;

    private globalEventSubscription: Subscription;
    private viewerSubscription: Subscription;
    activeViewer: Viewer;


    @HostListener('window:keydown', ['$event'])
    onKeyDown (e: KeyboardEvent)
    {
        if (e.ctrlKey && e.keyCode === 65)
        {
            e.preventDefault();
            this.selectAll();
        }
        //Down:
        else if (e.keyCode === 40)
        {
            //Select first element if no selection was done
            if (this.selection.length === 0)
            {
                if (this.shares.length === 0)
                {
                    return;
                }
                this.selection = [this.shares[0]];
                return;
            }
            let lastSelectedElement = this.selection[this.selection.length - 1];
            let index = this.shares.map((share: RemoteShare) => { return share.id; }).indexOf(lastSelectedElement.id);
            let elementToSelect = (index === this.shares.length - 1) ? this.shares[index] : this.shares[index + 1];
            if (this.selection.indexOf(elementToSelect) === -1 && e.ctrlKey && e.shiftKey && elementToSelect)
            {
                this.selection.push(elementToSelect);
            }
            else if (!e.ctrlKey || !e.shiftKey)
            {
                this.selection = [elementToSelect];
            }
        }
        //Up:
        else if (e.keyCode === 38)
        {
            //Select first element if no selection was done
            if (this.selection.length === 0)
            {
                if (this.shares.length)
                {
                    return;
                }
                this.selection = [this.shares[0]];
                return;
            }
            //Check if last selected element was a folder or file
            let lastSelectedElement = this.selection[this.selection.length - 1];
            let index = this.shares.map((share: RemoteShare) => { return share.id; }).indexOf(lastSelectedElement.id);
            //Test if a jump to folders is neccessary
            let elementToSelect = (index === 0) ? this.shares[0] : this.shares[index - 1];
            if (this.selection.indexOf(elementToSelect) === -1 && e.ctrlKey && e.shiftKey && elementToSelect)
            {
                this.selection.push(elementToSelect);
            }
            else if (!e.ctrlKey || !e.shiftKey)
            {
                this.selection = [elementToSelect];
            }
        }
    }

    constructor (private viewerService: ViewerService,
        private remoteShareApi: RemoteShareApi,
        private eventService: GlobalEventService) { }

    ngAfterViewInit ()
    {
        this.isiOs = /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        this.wrapper = document.getElementById('remote-share-table-wrapper');
        this.wrapper.oncontextmenu = e =>
        {
            this.openContextMenu(e);
        }

        this.globalEventSubscription = this.eventService.keyboardEvent.subscribe((key: VisKeyboardEvent) =>
        {
            if (key === VisKeyboardEvent.ESC)
            {
                this.remoteShareModal.close();
            }
        });

        this.searchText.valueChanges.subscribe(val =>
        {
            this.searchString = val;
            let timeStamp = Date.now();
            this.callTimestamp = timeStamp;
            setTimeout(() =>
            {
                if (this.callTimestamp === timeStamp)
                {
                    this.getRemoteShares();
                }
            }, 700);
        });

        //Clear selecion by clicking empty space in the browser body
        let wrapper = document.getElementById('file-link-table-wrapper');
        wrapper.onclick = e =>
        {
            let target = e.target as HTMLElement;
            let acceptedNodeNames = ['TD', 'TR'];
            if (acceptedNodeNames.indexOf(target.nodeName) === -1 && acceptedNodeNames.indexOf(target.parentElement.nodeName) === -1)
            {
                this.selection = [];
                this.allSelected = false;
            }
            return false;
        }
        this.dateNow = Date.now();
    }

    ngOnInit ()
    {
        this.viewerSubscription = this.viewerService.currentViewer.subscribe((viewer: Viewer) =>
        {
            this.activeViewer = viewer;
            this.getRemoteShares();
        });

        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof (isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });
    }

    ngOnDestroy ()
    {
        this.viewerSubscription.unsubscribe();
        this.globalEventSubscription.unsubscribe();
        this.layoutSubscription.unsubscribe();
    }

    openContextMenu (e)
    {
        e.preventDefault();
        e.stopPropagation();
        if (this.selection.length < 2 && this.hoveredShare)
        {
            this.selection = [this.hoveredShare];
        }
        this.ctxMenu.showContextMenu(e, this.selection);
    }

    resetSearchTextString ()
    {
        this.searchText.setValue('', { emitEvent: false });
        this.searchString = '';
        this.getRemoteShares();
    }

    sortTable (category: string)
    {
        this.sortAsc = this.sortCat === category ? !this.sortAsc : false;
        this.sortCat = category;
        this.changePage(1);
    }

    getRemoteShares ()
    {
        if (this.activeViewer && this.activeViewer.id)
        {
            let filter = {
                viewerId: this.activeViewer.id,
                search: this.searchString
            }
            this.remoteShareApi.countWithPageFilter(filter).subscribe((count) =>
            {
                this.shareCount = count.count;
                filter['skip'] = (this.page - 1) * this.pageSize;
                filter['order'] = this.sortCat + ' ' + (this.sortAsc ? 'ASC' : 'DESC');
                filter['limit'] = this.pageSize;
                this.remoteShareApi.getFilesWithPageFilter(filter).subscribe((remoteShares) =>
                {
                    remoteShares = remoteShares.shares;
                    this.shares = remoteShares;
                });
            });
        }
    }

    changePage (page: number)
    {
        if (page === -1)
        {
            page = Math.ceil((this.shareCount) / this.pageSize);
        }
        this.setPage(page);
    }

    setPage (page: number)
    {
        let maxPage = Math.ceil(this.shareCount / this.pageSize);
        if ((page > maxPage || page < 1) && maxPage !== 0)
        {
            return;
        }
        else
        {
            this.page = page;
            this.getRemoteShares();
        }
    }

    sendMails ()
    {
        this.confirmModal.open("AUTOMATED_MAILS", "AUTOMATED_MAILS_CONFIRM", "SEND");
    }

    selectAll (e?: MouseEvent)
    {
        if (e)
        {
            e.preventDefault();
            e.stopImmediatePropagation();
        }
        if (!this.allSelected)
        {
            this.selection = this.shares.slice();
        }
        else
        {
            this.selection = [];
        }
        this.allSelected = !this.allSelected;
    }

    selectShare (share: RemoteShare, e?: MouseEvent)
    {
        if (e)
        {
            e.stopPropagation();
        }
        if (this.selection.indexOf(share) === -1)
        {
            this.selection.push(share);
        }
        else
        {
            this.allSelected = false;
            this.selection.splice(this.selection.indexOf(share), 1);
        }
    }

    clickShare (share: RemoteShare, e: MouseEvent)
    {
        if (e.shiftKey)
        {
            this.applyShiftKeySelection(share, e.ctrlKey);
        }
        else if (e.ctrlKey)
        {
            this.selectShare(share, e);
        }
        else
        {
            this.allSelected = false;
            this.selection = [share];
        }
    }

    applyShiftKeySelection (share: RemoteShare, ctrlKeyClicked: boolean)
    {
        let selectionLength = this.selection.length;
        let lastSelectedElement = this.selection[selectionLength - 1];
        if (!ctrlKeyClicked && selectionLength > 1)
        {
            let s = this.selection.splice(0, selectionLength - 1);
        }
        //Get start index
        let startIndex: number;
        for (let i = 0; i < this.shares.length; i++)
        {
            if (this.shares[i].id === lastSelectedElement.id)
            {
                startIndex = i;
            }
        }
        //Get end index
        let endIndex: number;
        for (let j = 0; j < this.shares.length; j++)
        {
            if (this.shares[j].id === share.id)
            {
                endIndex = j;
            }
        }
        //Select forward
        //Case group is first and last selection
        if (startIndex <= endIndex)
        {
            //Select groups till endindex
            for (let i = startIndex + 1; i < endIndex + 1; i++)
            {
                this.selectShare(this.shares[i]);
            }
        }
        //Select backwards
        //Case group is first and last selection
        else if (endIndex < startIndex)
        {
            //Select groups
            for (let i = startIndex - 1; i >= endIndex; i--)
            {
                this.selectShare(this.shares[i]);
            }
        }
    }

    removeShare (share: RemoteShare)
    {
        this.remoteShareApi.deleteById(share.id).subscribe(res =>
        {
            this.getRemoteShares();
        });
    }

    deleteShares (shares: RemoteShare[])
    {
        let calls: Observable<any>[] = [];
        shares.forEach((share: RemoteShare) =>
        {
            calls.push(this.remoteShareApi.deleteById(share.id));
        });
        forkJoin(calls).subscribe(res =>
        {
            this.selection = [];
            this.getRemoteShares();
        });
    }

    editShares (shares: RemoteShare[])
    {
        this.remoteShareModal.open(shares);
    }

    OnModalClose (event)
    {
        if (event === 'close')
        {
            this.getRemoteShares();
        }
    }

    OnConfirm (event: boolean)
    {
        if (event)
        {
            this.selection.forEach((share: RemoteShare) =>
            {
                if (share.Email && share.Email.trim() !== '')
                {
                    this.remoteShareApi.sendMails(share.id).subscribe();
                }
            });
        }
    }

    OnDialogInput (event)
    {
        if (event.id === 'EditNote')
        {
            let comment = {
                Comment: event.value
            };
            this.remoteShareApi.patchAttributes(this.selection[0].id, comment).subscribe(e =>
            {
                this.getRemoteShares();
            });
        }
    }

    OnContextSelection (contextOption: any)
    {
        switch (contextOption)
        {
            case 'EditNote':
                if (this.selection.length === 1)
                {
                    this.simpleDialog.open('NOTE', 'EDIT_NOTE', 'EditNote', this.selection[0].Comment);
                }
                break;
            case 'EditShares':
                this.editShares(this.selection);
                break;
            case 'DeleteShares':
                this.deleteShares(this.selection);
                break;
            case 'SendMails':
                this.sendMails();
                break;
        }
    }

}
