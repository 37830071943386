import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Viewer, ModelFile, ModelFileApi, SharedFile, SharedFileApi } from 'src/app/shared/sdk';
import { ViewerService } from 'src/app/services/viewer.service';
import { FormControl } from '@angular/forms';
import { PortalError, ProgressService } from 'src/app/services/progress.service';

@Component({
    selector: 'app-share-modal',
    templateUrl: './share-modal.component.html',
    styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit
{
    //Layout variables
    @Input('isMobile')
    private isMobile: boolean = false;
    mobileUsers: Viewer[] = [];
    private unfilteredMobileUsers: Viewer[] = [];

    @Input()
    activeViewer: Viewer;
    @Input()
    files: ModelFile[];
    isOpen: boolean = false;
    private share: Viewer[] = [];
    private noShare: Viewer[] = [];
    private unfilteredShare: Viewer[] = [];
    private unfilteredNoShare: Viewer[] = [];
    private removeCallbackArr: Viewer[] = [];
    private addCallbackArr: Viewer[] = [];

    private selectionRemaining: Viewer[] = [];
    private selectionShared: Viewer[] = [];
   


    private searchTextShare = new FormControl();
    private searchTextRemaining = new FormControl();
    private shareFilterString: string = '';
    private remainingFilterString: string = '';

    //Emitter
    @Output()
    private emitter = new EventEmitter<any>();

    constructor (private viewerService: ViewerService,
        private fileApi: ModelFileApi,
        private sharedFileApi: SharedFileApi,
        private progressService: ProgressService) { }

    ngOnInit ()
    {
        //Register onchange listener for the share search field
        this.searchTextShare.valueChanges.subscribe(val =>
        {
            this.shareFilterString = val;
            this.updateUIArray();
        });

        //Register onchange listener for the remaining search field
        this.searchTextRemaining.valueChanges.subscribe(val =>
        {
            this.remainingFilterString = val;
            this.updateUIArray();
        });
    }


    open ()
    {
        this.share = [];
        this.noShare = [];
        this.unfilteredShare = [];
        this.unfilteredNoShare = [];
        this.removeCallbackArr = [];
        this.addCallbackArr = [];
        this.unfilteredMobileUsers = [];
        this.mobileUsers = [];
        this.fileApi.getSharedWith(this.files[0].id).subscribe((users: Viewer[]) =>
        {
            if (!this.isMobile)
            {
                this.unfilteredShare = users;
                this.share = this.unfilteredShare.slice();
            }
            else
            {
                users.forEach((user: Viewer) =>
                {
                    user['hasShare'] = true;
                    this.unfilteredMobileUsers.push(user);
            });
            }
            let filter = {
                where: {
                    IsDeleted: false
                }
            };
            this.viewerService.getAllUser(filter).subscribe((users: Viewer[]) =>
            {
                if (!this.isMobile)
                {
                    for (let i = 0; i < users.length; i++)
                    {
                        if (this.share.map((item) => { return item.id; }).indexOf(users[i].id) === -1 && users[i].id !== this.activeViewer.id)
                        {
                            this.unfilteredNoShare.push(users[i]);
                        }
                    }
                    this.noShare = this.unfilteredNoShare.slice();
                }
                else
                {
                    users.forEach((user: Viewer) =>
                    {
                        if (this.unfilteredMobileUsers.map((item: Viewer) => { return item.id }).indexOf(user.id) === -1 && this.activeViewer.id !== user.id)
                        {
                            this.unfilteredMobileUsers.push(user);
                        }
                    });
                    this.mobileUsers = this.unfilteredMobileUsers.slice();
                }
                this.isOpen = true;
            });
        });
    }

    updateUIArray ()
    {
        if (!this.isMobile)
        {
            this.share = this.unfilteredShare.filter(item =>
            {
                let regEx = new RegExp(".*(" + this.shareFilterString.toLowerCase() + ").*");
                return regEx.test(item.email.toLowerCase());
            });

            this.noShare = this.unfilteredNoShare.filter(item =>
            {
                let regEx = new RegExp(".*(" + this.remainingFilterString.toLowerCase() + ").*");
                return regEx.test(item.email.toLowerCase());
            });
        }
        else
        {
            this.mobileUsers = this.unfilteredMobileUsers.filter(item =>
            {
                let regEx = new RegExp(".*(" + this.shareFilterString.toLowerCase() + ").*");
                return regEx.test(item.email.toLowerCase());
            });
        }
    }

    resetShareSearchString ()
    {
        this.searchTextShare.setValue('');
    }

    resetRemainingSearchString ()
    {
        this.searchTextRemaining.setValue('');
    }

    selectRemaining (e: MouseEvent, viewer: Viewer)
    {
        if (e.shiftKey)
        {
            this.applyShiftKeySelection(this.noShare, this.selectionRemaining, viewer, e.ctrlKey);
        }
        else if (e.ctrlKey)
        {
            this.select(viewer, this.selectionRemaining)
        }
        else
        {
            this.selectionRemaining = [viewer];
        }
    }

    selectShared (e: MouseEvent, viewer: Viewer)
    {
        if (e.shiftKey)
        {
            this.applyShiftKeySelection( this.share, this.selectionShared, viewer, e.ctrlKey);
        }
        else if (e.ctrlKey)
        {
            this.select(viewer, this.selectionShared)
        }
        else
        {
            this.selectionShared = [viewer];
        }
    }

    select (viewer: Viewer, selection: Viewer[])
    {
        if (selection.indexOf(viewer) === -1)
        {
            selection.push(viewer);
        }
        else
        {
            selection.splice(selection.indexOf(viewer), 1);
        }
    }

    keyDownInShare (e: KeyboardEvent)
    {
        if (e.keyCode === 39)
        {
            this.removeFromShare();
        }
        else if (e.keyCode === 40)
        {
            let lastSelectedElement = this.selectionShared.length === 0 ? this.share[0] : this.selectionShared[this.selectionShared.length - 1];
            let index = this.share.map((admin: Viewer) => { return admin.id }).indexOf(lastSelectedElement.id);
            if (index < this.share.length - 1)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionShared.push(this.share[index + 1]);
                }
                else
                {
                    this.selectionShared = [this.share[index + 1]];
                }
            }
        }
        else if (e.keyCode === 38)
        {
            let lastSelectedElement = this.selectionShared.length === 0 ? this.share[0] : this.selectionShared[this.selectionShared.length - 1];
            let index = this.share.map((admin: Viewer) => { return admin.id }).indexOf(lastSelectedElement.id);
            let lastSelectableIndex = this.share.length;
            if (index > lastSelectableIndex)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionShared.push(this.share[index - 1]);
                }
                else
                {
                    this.selectionShared = [this.share[index - 1]];
                }
            }
        }
        else if (e.ctrlKey && e.keyCode === 65)
        {
            e.preventDefault();
            this.selectAllInShare();
        }
    }

    keyDownInRemaining (e?: KeyboardEvent)
    {
        if (e.keyCode === 37)
        {
            this.addToShare();
        }
        else if (e.keyCode === 40)
        {
            let lastSelectedElement = this.selectionRemaining.length === 0 ? this.noShare[0] : this.selectionRemaining[this.selectionRemaining.length - 1];
            let index = this.noShare.map((user: Viewer) => { return user.id }).indexOf(lastSelectedElement.id);
            if (index < this.noShare.length - 1)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionRemaining.push(this.noShare[index + 1]);
                }
                else
                {
                    this.selectionRemaining = [this.noShare[index + 1]];
                }
            }
        }
        else if (e.keyCode === 38)
        {
            let lastSelectedElement = this.selectionRemaining.length === 0 ? this.noShare[0] : this.selectionRemaining[this.selectionRemaining.length - 1];
            let index = this.noShare.map((user: Viewer) => { return user.id }).indexOf(lastSelectedElement.id);
            if (index > 0)
            {
                if (e.ctrlKey && e.shiftKey)
                {
                    this.selectionRemaining.push(this.noShare[index - 1]);
                }
                else
                {
                    this.selectionRemaining = [this.noShare[index - 1]];
                }
            }
        }
        else if (e.ctrlKey && e.keyCode === 65)
        {
            e.preventDefault();
            this.selectAllRemaining();
        }
    }

    selectAllInShare ()
    {
        this.selectionShared = this.share;
    }

    selectAllRemaining ()
    {
        this.selectionRemaining = this.noShare;
    }

    applyShiftKeySelection (arr: Viewer[], selection: Viewer[] ,viewer: Viewer, ctrlKeyClicked: boolean)
    {
        let selectionLength = selection.length;
        let lastSelectedElement = selection[selectionLength - 1];
        if (!ctrlKeyClicked && selectionLength > 1)
        {
            selection.splice(0, selectionLength - 2);
        }
        //Get start index
        let startIndex: number;
        for (let i = 0; i < arr.length; i++)
        {
            if (arr[i].id === lastSelectedElement.id)
            {
                startIndex = i;
            }
        }
        //Get end index
        let endIndex: number;
        for (let j = 0; j < arr.length; j++)
        {
            if (arr[j].id === viewer.id)
            {
                endIndex = j;
            }
        }
        //Select forward
        if (startIndex <= endIndex)
        {
            for (let i = startIndex + 1; i < endIndex + 1; i++)
            {
                this.select(arr[i], selection);
            }
        }
        //Select backwards
        else if (endIndex < startIndex)
        {
            for (let i = startIndex - 1; i >= endIndex; i--)
            {
                this.select(arr[i], selection);
            }
        }
    }

    addToShare ()
    {
        this.share = this.share.concat(this.selectionRemaining);
        for (let i = 0; i < this.selectionRemaining.length; i++)
        {
            let addEl = this.noShare.splice(this.noShare.indexOf(this.selectionRemaining[i]), 1)[0];
            this.addCallbackArr.push(addEl);
            if (this.removeCallbackArr.indexOf(addEl) !== -1)
            {
                this.removeCallbackArr.splice(this.removeCallbackArr.indexOf(addEl));
            }
        }
        this.selectionRemaining = [];
    }

    removeFromShare ()
    {
        this.noShare = this.noShare.concat(this.selectionShared);
        for (let i = 0; i < this.selectionShared.length; i++)
        {
            let remEl = this.share.splice(this.share.indexOf(this.selectionShared[i]), 1)[0];
            this.removeCallbackArr.push(remEl);
            if (this.addCallbackArr.indexOf(remEl) !== -1)
            {
                this.addCallbackArr.splice(this.addCallbackArr.indexOf(remEl));
            }
        }
        this.selectionShared = [];
    }

    close ()
    {
        this.isOpen = false;
    }

    submitShare ()
    {
        let mapping = [];
        for (let i = 0; i < this.addCallbackArr.length; i++)
        {
            for (let j = 0; j < this.files.length; j++)
            {
                let sharedFile = {
                    viewerId: this.addCallbackArr[i].id,
                    modelFileId: this.files[j].id
                };
                mapping.push(sharedFile);
            }
        }
        for (let i = 0; i < this.removeCallbackArr.length; i++)
        {
            for (let j = 0; j < this.files.length; j++)
            {
                let filter = {
                    where: {
                        viewerId: this.removeCallbackArr[i].id,
                        modelFileId: this.files[j].id,
                    }
                }
                this.sharedFileApi.find(filter).subscribe((sharedFile: SharedFile[]) =>
                {
                    this.sharedFileApi.deleteById(sharedFile[0].id).subscribe(res =>
                    {
                        this.emitter.emit('refresh');
                    });
                });
            }
        }
        if (mapping.length > 0)
        {
            this.sharedFileApi.createMany(mapping).subscribe(() =>
            {
                this.close();
            }, err =>
            {
                let error = err.details[0];
                let visError: PortalError = {
                    code: error.statusCode,
                    message: error.message,
                    name: error.name
                };
                this.progressService.addError(visError);
                this.close();
            });
        }
        else
        {
            this.close();
        }
    }

    setShareState (user: Viewer)
    {
        user['hasShare'] = !user['hasShare'];
        let mapping = [];
        this.files.forEach((file: ModelFile) =>
        {
            let sharedFile = {
                viewerId: user.id,
                modelFileId: file.id
            };
            mapping.push(sharedFile);

        });

        if (user['hasShare'])
        {
            this.sharedFileApi.createMany(mapping).subscribe();
        }
        else
        {
            mapping.forEach(item =>
            {
                this.sharedFileApi.find(item).subscribe((sharedFile: SharedFile[]) =>
                {
                    this.sharedFileApi.deleteById(sharedFile[0].id).subscribe();
                });
            });
        }
    }
}
