/* tslint:disable */
import {
  Group
} from '../index';

declare var Object: any;
export interface RootFolderInterface {
  "Name": string;
  "ViewerId"?: number;
  "rootFolderId"?: number;
  "id"?: number;
  "groupId"?: number;
  groups?: Group[];
  folders?: RootFolder[];
  parentFolder?: RootFolder;
}

export class RootFolder implements RootFolderInterface {
  "Name": string;
  "ViewerId": number;
  "rootFolderId": number;
  "id": number;
  "groupId": number;
  groups: Group[];
  folders: RootFolder[];
  parentFolder: RootFolder;
  constructor(data?: RootFolderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RootFolder`.
   */
  public static getModelName() {
    return "RootFolder";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RootFolder for dynamic purposes.
  **/
  public static factory(data: RootFolderInterface): RootFolder{
    return new RootFolder(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RootFolder',
      plural: 'RootFolders',
      path: 'RootFolders',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "ViewerId": {
          name: 'ViewerId',
          type: 'number'
        },
        "rootFolderId": {
          name: 'rootFolderId',
          type: 'number',
          default: -1
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "groupId": {
          name: 'groupId',
          type: 'number'
        },
      },
      relations: {
        groups: {
          name: 'groups',
          type: 'Group[]',
          model: 'Group',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'rootFolderId'
        },
        folders: {
          name: 'folders',
          type: 'RootFolder[]',
          model: 'RootFolder',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'rootFolderId'
        },
        parentFolder: {
          name: 'parentFolder',
          type: 'RootFolder',
          model: 'RootFolder',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'rootFolderId'
        },
      }
    }
  }
}
