import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RemoteShareApi } from '../shared/sdk/services/custom/RemoteShare';
declare var WebViewerClientUrlOverride;
@Component({
  selector: 'app-remote-share',
  templateUrl: './remote-share.component.html',
  styleUrls: ['./remote-share.component.scss']
})
export class RemoteShareComponent implements AfterViewInit {

    error: boolean = false;
    errorText: string;
    loading: boolean = true;
    constructor (private route: ActivatedRoute,
        private remoteShareApi: RemoteShareApi) { }

    ngAfterViewInit ()
    {
        this.route.params.subscribe(params =>
        {
            if (params.token)
            {
                this.remoteShareApi.openRemoteFile({ token: params.token }).subscribe(urlObj =>
                {
                    this.loading = false;
                    let url = urlObj.url;
                    try
                    {
                        if (WebViewerClientUrlOverride && WebViewerClientUrlOverride !== '' && WebViewerClientUrlOverride !== null)
                        {
                            let parts = url.split('/index.html');
                            if (parts && parts.length === 2)
                            {
                                url = WebViewerClientUrlOverride + 'index.html' + parts[1];
                            }
                        }
                    }
                    catch (e) { }
                    switch (url)
                    {
                        case 'token-early':
                            this.error = true;
                            this.errorText = 'The token is not valid yet.';
                            break;
                        case 'token-expired':
                            this.error = true;
                            this.errorText = 'The token is expired.';
                            break;
                        case 'no-file':
                            this.error = true;
                            this.errorText = 'The shared file is deleted.';
                            break;
                        case 'share-expired':
                            this.error = true;
                            this.errorText = 'The link was already used.';
                            break;
                        default:
                            window.open(url, '_self');
                            break;
                   }
                })
            }
        });
    }

}
