/* tslint:disable */
import {
  Viewer,
  ModelFile,
  Chat
} from '../index';

declare var Object: any;
export interface GroupFolderInterface {
  "Name": string;
  "IsParent"?: boolean;
  "Comment"?: string;
  "CreationTime"?: number;
  "IsDeleted"?: boolean;
  "LastModified"?: number;
  "AssemblyPath"?: string;
  "id"?: number;
  "ownerId"?: number;
  "childFolderType"?: string;
  "childFolderId"?: number;
  owner?: Viewer;
  childrenFiles?: ModelFile[];
  childrenFolder?: GroupFolder[];
  childFolder?: any;
  chat?: Chat;
}

export class GroupFolder implements GroupFolderInterface {
  "Name": string;
  "IsParent": boolean;
  "Comment": string;
  "CreationTime": number;
  "IsDeleted": boolean;
  "LastModified": number;
  "AssemblyPath": string;
  "id": number;
  "ownerId": number;
  "childFolderType": string;
  "childFolderId": number;
  owner: Viewer;
  childrenFiles: ModelFile[];
  childrenFolder: GroupFolder[];
  childFolder: any;
  chat: Chat;
  constructor(data?: GroupFolderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GroupFolder`.
   */
  public static getModelName() {
    return "GroupFolder";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GroupFolder for dynamic purposes.
  **/
  public static factory(data: GroupFolderInterface): GroupFolder{
    return new GroupFolder(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GroupFolder',
      plural: 'GroupFolders',
      path: 'GroupFolders',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string',
          default: 'Directory'
        },
        "IsParent": {
          name: 'IsParent',
          type: 'boolean',
          default: false
        },
        "Comment": {
          name: 'Comment',
          type: 'string',
          default: ''
        },
        "CreationTime": {
          name: 'CreationTime',
          type: 'number'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean',
          default: false
        },
        "LastModified": {
          name: 'LastModified',
          type: 'number'
        },
        "AssemblyPath": {
          name: 'AssemblyPath',
          type: 'string',
          default: 'null'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "ownerId": {
          name: 'ownerId',
          type: 'number'
        },
        "childFolderType": {
          name: 'childFolderType',
          type: 'string'
        },
        "childFolderId": {
          name: 'childFolderId',
          type: 'number'
        },
      },
      relations: {
        owner: {
          name: 'owner',
          type: 'Viewer',
          model: 'Viewer',
          relationType: 'belongsTo',
                  keyFrom: 'ownerId',
          keyTo: 'id'
        },
        childrenFiles: {
          name: 'childrenFiles',
          type: 'ModelFile[]',
          model: 'ModelFile',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'childFileId'
        },
        childrenFolder: {
          name: 'childrenFolder',
          type: 'GroupFolder[]',
          model: 'GroupFolder',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'childFolderId'
        },
        childFolder: {
          name: 'childFolder',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'childFolderId',
          keyTo: 'id'
        },
        chat: {
          name: 'chat',
          type: 'Chat',
          model: 'Chat',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'parentId'
        },
      }
    }
  }
}
