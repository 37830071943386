/* tslint:disable */
import {
  Chat
} from '../index';

declare var Object: any;
export interface LogEventInterface {
  "IsUpload"?: boolean;
  "CreationTime"?: number;
  "ViewerMail"?: string;
  "ItemId": number;
  "IsFile"?: boolean;
  "SubFolderId"?: number;
  "IsDeleted"?: boolean;
  "DeletedItemString"?: string;
  "DeletionTime"?: number;
  "id"?: number;
  "chatId"?: number;
  chat?: Chat;
}

export class LogEvent implements LogEventInterface {
  "IsUpload": boolean;
  "CreationTime": number;
  "ViewerMail": string;
  "ItemId": number;
  "IsFile": boolean;
  "SubFolderId": number;
  "IsDeleted": boolean;
  "DeletedItemString": string;
  "DeletionTime": number;
  "id": number;
  "chatId": number;
  chat: Chat;
  constructor(data?: LogEventInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `LogEvent`.
   */
  public static getModelName() {
    return "LogEvent";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of LogEvent for dynamic purposes.
  **/
  public static factory(data: LogEventInterface): LogEvent{
    return new LogEvent(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'LogEvent',
      plural: 'LogEvents',
      path: 'LogEvents',
      idName: 'id',
      properties: {
        "IsUpload": {
          name: 'IsUpload',
          type: 'boolean',
          default: true
        },
        "CreationTime": {
          name: 'CreationTime',
          type: 'number'
        },
        "ViewerMail": {
          name: 'ViewerMail',
          type: 'string'
        },
        "ItemId": {
          name: 'ItemId',
          type: 'number'
        },
        "IsFile": {
          name: 'IsFile',
          type: 'boolean',
          default: true
        },
        "SubFolderId": {
          name: 'SubFolderId',
          type: 'number'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean',
          default: false
        },
        "DeletedItemString": {
          name: 'DeletedItemString',
          type: 'string'
        },
        "DeletionTime": {
          name: 'DeletionTime',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "chatId": {
          name: 'chatId',
          type: 'number'
        },
      },
      relations: {
        chat: {
          name: 'chat',
          type: 'Chat',
          model: 'Chat',
          relationType: 'belongsTo',
                  keyFrom: 'chatId',
          keyTo: 'id'
        },
      }
    }
  }
}
