import { Component, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { ViewerService } from '../services/viewer.service';
import { ViewerVerificationApi, Viewer, Settings, ViewerVerification, RoleMappingApi, RoleMapping } from '../shared/sdk';
import { FormControl } from '@angular/forms';
import { SettingsService } from '../services/settings.service';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { AccountCreationModalComponent } from './account-creation-modal/account-creation-modal.component';
import { GlobalEventService } from '../services/global-event.service';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements AfterViewInit
{
    @ViewChild(AccountCreationModalComponent)
    private creationModal: AccountCreationModalComponent;

    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;
    selectedUsers: Viewer[] = [];
    private selectedAll: boolean = false;

    //iOs detection
    isiOs: boolean = false;

    users: Viewer[] = [];
    displayedUsers: Viewer[] = [];
    private unfilteredUsers: Viewer[] = [];
    isVerificationEnabled: boolean = false;
    activeViewer: Viewer;
    private viewerSubscription: Subscription;

    //Search
    searchInput = new FormControl();
    private searchString: string = '';

    //Filter
    showVerified: boolean = true;
    showUnverified: boolean = true;
    showAdmin: boolean = true;
    showManager: boolean = true;
    showNormalUser: boolean = true;
    showDeleted: boolean = true;

    //Settings
    private settingsSubscription: Subscription;

    //Sorting
    sortCat: string = 'CreationTime';
    sortAsc: boolean = false;

    //Sort display statets for angular build
    showEmailSort: boolean = false;
    showRoleSort: boolean = false;
    showCreationTimeSort: boolean = false;
    showLastLoginSort: boolean = false;
    showTenantsSort: boolean = false;

    //Pagination
    page: number = 0;
    count: number = 0;

    @HostListener('window:click', ['$event'])
    onClick (e)
    {
        if (!this.isMobile)
        {
            this.selectedUsers = [];
        }
    }

    constructor (private viewerService: ViewerService,
        private verifyApi: ViewerVerificationApi,
        private settingsService: SettingsService,
        private roleMappingApi: RoleMappingApi,
        private eventService: GlobalEventService) { }

    ngAfterViewInit ()
    {
        this.isiOs = /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        this.settingsSubscription = this.settingsService.settings.subscribe((settings: Settings) =>
        {
            if (settings && settings.AdminVerification)
            {
                setTimeout(() =>
                {
                    this.isVerificationEnabled = true;
                });
            }
            this.buildList();
        });

        this.viewerSubscription = this.viewerService.currentViewer.subscribe((viewer: Viewer) =>
        {
            setTimeout(() =>
            {
                this.activeViewer = viewer;
            }, 0);
        });


        this.searchInput.valueChanges.subscribe(val =>
        {
            this.searchString = val;
            this.buildList();
        });
    
        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });
    }

    ngOnDestroy ()
    {
        this.settingsSubscription.unsubscribe();
        this.layoutSubscription.unsubscribe();
        this.viewerSubscription.unsubscribe();
    }

    sortTable (category: string)
    {
        this.sortAsc = this.sortCat === category ? !this.sortAsc : false;
        this.sortCat = category;
        this.page = 0;
        this.applySort();
    }

    applySort ()
    {
        if (this.isMobile)
        {
            this.sortCat = 'id';
            this.sortAsc = false;
        }
        this.users = this.users.sort((viewer1: Viewer, viewer2: Viewer) =>
        {
            let val1, val2;
            if (this.sortCat === 'Role')
            {
                val1 = this.mapRoleToOrder(viewer1);
                val2 = this.mapRoleToOrder(viewer2);
            }
            else if(this.sortCat === 'email' || this.sortCat === 'Tenants')
            {
                val1 = viewer1[this.sortCat].toLowerCase();
                val2 = viewer2[this.sortCat].toLowerCase();
            }
            else if (this.sortCat === 'Verified')
            {
                val1 = viewer1['Verified'] ? 1 : 0;
                val2 = viewer2['Verified'] ? 1 : 0;
            }
            else
            {
                val1 = viewer1[this.sortCat];
                val2 = viewer2[this.sortCat];
            }
            if ( val1 > val2)
            {
                return this.sortAsc ? 1 : -1;
            }
            else
            {
                return this.sortAsc ? -1 : 1;
            }
        });
        this.displayedUsers = this.users.slice(this.page * 30, (this.page + 1) * 30);
    }

    mapRoleToOrder (viewer: Viewer)
    {
        if (viewer['IsAdmin'])
        {
            return 2;
        }
        else if (viewer['IsDeleted'])
        {
            return 0;
        }
        else
        {
            return 1;
        }
    }

    setPage (pageNumber: number)
    {
        if (pageNumber < 0)
        {
            pageNumber = 0;
        }
        let max = Math.ceil(this.count / 30);
        this.page = Math.min(pageNumber, max - 1);
        this.displayedUsers = this.users.slice(this.page * 30, (this.page + 1) * 30);
    }

    buildList ()
    {
        let filter = {};
        if (this.searchString && this.searchString !== null && null !== '')
        {
            filter = {
                where: {
                    email: {
                        ilike: '%' + this.searchString + '%'
                    }
                }
            };
        }
        filter['include'] = { relation: 'viewerVerifications' };
        this.roleMappingApi.find({
            where: {
                or: [
                    { roleId: 1 },
                    { roleId: 3 }
                ]
            }
        }).subscribe((roleMappings: RoleMapping[]) =>
        {
            let admins = roleMappings.map((roleMap: RoleMapping) => { return roleMap.roleId === 1 && parseInt(roleMap.principalId); });
            let manager = roleMappings.map((roleMap: RoleMapping) => { return roleMap.roleId === 3 && parseInt(roleMap.principalId); });
            this.viewerService.getAllUser(filter).subscribe((viewers: Viewer[]) =>
            {
                this.count = viewers.length;
                this.unfilteredUsers = [];
                for (let i = 0; i < viewers.length; i++)
                {
                    let viewer = viewers[i];
                    //Verification check
                    viewer['Verified'] = viewer.viewerVerifications ? viewer.viewerVerifications.Verified : false;
                    //Admin check
                    viewer['IsAdmin'] = admins.indexOf(viewer.id) !== -1;
                    viewer['IsManager'] = manager.indexOf(viewer.id) !== -1;
                    this.unfilteredUsers.push(viewer);
                }
                this.users = this.unfilteredUsers;
                this.applyFilter();
            });
        });
    }

    resetSearchInput ()
    {
        this.searchInput.setValue('');
    }

    grantManagerRights(viewer: Viewer)
    {
        this.viewerService.grantManagerRights(viewer.id).subscribe((state: boolean) =>
        {
            this.buildList();
        });
    }

    revokeManagerRights(viewer: Viewer)
    {
        this.viewerService.revokeManagerRights(viewer.id).subscribe((state: boolean) =>
        {
            this.buildList();
        });
    }

    deleteViewer (viewer: Viewer)
    {
        this.viewerService.deleteViewer(viewer.id).subscribe((state: boolean) =>
        {
            this.buildList();
        });
    }

    restoreViewer (viewer: Viewer)
    {
        this.viewerService.restoreViewer(viewer.id).subscribe(() =>
        {
            this.buildList();
        });
    }

    removeViewer (viewer: Viewer)
    {
        this.viewerService.removeViewer(viewer.id).subscribe(() =>
        {
            this.buildList();
        });
    }

    unlockViewer (viewer: Viewer)
    {
        this.viewerService.verifyViewer(viewer.id).subscribe(() =>
        {
            this.buildList();
        });
    }

    lockViewer (viewer: Viewer)
    {
        this.viewerService.unverifyViewer(viewer.id).subscribe(() =>
        {
            this.buildList();
        });
    }

    setFilter (filter)
    {
        this[filter] = !this[filter];
        this.page = 0;
        this.applyFilter();
    }

    applyFilter ()
    {
        this.users = this.unfilteredUsers.filter((item: Viewer) =>
        {
            if ((!this.showAdmin && item['IsAdmin']) || (!this.showManager && item['IsManager']) ||
                (!this.showNormalUser && !item['IsAdmin'] && !item['IsManager'] && !item.IsDeleted) ||
                (!this.showDeleted && item.IsDeleted) ||
                (this.isVerificationEnabled && !this.showVerified && item['Verified']) || (this.isVerificationEnabled && !this.showUnverified && !item['Verified']))
            {
                return false;
            }
            else
            {
                return true;
            }
        });
        this.count = this.users.length;
        this.applySort();
    }

    confirmMail (user: Viewer)
    {
        this.viewerService.confirmMail(user.id).subscribe(() =>
        {
            this.buildList();
        });
    }

    createAccount ()
    {
        this.creationModal.open();
    }

    OnAccountCreation (event)
    {
        if (event)
        {
            this.buildList();
        }
    }

    //---------------------- Mobile Methods --------------------------------------

    handleWrapperClick ()
    {
        this.selectedUsers = [];
    }

    selectUser (user: Viewer, e?: MouseEvent)
    {
        if (e)
        {
            e.preventDefault();
            e.stopPropagation();
        }
        let index = this.selectedUsers.indexOf(user);
        if (index === -1)
        {
            this.selectedUsers.push(user);
        }
        else
        {
            this.selectedUsers.splice(index, 1);
        }
    }

    selectAll (e: MouseEvent)
    {
        e.preventDefault();
        e.stopPropagation();
        this.selectedAll = !this.selectedAll;
        this.selectedUsers = this.selectedAll ? this.displayedUsers : [];
    }

    unlockSelectedViewers (e: MouseEvent)
    {
        e.preventDefault();
        e.stopPropagation();
        let calls: Observable<any>[] = [];
        this.selectedUsers.forEach((user: Viewer) =>
        {
            if (!user['Verified'])
            {
                calls.push(this.viewerService.verifyViewer(user.id));
            }
        });
        forkJoin(calls).subscribe(() =>
        {
            this.buildList();
        });
    }

    lockSelectedViewers (e: MouseEvent)
    {
        e.preventDefault();
        e.stopPropagation();
        let calls: Observable<any>[] = [];
        this.selectedUsers.forEach((user: Viewer) =>
        {
            if (user['Verified'])
            {
                calls.push(this.viewerService.unverifyViewer(user.id));
            }
        });
        forkJoin(calls).subscribe(() =>
        {
            this.buildList();
        });
    }

    deleteSelectedViewers (e: MouseEvent)
    {
        e.preventDefault();
        e.stopPropagation();
        let calls: Observable<any>[] = [];
        this.selectedUsers.forEach((user: Viewer) =>
        {
            if (!user.IsDeleted)
            {
                calls.push(this.viewerService.deleteViewer(user.id));
            }
        });
        forkJoin(calls).subscribe(() =>
        {
            this.buildList();
        });
    }

    restoreSelectedViewers (e: MouseEvent)
    {
        e.preventDefault();
        e.stopPropagation();
        let calls: Observable<any>[] = [];
        this.selectedUsers.forEach((user: Viewer) =>
        {
            if (!user.IsDeleted)
            {
                calls.push(this.viewerService.restoreViewer(user.id));
            }
        });
        forkJoin(calls).subscribe(() =>
        {
            this.buildList();
        });
    }

}
