import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, Input } from '@angular/core';
import { ViewerService } from 'src/app/services/viewer.service';

@Component({
  selector: 'app-account-creation-modal',
  templateUrl: './account-creation-modal.component.html',
  styleUrls: ['./account-creation-modal.component.scss']
})
export class AccountCreationModalComponent implements OnInit {

    //Layout variables
    @Input('isMobile')
    private isMobile: boolean = false;

    @ViewChild("inputVal")
    private inputVal: ElementRef;

    isOpen: boolean = false;
    accountCreated: boolean = false;
    tmpPassword: string;
    email: string;
    link: string;

    //Emitter
    @Output()
    private emitter = new EventEmitter<any>();

    constructor (private viewerService: ViewerService) { }

    ngOnInit ()
    {
    }

    open ()
    {
        this.accountCreated = false;
        this.isOpen = true;
        this.tmpPassword = undefined;
        this.email = undefined;
        this.link = undefined;
    }

    createAccount ()
    {
        if (this.inputVal.nativeElement.value)
        {
            this.viewerService.createAccount(this.inputVal.nativeElement.value).subscribe((password: any) =>
            {
                this.tmpPassword = password.Password;
                this.email = this.inputVal.nativeElement.value;
                this.link = location.origin + '/change-password';
                this.accountCreated = true;
            });
        }
    }

    close ()
    {
        this.emitter.emit(true);
        this.accountCreated = false;
        this.isOpen = false;
    }

}
