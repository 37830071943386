import { Component, AfterViewInit, Input, HostListener, ElementRef } from "@angular/core";

@Component({
    selector: 'tooltip-content',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.scss']
})
export class TooltipContentComponent implements AfterViewInit
{

    @Input() tooltipTitle: string;
    @Input() tooltipRef: ElementRef;

    @HostListener('window:resize')
    onWindowResize (): void
    {
        // update position based on `ref`
    }

    constructor (private element: ElementRef) { }
    ngAfterViewInit (): void
    {
        let el = this.element.nativeElement as HTMLElement;
        let refEl = this.tooltipRef.nativeElement as HTMLElement;
        let boundingBoxRef = refEl.getBoundingClientRect();
        //Set position
        el.style.top = boundingBoxRef.top - 35 + 'px';
        let refElCenter = boundingBoxRef.left + Math.round((boundingBoxRef.right - boundingBoxRef.left) / 2);
        el.style.left = Math.max(refElCenter - Math.round(el.clientWidth / 2), 0) + 'px';
    }
}