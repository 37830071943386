/* tslint:disable */
import {
  Viewer,
  ImageData,
  Attachment
} from '../index';

declare var Object: any;
export interface ModelFileInterface {
  "Name": string;
  "Path": string;
  "Container": string;
  "UID": string;
  "LastModified"?: number;
  "IsDeleted"?: boolean;
  "Comment"?: string;
  "IsNative": boolean;
  "Is2D"?: boolean;
  "Size"?: number;
  "IsInConv"?: boolean;
  "IsCopy"?: boolean;
  "HasAttachments"?: boolean;
  "id"?: number;
  "childFileId"?: number;
  "childFileType"?: string;
  "ownerId"?: number;
  "imageDataId"?: number;
  childFile?: any;
  owner?: Viewer;
  sharedWith?: Viewer[];
  imageData?: ImageData;
  attachments?: Attachment[];
}

export class ModelFile implements ModelFileInterface {
  "Name": string;
  "Path": string;
  "Container": string;
  "UID": string;
  "LastModified": number;
  "IsDeleted": boolean;
  "Comment": string;
  "IsNative": boolean;
  "Is2D": boolean;
  "Size": number;
  "IsInConv": boolean;
  "IsCopy": boolean;
  "HasAttachments": boolean;
  "id": number;
  "childFileId": number;
  "childFileType": string;
  "ownerId": number;
  "imageDataId": number;
  childFile: any;
  owner: Viewer;
  sharedWith: Viewer[];
  imageData: ImageData;
  attachments: Attachment[];
  constructor(data?: ModelFileInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ModelFile`.
   */
  public static getModelName() {
    return "ModelFile";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ModelFile for dynamic purposes.
  **/
  public static factory(data: ModelFileInterface): ModelFile{
    return new ModelFile(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ModelFile',
      plural: 'modelFiles',
      path: 'modelFiles',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Path": {
          name: 'Path',
          type: 'string'
        },
        "Container": {
          name: 'Container',
          type: 'string'
        },
        "UID": {
          name: 'UID',
          type: 'string'
        },
        "LastModified": {
          name: 'LastModified',
          type: 'number'
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean',
          default: false
        },
        "Comment": {
          name: 'Comment',
          type: 'string',
          default: ''
        },
        "IsNative": {
          name: 'IsNative',
          type: 'boolean',
          default: false
        },
        "Is2D": {
          name: 'Is2D',
          type: 'boolean',
          default: false
        },
        "Size": {
          name: 'Size',
          type: 'number'
        },
        "IsInConv": {
          name: 'IsInConv',
          type: 'boolean',
          default: false
        },
        "IsCopy": {
          name: 'IsCopy',
          type: 'boolean',
          default: false
        },
        "HasAttachments": {
          name: 'HasAttachments',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "childFileId": {
          name: 'childFileId',
          type: 'number'
        },
        "childFileType": {
          name: 'childFileType',
          type: 'string'
        },
        "ownerId": {
          name: 'ownerId',
          type: 'number'
        },
        "imageDataId": {
          name: 'imageDataId',
          type: 'number'
        },
      },
      relations: {
        childFile: {
          name: 'childFile',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'childFileId',
          keyTo: 'id'
        },
        owner: {
          name: 'owner',
          type: 'Viewer',
          model: 'Viewer',
          relationType: 'belongsTo',
                  keyFrom: 'ownerId',
          keyTo: 'id'
        },
        sharedWith: {
          name: 'sharedWith',
          type: 'Viewer[]',
          model: 'Viewer',
          relationType: 'hasMany',
          modelThrough: 'SharedFile',
          keyThrough: 'viewerId',
          keyFrom: 'id',
          keyTo: 'modelFileId'
        },
        imageData: {
          name: 'imageData',
          type: 'ImageData',
          model: 'ImageData',
          relationType: 'belongsTo',
                  keyFrom: 'imageDataId',
          keyTo: 'id'
        },
        attachments: {
          name: 'attachments',
          type: 'Attachment[]',
          model: 'Attachment',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'modelFileId'
        },
      }
    }
  }
}
