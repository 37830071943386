import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { SwitchComponent } from 'src/app/widgets/switch/switch.component';
import { Group, RemoteInviteInterface, RemoteInviteApi } from 'src/app/shared/sdk';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-remote-modal',
    templateUrl: './remote-modal.component.html',
    styleUrls: ['./remote-modal.component.scss']
})
export class RemoteModalComponent implements OnInit
{

    //Layout variables
    @Input('isMobile')
    private isMobile: boolean = false;

    @ViewChild(SwitchComponent)
    private writeAccessSwitch: SwitchComponent;

    @ViewChild('mails')
    mails: ElementRef;

    isOpen: boolean = false;
    private writeAccess: boolean = false;
    group: Group;
    constructor (private inviteApi: RemoteInviteApi) { }

    ngOnInit ()
    {
    }

    open (group: Group)
    {
        this.isOpen = true;
        this.group = group;
        setTimeout(() =>
        {
            this.writeAccessSwitch.setState(this.writeAccess, false);
            this.mails.nativeElement.focus();
        }, 0);
    }

    close ()
    {
        if (this.isOpen)
        {
            this.isOpen = false;
        }
    }


    OnSetWriteAccess (state: boolean)
    {
        this.writeAccess = state;
    }

    submit ()
    {
        let mailArr = this.mails.nativeElement.value.trim().split(',');
        for (let i = 0; i < mailArr.length; i++)
        {
            let mail = mailArr[i];
            let invite: RemoteInviteInterface = {
                Email: mail,
                GroupId: this.group.id,
                WriteAccess: this.writeAccess
            };
            this.inviteApi.create(invite).subscribe(res =>
            {
            });
            this.close();
        }
    }

}
