import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { SDKBrowserModule } from './shared/sdk/index';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './auth.guard';
import { RegisterComponent } from './register/register.component';
import { ViewerService } from './services/viewer.service';
import { FileService } from './services/file.service';
import { GalleryComponent } from './gallery/gallery.component';
import { GroupComponent } from './group/group.component';
import { BrowserBreadcrumbComponent } from './group/browser/browser-breadcrumb/browser-breadcrumb.component';
import { SwitchComponent } from './widgets/switch/switch.component';
import { EmbeddedViewerComponent } from './group/browser/embedded-viewer/embedded-viewer.component';
import { FileSizePipe } from './pipes/file-size/file-size.pipe';
import { ProgressService } from './services/progress.service';
import { ContextMenuComponent } from './group/browser/context-menu/context-menu.component';
import { ShareModalComponent } from './group/browser/share-modal/share-modal.component';
import { ShareComponent } from './share/share.component';
import { GroupModalComponent } from './gallery/group-modal/group-modal.component';
import { GalleryContextMenuComponent } from './gallery/gallery-context-menu/gallery-context-menu.component';
import { ShareWithComponent } from './share-with/share-with.component';
import { BrowserComponent } from './group/browser/browser.component';
import { TrashModalComponent } from './group/trash-modal/trash-modal.component';
import { SimpleDialogComponent } from './widgets/simple-dialog/simple-dialog.component';
import { TooltipContentComponent } from './widgets/tooltip/tooltip.component';
import { TooltipContainerComponent } from './widgets/tooltip/container.component';
import { TooltipDirective } from './widgets/tooltip/tooltip.directive';
import { TooltipService } from './widgets/tooltip/tooltip.service';
import { UploadBarComponent } from './widgets/upload-bar/upload-bar.component';
import { UploadService } from './services/upload.service';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { RemoteShareComponent } from './remote-share/remote-share.component';
import { RemoteShareModalComponent } from './group/browser/remote-share-modal/remote-share-modal.component';
import { ShareListComponent } from './remote-share/share-list/share-list.component';
import { ErrorsHandler } from './interceptor/http-error.interceptor';
import { RequestInterceptor } from './interceptor/http-request.interceptor';
import { RemoteModalComponent } from './gallery/remote-modal/remote-modal.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { SettingsService } from './services/settings.service';
import { SearchComponent } from './search/search.component';
import { GroupAdminModalComponent } from './gallery/group-admin-modal/group-admin-modal.component';
import { ConfirmModalDialogComponent } from './widgets/confirm-modal-dialog/confirm-modal-dialog.component';
import { ResendMailComponent } from './resend-mail/resend-mail.component';
import { FolderService } from './services/folder.service';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AssemblyUploadModalComponent } from './group/assembly-upload-modal/assembly-upload-modal.component';
import { AdvancedRegisterComponent } from './advanced-register/advanced-register.component';
import { TenantManagementComponent } from './tenant-management/tenant-management.component';
import { TenantModalComponent } from './tenant-management/tenant-modal/tenant-modal.component';
import { GlobalEventService } from './services/global-event.service';
import { SyncListComponent } from './sync-list/sync-list.component';
import { ProgressCircleComponent } from './widgets/progress-circle/progress-circle.component';
import { ColorSelectionComponent } from './color-selection/color-selection.component';
import { AccountCreationModalComponent } from './user-management/account-creation-modal/account-creation-modal.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { RoundUpPipe } from './pipes/round/round-up.pipe';
import { TermsComponent } from './terms/terms.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShareContextMenuComponent } from './remote-share/share-list/share-context-menu/share-context-menu.component';
import { ChatComponent } from './group/chat/chat.component';
import { FilePropertiesModalComponent } from './group/browser/file-properties-modal/file-properties-modal.component';
import { LoginOrRegisterComponent } from './login-or-register/login-or-register.component';
import { SortPipe } from './pipes/sort/sort.pipe';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        GalleryComponent,
        GroupComponent,
        BrowserBreadcrumbComponent,
        SwitchComponent,
        EmbeddedViewerComponent,
        FileSizePipe,
        RoundUpPipe,
        ContextMenuComponent,
        ShareModalComponent,
        ShareComponent,
        GroupModalComponent,
        GalleryContextMenuComponent,
        ShareWithComponent,
        BrowserComponent,
        TrashModalComponent,
        SimpleDialogComponent,
        TooltipContentComponent,
        TooltipContainerComponent,
        TooltipDirective,
        UploadBarComponent,
        PasswordResetComponent,
        PasswordChangeComponent,
        RemoteShareComponent,
        RemoteShareModalComponent,
        ShareListComponent,
        RemoteModalComponent,
        UserManagementComponent,
        SearchComponent,
        GroupAdminModalComponent,
        ConfirmModalDialogComponent,
        ResendMailComponent,
        AssemblyUploadModalComponent,
        AdvancedRegisterComponent,
        TenantManagementComponent,
        TenantModalComponent,
        SyncListComponent,
        ProgressCircleComponent,
        ColorSelectionComponent,
        AccountCreationModalComponent,
        InfoPageComponent,
        TermsComponent,
        ShareContextMenuComponent,
        ChatComponent,
        FilePropertiesModalComponent,
        LoginOrRegisterComponent,
        SortPipe
    ],
    imports: [
        BrowserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SDKBrowserModule.forRoot(),
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule
    ],
    providers: [AuthGuard, ViewerService, FileService, ProgressService, TooltipService, UploadService, SettingsService, FolderService, GlobalEventService, {
        provide: ErrorHandler,
        useClass: ErrorsHandler,
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestInterceptor,
        multi: true,
    }],
    entryComponents: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
