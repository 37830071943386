import { Component, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Group, GroupApi, GroupFolder, Viewer, GroupFolderApi, ModelFile, GroupViewerMappingApi, GroupViewerMapping, Settings, ModelFileApi, SourceApi, Chat, ChatInterface, ChatView, RootFolderApi, RootFolder } from '../shared/sdk';
import { ActivatedRoute, Router } from '@angular/router'
import { SwitchComponent } from '../widgets/switch/switch.component';
import { ViewerService } from '../services/viewer.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BrowserComponent } from './browser/browser.component';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { TrashModalComponent } from './trash-modal/trash-modal.component';
import { SimpleDialogComponent } from '../widgets/simple-dialog/simple-dialog.component';
import { PortalError, ProgressService } from '../services/progress.service';
import { UploadBarComponent } from '../widgets/upload-bar/upload-bar.component';
import { UploadItem } from '../services/upload.service';
import { SettingsService } from '../services/settings.service';
import { FileService } from '../services/file.service';
import { FolderService } from '../services/folder.service';
import { AssemblyUploadModalComponent, AssemblyUploadItem } from './assembly-upload-modal/assembly-upload-modal.component';
import { GlobalEventService, VisKeyboardEvent } from '../services/global-event.service';
import { BrowserBreadCrumb } from './browser/browser-breadcrumb/browser-breadcrumb.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChatComponent } from './chat/chat.component';
declare var VSShareBackendUrl: any;
declare var HideSplitBrowser: boolean;


interface AssemblyFile
{
    assemblyId: string,
    fileName: string,
    folderId: number,
    shouldConv: boolean,
    timestamp: boolean,
    size: boolean
}

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss'],
    animations: [
        trigger(
          'projectChatDisplay',
          [
            transition(
              ':enter',
              [
                style({ width: '0%' }),
                animate('0.5s ease-out',
                        style({ width: '*' }))
              ]
            ),
            transition(
              ':leave',
              [
                style({ width: '*' }),
                animate('0.5s ease-in',
                        style({ width: '0%' }))
              ]
            )
          ]
        ),
        trigger(
            'openClose',
            [
                state('true', style({ width: '50%' })),
                state('false', style({ width: '*' })),
                transition('false => true', animate('0.5s ease-out',
                    style({ width: '50%' }))),
                transition('true => false', animate('0.5s ease-in',
                    style({ width: '*' }))),
            ]
        )
      ]
})
export class GroupComponent implements AfterViewInit
{

    @ViewChild(SwitchComponent)
    private modalSwitch: SwitchComponent;

    @ViewChildren(BrowserComponent)
    private fileBrowser: QueryList<BrowserComponent>;

    @ViewChild(TrashModalComponent)
    private trashModal: TrashModalComponent;

    @ViewChild(UploadBarComponent)
    private uploadBar: UploadBarComponent;

    @ViewChild(AssemblyUploadModalComponent)
    private assemblyUploadDialog: AssemblyUploadModalComponent;

    @ViewChild(ChatComponent)
    private chat: ChatComponent;

    //Folder creation
    @ViewChild(SimpleDialogComponent)
    private simpleDialog: SimpleDialogComponent;

    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;
    hideSplitBrowser: boolean = false;

    activeViewer: Viewer
    private isGroup: boolean = false;

    private group: Group;
    private activeFolder: GroupFolder;
    private updateInterval: any;
    private folders: GroupFolder[] = [];

    writeAccess: boolean = false;
    isAssembly: boolean = false;
    formatBlacklist: string[];
    downloadWhitelist: string[];

    //Upload alternatives
    private shouldReplaceFile: boolean = false;
    //Assembly upload
    private assemblyProducts: AssemblyUploadItem[] = [];
    private assembliesInUpload: GroupFolder[] = [];
    private assemblyFiles: AssemblyFile[] = [];
    assemblyUploadBlock: boolean = false;
    private assemblyUploadCall: any;
    private assemblyContainerPath: string;

    //Views
    viewMode: number;
    splitMode: boolean = false;

    //Split view
    private groups: Group[] = [];
    private rightRoot: Group;
    private lastTargetFolder: GroupFolder;
    cutMode: boolean = false;
    private secondBrowserActive: boolean = false;

    //Trash
    private trash: any[] = [];
    trashCount: number = 0;

    //Remote public
    mockUser: boolean = false;

    //Settings
    private settingsSubscription: Subscription;
    settings: Settings;

    //URL paramter
    private params;

    //Pagination
    private folderCount: number;
    private fileCount: number;
    private page: number = 1;
    pageSize: number = 20;

    //Filter
    private showNative: boolean = true;
    private show3DVS: boolean = true;
    private showFolders: boolean = true;
    private showAssemblies: boolean = true;

    //Sort defaults
    private searchString: string = '';
    private sortCat: string = 'Name';
    private lastFolderSort: string = 'Name';
    private sortAsc: boolean = true;
    private lastFolderSortDir: boolean = false;

    //Chat
    chatOpen: boolean = false;
    activeChat: Chat;
    newMessages: boolean = false;

    private viewerSubscription: Subscription;
    private globalEventSubscription: Subscription;

    constructor (private route: ActivatedRoute,
        private router: Router,
        private viewerService: ViewerService,
        private groupApi: GroupApi,
        private folderApi: GroupFolderApi,
        private fileService: FileService,
        private fileApi: ModelFileApi,
        private folderService: FolderService,
        private groupViewerApi: GroupViewerMappingApi,
        private progressService: ProgressService,
        private http: HttpClient,
        private settingsService: SettingsService,
        private sourceApi: SourceApi,
        private eventService: GlobalEventService,
        private rootFolderApi: RootFolderApi)
    {
    }

    ngAfterViewInit ()
    {
        this.viewerSubscription = this.viewerService.currentViewer.subscribe((viewer: Viewer) =>
        {
            setTimeout(() =>
            {
                this.viewMode = viewer.ViewMode;
                if (!this.isMobile)
                {
                    this.modalSwitch.setState(viewer.AdvancedNativeLoading);
                    this.fileBrowser.last.setViewer(viewer);
                }
                this.fileBrowser.first.setViewer(viewer);
                let filterChanged: boolean = this.showNative !== !viewer.HideNative || this.show3DVS !== !viewer.HideNonNative || this.showAssemblies !== !viewer.HideAssemblies || this.showFolders !== !viewer.HideFolders;
                this.showNative = !viewer.HideNative;
                this.show3DVS = !viewer.HideNonNative;
                this.showAssemblies = !viewer.HideAssemblies;
                this.showFolders = !viewer.HideFolders;
                if (!this.activeViewer && viewer.id)
                {
                    this.activeViewer = viewer;
                    this.navigate(this.params, false);
                }
                else if(viewer.id)
                {
                    this.activeViewer = viewer;
                    if (filterChanged && viewer.id !== undefined && this.group)
                    {
                        this.setPage(1, false);
                        if (this.splitMode)
                        {
                            this.setPage(1, true);
                        }
                    }
                }
            }, 0);
        });

        this.settingsSubscription = this.settingsService.settings.subscribe((settings: Settings) =>
        {
            setTimeout(() =>
            {
                this.settings = settings;
                this.formatBlacklist = (this.settings && this.settings.IgnoreOnConvert) ? this.settings.IgnoreOnConvert.toLowerCase().replace(/\s/g, "").split(',') : [];
                this.downloadWhitelist = (this.settings && this.settings.AllowIgnoredFileTypeDownload) ? this.settings.AllowIgnoredFileTypeDownload.toLowerCase().replace(/\s/g, "").split(',') : [];
            });
        });

        this.globalEventSubscription = this.eventService.keyboardEvent.subscribe((key: VisKeyboardEvent) =>
        {
            if (key === VisKeyboardEvent.ESC)
            {
                this.trashModal.close(false);
                this.assemblyUploadDialog.close(false);
                this.simpleDialog.close();
            }
        });

        this.route.params.subscribe(params =>
        {
            if ((!this.activeViewer || !this.activeViewer['isRemote']) && !params.category)
            {
                let viewer = {
                    AdvancedNativeLoading: false,
                    email: '',
                    ViewMode: 0,
                    id: -1
                } as Viewer;
                viewer['isRemote'] = true;
                setTimeout(() =>
                {
                    this.viewerService.changeViewer(viewer);
                }, 0);
                setTimeout(() =>
                {
                    this.fileBrowser.first.setRemotePublicGroup(true);
                    this.mockUser = true;
                    this.params = params;
                    this.navigate(params, false);
                }, 0);
            }
            else
            {
                if (this.params !== params && this.activeViewer)
                {
                    this.navigate(params, false);
                }
                this.params = params;
            }
        });

        this.updateInterval = setInterval(e =>
        {
            this.update();
        }, 5000);

    }

    ngOnInit ()
    {
        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
                this.pageSize = isMobile ? 20 : 75;
            }
        });

        try
        {
            this.hideSplitBrowser = HideSplitBrowser;
        }
        catch (e) { }
    }

    ngOnDestroy ()
    {
        clearInterval(this.updateInterval);
        this.viewerSubscription.unsubscribe();
        this.settingsSubscription.unsubscribe();
        this.globalEventSubscription.unsubscribe();
        this.layoutSubscription.unsubscribe();
    }

    update (resetPage: boolean = false)
    {
        let filter = {
            fields: {
                LastModified: true
            }
        };
        if (resetPage)
        {
            this.page = 1;
            this.fileBrowser.first.setPage(1);
        }
        if (this.isGroup && this.group && this.group.id)
        {
            this.groupApi.findById(this.group.id, filter).subscribe((group: Group) =>
            {
                if (group.LastModified !== this.group.LastModified)
                {
                    this.group.LastModified = group.LastModified;
                    this.getChildren(false);
                    this.getDeletedChildren();
                }
            })
        }
        else if (this.activeFolder && this.activeFolder.id)
        {
            this.folderApi.findById(this.activeFolder.id, filter).subscribe((folder: GroupFolder) =>
            {
                if (folder.LastModified !== this.activeFolder.LastModified)
                {
                    this.activeFolder.LastModified = folder.LastModified;
                    this.getChildren(false);
                    this.getDeletedChildren();
                }
            });
        }
    }

    navigate (params, isSplitBrowser)
    {
        this.searchString = '';
        this.page = 1;
        if (!isSplitBrowser)
        {
            this.fileBrowser.first.setPage(1);
        }
        else
        {
            this.fileBrowser.last.setPage(1);
        }
        if (params.folder)
        {
            if (params.id)
            {
                this.groupApi.findById(params.id).subscribe((group: Group) =>
                {
                    if (!isSplitBrowser)
                    {
                        this.group = group;
                        this.fileBrowser.first.setGroup(group);
                    }
                    else
                    {
                        this.rightRoot = group;
                        this.fileBrowser.last.setGroup(group);
                    }
                });
            }
            this.folderApi.findById(params.folder).subscribe((folder: GroupFolder) =>
            {
                //In case the root group callback hasn't finished
                let tempRoot = new Group({ id: params.id, Name: 'root' });
                let crumb: BrowserBreadCrumb = {
                    name: folder.Name,
                    id: folder.id,
                    isGroup: false,
                    index: undefined,
                    isAssembly: folder.IsParent
                };
                if (!isSplitBrowser)
                {
                    if (!this.group)
                    {
                        this.group = tempRoot;
                    }
                    this.activeFolder = folder;
                    this.isGroup = false;
                    this.fileBrowser.first.setIsGroupFlag(false);
                    this.isAssembly = folder.IsParent;
                    this.fileBrowser.first.setActiveFolder(folder);
                    this.fileBrowser.first.addCrumb(crumb);
                    this.getChildren(false);
                    this.getDeletedChildren();
                    this.getChat(this.folderApi, folder.id);
                }
                else
                {
                    if (!this.rightRoot)
                    {
                        this.rightRoot = tempRoot;
                    }
                    this.lastTargetFolder = folder;
                    this.fileBrowser.last.setActiveFolder(folder);
                    this.fileBrowser.last.addCrumb(crumb);
                    this.getChildren(true);
                }
            }, err =>
            {
                //If folder for splitted browser was deleted
                if (isSplitBrowser)
                {
                    this.lastTargetFolder = undefined;
                    this.rightRoot = undefined;
                }
                let visError: PortalError = {
                    code: err.statusCode,
                    message: err.message,
                    name: 'Server Error'
                };
                this.progressService.addError(visError);
            });
        }
        //Project root
        else
        {
            if (params.id)
            {
                if (!isSplitBrowser)
                {
                    this.isGroup = true;
                    this.fileBrowser.first.setIsGroupFlag(true);
                }
                this.groupApi.findById(params.id).subscribe((group: Group) =>
                {
                    let crumb: BrowserBreadCrumb = {
                        name: group.Name,
                        id: group.id,
                        isGroup: true,
                        index: undefined,
                        isAssembly: false
                    };
                    let folderCrumb: BrowserBreadCrumb;
                    if (group.rootFolderId)
                    {
                        folderCrumb = {
                            name: '',
                            id: group.id,
                            isGroup: false,
                            index: undefined,
                            isAssembly: false,
                            rootFolderId: group.rootFolderId
                        }
                        this.rootFolderApi.findById(group.rootFolderId).subscribe((rf: RootFolder) =>
                        {
                            folderCrumb.name = rf.Name;
                        });
                    }
                    if (!isSplitBrowser)
                    {
                        this.activeFolder = undefined;
                        this.isAssembly = false;
                        this.fileBrowser.first.setActiveFolder(undefined);
                        this.group = group;
                        this.fileBrowser.first.setGroup(group);
                        this.hasWriteAccess();
                        if (folderCrumb)
                        {
                            this.fileBrowser.first.addCrumb(folderCrumb);
                        }
                        this.fileBrowser.first.addCrumb(crumb);
                        this.getChildren(false);
                        this.getDeletedChildren();
                        this.getChat(this.groupApi, group.id);
                    }
                    else
                    {
                        this.rightRoot = group;
                        this.lastTargetFolder = undefined;
                        this.fileBrowser.last.setActiveFolder(undefined);
                        this.fileBrowser.last.setGroup(group);
                        if (folderCrumb)
                        {
                            this.fileBrowser.last.addCrumb(folderCrumb);
                        }
                        this.fileBrowser.last.addCrumb(crumb);
                        this.getChildren(true);
                        this.fileBrowser.last.setIsGroupFlag(true);
                    }
                }, err =>
                {
                    //If group for splitted browser was deleted
                    if (isSplitBrowser)
                    {
                        this.lastTargetFolder = undefined;
                        this.rightRoot = undefined;
                    }
                    let visError: PortalError = {
                        code: err.statusCode,
                        message: err.message,
                        name: 'Server Error'
                    };
                    this.progressService.addError(visError);
                });
            }
        }
    }

    getChat (api, id)
    {
        api.getChat(id, true).subscribe((chat: Chat) =>
        {
            this.activeChat = chat;
            if (this.chat && this.chat.isInit)
            {
                this.chat.chat = chat;
                this.chat.getMessages();
            }
            if (this.activeViewer && this.activeViewer.id && this.activeViewer.id !== -1)
            {
                this.viewerService.getChatView(chat.id).subscribe((chatViews: ChatView[]) =>
                {
                    if (chatViews && chatViews.length > 0)
                    {
                        this.newMessages = chatViews[0].LastViewed < chat.LastEdit;
                    }
                    else
                    {
                        this.newMessages = false;
                    }
                });
            }
        },
            err =>
            {
                if (err.statusCode === 404)
                {
                    let chat: ChatInterface = {
                        ProjectId: this.group.id,
                        LastEdit: Date.now()
                    }
                    api.createChat(id, chat).subscribe((firstChat: Chat) =>
                    {
                        this.activeChat = firstChat;
                    });
                }
            });
    }

    getDeletedChildren ()
    {
        let trash: any[] = [];
        let id: number;
        let filter = {
            where: {
                IsDeleted: true
            }
        };

        if (this.isGroup)
        {
            id = this.group.id;
            if (!id)
            {
                return;
            }
            this.groupApi.getChildrenFolder(id, filter).subscribe((folders: GroupFolder[]) =>
            {
                if (folders.length > 0)
                {
                    trash = trash.concat(folders);
                }
                this.groupApi.getChildrenFiles(id, filter).subscribe((files: ModelFile[]) =>
                {
                    if (files.length > 0)
                    {
                        trash = trash.concat(files);
                    }
                    this.trash = trash;
                    this.trashCount = trash.length;
                });
            });
        }
        else
        {
            id = this.activeFolder.id;
            if (!id)
            {
                return;
            }
            this.folderApi.getChildrenFolder(id, filter).subscribe((folders: GroupFolder[]) =>
            {
                if (folders.length > 0)
                {
                    trash = trash.concat(folders);
                }
                this.folderApi.getChildrenFiles(id, filter).subscribe((files: ModelFile[]) =>
                {
                    if (files.length > 0)
                    {
                        trash = trash.concat(files);
                    }
                    this.trash = trash;
                    this.trashCount = trash.length;
                });
            });
        }
    }

    getChildren (isSplitBrowser: boolean)
    {
        let id: number;
        let filter = {};
        this.searchString = this.searchString;
        if (this.searchString)
        {
            if (this.searchString.startsWith('fileId='))
            {
                filter = {
                    where: {
                        id: this.searchString.split('fileId=')[1],
                        IsDeleted: false
                    }
                };
            }
            else
            {
                filter = {
                    where: {
                        Name: {
                            ilike: '%' + this.searchString + '%'
                        },
                        IsDeleted: false
                    }
                };
            }
        }
        else
        {
            filter = {
                where: {
                    IsDeleted: false
                }
            };
        }
        filter['limit'] = this.pageSize;
        if ((this.isGroup && !isSplitBrowser) || (isSplitBrowser && this.lastTargetFolder === undefined))
        {
            id = !isSplitBrowser ? this.group.id : this.rightRoot.id;
            if (!id)
            {
                return;
            }
            this.getPagedChildren(this.groupApi, id, filter, isSplitBrowser);
        }
        else
        {
            id = !isSplitBrowser ? this.activeFolder.id : this.lastTargetFolder.id;
            if (!id)
            {
                return;
            }
            this.getPagedChildren(this.folderApi, id, filter, isSplitBrowser);
        }
        this.hasWriteAccess();
    }

    getPagedChildren (api, id: number, filter, isSplitBrowser: boolean)
    {
        let countFilter = Object.assign({}, filter);
        countFilter.Name = filter.where.Name;
        countFilter.IsDeleted = false;
        countFilter.where = undefined;
        if (!this.showFolders && !this.showAssemblies)
        {
            this.folders = [];
            filter['order'] = this.sortCat + ' ' + (this.sortAsc ? 'ASC' : 'DESC');
            this.folderCount = 0;
            if (!isSplitBrowser)
            {
                this.fileBrowser.first.setFolderCount(this.folderCount);
                this.fileBrowser.first.setFolders([]);
            }
            else
            {
                this.fileBrowser.last.setFolderCount(this.folderCount);
                this.fileBrowser.last.setFolders([]);
            }
            if (this.sortCat !== 'Size')
            {
                this.lastFolderSortDir = this.sortAsc;
                this.lastFolderSort = this.sortCat;
            }
            this.getPagedFiles(api, id, filter, isSplitBrowser, this.folderCount );
        }
        else
        {
            if (!this.showFolders)
            {
                countFilter.IsParent = true;
            }
            else if (!this.showAssemblies)
            {
                countFilter.IsParent = false;
            }
            api.countChildrenFolder(id, countFilter).subscribe(count =>
            {
                this.folderCount = count.count;
                if (!isSplitBrowser)
                {
                    this.fileBrowser.first.setFolderCount(this.folderCount);
                }
                else
                {
                    this.fileBrowser.last.setFolderCount(this.folderCount);
                }
                let skipFiles: boolean = this.folderCount - (this.pageSize * this.page) >= 0;
                filter.skip = (this.page - 1) * this.pageSize;
                let folderOrder: string = this.sortCat === 'Size' ? this.lastFolderSort : this.sortCat;
                let folderOrderDir: boolean = this.sortCat === 'Size' ? this.lastFolderSortDir : this.sortAsc;
                if (this.sortCat !== 'Size')
                {
                    this.lastFolderSortDir = this.sortAsc;
                    this.lastFolderSort = this.sortCat;
                }
                filter['order'] = folderOrder + ' ' + (folderOrderDir ? 'ASC' : 'DESC');
                if (!this.showFolders)
                {
                    filter.where.IsParent = true;
                }
                else if (!this.showAssemblies)
                {
                    filter.where.IsParent = false;
                }
                api.getChildrenFolder(id, filter).subscribe((folders: GroupFolder[]) =>
                {
                    this.folders = folders;
                    if (!isSplitBrowser)
                    {
                        this.fileBrowser.first.setFiles([]);
                        this.fileBrowser.first.setFolders(folders);
                    }
                    else
                    {
                        this.fileBrowser.last.setFiles([]);
                        this.fileBrowser.last.setFolders(folders);
                    }
                    if (!skipFiles)
                    {
                        filter['order'] = this.sortCat + ' ' + (this.sortAsc ? 'ASC' : 'DESC');
                        if (!this.showNative)
                        {
                            filter.where.IsNative = false;
                        }
                        else if (!this.show3DVS)
                        {
                            filter.where.IsNative = true;
                        }
                        this.getPagedFiles(api, id, filter, isSplitBrowser, this.folderCount);
                    }
                    else
                    {
                        if (countFilter.IsParent)
                        {
                            delete countFilter.IsParent;
                        }
                        if (!this.showNative)
                        {
                            countFilter.IsNative = false;
                        }
                        else if (!this.show3DVS)
                        {
                            countFilter.IsNative = true;
                        }
                        api.countChildrenFiles(id, countFilter).subscribe(count =>
                        {
                            this.fileCount = count.count;
                            if (!isSplitBrowser)
                            {
                                this.fileBrowser.first.setFileCount(this.fileCount);
                            }
                            else
                            {
                                this.fileBrowser.last.setFileCount(this.fileCount);
                            }
                        });
                    }
                });
            });
        }
    }

    getPagedFiles (api, id: number, filter, isSplitBrowser: boolean, remainingFolderCount: number)
    {
        if (!this.show3DVS && !this.showNative)
        {
            this.fileCount = 0;
            if (!isSplitBrowser)
            {
                this.fileBrowser.first.setFileCount(this.fileCount);
                this.fileBrowser.first.setFiles([]);
            }
            else
            {
                this.fileBrowser.last.setFileCount(this.fileCount);
                this.fileBrowser.last.setFiles([]);
            }
            return;
        }
        let countFilter = Object.assign({}, filter);
        countFilter.Name = filter.where.Name;
        countFilter.IsDeleted = false;
        delete countFilter.where;
        if (countFilter.IsParent)
        {
            delete countFilter.IsParent;
        }
        if (!this.showNative)
        {
            countFilter.IsNative = false;
            filter.where.IsNative = false;
        }
        else if (!this.show3DVS)
        {
            countFilter.IsNative = true;
            filter.where.IsNative = true;
        }
        api.countChildrenFiles(id, countFilter).subscribe(count =>
        {
            this.fileCount = count.count;
            if (!isSplitBrowser)
            {
                this.fileBrowser.first.setFileCount(this.fileCount);
            }
            else
            {
                this.fileBrowser.last.setFileCount(this.fileCount);
            }
            let pageForFiles: number = Math.ceil((this.page * this.pageSize - this.folderCount) / this.pageSize);
            if (remainingFolderCount > 0 && pageForFiles === 1)
            {
                filter.limit = this.pageSize - remainingFolderCount;
            }
            filter.skip = (pageForFiles - 1) * this.pageSize - (pageForFiles > 1 ? remainingFolderCount : 0);
            //Compute how many folder are displayed including this page
            api.getChildrenFiles(id, filter).subscribe((files: ModelFile[]) =>
            {
                if (!isSplitBrowser)
                {
                    this.fileBrowser.first.setFiles(files);
                    this.fileBrowser.first.applySelection();
                }
                else
                {
                    this.fileBrowser.last.setFiles(files);
                    this.fileBrowser.last.applySelection();
                }
            });
        });
    }

    setPage (page: number, isSplitBrowser: boolean)
    {
        let maxPage = Math.ceil((this.folderCount + this.fileCount) / this.pageSize);
        if ((page > maxPage || page < 1) && maxPage !== 0)
        {
            return;
        }
        else
        {
            this.page = page;
            if (!isSplitBrowser)
            {
                this.fileBrowser.first.setPage(page);
            }
            else
            {
                this.fileBrowser.last.setPage(page);
            }
            this.getChildren(isSplitBrowser);
        }
    }

    hasWriteAccess ()
    {
        if (this.rightRoot)
        {
            this.fileBrowser.last.setWriteAccess(true);
            return;
        }
        if (this.mockUser)
        {
            this.writeAccess = false;
            return;
        }
        setTimeout(() =>
        {
            let filter = {
                where: {
                    viewerId: this.activeViewer.id,
                    writeAccess: true
                }
            };
            this.groupViewerApi.find(filter).subscribe((map: GroupViewerMapping[]) =>
            {
                if (!this.activeViewer || !this.activeViewer.id)
                {
                    this.hasWriteAccess();
                }
                else
                {
                    if (this.group.viewerId === this.activeViewer.id || map.map(item => { return item.groupId }).indexOf(this.group.id) !== -1
                        || (this.activeViewer['Verified'] && this.group.IsPublic))
                    {
                        this.writeAccess = true;
                        this.fileBrowser.first.setWriteAccess(true);
                    }
                    else
                    {
                        this.writeAccess = false;
                    }
                }
            });
        }, 0);
    }

    setViewMode (viewMode)
    {
        if (!this.mockUser)
        {
            this.viewerService.setViewMode(viewMode).subscribe((viewer) =>
            {
                this.viewerService.changeViewer(viewer);
            });
        }
        else
        {
            this.activeViewer.ViewMode = viewMode;
            this.viewMode = viewMode;
            this.fileBrowser.first.setViewer(this.activeViewer);
        }
    }

    createFolder (secondBrowser: boolean)
    {
        this.secondBrowserActive = secondBrowser;
        this.simpleDialog.open('CREATE_FOLDER', 'FOLDER_NAME', 'FolderCreation');
    }

    saveFolder (name: string)
    {
        if (this.secondBrowserActive)
        {
            this.fileBrowser.last.saveFolder(name);
        }
        else
        {
            this.fileBrowser.first.saveFolder(name);
        }
        this.secondBrowserActive = false;
    }

    uploadFile (event)
    {
        if (this.shouldReplaceFile)
        {
            this.replaceFile(event);
        }
        else
        {
            let files = event.target.files;
            for (let i = 0; i < files.length; i++)
            {
                this.singleUpload(files[i]);
            }
        }
        let target = event.target as HTMLInputElement;
        target.value = '';
    }

    uploadAssembly (event)
    {
        let uid = this.create_UUID();
        let firstLevelFiles: AssemblyUploadItem[] = [];
        //Non IE
        if (event.target.files && event.target.files.length > 0 && event.target.files[0].webkitRelativePath)
        {
            for (let i = 0; i < event.target.files.length; i++)
            {
                let item: AssemblyUploadItem = {
                    name: event.target.files[i].webkitRelativePath,
                    lastModified: event.target.files[i].lastModified,
                    assemblyId: uid
                };
                firstLevelFiles.push(item);
            }
            firstLevelFiles = this.sortAssemblyFiles(firstLevelFiles);
        }
        //IE
        else
        {
            firstLevelFiles = event.target.files;
        }
        setTimeout(() =>
        {
            this.assemblyUploadDialog.show(uid, firstLevelFiles, event.target.files);
        }, 100);
    }

    blockUI ()
    {
        this.progressService.startLoadingScreen();
        return true;
    }

    sortAssemblyFiles (files: AssemblyUploadItem[])
    {
        let assemblyExt: string[] = ['.catproduct', '.cpixml', '.asm', '.plmxml', '.sldasm'];
        return files.sort((f1: AssemblyUploadItem, f2: AssemblyUploadItem) =>
        {
            return f1.name.toLocaleLowerCase() !== f2.name.toLocaleLowerCase() ? f1.name.toLocaleLowerCase() < f2.name.toLocaleLowerCase() ? -1 : 1 : 0;
        }).sort((i1: AssemblyUploadItem, i2: AssemblyUploadItem) =>
        {
            if (assemblyExt.some(ext => i1.name.toLowerCase().includes(ext)))
            {
                return -1;
            }
            else if (assemblyExt.some(ext => i2.name.toLowerCase().includes(ext)))
            {
                return 1;
            }
            else
            {
                return 0;
            }
        });
    }

    recAssemblyUpload (files: any, index: number, folder: GroupFolder, uid: string)
    {
        let skipUpload = false;
        if (files.length > index && !skipUpload)
        {
            this.assemblyPartUpload(files, index, folder, uid)
        }
        //Recursion ready
        else
        {
            this.assembliesInUpload = this.assembliesInUpload.filter((item: GroupFolder) =>
            {
                if (folder.id === item.id)
                {
                    item['InAssemblyUpload'] = undefined;
                }
                return item.id !== folder.id;
            });
            let virtFiles = this.assemblyFiles.filter((item: AssemblyFile) =>
            {
                return item.assemblyId === uid;
            });
            this.assemblyVirtFileCreationRec(virtFiles);
        }
    }

    assemblyPartUpload(files: any[], index: number, folder: GroupFolder, uid: string)
    {
        let shouldConv = this.assemblyProducts.map((item: AssemblyUploadItem) => { return item.name; }).indexOf(files[index].webkitRelativePath) !== -1;
        let assembylFile: AssemblyFile = {
            fileName: files[index].webkitRelativePath,
            assemblyId: uid,
            folderId: folder.id,
            shouldConv: shouldConv,
            timestamp: files[index].lastModified,
            size: files[index].size
        };
        this.assemblyFiles.push(assembylFile);
        index++;
        this.folders = this.folders.map((f: GroupFolder) =>
        {
            if (f.id == folder.id)
            {
                f['InAssemblyUpload'] = true;
            }
            return f;
        });
        if (!this.splitMode || !this.secondBrowserActive)
        {
            this.fileBrowser.first.setFolders(this.folders);
        }
        else if (this.secondBrowserActive)
        {
            this.fileBrowser.last.setFolders(this.folders);
        }
        this.recAssemblyUpload(files, index, folder, uid);
        return;
    }

    assemblyVirtFileCreationRec(virtFiles: AssemblyFile[])
    {
        let filesToUpload = [];
        for (let i = 0; i < virtFiles.length; i++)
        {
            let virtFile: AssemblyFile = virtFiles[i];
            let fileObj = {
                AssemblyId: virtFile.assemblyId,
                FileName: virtFile.fileName,
                ShouldConv: virtFile.shouldConv,
                Size: virtFile.size,
                Timestamp: virtFile.timestamp,
                ParentId: virtFile.folderId
            };
            filesToUpload.push(fileObj);
        }
        this.folderApi.createAssemblyFile(filesToUpload).subscribe(res =>
        {
            this.assemblyUploadBlock = false;
        });
    }

    singleUpload (file, folderId?: number)
    {
        let name = file.name;
        let size = file.size;

        let modelFileObject = {
            Name: name,
            Container: '',
            UID: undefined,
            Path: ' ',
            Size: undefined,
            ownerId: this.activeViewer.id,
            LastModified: file.lastModified
        };
        let formData = new FormData();
        //Check file etxentions for numeric endings and remove them (Creo)
        let uploadName: string;
        let splittedNameArr = file.name.split('.');
        let ext = splittedNameArr.pop();
        uploadName = !isNaN(ext) ? splittedNameArr.join('.') : file.name;
        formData.append('file', file, uploadName);
        let tempFile: UploadItem;
        let headers = new HttpHeaders({ "Authorization": this.viewerService.getAuthToken().id });
        let call = this.http.post(VSShareBackendUrl + '/api/Sources/temp/upload', formData, { headers }).subscribe((res: any) =>
        {
            //Remove placeholder file
            if (this.secondBrowserActive)
            {
                this.fileBrowser.last.removeTempFile(tempFile);
            }
            else
            {
                this.fileBrowser.first.removeTempFile(tempFile);
            }
            //Create virtual file
            let savedFile = res.result.files.file[0];
            modelFileObject.Container = 'repo';
            modelFileObject.UID = savedFile.name;
            modelFileObject.Size = savedFile.size;
            if (folderId)
            {
                this.folderApi.createChildrenFiles(folderId, modelFileObject).subscribe(resp =>
                    {
                        this.getChildren(false);
                        if (this.splitMode)
                        {
                            this.getChildren(true);
                        }
                });
                return;
            }
            let id = this.isGroup ? this.group.id : this.activeFolder.id;
            let isRootGroup = this.isGroup;
            if (this.secondBrowserActive)
            {
                id = this.fileBrowser.last.getActiveRoot().id;
                isRootGroup = this.fileBrowser.last.isRootGroup();
                this.secondBrowserActive = false;
            }
            id = folderId ? folderId : id;
            if (isRootGroup)
            {
                this.groupApi.createChildrenFiles(id, modelFileObject).subscribe(resp =>
                {
                    this.getChildren(false);
                    if (this.splitMode)
                    {
                        this.getChildren(true);
                    }
                });
            }
            else
            {
                this.folderApi.createChildrenFiles(id, modelFileObject).subscribe(resp =>
                {
                    this.getChildren(false);
                    if (this.splitMode)
                    {
                        this.getChildren(true);
                    }
                });
            }
        }, err =>
        {
            //Remove placeholder file
            if (this.secondBrowserActive)
            {
                this.fileBrowser.last.removeTempFile(tempFile);
            }
            else
            {
                this.fileBrowser.first.removeTempFile(tempFile);
            }
            let visError: PortalError = {
                code: err.status,
                message: err.error.error.message,
                name: err.error.error.name
            };
            this.progressService.addError(visError);
        });
        //Add placeholder file
        let timestamp = Date.now();
        if (this.secondBrowserActive)
        {
            tempFile = this.fileBrowser.last.addTempFile({ name: file.name, uploadTime: timestamp, call: call });
        }
        else
        {
            tempFile = this.fileBrowser.first.addTempFile({ name: file.name, uploadTime: timestamp, call: call });
        }
    }

    replaceFile (event)
    {
        let uploadFile = event.target.files[0];

        let formData = new FormData();
        formData.append('file', uploadFile, uploadFile.name);
        let tempFile: UploadItem;
        let headers = new HttpHeaders({ "Authorization": this.viewerService.getAuthToken().id });
        let call = this.http.post(VSShareBackendUrl + '/api/Sources/temp/upload', formData, { headers }).subscribe((res: any) =>
        {
            //Update virtual file
            let savedFile = res.result.files.file[0];
            let selectedFile;
            if (this.secondBrowserActive)
            {
                selectedFile = this.fileBrowser.last.getSelection()[0];
            }
            else
            {
                selectedFile = this.fileBrowser.first.getSelection()[0];
            }
            this.fileApi.patchAttributes(selectedFile.id, { Name: uploadFile.name, UID: savedFile.name, Size: savedFile.Size, LastModified: uploadFile.lastModified, Replace: true }).subscribe((res) =>
            {
                this.update();
            });
        });
    }

    completeDeletion ()
    {
        if (this.secondBrowserActive)
        {
            this.fileBrowser.last.deleteSelection();
        }
        else
        {
            this.fileBrowser.first.deleteSelection();
        }
    }

    //Split mode
    toggleSplitMode ()
    {
        $(window).trigger('resize');
        if (this.splitMode)
        {
            this.splitMode = false;
        }
        else
        {
            this.splitMode = true;
            this.getAccessibleGroups();
        }
    }

    setRightRoot (group: Group)
    {
        this.rightRoot = group;
        this.navigate({ id: group.id }, true);
    }

    getAccessibleGroups ()
    {
        forkJoin(this.viewerService.getAccsessibleGroups()).subscribe(groups =>
        {
            if (groups.length > 0 && Array.isArray(groups[0]))
            {
                this.groups = groups[0];
            }
            if (groups.length > 1 && Array.isArray(groups[1]))
            {
                for (let i = 0; i < groups[1].length; i++)
                {
                    this.groupApi.findById(groups[1][i].groupId).subscribe((group: Group) =>
                    {
                        this.groups.push(group);
                    })
                }
            }
        });
    }

    move (direction: string)
    {
        let files: ModelFile[] = [];
        let folders: GroupFolder[] = [];
        let isRightToLeft: boolean = direction === 'right';
        let browser: BrowserComponent = isRightToLeft ? this.fileBrowser.first : this.fileBrowser.last;
        let data = browser.getSelection();
        data.forEach(item =>
        {
            if (item.UID)
            {
                item.IsCopy = true;
                files.push(item);
            }
            else
            {
                item['ProjectId'] = isRightToLeft ? this.group.id : this.rightRoot.id;
                folders.push(item);
            }
        });
        if (folders.length > 0 || files.length > 0)
        {
            if (this.cutMode)
            {
                browser.deleteSelection();
            }
            this.addData(isRightToLeft, files);
            this.pasteFolder(folders, undefined, isRightToLeft);
        }
    }

    paste (onDropFolder: GroupFolder, isSplitBrowser: boolean = false)
    {
        if (this.chatOpen)
        {
            return;
        }
        let collection = [];
        if ((this.fileService.isClipboardCut() || this.folderService.isClipboardCut()) && !isSplitBrowser)
        {
            this.fileBrowser.first.crudActive = false;
            this.fileBrowser.first.crudStarted = false;
            this.fileBrowser.first.clipboardSize = 0;
            this.fileBrowser.first.isClipboardCut = false;
        }
        let files = this.fileService.getClipboard();
        if (files.length > 0)
        {
            files.forEach((f: ModelFile) =>
            {
                f.IsCopy = true;
            });
            collection = files;
            let rootFolder = isSplitBrowser ? this.lastTargetFolder : this.activeFolder;
            if (onDropFolder || rootFolder)
            {
                let folder = onDropFolder ? onDropFolder : rootFolder;
                let tmpFolder = Object.assign({}, rootFolder);
                if (isSplitBrowser)
                {
                    this.lastTargetFolder = folder;
                }
                else
                {
                    this.activeFolder = folder;
                }
                this.addData(isSplitBrowser, collection);
                if (isSplitBrowser)
                {
                    this.lastTargetFolder = tmpFolder;
                }
                else
                {
                    this.activeFolder = tmpFolder;
                }
            }
            else
            {
                this.addData(isSplitBrowser, collection);
            }
        }
        let folders = this.folderService.getClipboard();
        if (folders.length > 0)
        {
           this.pasteFolder(folders, onDropFolder ? onDropFolder.id : undefined, isSplitBrowser);
        }
    }

    pasteFolder (folder: GroupFolder[], dropFolderId?: number, isSplitBrowser?: boolean)
    {
        if (!folder || folder.length === 0)
        {
            return;
        }
        let destinationFolderId: number;
        //Splitmode
        if (isSplitBrowser)
        {
            destinationFolderId = this.lastTargetFolder ? this.lastTargetFolder.id : undefined;
        }
        else if (dropFolderId)
        {
            destinationFolderId = dropFolderId;
        }
        else
        {
            destinationFolderId = this.activeFolder ? this.activeFolder.id : undefined;
        }
        this.progressService.startLoadingScreen('PASTE_WAIT');
        let calls: Observable<any>[] = [];
        let clearCalls: Observable<any>[] = [];
        folder.forEach((f: GroupFolder) =>
        {
            let dtoObj =
            {
                SourceProjectId: f['ProjectId'],
                SourceFolderId: f.id,
                DestinationFolderId: destinationFolderId
            };
            if (f.IsDeleted)
            {
                clearCalls.push(this.folderApi.deleteById(f.id));
            }
            calls.push(this.groupApi.pasteFolder( isSplitBrowser ? this.rightRoot.id : this.group.id, dtoObj));
        });
        forkJoin(calls).subscribe(res =>
        {
            this.progressService.endLoadingScreen();
            if (isSplitBrowser)
            {
                this.getChildren(true);
            }
            forkJoin(clearCalls).subscribe();
        }, err =>
        {
            this.progressService.endLoadingScreen();
        });
    }

    addData (isSplitBrowser: boolean, data)
    {
        let calls: Observable<any>[] = [];
        let group = isSplitBrowser ? this.rightRoot : this.group;
        let folder = isSplitBrowser ? this.lastTargetFolder : this.activeFolder;
        let api = !folder ? this.groupApi : this.folderApi;
        let id = !folder ? group.id : folder.id;
        let clearCalls: Observable<any>[] = [];
        let tmpData = data.map((dto) =>
        {
            let newDTO = Object.assign({}, dto)
            newDTO.id = undefined;
            if (dto.UID)
            {
                newDTO.pastedFrom = dto.id;
                if (dto.IsDeleted)
                {
                    clearCalls.push(this.fileApi.deleteById(dto.id));
                }
                newDTO.IsDeleted = false;
                calls.push(api.createChildrenFiles(id, newDTO));
            }
            return newDTO;
        });
        this.progressService.startLoadingScreen('PASTE_WAIT');
        forkJoin(calls).subscribe(res =>
        {
            this.progressService.endLoadingScreen();
            forkJoin(clearCalls).subscribe();
            this.getChildren(isSplitBrowser);
        });
    }

    openContextMenu (event: MouseEvent)
    {
        event.preventDefault();
        event.stopPropagation();
        let x = document.body.clientWidth;
        let y = 120;
        this.fileBrowser.first.openContextMenu(x, y);
    }

    downloadFolders (folders: GroupFolder[], includeAttachments: boolean)
    {
        let calls: Observable<any>[] = [];
        this.progressService.startLoadingScreen('START_ZIPPING');
        let headers = new HttpHeaders({
            "Authorization": this.viewerService.getAuthToken().id,
        });
        folders.forEach((folder: GroupFolder) =>
        {
            let secret = this.create_UUID();
            let method = folder.IsParent ? this.viewerService.downloadAssembly.bind(this.viewerService) : this.viewerService.downloadFolder.bind(this.viewerService);
            folder['secret'] = secret;
            calls.push(method(folder, this.group.id, secret, includeAttachments));
        });
        forkJoin(calls).subscribe(zips =>
        {
            this.progressService.endLoadingScreen();
            zips.forEach((zip, index) =>
            {
                let queryParams = '?project=' + this.group.id + '&id=' + folders[index].id + '&secret=' + folders[index]['secret'] + '&hash=' + zip.Response.Hash;
                this.http.get(VSShareBackendUrl + '/api/Sources/downloads/download/' + zip.Response.Path + queryParams,
                    { headers, responseType: 'blob' }).subscribe((res: any) =>
                    {
                        let link = document.createElement('a');
                        link.href = URL.createObjectURL(res);
                        link.download = folders[index].Name + '.zip';
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        URL.revokeObjectURL(res);
                    });
            });
        });
    }

    downloadFile (file: ModelFile, includeAttachments: boolean)
    {
        if (!includeAttachments)
        {
            this.downloadFileWithoutAttachments(file);
        }
        else
        {
            this.downloadFileWithAttachments(file);
        }
    }

    downloadFileWithAttachments (file: ModelFile)
    {
        let headers = new HttpHeaders({
            "Authorization": this.viewerService.getAuthToken().id,
        });
        let secret = this.create_UUID();
        this.viewerService.downloadFileWithAttachments(file, this.group.id, secret).subscribe(zip =>
        {
            let queryParams = '?project=' + this.group.id + '&id=' + file.id + '&secret=' + secret + '&hash=' + zip.Response.Hash;
            this.http.get(VSShareBackendUrl + '/api/Sources/downloads/download/' + zip.Response.Path + queryParams,
                { headers, responseType: 'blob' }).subscribe((res: any) =>
                {
                    let link = document.createElement('a');
                    link.href = URL.createObjectURL(res);
                    link.download = file.Name + '.zip';
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    URL.revokeObjectURL(res);
                });
        });
    }

    downloadFileWithoutAttachments (file: ModelFile)
    {
        let headers = new HttpHeaders({
            "Authorization": this.viewerService.getAuthToken().id,
        });
        let path: string;
        let fileName: string = file.UID;
        if (this.isAssembly)
        {
            path = file.Container.split('/')[0];
            fileName += '_VSAssemblyTemp';
        }
        else
        {
            path = 'repo';
        }
        this.http.get(VSShareBackendUrl + '/api/Sources/' + path + '/download/' + fileName + '?project=' + this.group.id + '&fileId=' + file.id,
            { headers, responseType: 'blob' }).subscribe((res: any) =>
            {
                let link = document.createElement('a');
                link.href = URL.createObjectURL(res);
                link.download = file.Name;
                document.body.appendChild(link);
                link.click();
                link.remove();
                URL.revokeObjectURL(res);
            });
    }

    //Chat
    toggleChat ()
    {
        this.chatOpen = !this.chatOpen;
        this.newMessages = false;
    }

    //Event Emitter/Listener
    LeftBrowserEmit (event)
    {
        if (event === 'UploadFile' && this.writeAccess)
        {
            this.shouldReplaceFile = false;
            let input = document.getElementById('file-upload-input');
            input.click();
        }
        else if (event === 'UploadAssemblyFolder' && this.writeAccess)
        {
            this.shouldReplaceFile = false;
            let input = document.getElementById('folder-upload-input');
            input.click();
        }
        else if (event === 'ReplaceFile' && this.writeAccess)
        {
            this.shouldReplaceFile = true;
            let input = document.getElementById('file-upload-input');
            input.click();
        }
        else if (event === 'CreateFolder' && this.writeAccess)
        {
            this.createFolder(false);
        }
        else if (event.DragFinished && this.writeAccess)
        {
            event.DragFinished.forEach(item =>
            {
                if (item.UID)
                {
                    item.IsCopy = true;
                }
            });
            this.addData(false, event.DragFinished);
            if (!this.cutMode)
            {
                this.getChildren(true);
            }
            else
            {
                this.fileBrowser.last.deleteSelection();
            }
        }
        else if (event.Paste && this.writeAccess)
        {
            this.paste(event.Folder, false);
        }
        else if (event === 'Select')
        {
            this.secondBrowserActive = false;
        }
        else if (event === 'Update')
        {
            this.update();
        }
        else if (event === 'UpdateAndReset')
        {
            this.update(true);
        }
        else if (event.FolderSaved && this.writeAccess)
        {
            if (this.isGroup)
            {
                this.groupApi.createChildrenFolder(this.group.id, { Name: event.FolderSaved, ownerId: this.activeViewer.id }).subscribe(() =>
                {
                    this.getChildren(false);
                });
            }
            else
            {
                this.folderApi.createChildrenFolder(this.activeFolder.id, { Name: event.FolderSaved, ownerId: this.activeViewer.id }).subscribe(() =>
                {
                    this.getChildren(false);
                });
            }
        }
        else if (event.JumpToFolder)
        {
            let params = event.JumpToFolder;
            if (params.folder)
            {
                this.router.navigate(['../', this.group.id, { folder: params.folder }], { relativeTo: this.route });
            }
            else
            {
                this.router.navigate(['../', this.group.id ], { relativeTo: this.route });
            }
        }
        else if (event.ExternalDrop && this.writeAccess)
        {
            let files = event.ExternalDrop;
            for (let i = 0; i < files.length; i++)
            {
                if (event.Folder)
                {
                    this.singleUpload(files[i], event.Folder.id);
                }
                else
                {
                    this.singleUpload(files[i]);
                }
            }
        }
        else if (event.Page)
        {
            this.sortCat = event.SortCat;
            this.sortAsc = event.SortAsc;
            this.searchString = event.SearchText;
            this.setPage(event.Page, false);
        }
        else if (event.VisFilter)
        {
            let key = event.VisFilter.Key;
            let vis = event.VisFilter.Vis;
            switch (key)
            {
                case 'showNative':
                    this.showNative = vis;
                    break;
                case 'show3DVS':
                    this.show3DVS = vis;
                    break;
                case 'showFolders':
                    this.showFolders = vis;
                    break;
                case 'showAssemblies':
                    this.showAssemblies = vis;
                    break;
                default:
                    break;
            }
            this.getChildren(false);
        }
        else if (event.Type && event.Type === 'DownloadFiles')
        {
            event.Files.forEach((file: ModelFile) =>
            {
                this.downloadFile(file, event.IncludeAttachments);
            });
        }
        else if (event.Type && event.Type === 'DownloadFolders' && this.writeAccess)
        {
            this.downloadFolders(event.Folders, event.IncludeAttachments);
        }
        else if (event === 'ToggleChat')
        {
            this.toggleChat();
        }
    }

    RightBrowserEmit (event)
    {
        if (event === 'home')
        {
            this.lastTargetFolder = undefined;
            this.rightRoot = undefined;
        }
        else if (event === 'CreateFolder' && this.writeAccess)
        {
            this.createFolder(true);
        }
        else if (event.FolderSaved && this.writeAccess)
        {
            this.secondBrowserActive = false;
            if (!this.lastTargetFolder)
            {
                this.groupApi.createChildrenFolder(this.rightRoot.id, { Name: event.FolderSaved, ownerId: this.activeViewer.id }).subscribe(() =>
                {
                    this.getChildren(true);
                });
            }
            else
            {
                this.folderApi.createChildrenFolder(this.lastTargetFolder.id, { Name: event.FolderSaved, ownerId: this.activeViewer.id }).subscribe(() =>
                {
                    this.getChildren(true);
                });
            }
        }
        else if (event === 'UploadFile' && this.writeAccess)
        {
            this.secondBrowserActive = true;
            let input = document.getElementById('file-upload-input');
            input.click();
        }
        else if (event === 'ReplaceFile' && this.writeAccess)
        {
            this.secondBrowserActive = true;
            this.shouldReplaceFile = true;
            let input = document.getElementById('file-upload-input');
            input.click();
        }
        else if (event === 'UploadAssemblyFolder' && this.writeAccess)
        {
            this.secondBrowserActive = true;
            this.shouldReplaceFile = false;
            let input = document.getElementById('folder-upload-input');
            input.click();
        }
        else if (event.DragFinished && this.writeAccess)
        {
            event.DragFinished.forEach(item =>
            {
                if (item.UID)
                {
                    item.IsCopy = true;
                }
            });
            this.addData(true, event.DragFinished);
            if (!this.cutMode)
            {
                this.getChildren(false);
            }
            else
            {
                this.fileBrowser.first.deleteSelection();
            }
        }
        else if (event === 'Select')
        {
            this.secondBrowserActive = true;
        }
        else if (event.Paste && this.writeAccess)
        {
            this.paste(event.Folder, true);
        }
        else if (event === 'Update')
        {
            this.getChildren(true);
        }
        else if (event.JumpToFolder)
        {
            this.navigate(event.JumpToFolder, true);
        }
        else if (event.ExternalDrop && this.writeAccess)
        {
            let files = event.ExternalDrop;
            this.secondBrowserActive = true;
            for (let i = 0; i < files.length; i++)
            {
                if (event.Folder)
                {
                    this.singleUpload(files[i], event.Folder.id);
                }
                else
                {
                    this.singleUpload(files[i]);
                }
            }
        }
        else if (event.Page)
        {
            this.sortCat = event.SortCat;
            this.sortAsc = event.SortAsc;
            this.searchString = event.SearchText;
            this.setPage(event.Page, true);
        }
        else if (event.VisFilter)
        {
            let key = event.VisFilter.Key;
            let vis = event.VisFilter.Vis;
            switch (key)
            {
                case 'showNative':
                    this.showNative = vis;
                    break;
                case 'show3DVS':
                    this.show3DVS = vis;
                    break;
                case 'showFolders':
                    this.showFolders = vis;
                    break;
                case 'showAssemblies':
                    this.showAssemblies = vis;
                    break;
                default:
                    return;
            }
            this.getChildren(true);
        }
        else if (event.Type && event.Type === 'DownloadFiles' && this.writeAccess)
        {
            event.Files.forEach((file: ModelFile) =>
            {
                this.downloadFile(file, event.IncludeAttachments);
            });
        }
        else if (event.Type && event.Type === 'DownloadFolders' && this.writeAccess)
        {
            this.downloadFolders(event.Folders, event.IncludeAttachments);
        }
    }

    OnTrashChange (event)
    {
        if (event === 'refresh')
        {
            this.update();
        }
    }

    OnDialogInput (event)
    {
        if (event.id === 'FolderCreation')
        {
            let name = event.value;
            this.saveFolder(name);
        }
    }

    OnAssemblySet (event)
    {
        let id = this.isGroup ? this.group.id : this.activeFolder.id;
        let api = this.isGroup ? this.groupApi : this.folderApi;
        let files = event.FileList;
        this.assemblyProducts = event.PartsToConvert;
        if (files && files.length > 0 && !files[0].webkitRelativePath)
        {
            for (let i = 0; i < files.length; i++)
            {
                files[i].webkitRelativePath = event.AssemblyName + '/' + files[i].name;
            }
        }
        let path = event.Container + '_' + event.AssemblyName;
        api.createChildrenFolder(id, { Name: event.AssemblyName, IsParent: true, ownerId: this.activeViewer.id, AssemblyPath: path }).subscribe(folder =>
        {
            this.getChildren(false);
            if (this.splitMode)
            {
                this.getChildren(true);
            }
            this.assembliesInUpload.push(folder);
            let realPath = files[0].webkitRelativePath.split('/');
            realPath = realPath[0] + '_VisShare_';
            let formData = new FormData();
            for (let file of files)
            {
                let relFileName = file.webkitRelativePath.split('/');
                relFileName.shift();
                relFileName = relFileName.join('/');
                formData.append('file', file, relFileName);
            }
            let headers = new HttpHeaders({ "Authorization": this.viewerService.getAuthToken().id });
            this.sourceApi.createContainer({ name: '_VisShareTemp_' + event.Container + '_' + realPath }).subscribe(() =>
            {
                this.assemblyUploadBlock = true;
                this.assemblyContainerPath = '_VisShareTemp_' + event.Container + '_' + realPath;
                this.assemblyUploadCall = this.http.post(VSShareBackendUrl + '/api/Sources/_VisShareTemp_' + event.Container + '_' + realPath + '/upload', formData, { headers }).subscribe(() =>
                {
                    this.sourceApi.destroyContainer('_VisShareTemp_' + event.Container + '_' + realPath).subscribe(() =>
                    {
                        this.recAssemblyUpload(files, 0, folder, event.Container);
                    });
                });
            });
        });
    }

    abortAssemblyUpload()
    {
        this.assemblyUploadCall.unsubscribe();
        this.assemblyUploadBlock = false;
        this.sourceApi.destroyContainer(this.assemblyContainerPath).subscribe(() =>
        {
        });
    }

    openPageInNewTab()
    {
        window.open(window.location.href, '_blank');
    }

    OnChatCommand (event: number)
    {
        this.router.navigate(['../', this.group.id, { folder: event }], { relativeTo: this.route });
    }

    onAdvancedNativeLoading (event)
    {
        if (!this.mockUser)
        {
            this.viewerService.setAdvancedNativeLoading(event).subscribe((viewer) =>
            {
                this.viewerService.changeViewer(viewer);
            });
        }
        else
        {
            this.activeViewer.AdvancedNativeLoading = event;
        }
    }

    create_UUID(){
        var dt = Date.now();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) =>
        {
            var r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c=='x' ? r :(r&0x3|0x8)).toString(16);
        });
        return uuid;
    }
}
