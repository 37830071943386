import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProgressService } from 'src/app/services/progress.service';


export interface AssemblyUploadItem {
    name: string,
    lastModified: number,
    assemblyId: string
};


@Component({
    selector: 'app-assembly-upload-modal',
    templateUrl: './assembly-upload-modal.component.html',
    styleUrls: ['./assembly-upload-modal.component.scss']
})
export class AssemblyUploadModalComponent implements OnInit
{
    //Layout variables
    @Input('isMobile')
    private isMobile: boolean = false;

    isOpen: boolean = false;
    products: AssemblyUploadItem[] = [];
    assemblyParts: AssemblyUploadItem[] = [];
    private fileList: any[] = [];
    private containerId: string;

    assemblyName = new FormControl();

    //Emitter
    @Output()
    private emitter = new EventEmitter<any>();

    constructor (private progressService: ProgressService) { }

    ngOnInit ()
    {
    }

    ngOnDestroy ()
    {
    }

    show (containerId: string, assemblyParts: AssemblyUploadItem[], fileList: any[] = [])
    {
        this.products = [];
        this.containerId = containerId;
        this.assemblyParts = assemblyParts;
        this.isOpen = true;
        this.fileList = fileList;
        this.assemblyName.setValue(fileList[0].webkitRelativePath ? fileList[0].webkitRelativePath.split('/')[0] : undefined);
    }

    close (update: boolean = true)
    {
        this.progressService.endLoadingScreen();
        this.isOpen = false;
        if (update)
        {
            this.emitter.emit({ PartsToConvert: this.products, FileList: this.fileList , Container: this.containerId, AssemblyName: this.assemblyName.value});
        }
    }

    addPart (part: AssemblyUploadItem)
    {
        let index = this.products.map((item: AssemblyUploadItem) => { return item.name }).indexOf(part.name);
        if (index === -1)
        {
            this.products.push(part);
        }
        else
        {
            this.products.splice(index, 1);
        }
    }

    trackById (index, item: AssemblyUploadItem)
    {
        return item.name;
    }


}
