import { Component, AfterViewInit } from '@angular/core';
import { ViewerService } from '../services/viewer.service';
import { Subscription } from 'rxjs';
import { Viewer } from '../shared/sdk';
import { GlobalEventService } from '../services/global-event.service';
declare var VisShareDisclaimerUrl: string;
declare var VisShareImprintUrl: string;
declare var VisShareVersion;
@Component({
    selector: 'app-info-page',
    templateUrl: './info-page.component.html',
    styleUrls: ['./info-page.component.scss']
})
export class InfoPageComponent implements AfterViewInit
{
    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;

    version: string;

    isAuthenticated: boolean = false;
    isGuest: boolean = false;
    private activeViewer: Viewer;

    //URLs
    disclaimerUrl = "https://viewer.kisters.de/en/legal-information-disclaimers.html";
    imprintUrl = "https://viewer.kisters.de/en/imprint.html";

    private viewerSubscription: Subscription;
    constructor (private viewerService: ViewerService,
        private eventService: GlobalEventService) { }

    ngOnInit ()
    {
        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });
        try
        {
            this.version = VisShareVersion.short;
        }
        catch (e)
        {
            this.version = 'Demo-Version';
        }
    }

    ngAfterViewInit ()
    {
        this.viewerSubscription = this.viewerService.currentViewer.subscribe((viewer: Viewer) =>
        {
            let condEqualId = (viewer.id && (this.activeViewer && this.activeViewer.id && this.activeViewer.id === viewer.id));
            let condNoId = (!viewer.id && ((this.activeViewer && !this.activeViewer.id) || !this.activeViewer));
            if (!condEqualId && !condNoId)
            {
                setTimeout(() =>
                {
                    this.isAuthenticated = this.viewerService.isAuthenticated();
                    this.isGuest = !viewer['Verified'];
                }, 0);
            }
        });

        try
        {
            this.disclaimerUrl = VisShareDisclaimerUrl;
            this.imprintUrl = VisShareImprintUrl;
        }
        catch (e)
        {
        }
    }

    ngOnDestroy ()
    {
        this.viewerSubscription.unsubscribe();
        this.layoutSubscription.unsubscribe();
    }

}
