import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'app-simple-dialog',
    templateUrl: './simple-dialog.component.html',
    styleUrls: ['./simple-dialog.component.scss']
})
export class SimpleDialogComponent implements OnInit
{

    @Input("isMobile")
    private isMobile: boolean;

    title: string;
    text: string;
    isOpen: boolean = false;
    private id: string;

    @ViewChild("inputVal")
    private inputVal: ElementRef;

    //Emitter
    @Output()
    private emitter = new EventEmitter<any>();

    constructor () { }

    ngOnInit ()
    {
    }

    open (title: string, text: string, id: string, initialValue?: string)
    {
        this.title = title;
        this.text = text;
        this.id = id;
        this.isOpen = true;
        setTimeout(() =>
        {
            if (initialValue)
            {
                this.inputVal.nativeElement.value = initialValue;
            }
            this.inputVal.nativeElement.focus();
        }, 0);
    }

    callback (value: string)
    {
        this.emitter.emit({ id: this.id, value: this.inputVal.nativeElement.value });
        this.close();
    }

    close ()
    {
        if (this.isOpen)
        {
            this.isOpen = false;
        }
    }

}
