import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

    transform(array: any, sortBy: string, order?: string): any[]
    {
        if (!Array.isArray(array))
        {
            return;
        }
        return array.sort((v1, v2) =>
        {
            let val1 = isNaN(v1[sortBy]) ? v1[sortBy].toLowerCase() : parseFloat(v1[sortBy]);
            let val2 = isNaN(v2[sortBy]) ? v2[sortBy].toLowerCase() : parseFloat(v2[sortBy]);
            if (val1 > val2)
            {
                return (!order || order === 'ASC') ? 1 : -1;
            }
            else
            {
                return (!order || order === 'ASC') ? -1 : 1;
            }
        });
    }
}