/* tslint:disable */

declare var Object: any;
export interface AppRoleInterface {
  "UID": number;
  "MgmtAccess": boolean;
  "GalleryAccess": boolean;
  "WVAccess": boolean;
  "AllWrite": boolean;
  "NoneWrite"?: boolean;
  "CreateGroups"?: boolean;
  "DeleteGroups"?: boolean;
  "PrivateDirectory"?: boolean;
  "id"?: number;
}

export class AppRole implements AppRoleInterface {
  "UID": number;
  "MgmtAccess": boolean;
  "GalleryAccess": boolean;
  "WVAccess": boolean;
  "AllWrite": boolean;
  "NoneWrite": boolean;
  "CreateGroups": boolean;
  "DeleteGroups": boolean;
  "PrivateDirectory": boolean;
  "id": number;
  constructor(data?: AppRoleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AppRole`.
   */
  public static getModelName() {
    return "AppRole";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AppRole for dynamic purposes.
  **/
  public static factory(data: AppRoleInterface): AppRole{
    return new AppRole(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AppRole',
      plural: 'appRoles',
      path: 'appRoles',
      idName: 'id',
      properties: {
        "UID": {
          name: 'UID',
          type: 'number'
        },
        "MgmtAccess": {
          name: 'MgmtAccess',
          type: 'boolean',
          default: true
        },
        "GalleryAccess": {
          name: 'GalleryAccess',
          type: 'boolean',
          default: true
        },
        "WVAccess": {
          name: 'WVAccess',
          type: 'boolean',
          default: true
        },
        "AllWrite": {
          name: 'AllWrite',
          type: 'boolean'
        },
        "NoneWrite": {
          name: 'NoneWrite',
          type: 'boolean',
          default: false
        },
        "CreateGroups": {
          name: 'CreateGroups',
          type: 'boolean',
          default: true
        },
        "DeleteGroups": {
          name: 'DeleteGroups',
          type: 'boolean',
          default: false
        },
        "PrivateDirectory": {
          name: 'PrivateDirectory',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
