import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { ModelFileApi, GroupFolderApi, Settings } from 'src/app/shared/sdk';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { ViewerService } from 'src/app/services/viewer.service';
import { FileService } from 'src/app/services/file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
    selector: 'app-trash-modal',
    templateUrl: './trash-modal.component.html',
    styleUrls: ['./trash-modal.component.scss']
})
export class TrashModalComponent implements OnInit
{

    isOpen: boolean = false;
    private trash: any[] = [];

    //Settings
    private settings: Settings;
    private settingsSubscription: Subscription;
    //Emitter
    @Output()
    private emitter = new EventEmitter<any>();

    constructor (private fileApi: ModelFileApi,
        private folderApi: GroupFolderApi,
        private fileService: FileService,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private viewerService: ViewerService,
        private settingsService: SettingsService) { }

    ngOnInit ()
    {
        this.settingsSubscription = this.settingsService.settings.subscribe((settings: Settings) =>
        {
            this.settings = settings;
        });
    }

    ngOnDestroy ()
    {
        this.settingsSubscription.unsubscribe();
    }

    show (trash: any[])
    {
        this.setTrash(trash);
        this.isOpen = true;
    }

    close (update: boolean = false)
    {
        if (update)
        {
            this.emitter.emit('refresh');
        }
        this.isOpen = false;
    }

    setTrash (trash: any[])
    {
        this.trash = trash;
    }

    restoreItem (item)
    {
        let attribute = {
            IsDeleted: false
        };
        if (item.UID)
        {
            this.fileApi.patchAttributes(item.id, attribute).subscribe(res =>
            {
                this.trash.splice(this.trash.indexOf(item), 1);
                this.emitter.emit('refresh');
            });
        }
        else
        {
            this.folderApi.patchAttributes(item.id, attribute).subscribe(res =>
            {
                this.trash.splice(this.trash.indexOf(item), 1);
                this.emitter.emit('refresh');
            });
        }
    }

    deleteItem (item)
    {
        if (item.UID)
        {
            this.fileApi.deleteById(item.id).subscribe();
        }
        else
        {
            this.folderApi.deleteById(item.id).subscribe();
        }
        this.trash.splice(this.trash.indexOf(item), 1);
        this.emitter.emit('refresh');
    }

    clearTrash ()
    {
        let calls: Observable<any>[] = [];
        this.renderer.setStyle(document.body, 'cursor', 'progress');
        for (let i = 0; i < this.trash.length; i++)
        {
            let item = this.trash[i];
            if (item.UID)
            {
                calls.push(this.fileApi.deleteById(item.id));
            }
            else
            {
                calls.push(this.folderApi.deleteById(item.id));
            }
        }
        forkJoin(calls).subscribe(res =>
        {
            this.trash = [];
            this.renderer.removeStyle(document.body, 'cursor');
            this.close(true);
        })
    }

    restoreTrash ()
    {
        let calls: Observable<any>[] = [];
        this.renderer.setStyle(document.body, 'cursor', 'progress');
        let attribute = {
            IsDeleted: false
        };
        for (let i = 0; i < this.trash.length; i++)
        {
            let item = this.trash[i];
            if (item.UID)
            {
                calls.push(this.fileApi.patchAttributes(item.id, attribute));
            }
            else
            {
                calls.push(this.folderApi.patchAttributes(item.id, attribute));
            }
        }
        forkJoin(calls).subscribe(res =>
        {
            this.trash = [];
            this.renderer.removeStyle(document.body, 'cursor');
            this.close(true);
        })
    }


    //File utility methods
    openFile (file)
    {
        let map = this.fileService.getMap();
        if (this.settings.SingleSession && map.length > 0)
        {
            let token = this.fileService.getToken();
            this.viewerService.openInTab(token, file).subscribe();
        }
        else
        {
            this.viewerService.openFile(file).subscribe(token =>
            {
                this.fileService.addTab(file, token.token);
            });
        }
    }

    sanitizeBase64Image (imageSrc: string)
    {
        return imageSrc ? this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + imageSrc) : '';
    }
}
