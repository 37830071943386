import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';


  
export interface UploadItem
{
  name: string,
  groupId?: number,
  folderId?: number,
  uploadTime: number,
  call: Subscription,
  id?: number
}
@Injectable()
export class UploadService {

  private uploadStream = new BehaviorSubject({});
  currentUploads = this.uploadStream.asObservable();
  
  private index: number = 0;
  private uploads: UploadItem[] = [];
  constructor () { }
  
  addItem (uploadItem: UploadItem): number
  {
    uploadItem.id = this.index;
    this.uploads.push(uploadItem);
    this.uploadStream.next(this.uploads);
    this.index++;
    return uploadItem.id;
  }

  removeItem (id: number): UploadItem
  {
    let index = this.uploads.map(item =>
    {
      return item.id;
    }).indexOf(id);
    if (index !== -1)
    {
      let removedItem = this.uploads.splice(index, 1)[0];
      this.uploadStream.next(this.uploads);
      return removedItem;
    }
    else
    {
      return undefined;
    }
  }
}
