import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class RequestInterceptor implements HttpInterceptor
{
    constructor () { }

    intercept (request: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>>
    {
        let isIE = (navigator.userAgent.indexOf("Trident") != -1);
        if (request.method === 'GET' && isIE)
        {
            const customRequest = request.clone({
                headers: request.headers.set('Cache-Control', 'no-cache')
                    .set('Pragma', 'no-cache')
            });
            return next.handle(customRequest);
        }
        return next.handle(request);
    }
}