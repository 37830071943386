import { Injectable } from '@angular/core';
import { GroupFolder } from '../shared/sdk';

@Injectable({
  providedIn: 'root'
})
export class FolderService {

    private clipboard: GroupFolder[] = [];
    private cut: boolean = false;
    constructor () {}
  
    setClipboard (folders: GroupFolder[], cut?: boolean)
    {
        if (cut)
        {
            this.cut = cut;
        }
        this.clipboard = folders;
    }

    getClipboard (skipCut: boolean = false)
    {
        let data = this.clipboard.slice();
        if (this.cut && !skipCut)
        {
            this.clipboard = [];
            this.cut = false;
        }
        return data;
    }

    isClipboardCut ()
    {
        return this.cut;
    }

}
