/* tslint:disable */
import {
  ModelFile,
  Viewer,
  GroupFolder,
  RootFolder,
  Chat
} from '../index';

declare var Object: any;
export interface GroupInterface {
  "Name": string;
  "Image"?: string;
  "IsPublic"?: boolean;
  "IsShare"?: boolean;
  "IsPrivate"?: boolean;
  "LastModified"?: number;
  "tenantId"?: number;
  "AllowDownload"?: boolean;
  "AllowDirectSave"?: boolean;
  "id"?: number;
  "viewerId"?: number;
  "ownerId"?: number;
  "rootFolderId"?: number;
  childrenFiles?: ModelFile[];
  owner?: Viewer;
  viewers?: Viewer[];
  childrenFolder?: GroupFolder[];
  rootFolders?: RootFolder;
  chat?: Chat;
}

export class Group implements GroupInterface {
  "Name": string;
  "Image": string;
  "IsPublic": boolean;
  "IsShare": boolean;
  "IsPrivate": boolean;
  "LastModified": number;
  "tenantId": number;
  "AllowDownload": boolean;
  "AllowDirectSave": boolean;
  "id": number;
  "viewerId": number;
  "ownerId": number;
  "rootFolderId": number;
  childrenFiles: ModelFile[];
  owner: Viewer;
  viewers: Viewer[];
  childrenFolder: GroupFolder[];
  rootFolders: RootFolder;
  chat: Chat;
  constructor(data?: GroupInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Group`.
   */
  public static getModelName() {
    return "Group";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Group for dynamic purposes.
  **/
  public static factory(data: GroupInterface): Group{
    return new Group(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Group',
      plural: 'groups',
      path: 'groups',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "Image": {
          name: 'Image',
          type: 'string'
        },
        "IsPublic": {
          name: 'IsPublic',
          type: 'boolean',
          default: false
        },
        "IsShare": {
          name: 'IsShare',
          type: 'boolean',
          default: false
        },
        "IsPrivate": {
          name: 'IsPrivate',
          type: 'boolean',
          default: false
        },
        "LastModified": {
          name: 'LastModified',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number',
          default: -1
        },
        "AllowDownload": {
          name: 'AllowDownload',
          type: 'boolean',
          default: false
        },
        "AllowDirectSave": {
          name: 'AllowDirectSave',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "viewerId": {
          name: 'viewerId',
          type: 'number'
        },
        "ownerId": {
          name: 'ownerId',
          type: 'number'
        },
        "rootFolderId": {
          name: 'rootFolderId',
          type: 'number'
        },
      },
      relations: {
        childrenFiles: {
          name: 'childrenFiles',
          type: 'ModelFile[]',
          model: 'ModelFile',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'childFileId'
        },
        owner: {
          name: 'owner',
          type: 'Viewer',
          model: 'Viewer',
          relationType: 'belongsTo',
                  keyFrom: 'ownerId',
          keyTo: 'id'
        },
        viewers: {
          name: 'viewers',
          type: 'Viewer[]',
          model: 'Viewer',
          relationType: 'hasMany',
          modelThrough: 'GroupViewerMapping',
          keyThrough: 'viewerId',
          keyFrom: 'id',
          keyTo: 'viewerId'
        },
        childrenFolder: {
          name: 'childrenFolder',
          type: 'GroupFolder[]',
          model: 'GroupFolder',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'childFolderId'
        },
        rootFolders: {
          name: 'rootFolders',
          type: 'RootFolder',
          model: 'RootFolder',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'groupId'
        },
        chat: {
          name: 'chat',
          type: 'Chat',
          model: 'Chat',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'parentId'
        },
      }
    }
  }
}
