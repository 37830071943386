import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ViewerApi } from './shared/sdk/services';

@Injectable()
export class AuthGuard implements CanActivate
{
    constructor (
        private router: Router,
        private viewerApi: ViewerApi
    ) { }

    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        if (this.viewerApi.isAuthenticated())
        {
            return true;
        }
        else
        {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }
}