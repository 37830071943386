import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViewerApi, Viewer } from '../shared/sdk';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewerService } from '../services/viewer.service';
import { PortalError, ProgressService } from '../services/progress.service';
import { SettingsService } from '../services/settings.service';
import { Subscription } from 'rxjs';
import { GlobalEventService } from '../services/global-event.service';

declare var AdminCreationOnly: boolean;
declare var HidePasswordReset: boolean;
declare var HideResendMail: boolean;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{
    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;

    creds: FormGroup;
    private returnUrl: string;
    mail: string = "";
    failedTry: boolean = false;
    inLoginTry: boolean = false;
    //UI visibility
    showRegister: boolean = true;
    showPasswordForgotten: boolean = true;
    showResendMail: boolean = true;
    constructor (private formBuilder: FormBuilder,
        private viewerApi: ViewerApi,
        private route: ActivatedRoute,
        private router: Router,
        private viewerService: ViewerService,
        private progressService: ProgressService,
        private settingsService: SettingsService,
        private eventService: GlobalEventService
    )
    {
        this.creds = this.formBuilder.group({
            name: [this.viewerService.getEmailForPasswordReset(), [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
            rememberMe: [false]
        });
        try
        {
            this.showRegister = !AdminCreationOnly;
        }
        catch (e) {}
        try
        {
            this.showPasswordForgotten = !HidePasswordReset;
        }
        catch (e) {}
        try
        {
            this.showResendMail = !HideResendMail;
        }
        catch (e) {}
    }

    ngOnInit ()
    {
        let viewer = {
            id: 0
        } as Viewer;
        viewer['isRemote'] = false;
        setTimeout(() =>
        {
            this.viewerService.changeViewer(viewer);
        }, 0);
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.creds.controls['name'].valueChanges.subscribe(val =>
        {
            this.mail = val;
        });
        if (this.route.snapshot.queryParams['mail'])
        {
            this.creds.controls['name'].setValue(this.route.snapshot.queryParams['mail']);
        }
        this.failedTry = false;

        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });
    }

    ngOnDestroy ()
    {
        this.layoutSubscription.unsubscribe();
    }

    signIn (): void
    {
        if (this.creds.status === 'INVALID')
        {
            this.failedTry = true;
            return;
        }
        this.inLoginTry = true;
        this.viewerApi.login({ email: this.creds.value.name.toLowerCase(), password: this.creds.value.password }, 'user', this.creds.value.rememberMe).subscribe((token: any) =>
        {
            this.progressService.hideError();
            this.router.navigateByUrl(this.returnUrl);
            this.viewerService.changeViewer(token.user);
            this.settingsService.startSettingsSubscription();
            this.inLoginTry = false;
        }, err =>
        {
            this.inLoginTry = false;
            let error: PortalError = {
                code: err.statusCode,
                message: err.message,
                name: err.name ? err.name : 'Error'
            };
            this.progressService.addError(error);
        });
    }

}
