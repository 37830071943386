/* tslint:disable */
import {
  Viewer
} from '../index';

declare var Object: any;
export interface ChatViewInterface {
  "Bookmarked"?: boolean;
  "LastViewed"?: number;
  "ChatId": number;
  "id"?: number;
  "viewerId"?: number;
  viewer?: Viewer;
}

export class ChatView implements ChatViewInterface {
  "Bookmarked": boolean;
  "LastViewed": number;
  "ChatId": number;
  "id": number;
  "viewerId": number;
  viewer: Viewer;
  constructor(data?: ChatViewInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ChatView`.
   */
  public static getModelName() {
    return "ChatView";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ChatView for dynamic purposes.
  **/
  public static factory(data: ChatViewInterface): ChatView{
    return new ChatView(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ChatView',
      plural: 'ChatViews',
      path: 'ChatViews',
      idName: 'id',
      properties: {
        "Bookmarked": {
          name: 'Bookmarked',
          type: 'boolean',
          default: false
        },
        "LastViewed": {
          name: 'LastViewed',
          type: 'number',
          default: 0
        },
        "ChatId": {
          name: 'ChatId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "viewerId": {
          name: 'viewerId',
          type: 'number'
        },
      },
      relations: {
        viewer: {
          name: 'viewer',
          type: 'Viewer',
          model: 'Viewer',
          relationType: 'belongsTo',
                  keyFrom: 'viewerId',
          keyTo: 'id'
        },
      }
    }
  }
}
