import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Viewer, GroupSync, GroupSyncApi, GroupApi, Group, Settings } from '../shared/sdk';
import { ViewerService } from '../services/viewer.service';
import { SimpleDialogComponent } from '../widgets/simple-dialog/simple-dialog.component';
import { ProgressService, PortalError } from '../services/progress.service';
import { GroupModalComponent } from '../gallery/group-modal/group-modal.component';
import { SettingsService } from '../services/settings.service';
import { GlobalEventService, VisKeyboardEvent } from '../services/global-event.service';

@Component({
    selector: 'app-sync-list',
    templateUrl: './sync-list.component.html',
    styleUrls: ['./sync-list.component.scss']
})
export class SyncListComponent implements AfterViewInit
{
    @ViewChild(SimpleDialogComponent)
    private confirmModal: SimpleDialogComponent;

    @ViewChild(GroupModalComponent)
    private groupModal: GroupModalComponent;

    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;

    //iOs detection
    isiOs: boolean = false;

    //Model
    syncs: GroupSync[] = [];
    //Search
    searchInput = new FormControl();
    private searchString: string = '';

    //Sorting
    sortCat: string;
    sortAsc: boolean = true;

    //Sort display statets for angular build
    showPathSort: boolean = false;
    showGroupSort: boolean = false;
    showBackupSort: boolean = false;
    showLastFinishedSort: boolean = false;
    showIdSort: boolean = false;
    showAutomateSort: boolean = false;

    //Viewer
    activeViewer: Viewer;
    private viewerSubscription: Subscription;

    //Inline comment and name change
    cellBlurListener: any;
    cellKeyDownListener: any;
    editableElement: GroupSync;

    //Group import
    private dirSize: number = 0;
    private path: string;

    //Settings
    private settings: Settings;
    private settingsSubscription: Subscription;

    //Global events
    private globalEventSubscription: Subscription;

    constructor (private viewerService: ViewerService,
        private progressService: ProgressService,
        private settingsService: SettingsService,
        private eventService: GlobalEventService,
        private syncApi: GroupSyncApi,
        private groupApi: GroupApi) { }

    ngAfterViewInit ()
    {
        this.isiOs = /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        this.viewerSubscription = this.viewerService.currentViewer.subscribe((viewer: Viewer) =>
        {
            setTimeout(() =>
            {
                if (viewer.id && ((this.activeViewer && this.activeViewer.id !== viewer.id) || !this.activeViewer))
                {
                    this.buildList();
                }
                this.activeViewer = viewer;
            }, 0);
        });
        this.searchInput.valueChanges.subscribe(val =>
        {
            this.searchString = val;
            this.buildList();
        });
        this.settingsSubscription = this.settingsService.settings.subscribe((settings: Settings) =>
        {
            this.settings = settings;
        });

        //Keyboard listener
        this.globalEventSubscription = this.eventService.keyboardEvent.subscribe((key: VisKeyboardEvent) =>
        {
            if (key === VisKeyboardEvent.ESC)
            {
                this.groupModal.close();
                this.confirmModal.close();
            }
        });

    }

    ngOnInit()
    {
        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof (isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });
    }

    ngOnDestroy ()
    {
        this.viewerSubscription.unsubscribe();
        this.settingsSubscription.unsubscribe();
        this.layoutSubscription.unsubscribe();
    }

    sortTable (category: string)
    {
        this.sortAsc = this.sortCat === category ? !this.sortAsc : false;
        this.sortCat = category;
        this.syncs = this.syncs.sort((sync1: GroupSync, sync2: GroupSync) =>
        {
            let val1, val2;
            if (category !== 'Backup' && category !== 'id' && category !== 'Automate')
            {
                val1 = sync1[this.sortCat].toLowerCase();
                val2 = sync2[this.sortCat].toLowerCase();
            }
            else
            {
                val2 = sync2[this.sortCat];
                val1 = sync1[this.sortCat];
            }
            if (val1 > val2)
            {
                return this.sortAsc ? 1 : -1;
            }
            else
            {
                return this.sortAsc ? -1 : 1;
            }
        });
    }

    buildList ()
    {
        let filter = {};
        if (this.searchString && this.searchString !== null && null !== '')
        {
            filter = {
                where: {
                    Path: {
                        ilike: '%' + this.searchString + '%'
                    }
                }
            };
        }
        this.syncApi.find(filter).subscribe((syncs: GroupSync[]) =>
        {
            this.syncs = syncs;
            this.syncs.forEach((s: GroupSync) =>
            {
                this.groupApi.findById(s.groupId).subscribe((group: Group) =>
                {
                    s['GroupName'] = group.Name;
                },
                    (err) =>
                    {
                        s['GroupName'] = 'NO_PROJECT_ACCESS';
                        s['AccessDenied'] = true;
                    });
            });
        });
    }

    resetSearchInput ()
    {
        this.searchInput.setValue('');
    }

    startSync (sync: GroupSync)
    {
        sync.Running = true;
        this.syncApi.startSync(sync.id, { Backup: true }).subscribe(res =>
        {
            this.buildList();
        });
    }

    deleteSync (sync: GroupSync)
    {
        this.syncApi.deleteById(sync.id).subscribe(res =>
        {
            this.buildList();
        });
    }

    toggleBackup (sync: GroupSync)
    {
        this.syncApi.patchAttributes(sync.id, { Backup: !sync.Backup }).subscribe(res =>
        {
            this.buildList();
        });
    }

    toggleAutomation (sync: GroupSync)
    {
        this.syncApi.patchAttributes(sync.id, { Automate: !sync.Automate }).subscribe(res =>
        {
            this.buildList();
        });
    }

    editSyncPath (sync: GroupSync, e?: MouseEvent)
    {
        let cell = e.target as HTMLElement;
        if (cell.nodeName !== 'TD')
        {
            cell = cell.parentElement;
        }
        cell.contentEditable = 'true';
        cell.focus();
        this.cellBlurListener = this.saveEdit.bind(this);
        this.cellKeyDownListener = this.saveEdit.bind(this);
        this.editableElement = sync;
        cell.addEventListener('blur', this.cellBlurListener);
        cell.addEventListener('keydown', this.cellKeyDownListener);
    }

    saveEdit (e)
    {
        let cell = e.target as HTMLElement;
        if (!e.keyCode || (e.keyCode && (e.keyCode === 13 || e.keyCode === 27)))
        {
            cell.removeEventListener('blur', this.cellBlurListener);
            cell.removeEventListener('keydown', this.cellKeyDownListener);
            cell.contentEditable = 'false';
            if (!e.keyCode || e.keyCode && e.keyCode !== 27)
            {
                if (e.keyCode && e.keyCode === 13)
                {
                    e.preventDefault();
                }
                let path = {
                    Path: cell.textContent.trim()
                };
                this.syncApi.patchAttributes(this.editableElement.id, path).subscribe(e =>
                {
                    this.buildList();
                });
            }
            else
            {
                e.preventDefault();
                this.buildList();
            }
        }
    }

    uploadDir ()
    {
        this.confirmModal.open('UPLOAD_DIR', 'ENTER_DIR_PATH', 'UploadFolder');
    }


    OnImportGroupClose (event)
    {
        if (event)
        {
            setTimeout(() =>
            {
                this.viewerService.importDir(event.id, this.dirSize, this.path).subscribe(() =>
                {
                    this.buildList();
                });
            }, 0);
        }
    }

    OnUploadConfirm (event)
    {
        if (event && event.id === 'UploadFolder')
        {
            this.progressService.startLoadingScreen();
            let pathObj = {
                dir: event.value
            };
            this.path = event.value;
            this.viewerService.countDir(pathObj).subscribe((res) =>
            {
                //No error
                if (!res.count.code)
                {
                    this.progressService.endLoadingScreen();
                    this.groupModal.setDirSize(res.count);
                    this.dirSize = res.count;
                    this.groupModal.open(this.settings.UseTenants);
                }
                //Error
                else
                {
                    this.progressService.endLoadingScreen();
                    let error: PortalError = {
                        code: res.count.errno,
                        message: 'SYNC_ERROR',
                        name: res.count.code
                    };
                    this.progressService.addError(error);
                }
            },
                err =>
                {
                    let visError: PortalError = {
                        code: err['statusCode'],
                        message: err.message,
                        name: 'Server Error'
                    };
                    this.progressService.endLoadingScreen();
                    this.progressService.addError(visError)
                });
        }
    }

}
