/* tslint:disable */
import {
  Viewer
} from '../index';

declare var Object: any;
export interface ViewerVerificationInterface {
  "Verified"?: boolean;
  "id"?: number;
  "viewerId"?: number;
  viewers?: Viewer;
}

export class ViewerVerification implements ViewerVerificationInterface {
  "Verified": boolean;
  "id": number;
  "viewerId": number;
  viewers: Viewer;
  constructor(data?: ViewerVerificationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ViewerVerification`.
   */
  public static getModelName() {
    return "ViewerVerification";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ViewerVerification for dynamic purposes.
  **/
  public static factory(data: ViewerVerificationInterface): ViewerVerification{
    return new ViewerVerification(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ViewerVerification',
      plural: 'ViewerVerifications',
      path: 'ViewerVerifications',
      idName: 'id',
      properties: {
        "Verified": {
          name: 'Verified',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "viewerId": {
          name: 'viewerId',
          type: 'number'
        },
      },
      relations: {
        viewers: {
          name: 'viewers',
          type: 'Viewer',
          model: 'Viewer',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'viewerVerificationId'
        },
      }
    }
  }
}
