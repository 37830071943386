import { Component, Input, AfterViewInit, ViewChild, Output, EventEmitter, Renderer2, HostListener, NgZone } from '@angular/core';
import { Group, GroupFolder, ModelFile, GroupFolderApi, ModelFileApi, Viewer, ImageData, Settings, GroupApi, ModelFileInterface, ConvJobInterface, ConvJobApi, ConvJob } from 'src/app/shared/sdk';
import { ViewerService } from 'src/app/services/viewer.service';
import { FileService, ModelMapping } from 'src/app/services/file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { EmbeddedViewerComponent } from './embedded-viewer/embedded-viewer.component';
import { ShareModalComponent } from './share-modal/share-modal.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { BrowserBreadcrumbComponent, BrowserBreadCrumb, ShareBreadCrumb } from './browser-breadcrumb/browser-breadcrumb.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SimpleDialogComponent } from 'src/app/widgets/simple-dialog/simple-dialog.component';
import { SharedFolder } from 'src/app/share/share.component';
import { UploadService, UploadItem } from 'src/app/services/upload.service';
import { RemoteShareModalComponent } from './remote-share-modal/remote-share-modal.component';
import { SettingsService } from 'src/app/services/settings.service';
import { FolderService } from 'src/app/services/folder.service';
import { GlobalEventService, VisKeyboardEvent } from 'src/app/services/global-event.service';
import { PortalError, ProgressService } from 'src/app/services/progress.service';
import { ProgressCircleComponent } from 'src/app/widgets/progress-circle/progress-circle.component';
import { FilePropertiesModalComponent } from './file-properties-modal/file-properties-modal.component';
import { ConfirmModalDialogComponent } from 'src/app/widgets/confirm-modal-dialog/confirm-modal-dialog.component';
declare var WebViewerClientUrlOverride;

@Component({
    selector: 'app-browser',
    templateUrl: './browser.component.html',
    styleUrls: ['./browser.component.scss']
})
export class BrowserComponent implements AfterViewInit
{

    //Children
    @ViewChild(EmbeddedViewerComponent)
    private previewClient: EmbeddedViewerComponent;

    @ViewChild(ShareModalComponent)
    private shareModal: ShareModalComponent;

    @ViewChild(ContextMenuComponent)
    private ctxMenu: ContextMenuComponent;

    @ViewChild(BrowserBreadcrumbComponent)
    private breadcrumb: BrowserBreadcrumbComponent;

    @ViewChild(SimpleDialogComponent)
    private simpleDialog: SimpleDialogComponent;

    @ViewChild(ConfirmModalDialogComponent)
    private confirmModal: ConfirmModalDialogComponent;

    @ViewChild(RemoteShareModalComponent)
    private remoteShareModal: RemoteShareModalComponent;

    @ViewChild(ProgressCircleComponent)
    private progressCircle: ProgressCircleComponent;

    @ViewChild(FilePropertiesModalComponent)
    private propertiesModal: FilePropertiesModalComponent;

    activeViewer: Viewer;
    @Input('root')
    root: Group;
    //Split
    @Input('splitMode')
    private splitMode: boolean;
    @Input('lastTargetFolder')
    private lastTargetFolder: GroupFolder;
    @Input('lastViewer')
    private lastViewer: Viewer;
    @Input('rootId')
    rootId: string;
    @Input('cutMode')
    cutMode: boolean;
    @Input('isShare')
    isShare: boolean = false;
    @Input('shareContextMenu')
    shareContextMenu: boolean = false;
    @Input('chatOpen')
    private chatOpen: boolean = false;
    @Input('formatBlacklist')
    formatBlacklist: string[];
    @Input('downloadWhitelist')
    downloadWhitelist: string[];
    //Layout variables
    @Input('isMobile')
    isMobile: boolean = false;
    filesSelected: boolean = false;
    sceneSelection: boolean = false;
    scenes: ModelMapping[] = [];
    crudActive: boolean = false;
    crudStarted: boolean = true;
    clipboardSize: number = 0;
    isClipboardCut: boolean = false;
    showMobileSearch: boolean = false;
    showFilterSettings: boolean = false;
    //Query params
    fileForAttachmentId: number;
    attachmentId: number;
    fileForRemoteShareId: number;
    fileForShareId: number;
    queryFilter: string;


    group: Group;
    private isGroup: boolean;
    private activeFolder: GroupFolder;
    folders: any[] = [];
    files: ModelFile[] = [];
    convFiles: ModelFile[] = [];
    conversionToAbort: ConvJob;
    placeholderFiles: UploadItem[] = [];
    placeholderSubscription: Subscription;
    viewMode: number;
    isAssembly: boolean = false;

    //iOs detection
    isiOs: boolean = false;
    //ImageCache
    private images = {};

    //Selection variable
    allSelected: boolean = false;
    selection: any[] = [];
    hoveredFolder: GroupFolder;
    hoveredFile: ModelFile;

    //Model preview in table view
    showPreview: boolean;
    private isPreviewInit: boolean = false;
    private previewId: number = 0;

    //Search bar
    searchText = new FormControl();
    private searchRegEx: string = '';

    //Filter
    showNative: boolean = true;
    show3DVS: boolean = true;
    showAssemblies: boolean = true;
    showFolders: boolean = true;

    //Context menu
    private wrapper: HTMLElement;

    //Admin rights
    writeAccess: boolean = false;

    //Drag
    dragStarted: boolean = false;
    private dragElement: HTMLElement;
    private inDrag: boolean = false;

    //Remote public
    private isRemotePublicGroup: boolean = false;

    //Settings
    settings: Settings;
    private settingsSubscription: Subscription;

    //Sorting
    sortCat: string = 'Name';
    sortAsc: boolean = true;
    wasNavigation: boolean = false;

    //Pagination
    page: number = 1;
    @Input('pageSize')
    pageSize: number = 75;
    folderCount: number = 0;
    fileCount: number = 0;

    private globalEventSubscription: Subscription;

    //Emitter
    @Output()
    private emitter = new EventEmitter<any>();


    @HostListener('window:contextmenu', ['$event'])
    onClick(e)
    {
        if (this.ctxMenu.isOpen)
        {
            e.preventDefault();
        }
    }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(e: KeyboardEvent)
    {
        if (this.isMenuOpen())
        {
            return;
        }
        if (e.keyCode === 46 && this.writeAccess)
        {
            this.deleteSelection();
        }
        //Copy
        else if (e.ctrlKey && e.keyCode === 67)
        {
            if (!this.writeAccess)
            {
                let warning: PortalError = {
                    code: -1,
                    message: 'COPY_FORBIDDEN',
                    name: 'NoWriteAccess'
                };
                this.progressService.addError(warning);
            }
            else
            {
                this.copyToClipboard();
            }
        }
        //Cut
        else if (e.ctrlKey && e.keyCode === 88)
        {
            if (!this.writeAccess)
            {
                let warning: PortalError = {
                    code: -1,
                    message: 'COPY_FORBIDDEN',
                    name: 'NoWriteAccess'
                };
                this.progressService.addError(warning);
            }
            else
            {
                this.cutToClipboard();
            }
        }
        //Paste
        else if ((e.ctrlKey && e.keyCode === 86) || e.keyCode === 45)
        {
            if (this.fileService.getClipboard(true).length + this.folderService.getClipboard(true).length > 0)
            {
                this.emitter.emit({ Paste: true });
            }
        }
        //Select all
        else if (e.ctrlKey && e.keyCode === 65)
        {
            e.preventDefault();
            this.selection = this.folders.concat(this.files);
        }
        //Down:
        else if ((e.keyCode === 40 && this.viewMode === 2) || (e.keyCode === 39 && this.viewMode !== 2))
        {
            //Select first element if no selection was done
            if (this.selection.length === 0)
            {
                if (this.folders.length === 0 && this.files.length === 0)
                {
                    return;
                }
                this.selection = this.folders.length > 0 ? [this.folders[0]] : [this.files[0]];
                return;
            }
            //Check if last selected element was a folder or file
            let lastSelectedElement = this.selection[this.selection.length - 1];
            let isFolder = !lastSelectedElement.UID;
            let index = isFolder ? this.folders.map((folder: GroupFolder) => { return folder.id; }).indexOf(lastSelectedElement.id)
                : this.files.map((file: ModelFile) => { return file.id; }).indexOf(lastSelectedElement.id);
            //Test if a jump to files is neccessary
            let elementToSelect;
            if (isFolder && index === this.folders.length - 1)
            {
                elementToSelect = this.files.length > 0 ? this.files[0] : this.folders[index];
            }
            else if (isFolder)
            {
                elementToSelect = this.folders[index + 1];
            }
            else
            {
                if (index === this.files.length - 1)
                {
                    index--;
                }
                elementToSelect = this.files[index + 1];
            }
            if (this.selection.indexOf(elementToSelect) === -1 && e.ctrlKey && e.shiftKey && elementToSelect)
            {
                this.selection.push(elementToSelect);
            }
            else
            {
                this.selection = [elementToSelect];
            }
        }
        //Up:
        else if ((e.keyCode === 38 && this.viewMode === 2) || (e.keyCode === 37 && this.viewMode !== 2))
        {
            //Select first element if no selection was done
            if (this.selection.length === 0)
            {
                if (this.folders.length === 0 && this.files.length === 0)
                {
                    return;
                }
                this.selection = this.folders.length > 0 ? [this.folders[0]] : [this.files[0]];
                return;
            }
            //Check if last selected element was a folder or file
            let lastSelectedElement = this.selection[this.selection.length - 1];
            let isFile = lastSelectedElement.UID;
            let index = !isFile ? this.folders.map((folder: GroupFolder) => { return folder.id; }).indexOf(lastSelectedElement.id)
                : this.files.map((file: ModelFile) => { return file.id; }).indexOf(lastSelectedElement.id);
            //Test if a jump to folders is neccessary
            let elementToSelect;
            if (isFile && index === 0)
            {
                elementToSelect = this.folders.length > 0 ? this.folders[this.folders.length - 1] : this.files[0];
            }
            else if (isFile)
            {
                elementToSelect = this.files[index - 1];
            }
            else
            {
                elementToSelect = index !== 0 ? this.folders[index - 1] : this.folders[0];
            }
            if (this.selection.indexOf(elementToSelect) === -1 && e.ctrlKey && e.shiftKey && elementToSelect)
            {
                this.selection.push(elementToSelect);
            }
            else
            {
                this.selection = [elementToSelect];
            }
        }
    }

    constructor(private viewerService: ViewerService,
        private folderApi: GroupFolderApi,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fileService: FileService,
        private folderService: FolderService,
        private sanitizer: DomSanitizer,
        private fileApi: ModelFileApi,
        private renderer: Renderer2,
        private ngZone: NgZone,
        private uploadService: UploadService,
        private settingsService: SettingsService,
        private eventService: GlobalEventService,
        private groupApi: GroupApi,
        private progressService: ProgressService,
        private convApi: ConvJobApi) { }

    ngAfterViewInit()
    {
        this.files = [];
        this.convFiles = [];
        this.folders = [];
        this.isGroup = false;
        this.selection = [];
        this.filesSelected = false;

        this.isiOs = /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        setTimeout(() => { this.closePreview(); }, 0);

        //Right browser
        if (this.root)
        {
            if (this.lastTargetFolder)
            {
                this.emitter.emit({ JumpToFolder: { id: this.root.id, folder: this.lastTargetFolder.id } });
            }
            if (this.lastViewer)
            {
                setTimeout(() => { this.setViewer(this.lastViewer); }, 0);
            }
        }

        //----------------------- Event listener ------------------------------------------
        this.registerWrapperEventListener(document.getElementById(this.rootId));
        this.registerWrapperEventListener(document.getElementById('mobile_' + this.rootId));

        //Register onchange listener for the search field
        this.searchText.valueChanges.subscribe(val =>
        {
            this.searchRegEx = val.startsWith('*') ? val.substr(1) : val;
            this.changePage(1);
        });
        this.globalEventSubscription = this.eventService.keyboardEvent.subscribe((key: VisKeyboardEvent) =>
        {
            if (key === VisKeyboardEvent.ESC)
            {
                this.remoteShareModal.close();
                this.shareModal.close();
                this.simpleDialog.close();
                this.propertiesModal.close();
                this.confirmModal.close();
            }
        });
        if (this.isMobile)
        {
            setTimeout(() =>
            {
                this.crudActive = this.settingsService.getCrud();
                let fileClipoardSize = this.fileService.getClipboard(true).length;
                let folderClipboardSize = this.folderService.getClipboard(true).length;
                this.crudStarted = !(fileClipoardSize > 0 || folderClipboardSize > 0);
                if (this.crudStarted)
                {
                    this.clipboardSize = fileClipoardSize + folderClipboardSize;
                    this.isClipboardCut = this.fileService.isClipboardCut() || this.folderService.isClipboardCut();
                }
            }, 0);
        }
    }

    registerWrapperEventListener(wrapper)
    {
        //Context menu
        wrapper.oncontextmenu = e =>
        {
            e.preventDefault();
            e.stopPropagation();
            if (!$(e.target).hasClass('2DCanvas')
                && !this.isRemotePublicGroup
                //No admin verification
                && (!this.settings.AdminVerification ||
                    //Admin verification
                    (this.settings.AdminVerification &&
                        //Verified
                        (this.activeViewer['Verified'] ||
                            //or guest
                            this.settings.AllowGuests))))
            {
                this.openContextMenu(e.pageX, e.pageY);
            }
        };

        //Clear selecion by clicking empty space in the browser body
        wrapper.onclick = e =>
        {
            if (($(e.target).hasClass('browser-list') || $(e.target).hasClass('browser-grid-small') || $(e.target).hasClass('browser-grid-large'))
                && !this.ctxMenu.isOpen)
            {
                this.filesSelected = false;
                this.selection = [];
            }
            return false;
        };

        //Drag over
        this.ngZone.runOutsideAngular(() =>
        {
            wrapper.addEventListener('dragover', (e) =>
            {
                let node = e.target as HTMLElement;
                if ($(e.target).hasClass('browser-folder') || $(e.target).hasClass('file-browser') || $(e.target).hasClass('browser')
                    || node.nodeName.toLocaleLowerCase() === 'td' || $(e.target).hasClass('browser-list') || $(e.target).hasClass('fa-folder'))
                {
                    if ($(e.target).hasClass('browser-folder') || !this.inDrag || $(node.parentElement).hasClass('browser-list-folder-row') || $(e.target).hasClass('fa-folder'))
                    {
                        e.preventDefault();
                    }
                }
            });
        });

    }

    isMenuOpen()
    {
        return this.remoteShareModal.isOpen || this.shareModal.isOpen || this.simpleDialog.isOpen || this.chatOpen || this.propertiesModal.isOpen || this.confirmModal.isOpen;
    }

    openContextMenu(x: number, y: number, event?: MouseEvent)
    {
        if (event)
        {
            event.preventDefault();
            event.stopPropagation();
        }
        if (this.shareContextMenu && this.files.length === 0)
        {
            return;
        }
        if (this.selection.length < 2 && (this.hoveredFolder || this.hoveredFile))
        {
            if (this.hoveredFile)
            {
                this.selection = [this.hoveredFile];
            }
            else
            {
                this.selection = [this.hoveredFolder];
            }
        }
        let folderSelected: boolean = false;
        let isOwner: boolean = true;
        let downloadOnlySelected: boolean = false;
        let downloadWitelisted: boolean = true;
        for (let i = 0; i < this.selection.length; i++)
        {
            if (!this.selection[i].UID)
            {
                folderSelected = true;
                downloadWitelisted = false;
            }
            else
            {
                downloadOnlySelected = downloadOnlySelected || this.selection[i].ForeignFormat;
                if (downloadOnlySelected)
                {
                    let fileType: string = this.selection[i].UID.toLowerCase().split('.').pop();
                    downloadWitelisted = downloadWitelisted && this.downloadWhitelist.map(format => { return format.toLowerCase(); }).indexOf(fileType.toLowerCase()) !== -1;
                }
                else
                {
                    downloadWitelisted = false;
                }
            }
            if (this.group && this.group.IsPublic)
            {
                isOwner = isOwner && this.selection[i].ownerId === this.activeViewer.id;
            }
        }
        let assemblySelected = this.selection.length === 1 && folderSelected && this.selection[0].IsParent;
        let isPublic = this.shareContextMenu ? false : this.group ? this.group.IsPublic : false;
        this.ctxMenu.showContextMenu(isPublic, this.group ? this.group.IsShare : false, x, y, this.selection.length, folderSelected, assemblySelected, this.activeViewer['isAdmin'] || isOwner, downloadOnlySelected, downloadWitelisted);
    }

    openPropertiesDialog(item, showAttachments?: boolean)
    {
        this.propertiesModal.open(item, showAttachments, this.isGroup ? undefined : this.group.id);
    }

    mobileSort(event)
    {
        let sortVal = event.target.value;
        let sortCat: string;
        switch (sortVal)
        {
            case 'NameAsc':
                this.sortAsc = true;
                sortCat = 'Name';
                break;
            case 'NameDesc':
                this.sortAsc = false;
                sortCat = 'Name';
                break;
            case 'SizeAsc':
                this.sortAsc = true;
                sortCat = 'Size';
                break;
            case 'SizeDesc':
                this.sortAsc = false;
                sortCat = 'Size';
                break;
            case 'DateAsc':
                this.sortAsc = true;
                sortCat = 'LastModified';
                break;
            case 'DateDesc':
                this.sortAsc = false;
                sortCat = 'LastModified';
                break;
        }
        this.sortTable(sortCat, false);
    }

    sortTable(category: string, changeDirection: boolean = true)
    {
        if (changeDirection)
        {
            this.sortAsc = this.sortCat === category ? !this.sortAsc : false;
        }
        this.sortCat = category;
        if (this.isShare && this.folders.length > 1)
        {
            this.folders = this.folders.sort((folder1: GroupFolder, folder2: GroupFolder) =>
            {
                let val1 = folder1['name'].toLowerCase();
                let val2 = folder2['name'].toLowerCase();
                if (val1 > val2)
                {
                    return this.sortAsc ? 1 : -1;
                }
                else
                {
                    return this.sortAsc ? -1 : 1;
                }
            });
        }
        else
        {
            if (!this.wasNavigation)
            {
                this.changePage(1);
            }
        }
    }

    ngOnInit()
    {
        this.settingsSubscription = this.settingsService.settings.subscribe((settings: Settings) =>
        {
            this.settings = settings;
        });
        this.activatedRoute.queryParams.subscribe((queryParams: Params) =>
        {
            this.queryFilter = queryParams.filter ? queryParams.filter : undefined;
            this.fileForAttachmentId = undefined;
            this.fileForRemoteShareId = undefined;
            this.fileForShareId = undefined;
            if (queryParams.fileForAttachment)
            {
                this.fileForAttachmentId = parseInt(queryParams.fileForAttachment);
                this.attachmentId = queryParams.attachment ? parseInt(queryParams.attachment) : undefined;
            }
            else if (queryParams.fileForRemoteShare)
            {
                this.fileForRemoteShareId = parseInt(queryParams.fileForRemoteShare);
            }
            else if (queryParams.fileForShare)
            {
                this.fileForShareId = parseInt(queryParams.fileForShare);
            }
        });
    }

    ngOnDestroy()
    {
        if (this.placeholderSubscription)
        {
            this.placeholderSubscription.unsubscribe();
        }
        this.settingsSubscription.unsubscribe();
        this.globalEventSubscription.unsubscribe();
    }

    setRemotePublicGroup(state: boolean)
    {
        this.isRemotePublicGroup = state;
    }

    getActiveRoot()
    {
        return this.isGroup ? this.group : this.activeFolder;
    }

    isRootGroup()
    {
        return this.isGroup;
    }

    clearSelection()
    {
        this.filesSelected = false;
        this.selection = [];
    }

    getSelection()
    {
        return this.selection;
    }

    setViewer(viewer: Viewer)
    {
        this.viewMode = viewer.ViewMode;
        if (viewer.ViewMode !== 2)
        {
            this.closePreview();
        }
        this.activeViewer = viewer;
        this.showNative = !viewer.HideNative;
        this.show3DVS = !viewer.HideNonNative;
        this.showAssemblies = !viewer.HideAssemblies;
        this.showFolders = !viewer.HideFolders;
    }

    setGroup(group: Group)
    {
        this.group = group;
        this.wasNavigation = true;
        this.placeholderSubscription = this.uploadService.currentUploads.subscribe((uploads: UploadItem[]) =>
        {
            if (!Array.isArray(uploads))
            {
                uploads = [uploads];
            }
            if (uploads && this.group)
            {
                this.placeholderFiles = uploads.filter(item =>
                {
                    return this.isGroup ? item.groupId === this.group.id : item.groupId === this.group.id && item.folderId === this.activeFolder.id;
                });
            }
        });
    }


    setFolders(folder: GroupFolder[])
    {
        this.folders = folder;
        if (this.wasNavigation)
        {
            if (this.queryFilter)
            {
                this.searchText.setValue(this.queryFilter);
                this.filesSelected = false;
            }
            else
            {
                this.searchText.setValue('', { emitEvent: false });
                this.searchRegEx = '';
                this.sortTable(this.sortCat, false);
            }
        }
        this.wasNavigation = false;
    }

    setShareFolders(folder: SharedFolder[])
    {
        this.folders = folder;
    }

    getShareFolders(): SharedFolder[]
    {
        return this.folders;
    }

    trackById(index, item)
    {
        return item.id;
    }

    setFiles(files: ModelFile[])
    {
        this.files = files.filter(item =>
        {
            return !item.IsInConv;
        });
        this.convFiles = files.filter(item =>
        {
            return item.IsInConv && item.ownerId === this.viewerService.getViewer().id;
        });
        if (files.length > 0)
        {
            this.loadImages();
        }
        if (this.formatBlacklist && this.formatBlacklist.length > 0)
        {
            this.files.forEach((f: ModelFile) =>
            {
                let format = f.UID.toLowerCase().split('.').pop();
                if (this.formatBlacklist.indexOf(format) !== -1)
                {
                    f['ForeignFormat'] = true;
                }
            });
        }
        if (this.wasNavigation)
        {
            if (this.queryFilter)
            {
                this.searchText.setValue(this.queryFilter);
                this.filesSelected = false;
            }
            else
            {
                this.searchText.setValue('', { emitEvent: false });
                this.searchRegEx = '';
                this.sortTable(this.sortCat, false);
                this.filesSelected = false;
            }
        }
        else
        {
            this.files.forEach((f: ModelFile) =>
            {
                f['InView'] = f.id === this.previewId;
            });
        }
        this.wasNavigation = false;
        //Open modals from URL open
        if (files.length > 0)
        {
            if (this.fileForAttachmentId && !this.propertiesModal.isOpen)
            {
                let index = this.files.map((f: ModelFile) => { return f.id; }).indexOf(this.fileForAttachmentId);
                if (index !== -1)
                {
                    this.selection = [this.files[index]];
                    this.propertiesModal.open(this.files[index], true, this.isGroup ? undefined : this.group.id, this.attachmentId);
                }
                this.fileForAttachmentId = undefined;
                this.attachmentId = undefined;
            }
            else if (this.fileForRemoteShareId && !this.remoteShareModal.isOpen)
            {
                let index = this.files.map((f: ModelFile) => { return f.id; }).indexOf(this.fileForRemoteShareId);
                if (index !== -1)
                {
                    this.selection = [this.files[index]];
                    setTimeout(() => { this.remoteShareModal.open(); }, 0);
                }
                this.fileForRemoteShareId = undefined;
            }
            else if (this.fileForShareId && !this.shareModal.isOpen)
            {
                let index = this.files.map((f: ModelFile) => { return f.id; }).indexOf(this.fileForShareId);
                if (index !== -1)
                {
                    this.selection = [this.files[index]];
                    setTimeout(() => { this.shareModal.open(); }, 0);
                }
                this.fileForShareId = undefined;
            }
        }
    }

    setWriteAccess(flag: boolean)
    {
        this.writeAccess = flag;
    }

    setIsGroupFlag(flag: boolean)
    {
        this.isGroup = flag;
    }

    setActiveFolder(folder: GroupFolder)
    {
        if (folder && folder.IsParent)
        {
            this.isAssembly = true;
        }
        else
        {
            this.isAssembly = false;
        }
        this.activeFolder = folder;
        this.wasNavigation = true;
    }

    setViewMode(viewMode)
    {
        this.viewerService.setViewMode(viewMode).subscribe((viewer) =>
        {
            this.viewerService.changeViewer(viewer);
        });
    }

    addCrumb(crumb: BrowserBreadCrumb)
    {
        this.breadcrumb.addCrumb(crumb);
    }

    addShareCrumb(crumb: ShareBreadCrumb)
    {
        this.breadcrumb.addShareCrumb(crumb);
    }

    resetShareCrumb()
    {
        this.breadcrumb.resetShareCrumb();
    }

    //Pagination
    setPageSize(size: number)
    {
        this.pageSize = size;
    }

    setFolderCount(count: number)
    {
        this.folderCount = count;
    }

    setFileCount(count: number)
    {
        this.fileCount = count;
    }

    setPage(page: number)
    {
        this.filesSelected = false;
        this.page = page;
    }

    changePage(page: number)
    {
        if (page === -1)
        {
            page = Math.ceil((this.fileCount + this.folderCount) / this.pageSize);
        }
        this.emitter.emit({ Page: page, SearchText: this.searchRegEx, SortCat: this.sortCat, SortAsc: this.sortAsc });
    }

    loadImages()
    {
        this.files.map((file: ModelFile) =>
        {
            if (!this.images[file.id])
            {
                this.fileApi.getImageData(file.id, false).subscribe((imageData: ImageData) =>
                {
                    if (imageData)
                    {
                        this.images[file.id] = imageData.Image;
                    }
                }, err =>
                {
                });
            }
        });
    }

    filterResult(filterKey: string)
    {
        let vis: boolean;
        let prop: any;
        switch (filterKey)
        {
            case 'showNative':
                vis = !this.showNative;
                prop = { HideNative: this.showNative };
                break;
            case 'show3DVS':
                vis = !this.show3DVS;
                prop = { HideNonNative: this.show3DVS };
                break;
            case 'showFolders':
                vis = !this.showFolders;
                prop = { HideFolders: this.showFolders };
                break;
            case 'showAssemblies':
                vis = !this.showAssemblies;
                prop = { HideAssemblies: this.showAssemblies };
                break;
            default:
                return;
        }
        if (this.isRemotePublicGroup)
        {
            this.activeViewer = { ... this.activeViewer, ...prop };
            this.viewerService.changeViewer(this.activeViewer);
        }
        else
        {
            this.viewerService.setAttribute(prop).subscribe((viewer: Viewer) =>
            {
                this.viewerService.changeViewer(viewer);
            });
        }
    }

    applySelection()
    {
        let selection = this.selection.slice();
        this.selection = [];
        //Re-select all files after update
        for (let i = 0; i < this.files.length; i++)
        {
            let index = selection.map(item => { return item.id; }).indexOf(this.files[i].id);
            if (index !== -1 && selection[index].UID)
            {
                this.selection.push(this.files[i]);
            }
        }
        //Re-select all folder after update
        for (let i = 0; i < this.folders.length; i++)
        {
            let index = selection.map(item => { return item.id; }).indexOf(this.folders[i].id);
            if (index !== -1 && !selection[index].UID)
            {
                this.selection.push(this.folders[i]);
            }
        }
    }

    addTempFile(file: UploadItem): UploadItem
    {
        file.groupId = this.group.id;
        file.folderId = (this.isGroup || this.root) ? undefined : this.activeFolder.id;
        let id = this.uploadService.addItem(file);
        file.id = id;
        return file;
    }

    removeTempFile(file: UploadItem)
    {
        this.uploadService.removeItem(file.id);
    }

    abortUpload(file: UploadItem)
    {
        file.call.unsubscribe();
        this.removeTempFile(file);
    }

    resetSearchTextString()
    {
        this.searchText.setValue('');
    }

    openFile(file, e?: MouseEvent)
    {
        if (e && e.ctrlKey)
        {
            this.selectFile(file, e);
            return;
        }
        let map = this.fileService.getMap();
        if (file['ForeignFormat'])
        {
            let isWhiteListed: boolean = false;
            let fileType: string = file.Name.split('.').pop();
            if (this.formatBlacklist.map(format => { return format.toLowerCase(); }).indexOf(fileType.toLowerCase()) !== -1)
            {
                if (this.downloadWhitelist.map(format => { return format.toLowerCase(); }).indexOf(fileType.toLowerCase()) !== -1)
                {
                    isWhiteListed = true;
                }
            }
            if ((this.writeAccess && this.settings.AllowDownloads && this.group.AllowDownload) || isWhiteListed)
            {
                this.emitter.emit({ Type: 'DownloadFiles', Files: [file] });
            }
            else
            {
                let error: PortalError = {
                    code: 0,
                    message: 'WRONG_FORMAT_NO_DOWNLOAD',
                    name: 'DownloadRights'
                };
                this.progressService.addError(error);
            }
            return;
        }
        if (this.settings.SingleSession && map.length > 0)
        {
            let token = this.fileService.getToken();
            this.viewerService.openInTab(token, file).subscribe(res =>
            {
                if (this.isMobile)
                {
                    this.selection = [];
                    this.filesSelected = false;
                }
            });
        }
        else
        {
            this.viewerService.openFile(file).subscribe(token =>
            {
                this.fileService.addTab(file, token.token);
                if (this.isMobile)
                {
                    this.selection = [];
                    this.filesSelected = false;
                }
            });
        }
    }

    openTab(e: MouseEvent, file)
    {
        if (e && (e.which === 2 || e.button === 1))
        {
            let token = this.fileService.getToken();
            if (!token)
            {
                this.openFile(file, e);
            }
            else
            {
                this.viewerService.openInTab(token, file).subscribe(res =>
                {

                },
                    err =>
                    {
                        if (err.statusCode === 404)
                        {
                            this.openFile(file, e);
                        }
                    });
            }
        }
        else if (e && (e.which === 3 || e.button === 2))
        {
            if (this.selection.indexOf(file) === -1)
            {
                this.selection = [file];
            }
        }
    }

    openSelectedFiles()
    {
        for (let i = 0; i < this.selection.length; i++)
        {
            if (this.selection[i].UID)
            {
                this.openFile(this.selection[i]);
            }
        }
        if (this.isMobile)
        {
            this.selection = [];
            this.filesSelected = false;
        }
    }

    openSelectedFilesInClient(token)
    {
        for (let i = 0; i < this.selection.length; i++)
        {
            if (this.selection[i].UID)
            {
                this.viewerService.openInTab(token, this.selection[i]).subscribe(res =>
                {
                    if (this.isMobile)
                    {
                        this.selection = [];
                        this.filesSelected = false;
                    }
                });
            }
        }
    }

    openSelectedFilesInNewScene()
    {
        let filesToLoad = [];
        for (let i = 0; i < this.selection.length; i++)
        {
            if (this.selection[i].UID)
            {
                filesToLoad.push(this.selection[i]);
            }
        }
        this.openFile(filesToLoad);
    }

    importSelectedFilesToScene(token)
    {
        let files = [];
        for (let i = 0; i < this.selection.length; i++)
        {
            if (this.selection[i].UID)
            {
                files.push(this.selection[i]);
            }
        }
        this.viewerService.importInTab(token, files).subscribe(res =>
        {
            if (this.isMobile)
            {
                this.selection = [];
                this.filesSelected = false;
            }
        });
    }

    importFromMobile(token)
    {
        this.importSelectedFilesToScene(token);
        this.sceneSelection = false;
        this.selection = [];
        this.filesSelected = false;
    }

    openSceneSelection()
    {
        this.viewerService.getMap().subscribe((map: ModelMapping[]) =>
        {
            this.scenes = map;
        });
        this.sceneSelection = true;
    }

    selectFile(file: ModelFile, e?: MouseEvent)
    {
        e.stopPropagation();
        this.ctxMenu.hideContextMenu();
        this.emitter.emit('Select');
        if (this.selection.indexOf(file) === -1)
        {
            this.selection.push(file);
            if (this.isMobile)
            {
                this.filesSelected = true;
            }
        }
        else
        {
            this.selection.splice(this.selection.indexOf(file), 1);
            if (this.isMobile && this.selection.filter(item => { return item.UID; }).length === 0)
            {
                this.filesSelected = false;
            }
        }
    }

    selectFolder(folder: GroupFolder, e?: MouseEvent)
    {
        e.stopPropagation();
        if (!folder)
        {
            return;
        }
        this.ctxMenu.hideContextMenu();
        this.emitter.emit('Select');
        if (this.selection.indexOf(folder) === -1)
        {
            this.selection.push(folder);
        }
        else
        {
            this.selection.splice(this.selection.indexOf(folder), 1);
        }
    }

    selectAll()
    {
        if (!this.allSelected)
        {
            this.selection = this.folders.concat(this.files);
            this.filesSelected = this.files.length !== 0;
        }
        else
        {
            this.selection = [];
            this.filesSelected = false;
        }
        this.allSelected = !this.allSelected;
    }

    jumpToFolder(folder: GroupFolder)
    {
        this.emitter.emit({ JumpToFolder: { id: this.root ? this.root.id : this.group.id, folder: folder.id } });
    }

    fileClicked(file: ModelFile, e?: MouseEvent)
    {
        e.preventDefault();
        e.stopPropagation();
        if (e.which !== 1 || e.button !== 0)
        {
            if (e.which === 3 || e.button === 2)
            {
                if (this.selection.length <= 1)
                {
                    this.selection = [file];
                }
            }
            return;
        }
        if (e && e.shiftKey)
        {
            let selectionLength = this.selection.length;
            //No previous selection
            if (selectionLength === 0)
            {
                this.selectFile(file, e);
            }
            else
            {
                let lastSelectedElement = this.selection[selectionLength - 1];
                //Reset selection if ctrl key wasn't pressed
                if (!e.ctrlKey)
                {
                    this.selection = [lastSelectedElement];
                }
                let wasFileSelected = lastSelectedElement.UID !== undefined;
                //Get start index
                let startIndex: number;
                let searchArray = wasFileSelected ? this.files : this.folders;
                for (let i = 0; i < searchArray.length; i++)
                {
                    if (searchArray[i].id === lastSelectedElement.id)
                    {
                        startIndex = i;
                    }
                }
                //Get end index
                let endIndex: number;
                for (let j = 0; j < this.files.length; j++)
                {
                    if (this.files[j].id === file.id)
                    {
                        endIndex = j;
                    }
                }
                //Select forward
                if (startIndex <= endIndex || !wasFileSelected)
                {
                    //Select all folders
                    if (!wasFileSelected)
                    {
                        for (let f = startIndex + 1; f < this.folders.length + 1; f++)
                        {
                            this.selectFolder(this.folders[f], e);
                        }
                    }
                    //Select files
                    let fileIndexStart = !wasFileSelected ? -1 : startIndex;
                    for (let f = fileIndexStart + 1; f < endIndex + 1; f++)
                    {
                        this.selectFile(this.files[f], e);
                    }
                }
                //Select backwards
                else
                {
                    //Select files
                    for (let f = startIndex - 1; f > endIndex - 1; f--)
                    {
                        this.selectFile(this.files[f], e);
                    }
                }
            }
        }
        else if (e && e.ctrlKey)
        {
            this.selectFile(file, e);
        }
        else
        {
            if (!$(e.target).hasClass('browser-list-table-selection-col') &&
                !$(e.target).hasClass('browser-grid-selection-wrapper'))
            {
                this.filesSelected = false;
                this.selection = [];
            }
            if (this.viewMode !== 2)
            {
                this.openFile(file, e);
            }
            else
            {
                this.selectFile(file, e);
            }
        }
    }

    folderClicked(folder: GroupFolder, e?: MouseEvent, thumbnailClicked?: boolean)
    {
        e.preventDefault();
        e.stopPropagation();
        if (folder['InAssemblyUpload'])
        {
            //Shouldn't happen
            return;
        }
        if (e.which !== 1 || e.button !== 0)
        {
            if (e.which === 3 || e.button === 2)
            {
                if (this.selection.length <= 1)
                {
                    this.selection = [folder];
                }
            }
            return;
        }
        if (e && e.shiftKey)
        {
            let selectionLength = this.selection.length;
            //No previous selection
            if (selectionLength === 0)
            {
                this.selectFolder(folder, e);
            }
            else
            {
                let lastSelectedElement = this.selection[selectionLength - 1];
                //Reset selection if ctrl key wasn't pressed
                if (!e.ctrlKey)
                {
                    this.selection = [lastSelectedElement];
                }
                let wasFileSelected = lastSelectedElement.UID !== undefined;
                //Get start index
                let startIndex: number;
                let searchArray = wasFileSelected ? this.files : this.folders;
                for (let i = 0; i < searchArray.length; i++)
                {
                    if (searchArray[i].id === lastSelectedElement.id)
                    {
                        startIndex = i;
                    }
                }
                //Get end index
                let endIndex: number;
                for (let j = 0; j < this.folders.length; j++)
                {
                    if (this.folders[j].id === folder.id)
                    {
                        endIndex = j;
                    }
                }
                //Select forward
                if (startIndex <= endIndex && !wasFileSelected)
                {
                    //Select folders
                    let lastFolderIndex = wasFileSelected ? this.folders.length : endIndex;
                    for (let f = startIndex + 1; f < lastFolderIndex + 1; f++)
                    {
                        this.selectFolder(this.folders[f], e);
                    }
                    //Select files
                    if (wasFileSelected)
                    {
                        for (let f = 0; f < endIndex; f++)
                        {
                            this.selectFile(this.files[f], e);
                        }
                    }
                }
                //Select backwards
                else
                {
                    //Select files
                    if (wasFileSelected)
                    {
                        for (let f = startIndex - 1; f >= 0; f--)
                        {
                            this.selectFile(this.files[f], e);
                        }
                    }
                    //Select folders
                    let firstFolderIndex = wasFileSelected ? this.folders.length : startIndex;
                    for (let f = firstFolderIndex; f > endIndex - 1; f--)
                    {
                        this.selectFolder(this.folders[f], e);
                    }
                }
            }
        }
        else if (e && e.ctrlKey)
        {
            this.selectFolder(folder, e);
        }
        else if (this.splitMode && this.viewMode !== 2 && folder.IsParent)
        {
            let error: PortalError = {
                code: 0,
                message: 'ASS_OPEN_IN_SPLIT',
                name: 'NavigationDenied'
            };
            this.progressService.addError(error);
        }
        else
        {
            if (this.viewMode === 2 && thumbnailClicked && (this.splitMode || this.isMobile))
            {
                return this.jumpToFolder(folder);
            }
            if (!$(e.target).hasClass('browser-list-table-selection-col') &&
                !$(e.target).hasClass('browser-grid-selection-wrapper'))
            {
                this.selection = [];
                this.filesSelected = false;
            }
            if (this.viewMode !== 2)
            {
                this.jumpToFolder(folder);
            }
            else
            {
                this.selectFolder(folder, e);
            }
        }
    }

    folderDoubleClicked(folder: GroupFolder)
    {
        if (this.splitMode && folder.IsParent)
        {
            let error: PortalError = {
                code: 0,
                message: 'ASS_OPEN_IN_SPLIT',
                name: 'NavigationDenied'
            };
            this.progressService.addError(error);
        }
        else
        {
            this.emitter.emit({ JumpToFolder: { id: this.root ? this.root.id : this.group.id, folder: folder.id } });
        }
    }

    showModelPreview(file: ModelFile, e?: MouseEvent)
    {
        e.stopPropagation();
        if (this.isPreviewInit)
        {
            this.showPreview = true;
            let token = this.previewClient.getToken();
            this.viewerService.openInTab({ token: token }, file).subscribe(() =>
            {
                this.previewClient.openFileHandler();
            });
        }
        else
        {
            this.isPreviewInit = true;
            this.showPreview = true;
            this.viewerService.openFile(file, true).subscribe(token =>
            {
                this.previewClient.openConnection(token);
            });
        }
        this.files.forEach((f: ModelFile) =>
        {
            f['InView'] = false;
        });
        file['InView'] = true;
        this.previewId = file.id;
    }

    closePreview()
    {
        this.showPreview = false;
        this.previewId = 0;
        this.files.forEach((f: ModelFile) =>
        {
            f['InView'] = false;
        });
    }

    openShare()
    {
        if (this.selection && this.selection.length > 0)
        {
            this.shareModal.open();
        }
    }

    deleteSelection(isCut?: boolean)
    {
        if (this.selection && this.selection.length > 0)
        {
            let attribute = {
                IsDeleted: true
            };
            let calls: Observable<any>[] = [];
            let publicFolders: GroupFolder[] = [];
            for (let i = 0; i < this.selection.length; i++)
            {
                let item = this.selection[i];
                item.IsDeleted = true;
                if (item.UID)
                {
                    calls.push(this.fileApi.patchAttributes(item.id, attribute));
                }
                else
                {
                    if (this.group.IsPublic)
                    {
                        publicFolders.push(item);
                        this.checkFolderContentForPublicDelete(item);
                    }
                    else
                    {
                        calls.push(this.folderApi.patchAttributes(item.id, attribute));
                    }
                }
            }
            forkJoin(calls).subscribe(responseList =>
            {
                let jumpToPageOne = this.files.length + this.folders.length === this.selection.length;
                this.selection = [];
                this.filesSelected = false;
                if (jumpToPageOne)
                {
                    this.emitter.emit('UpdateAndReset');
                }
                else
                {
                    this.emitter.emit('Update');
                }
            }, err => { },
                () =>
                {
                    if (this.group.IsPublic && publicFolders.length > 0)
                    {
                        this.finishPublicFolderDeletion(publicFolders);
                    }
                });
        }
        if (this.isMobile && !isCut)
        {
            this.settingsService.setCrud(false);
            this.crudActive = false;
            this.crudStarted = false;
        }
    }

    finishPublicFolderDeletion(folders: GroupFolder[])
    {
        folders.forEach((folder: GroupFolder) =>
        {
            this.checkFolderContentForPublicDelete(folder);
        });
    }

    checkFolderContentForPublicDelete(folder: GroupFolder)
    {
        let attribute = {
            IsDeleted: true
        };
        this.folderApi.countChildrenFiles(folder.id).subscribe(childFiles =>
        {
            if (childFiles.count > 0)
            {
                this.selection.push(folder);
                let error: PortalError = {
                    code: 0,
                    message: 'PUBLIC_NON_EMPTY_DELETE',
                    name: 'NotEmptyError'
                };
                this.progressService.addError(error);
                return;
            }
            this.folderApi.countChildrenFolder(folder.id).subscribe(childFolder =>
            {
                if (childFolder.count > 0)
                {
                    this.selection.push(folder);
                    let error: PortalError = {
                        code: 0,
                        message: 'PUBLIC_NON_EMPTY_DELETE',
                        name: 'NotEmptyError'
                    };
                    this.progressService.addError(error);
                    return;
                }
                this.folderApi.patchAttributes(folder.id, attribute).subscribe(res =>
                {
                    this.emitter.emit('Update');
                });
            });
        });
    }

    saveFolder(name: string)
    {
        if (!name || name === "" || name === null || name === " ")
        {
            name = "New";
        }
        this.emitter.emit({ FolderSaved: name });
    }

    //Dragging
    dragStart(e: DragEvent, singleSelection, isRow: boolean = false)
    {
        if (!this.splitMode)
        {
            //return false;
        }
        this.inDrag = true;
        this.selection = this.selection.length === 0 ? [singleSelection] : this.selection;
        let data = JSON.stringify(this.selection);
        e.dataTransfer.setData('text/plain', data);
        if (this.selection.length > 1 && !isRow)
        {
            this.dragElement = document.createElement('div');
            this.renderer.addClass(this.dragElement, 'drag-stack');
            $(e.target).append(this.dragElement);
        }
    }

    dragEnd(e: DragEvent)
    {
        this.dragStarted = false;
        this.inDrag = false;
        $(this.dragElement).remove();
    }

    onFolderDrop(e: DragEvent, folder: GroupFolder)
    {
        e.stopPropagation();
        //Paste into folder in same browser
        if (e.dataTransfer.getData('text/plain') !== "")
        {
            this.selection = JSON.parse(e.dataTransfer.getData('text/plain'));
            if (!this.selection.every(item => { return item.UID || item.id !== folder.id; }))
            {
                let error: PortalError = {
                    code: 401,
                    message: 'CIRCULAR_CREATION',
                    name: 'CircularCreation'
                };
                this.progressService.addError(error);
                return;
            }
            if ((!this.splitMode && this.inDrag) || this.cutMode)
            {
                this.cutToClipboard();
            }
            else
            {
                this.copyToClipboard();
            }
            if (this.fileService.getClipboard(true).length + this.folderService.getClipboard(true).length > 0)
            {
                this.emitter.emit({ Paste: true, Folder: folder });
            }
        }
        else if (e.dataTransfer.getData('text/plain') === "")
        {
            e.preventDefault();
            this.emitter.emit({ ExternalDrop: e.dataTransfer.files, Folder: folder });
        }
    }

    onGroupDrop(e: DragEvent)
    {
        e.stopPropagation();
        if (e.dataTransfer.getData('text/plain') !== "" && !this.inDrag)
        {
            this.selection = JSON.parse(e.dataTransfer.getData('text/plain'));
            if (this.cutMode)
            {
                this.cutToClipboard();
            }
            else
            {
                this.copyToClipboard();
            }
            if (this.fileService.getClipboard(true).length + this.folderService.getClipboard(true).length > 0)
            {
                this.emitter.emit({ Paste: true });
            }
        }
        else if (e.dataTransfer.getData('text/plain') === "")
        {
            e.preventDefault();
            this.emitter.emit({ ExternalDrop: e.dataTransfer.files });
        }
    }

    onDragEnter(e: DragEvent, folder?: GroupFolder)
    {
        if (!this.inDrag)
        {
            this.dragStarted = true;
        }
        if (folder)
        {
            setTimeout(() => { this.selection = [folder]; }, 0);
        }
    }

    onDragLeave(e, folder?: GroupFolder)
    {
        if (!this.inDrag || folder)
        {
            this.selection = [];
        }
    }

    sanitizeBase64Image(imageSrc: string)
    {
        return imageSrc ? this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + imageSrc) : '';
    }

    cancelClipboard()
    {
        this.crudStarted = true;
        this.crudActive = false;
        this.settingsService.setCrud(false);
    }

    pasteFromClipboard()
    {
        if (this.fileService.getClipboard(true).length + this.folderService.getClipboard(true).length > 0)
        {
            this.emitter.emit({ Paste: true });
        }
    }

    copyToClipboard()
    {
        let tmpFiles: ModelFile[] = [];
        let tmpFolders: GroupFolder[] = [];
        for (let i = 0; i < this.selection.length; i++)
        {
            let item = this.selection[i];
            if (item.UID)
            {
                tmpFiles.push(item);
            }
            else
            {
                item['ProjectId'] = this.group.id;
                tmpFolders.push(item);
            }
        }
        this.fileService.setClipboard(tmpFiles);
        this.folderService.setClipboard(tmpFolders);
        if (this.isMobile)
        {
            this.clipboardSize = this.selection.length;
            this.isClipboardCut = false;
            this.crudStarted = false;
            this.settingsService.setCrud(true);
        }
    }

    cutToClipboard()
    {
        let tmpFiles: ModelFile[] = [];
        let tmpFolders: GroupFolder[] = [];
        for (let i = 0; i < this.selection.length; i++)
        {
            let item = this.selection[i];
            if (item.UID)
            {
                tmpFiles.push(item);
            }
            else
            {
                item['ProjectId'] = this.group.id;
                tmpFolders.push(item);
            }
        }
        this.fileService.setClipboard(tmpFiles, true);
        this.folderService.setClipboard(tmpFolders, true);
        this.deleteSelection(true);
        if (this.isMobile)
        {
            this.crudStarted = false;
            this.clipboardSize = this.selection.length;
            this.isClipboardCut = true;
            this.settingsService.setCrud(true);
        }
    }

    abortConversion(file: ModelFile)
    {
        this.convApi.find({ where: { ConvFileId: file.id } }).subscribe((jobs: ConvJob[]) =>
        {
            if (jobs.length === 0)
            {
                this.fileApi.deleteById(file.id).subscribe(res =>
                {
                    this.emitter.emit('Update');
                });
            }
            else if (!jobs[0].ServiceId || jobs[0].ServiceId === 'null')
            {
                this.convApi.deleteById(jobs[0].id).subscribe(res =>
                {
                    this.fileApi.deleteById(file.id).subscribe(res =>
                    {
                        this.emitter.emit('Update');
                    });
                });
            }
            else
            {
                this.conversionToAbort = jobs[0];
                this.confirmModal.open('ABORT_CONVERSION', 'RUNNING_CONV_JOB', 'ABORT');
            }
        });
    }

    toggleChat()
    {
        this.emitter.emit('ToggleChat');
    }

    //Context menu event delegation
    OnContextSelection(contextOption: any)
    {
        let type = typeof contextOption === 'string' ? contextOption : contextOption.Type;
        switch (type)
        {
            case 'StartUpload':
                this.emitter.emit('UploadFile');
                break;
            case 'UploadAssembly':
                this.emitter.emit('UploadAssembly');
                break;
            case 'UploadAssemblyFolder':
                this.emitter.emit('UploadAssemblyFolder');
                break;
            case 'ConvertFiles':
                for (let i = 0; i < this.selection.length; i++)
                {
                    let file = this.selection[i];
                    if (file.UID)
                    {
                        //Distinguish between assemblies and single files
                        //Assembly file
                        let uid = this.create_UUID();
                        if (file.Container !== 'repo')
                        {
                            //Get parent from breadcrumb
                            let path = this.breadcrumb.getPath();
                            let parent: BrowserBreadCrumb;
                            let assembly: BrowserBreadCrumb;
                            let parentIsGroup: boolean;
                            for (let p = path.length - 1; p--; p >= 0)
                            {
                                if (!path[p].isAssembly)
                                {
                                    parent = path[p];
                                    parentIsGroup = path[p].isGroup;
                                    assembly = path[p + 1];
                                    break;
                                }
                            }
                            let id = parent.id;
                            let api = parentIsGroup ? this.groupApi : this.folderApi;
                            let assemblyPath = file.Container.replace(/\//g, '\\');
                            let isNative: boolean = !file.UID.endsWith('.3dvs');
                            let convFile: ModelFileInterface = {
                                Name: file.Name + '_' + new Date().toLocaleDateString('en-GB', { 'hour': 'numeric', 'minute': 'numeric' }) + '.3dvs',
                                Container: isNative ? 'repo' : file.Container,
                                UID: isNative ? uid + '_' + file.Name + '.3dvs' : file.UID,
                                Path: 'Assembly',
                                Size: file.Size,
                                IsCopy: true,
                                ownerId: this.activeViewer.id,
                                LastModified: Date.now(),
                                IsNative: false,
                                IsInConv: isNative
                            };
                            api.createChildrenFiles(id, convFile).subscribe((newFile: ModelFile) =>
                            {
                                if (isNative)
                                {
                                    let convJob: ConvJobInterface = {
                                        FileIn: this.settings.WVDriveName + '\\' + assemblyPath + '\\' + file.UID,
                                        FileOut: this.settings.WVDriveName + '\\repo\\' + uid + '_' + file.Name + '.3dvs',
                                        IsConvJob: true,
                                        FileId: file.id,
                                        ConvFileId: newFile.id,
                                        viewerId: this.activeViewer.id,
                                        IsAssemblyConv: true,
                                        ServiceId: 'null'
                                    };
                                    this.convApi.create(convJob).subscribe(res =>
                                    {
                                        this.emitter.emit('Update');
                                    });
                                }
                                else
                                {
                                    this.fileApi.patchAttributes(newFile.id, { imageDataId: file.imageDataId }).subscribe((f: ModelFile) =>
                                    {
                                        this.emitter.emit('Update');
                                    });
                                    this.emitter.emit('Update');
                                }
                            });
                        }
                        else
                        {
                            let convFile: ModelFileInterface = {
                                Name: file.Name + '_' + new Date().toLocaleDateString('en-GB', { 'hour': 'numeric', 'minute': 'numeric' }) + '.3dvs',
                                Container: 'repo',
                                UID: uid + '_' + file.Name + '.3dvs',
                                Path: ' ',
                                Size: file.Size,
                                IsCopy: true,
                                ownerId: this.activeViewer.id,
                                LastModified: Date.now(),
                                IsNative: false,
                                IsInConv: true
                            };
                            let id = this.activeFolder ? this.activeFolder.id : this.group.id;
                            let api = this.activeFolder ? this.folderApi : this.groupApi;
                            api.createChildrenFiles(id, convFile).subscribe((newFile: ModelFile) =>
                            {
                                let convJob: ConvJobInterface = {
                                    FileIn: this.settings.WVDriveName + '\\repo\\' + file.UID,
                                    FileOut: this.settings.WVDriveName + '\\repo\\' + uid + '_' + file.Name + '.3dvs',
                                    IsConvJob: true,
                                    FileId: file.id,
                                    ConvFileId: newFile.id,
                                    viewerId: this.activeViewer.id,
                                    IsAssemblyConv: false,
                                    ServiceId: 'null'
                                };
                                this.convApi.create(convJob).subscribe(res =>
                                {
                                    this.emitter.emit('Update');
                                });
                            });
                        }
                    }
                }
                break;
            case 'ReplaceFile':
                this.emitter.emit('ReplaceFile');
                break;
            case 'CreateFolder':
                this.emitter.emit('CreateFolder');
                break;
            case 'ShareFile':
                this.shareModal.open();
                break;
            case 'RemoteShare':
                this.remoteShareModal.open();
                break;
            case 'e':
                console.log(contextOption.Group);
                break;
            case 'CopyFile':
                this.copyToClipboard();
                break;
            case 'PasteFile':
                if (this.fileService.getClipboard(true).length + this.folderService.getClipboard(true).length > 0)
                {
                    this.emitter.emit({ Paste: true });
                }
                break;
            case 'CutFile':
                this.cutToClipboard();
                break;
            case 'DeleteFile':
                this.deleteSelection();
                break;
            case 'Import':
                this.importSelectedFilesToScene(contextOption.Token);
                break;
            case 'OpenInClient':
                this.openSelectedFilesInClient(contextOption.Token);
                break;
            case 'OpenInNewScene':
                this.openSelectedFiles();
                break;
            case 'ImportInNewScene':
                this.openSelectedFilesInNewScene();
                break;
            case 'Rename':
                if (this.selection.length === 1)
                {
                    if (this.selection[0].UID)
                    {
                        this.simpleDialog.open('RENAME_FILE', 'FILENAME', 'RenameFile', this.selection[0].Name);
                    }
                    else
                    {
                        this.simpleDialog.open('RENAME_FOLDER', 'FOLDER_NAME', 'RenameFolder', this.selection[0].Name);
                    }
                }
                break;
            case 'ShowProperties':
                if (this.selection.length === 1)
                {
                    this.openPropertiesDialog(this.selection[0], false);
                }
                break;
            case 'CompareFiles':
                if (this.selection.length !== 2 && this.selection[0].UID && this.selection[1].UID)
                {
                    break;
                }
                else
                {
                    this.viewerService.compareFiles(this.selection).subscribe(token =>
                    {
                        let baseUrl: string = this.settings.WebViewerClientUrl;
                        try
                        {
                            if (WebViewerClientUrlOverride && WebViewerClientUrlOverride !== '' && WebViewerClientUrlOverride !== null)
                            {
                                baseUrl = WebViewerClientUrlOverride;
                            }
                        }
                        catch (e) { }
                        let url = baseUrl + '/index.html?token=' + token.token + '#' + encodeURI(unescape(window.btoa(this.activeViewer.email)));
                        window.open(url);
                    });
                }
                break;
            case 'DownloadWithAttachments':
                let includeAttachments = true;
            case 'Download':
                let filesToDownload: ModelFile[] = [];
                let foldersToDownLoad: GroupFolder[] = [];
                this.selection.forEach(item =>
                {
                    if (item.UID)
                    {
                        filesToDownload.push(item);
                    }
                    else
                    {
                        foldersToDownLoad.push(item);
                    }
                });
                if (filesToDownload.length > 0)
                {
                    this.emitter.emit({ Type: 'DownloadFiles', Files: filesToDownload, IncludeAttachments: includeAttachments });
                }
                if (foldersToDownLoad.length > 0)
                {
                    this.emitter.emit({ Type: 'DownloadFolders', Folders: foldersToDownLoad, IncludeAttachments: includeAttachments });
                }
                break;
            //Share context menu calls -> handle in parent
            case 'RemoveShare':
                this.emitter.emit({ Type: 'RemoveShare', SearchText: this.searchRegEx, SortCat: this.sortCat, SortAsc: this.sortAsc });
                break;
            case 'RemoveAllSharesForUser':
                this.emitter.emit({ Type: 'RemoveAllSharesForUser', SearchText: this.searchRegEx, SortCat: this.sortCat, SortAsc: this.sortAsc });
                break;
            case 'StartCRUD':
                if (!this.crudActive)
                {
                    this.crudStarted = true;
                }
                this.crudActive = !this.crudActive;
                break;
        }
    }

    OnBreadcrumbClick(event)
    {
        if (event === 'home')
        {
            this.emitter.emit('home');
            return;
        }
        if (event.isGroup)
        {
            this.isGroup = true;
        }
        if (this.splitMode)
        {
            let params = event.isGroup ? { id: this.group.id } : { id: this.group.id, folder: event.id };
            this.emitter.emit({ JumpToFolder: params });
        }
        else
        {
            if (!this.isGroup)
            {
                this.router.navigate(['../', event.root.id, { folder: event.id }], { relativeTo: this.activatedRoute });
            }
            else
            {
                this.router.navigate(['../', event.root.id], { relativeTo: this.activatedRoute });
            }
        }
    }

    OnDialogInput(event)
    {
        if (event.id === 'RenameFolder')
        {
            let name = {
                Name: event.value
            };
            this.folderApi.patchAttributes(this.selection[0].id, name).subscribe(() =>
            {
                this.emitter.emit('Update');
            });
        }
        else if (event.id === 'RenameFile')
        {
            let name = {
                Name: event.value
            };
            this.fileApi.patchAttributes(this.selection[0].id, name).subscribe(() =>
            {
                this.emitter.emit('Update');
            });
        }
        else if (event.id === 'EditNote')
        {
            let comment = {
                Comment: event.value
            };
            if (this.selection[0].UID)
            {
                this.fileApi.patchAttributes(this.selection[0].id, comment).subscribe(() =>
                {
                    this.emitter.emit('Update');
                });
            }
            else
            {
                this.folderApi.patchAttributes(this.selection[0].id, comment).subscribe(() =>
                {
                    this.emitter.emit('Update');
                });
            }
        }
    }

    OnConfirm(event: boolean)
    {
        if (event && this.conversionToAbort)
        {
            this.convApi.deleteById(this.conversionToAbort.id).subscribe(res =>
            {
                this.fileApi.deleteById(this.conversionToAbort.ConvFileId).subscribe(res =>
                {
                    this.emitter.emit('Update');
                    this.conversionToAbort = undefined;
                });
            });
        }
    }

    create_UUID()
    {
        var dt = Date.now();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) =>
        {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

}
