import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PortalError, ProgressService } from 'src/app/services/progress.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { Chat, ChatApi, ChatEntry, ChatEntryApi, ChatEntryInterface, ChatImage, ChatImageInterface, ChatView, GroupFolder, GroupFolderApi, LogEvent, ModelFile, ModelFileApi, Viewer } from 'src/app/shared/sdk';
import { FileService } from 'src/app/services/file.service';
import { ConfirmModalDialogComponent } from 'src/app/widgets/confirm-modal-dialog/confirm-modal-dialog.component';
import { SimpleDialogComponent } from 'src/app/widgets/simple-dialog/simple-dialog.component';
import { GlobalEventService, VisKeyboardEvent } from 'src/app/services/global-event.service';
import { ChatFileView } from 'src/app/shared/sdk/models/ChatFileView';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit
{
    //Layout variables
    @Input('isMobile')
    isMobile: boolean = false;

    @Input('chat')
    chat: Chat;

    @Input('projectOwnerId')
    projectOwnerId: number;

    @Input('parentName')
    parentName: string;

    @Input('folderId')
    folderId: number;

    @Input('downloadAllowed')
    downloadAllowed: boolean;

    @Input('formatBlacklist')
    formatBlacklist: string[];

    @Input('downloadWhitelist')
    downloadWhitelist: string[];

    //Emitter
    @Output()
    private emitter = new EventEmitter<any>();

    @ViewChild('messageArea')
    private messageArea: ElementRef;

    @ViewChild(ConfirmModalDialogComponent)
    private confirmModal: ConfirmModalDialogComponent;

    @ViewChild(SimpleDialogComponent)
    private simpleModal: SimpleDialogComponent;

    activeViewer: Viewer;
    isOwner: boolean = false;

    //Search
    searchText = new FormControl();
    private searchString = "";

    private messages: ChatEntry[] = [];
    private fileEvents: LogEvent[] = [];
    private files: number[] = [];
    private assemblies: number[] = [];
    chatMessages: any[] = [];
    showLogs: boolean = true;
    viewerColorMap: object = {};
    colorArr: string[] = ['#890e62', '#e92558', '#005b65', '#19abd7', '#00884a',
    '#fd6b00', '#34920f', '#fd22ff', '#9abc13', '#f3261f',
    '#208395', '#d19f47', '#40b87b', '#e4e901', '#42c200',
    '#cd3226', '#ffa34e', '#a59479', '#2400ff', '#3bf10f'];

    messageText: string;
    messageInput = new FormControl();
    messageRows: number = 1;

    images: string[] = [];

    //Scrolling
    private lastScrollPos: number;

    //Chat automation
    private chatView: ChatView;
    private lastRead: number;
    private disableAutomaticScroll: boolean = false;
    private lockApi: boolean = false;
    isInit: boolean = false;

    //Message selection
    private selectedMessage: ChatEntry;
    hoveredMessage: ChatEntry;
    private selectedImage: ChatImage;
    hoveredImage: ChatImage;

    //Subscriptions
    private viewerSubscription: Subscription;
    private globalEventSubscription: Subscription;
    private checkInterval;

    @HostListener('window:paste', ['$event'])
    onPaste (e: ClipboardEvent)
    {
        let file = e.clipboardData.files[0];
        if (file)
        {
            e.preventDefault();
            e.stopPropagation();
            this.uploadImage(file);
        }
    }

    constructor (private chatApi: ChatApi,
        private messageApi: ChatEntryApi,
        private progressService: ProgressService,
        private viewerService: ViewerService,
        private sanitizer: DomSanitizer,
        private fileApi: ModelFileApi,
        private fileService: FileService,
        private eventService: GlobalEventService) { }

    ngOnInit ()
    {
        this.viewerSubscription = this.viewerService.currentViewer.subscribe((viewer: Viewer) =>
        {
            if (viewer.id && ((this.activeViewer && this.activeViewer.id !== viewer.id) || !this.activeViewer))
            {
                this.isOwner = this.projectOwnerId === viewer.id;
                this.getChatView(true);
            }
            this.activeViewer = viewer;
        });


        //Register onchange listener for the fields
        this.messageInput.valueChanges.subscribe(val =>
            {
                this.messageRows = Math.min(val.split(/\r\n|\r|\n/).length, 10);
                this.messageText = val;
        });
        
        this.searchText.valueChanges.subscribe(val =>
        {
            this.searchString = val;
            this.getMessages();
        });

        //Global events
        this.globalEventSubscription = this.eventService.keyboardEvent.subscribe((key: VisKeyboardEvent) =>
        {
            if (key === VisKeyboardEvent.ESC)
            {
                this.simpleModal.close();
                this.confirmModal.close();
            }
        });
    }

    ngOnDestroy ()
    {
        this.viewerService.updateChatViewReadState(this.chatView.id, Date.now()).subscribe();
        this.viewerSubscription.unsubscribe();
        this.globalEventSubscription.unsubscribe();
        clearInterval(this.checkInterval);
    }

    changeChat ()
    {
        this.viewerService.updateChatViewReadState(this.chatView.id, Date.now()).subscribe();
        let toSubChat: boolean = this.chat.ProjectId !== this.chat.parentId;
        let filterAttr = !toSubChat ? { parentId: this.folderId } : { ProjectId: this.chat.ProjectId, parentType: 'Group' };
        this.chatApi.find({ where: filterAttr }).subscribe((chats: Chat[]) =>
        {
            let newChat = chats[0];
            this.chat = newChat;
            this.getChatView(false);
        });
    }

    getChatView (startIntervall: boolean)
    {
        //Get chat view or create for a new view
        this.viewerService.getChatView(this.chat.id).subscribe((views: ChatView[]) =>
        {
            this.isInit = true;
            if (!views || views.length === 0)
            {
                let date = Date.now();
                this.viewerService.createChatView(this.chat.id, date).subscribe((view: ChatView) =>
                {
                    this.lastRead = date;
                    this.chatView = view;
                    this.getMessages();
                    if (startIntervall)
                    {
                        this.checkInterval = setInterval(() =>
                        {
                            this.checkForMessages();
                        }, 2000);
                    }
                });
            }
            else
            {
                let date = Date.now();
                this.lastRead = views[0].LastViewed;
                this.chatView = views[0];
                this.chatView.LastViewed = date;
                this.getMessages();
                if (startIntervall)
                {
                    this.viewerService.updateChatViewReadState(this.chatView.id, Date.now()).subscribe();
                    this.checkInterval = setInterval(() =>
                    {
                        this.checkForMessages();
                    }, 2000);
                }
            }
        });
    }

    getMessages ()
    {
        this.lockApi = true;
        let filter = {
            where: {
                Message: {
                    ilike: '%' + this.searchString + '%'
                }
            }
        };
        this.chatApi.getMessages(this.chat.id, filter).subscribe((messages: ChatEntry[]) =>
        {
            this.viewerService.updateChatViewReadState(this.chatView.id, Date.now()).subscribe();
            this.chatApi.getEvents(this.chat.id).subscribe((events: LogEvent[]) =>
            {
                this.messages = messages;
                this.fileEvents = events;
                this.chatMessages = this.messages;
                this.files = [];
                this.assemblies = [];
                if (this.showLogs)
                {
                    this.chatMessages = this.chatMessages.concat(this.fileEvents).sort((item1, item2) =>
                    {
                        return item1['CreationTime'] > item2['CreationTime'] ? 1 : -1;
                    });
                }
                this.fileEvents.forEach((event: LogEvent) =>
                {
                    if (event['Name'])
                    {
                        let format = event['Name'].toLowerCase().split('.').pop();
                        if (this.formatBlacklist.indexOf(format) !== -1)
                        {
                            event['ForeignFormat'] = true;
                        }
                    }
                    if (!this.viewerColorMap[event.ViewerMail])
                    {
                        this.viewerColorMap[event.ViewerMail] = "";
                    }
                    if (event.IsFile)
                    {
                        this.files.push(event.ItemId);
                    }
                    else
                    {
                        this.assemblies.push(event.ItemId);
                    }
                });
                this.lockApi = false;
                this.messages.forEach((message: ChatEntry) =>
                {
                    if (!this.viewerColorMap[message.Author])
                    {
                        this.viewerColorMap[message.Author] = "";
                    }
                    this.messageApi.getImages(message.id).subscribe((images: ChatImage[]) =>
                    {
                        message['Images'] = [];
                        if (images && images.length > 0)
                        {
                            images.forEach((image: ChatImage) =>
                            {
                                message['Images'].push(image);
                            });
                        }
                        if (message.HasView)
                        {
                            this.messageApi.getView(message.id, true).subscribe((view: ChatFileView) =>
                            {
                                if (view)
                                {
                                    message['View'] = view;
                                }
                            });
                        }
                    });
                });
                Object.keys(this.viewerColorMap).forEach((name: string, index: number) =>
                {
                    this.viewerColorMap[name] = name === this.activeViewer.email ? 'black' : this.colorArr[index % 20];
                });
            });
        });
    }

    confirmDeletion (deleteAll: boolean)
    {
        this.confirmModal.open('MESSAGE_DELETION', deleteAll ? 'DELETE_ALL_MESSAGES' : 'DELETE_SINGLE_MESSAGE', 'DELETE');
    }

    singleDelete (message: ChatEntry)
    {
        this.selectedMessage = message;
        this.confirmDeletion(false);
    }

    deleteMessage ()
    {
        this.chatApi.destroyByIdMessages(this.chat.id, this.selectedMessage.id).subscribe(res =>
        {
            this.selectedMessage = undefined;
            this.getMessages();
        });
    }

    deleteAllChatMessages ()
    {
        this.chatApi.deleteMessages(this.chat.id).subscribe(res =>
        {
            this.getMessages();
        });
    }

    editMessage (message: ChatEntry)
    {
        this.selectedMessage = message;
        this.simpleModal.open('MESSAGE', 'EDIT_MESSAGE', 'EditNote', message.Message);
    }

    confirmImageDeletion (image: ChatImage)
    {
        this.selectedImage = image;
        this.confirmModal.open('IMAGE_DELETION','DELETE_IMAGE', 'DELETE');
    }

    deleteImage ()
    {
        this.messageApi.destroyByIdImages(this.selectedImage.chatEntryId, this.selectedImage.id).subscribe(res =>
        {
            this.selectedImage = undefined;
            this.getMessages();
        });
    }

    checkForMessages ()
    {
        if (!this.lockApi)
        {
            this.lockApi = true;
            this.chatApi.findById(this.chat.id).subscribe((newChat: Chat) =>
            {
                this.lockApi = false;
                if (newChat.LastEdit > this.chat.LastEdit)
                {
                    this.chat = newChat;
                    this.getMessages();
                }
            });
        }
    }

    toggleLogs ()
    {
        this.showLogs = !this.showLogs;
        if (this.showLogs)
        {
            this.chatMessages = this.messages;
            this.chatMessages = this.chatMessages.concat(this.fileEvents).sort((item1, item2) =>
            {
                return item1['CreationTime'] > item2['CreationTime'] ? 1 : -1;
            });
        }
        else
        {
            this.chatMessages = this.messages.sort((item1, item2) =>
            {
                return item1['CreationTime'] > item2['CreationTime'] ? 1 : -1;
            });
        }
    }

    openItem (item: LogEvent)
    {
        if (item.IsFile)
        {
            let index = this.files.indexOf(item.ItemId);
            if (index !== -1)
            {
                this.fileApi.findById(item.ItemId).subscribe((file: ModelFile) =>
                {
                    if (item['ForeignFormat'])
                    {
                        let isWhiteListed: boolean = false;
                        let fileType: string = file.Name.split('.').pop();
                        if (this.formatBlacklist.map(format => { return format.toLowerCase(); }).indexOf(fileType.toLowerCase()) !== -1)
                        {
                            if (this.downloadWhitelist.map(format => { return format.toLowerCase(); }).indexOf(fileType.toLowerCase()) !== -1)
                            {
                                isWhiteListed = true;
                            }
                        }
                        if (this.downloadAllowed || isWhiteListed)
                        {
                            this.emitter.emit({ Type: 'DownloadFiles', Files: [file] });
                        }
                        else
                        {
                            let error: PortalError = {
                                code: 0,
                                message: 'WRONG_FORMAT_NO_DOWNLOAD',
                                name: 'DownloadRights'
                            };
                            this.progressService.addError(error);
                        }
                        return;
                    }
                    this.viewerService.openFile(file).subscribe(token =>
                    {
                        this.fileService.addTab(file, token.token);
                    });
                });
            }
            else
            {
                let error: PortalError = {
                    code: -1,
                    message: "FILE_DELETED",
                    name: 'FileDeleted'
                };
                this.progressService.addError(error);
            }
        }
        else
        {
            let index = this.assemblies.indexOf(item.ItemId);
            if (index !== -1)
            {
                this.jumpToSubFolder(item.ItemId);
            }
            else
            {
                let error: PortalError = {
                    code: -1,
                    message: "ASSEMBLY_DELETED",
                    name: 'AssemblyDeleted'
                };
                this.progressService.addError(error);
            }
        }
    }

    openView (view: ChatFileView)
    {
        this.fileApi.findById(view.FileId).subscribe((file: ModelFile) =>
        {
            this.viewerService.openFile(file, false, view.id).subscribe(token =>
            {
                this.fileService.addTab(file, token.token);
            });
        });
    }

    jumpToSubFolder (folderId: number)
    {
        this.emitter.emit(folderId);
    }

    onScroll (e)
    {
        if (this.messageArea.nativeElement.scrollTop < this.lastScrollPos)
        {
            this.disableAutomaticScroll = true;
        }
    }

    scrollToBottom (): void
    {
        if (!this.disableAutomaticScroll)
        {
            this.messageArea.nativeElement.scrollTop = this.messageArea.nativeElement.scrollHeight;
            this.lastScrollPos = this.messageArea.nativeElement.scrollTop;
        }
    }

    messageKeyInput (e: KeyboardEvent)
    {
        if (e.key === 'Enter' && !e.shiftKey)
        {
            e.preventDefault();
            this.submitMessage();
        }
    }

    submitMessage (e?: KeyboardEvent)
    {
        let message: ChatEntryInterface = {
            Message: this.messageText ? this.messageText: ' ',
            CreationTime: Date.now(),
            Author: this.activeViewer.email,
            IsDeleted: false,
            IsEdited: false
        };
        this.lockApi = true;
        this.chatApi.findById(this.chat.id).subscribe((chat: Chat) =>
        {
            let fetchMessages: boolean = false;
            if (chat.LastEdit > this.chat.LastEdit)
            {
                fetchMessages = true;
            }
            this.chatApi.createMessages(this.chat.id, message).subscribe((newMessage: ChatEntry) =>
            {
                if (this.images.length > 0)
                {
                    let chatImages: ChatImageInterface[] = [];
                    this.images.forEach((image: string) =>
                    {
                        let newImage: ChatImageInterface = {
                            Image: image
                        };
                        chatImages.push(newImage);
                    });
                    this.messageApi.createManyImages(newMessage.id, chatImages).subscribe((images: ChatImage[]) =>
                    {
                        if (fetchMessages)
                        {
                            this.getMessages();
                        }
                        else
                        {
                            newMessage['Images'] = images;
                            this.messages.push(newMessage);
                            this.chatMessages.push(newMessage);
                        }
                        this.finishMessageCreation();
                        this.disableAutomaticScroll = false;
                        this.scrollToBottom();
                    });
                }
                else
                {
                    if (fetchMessages)
                    {
                        this.getMessages();
                    }
                    else
                    {
                        this.messages.push(newMessage);
                        this.chatMessages.push(newMessage);
                    }
                    this.finishMessageCreation();
                    this.disableAutomaticScroll = false;
                    this.scrollToBottom();
                }
            });
        });
    }

    finishMessageCreation ()
    {
        this.chatApi.findById(this.chat.id).subscribe((newChat: Chat) =>
        {
            this.chat = newChat;
            this.lockApi = false;
        });
        this.messageInput.setValue("");
        this.messageRows = 1;
        this.images = [];
    }

    showImageOverlay (image: string)
    {
        this.progressService.startImageOverlay(image);
    }

    fileUpload (e, isDropEvent: boolean)
    {
        e.preventDefault();
        let files = isDropEvent ? e.dataTransfer.files : e.target.files;
        if (files && files.length > 0)
        {
            for (let i = 0; i < files.length; i++)
            {
                this.uploadImage(files[i]);
            }
        }
    }

    uploadImage (image)
    {
        var reader = new FileReader();
        reader.onload = () =>
        {
            this.images.push(reader.result.toString());
        };
        reader.readAsDataURL(image);
    }

    sanitizeBase64Image (imageSrc: string)
    {
        return imageSrc ? this.sanitizer.bypassSecurityTrustResourceUrl(imageSrc) : '';
    }

    OnConfirm (event: string)
    {
        if (event)
        {
            if (this.selectedImage)
            {
                this.deleteImage();
            }
            else if (this.selectedMessage)
            {
                this.deleteMessage();
            }
            else
            {
                this.deleteAllChatMessages();
            }
        }
    }

    OnDialogInput (event)
    {
        if (event.id === 'EditNote')
        {
            let newText = event.value;
            this.messageApi.patchAttributes(this.selectedMessage.id, { Message: newText }).subscribe((msg: ChatEntry) =>
            {
                this.selectedMessage = undefined;
                this.getMessages();
            });
        }
    }

}
