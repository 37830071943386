import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { SettingsApi, Settings, SettingsInterface, ViewerApi } from "../shared/sdk";


@Injectable()
export class SettingsService
{

    private settingsSource = new BehaviorSubject({});
    settings = this.settingsSource.asObservable();
    private currentSettings: Settings = undefined;
    private searchString: string;
    private sortCategory: string = 'Name';
    private sortAsc: boolean = true;
    private settingsInterval;

    //Mobile settings
    private crudActive: boolean = false;

    constructor (private settingsApi: SettingsApi,
        private viewerApi: ViewerApi,
        private router: Router)
    {
    }

    getSettings ()
    {
        this.settingsApi.getSettings().subscribe((settingsDto: Settings) =>
        {
            if (settingsDto !== this.currentSettings)
            {
                this.currentSettings = settingsDto;
                if (settingsDto.Color1 && settingsDto.Color2 && settingsDto.Color3)
                {
                    let root = document.documentElement;
                    root.style.setProperty('--app-color1', settingsDto.Color1);
                    root.style.setProperty('--app-color2', settingsDto.Color2);
                    root.style.setProperty('--app-color3', settingsDto.Color3);
                }
                this.settingsSource.next(settingsDto);
            }
        }, err =>
        {
            this.logOut();
        });
    }

    logOut ()
    {
        this.viewerApi.logout();
        if (this.router.routerState.snapshot.url.indexOf('/login') === -1)
        {
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url }});
        }
        
    }

    setSettings (settings: SettingsInterface)
    {
        this.settingsApi.patchAttributes(1, settings).subscribe((newSettings: Settings) =>
        {
            this.settingsSource.next(newSettings);
        });
    }

    startSettingsSubscription ()
    {
        if (this.settingsInterval)
        {
            clearInterval(this.settingsInterval);
        }
        this.getSettings();
        this.settingsInterval = setInterval(() =>
        {
            this.getSettings();
        }, 60000);
    }

    setSearchString (searchString: string)
    {
        this.searchString = searchString;
    }

    getSearchString ()
    {
        return this.searchString;
    }

    setSortCategory (cat: string)
    {
        this.sortCategory = cat;
    }

    getSortCategory ()
    {
        return this.sortCategory;
    }

    setSortAsc (isAsc: boolean)
    {
        this.sortAsc = isAsc;
    }

    getSortAsc ()
    {
        return this.sortAsc;
    }

    setCrud (isActive: boolean)
    {
        this.crudActive = isActive;
    }

    getCrud ()
    {
        return this.crudActive;
    }
}
