import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViewerApi } from '../shared/sdk';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalEventService } from '../services/global-event.service';

@Component({
    selector: 'app-password-change',
    templateUrl: './password-change.component.html',
    styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit
{
      //Layout variables
      private layoutSubscription: Subscription;
      isMobile: boolean = false;
    newPassword: FormGroup
    constructor (
        private formBuilder: FormBuilder,
        private viewerApi: ViewerApi,
        private router: Router,
        private eventService: GlobalEventService)
    {
        this.newPassword = this.formBuilder.group({
            password: ['', Validators.required],
            confirmPassword: ['', [Validators.required]]
        }, {
                validator: this.checkPasswords
            });
    }

    ngOnInit ()
    {
        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });
    }

    ngOnDestroy ()
    {
        this.layoutSubscription.unsubscribe();
    }

    changePassword ()
    {
        this.viewerApi.setPassword(this.newPassword.value.password).subscribe(res =>
        {
            this.router.navigateByUrl('');
        })
    }

    checkPasswords (group: FormGroup)
    {
        let pass = group.controls.password.value;
        let confirmPass = group.controls.confirmPassword.value;

        return pass === confirmPass ? null : { notSame: true }
    }

}
