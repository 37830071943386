import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-progress-circle',
    templateUrl: './progress-circle.component.html',
    styleUrls: ['./progress-circle.component.scss']
})
export class ProgressCircleComponent implements OnInit
{
    @Input('Radius')
    Radius: number;

    @Input('Value')
    Value: number;

    @Input('Stroke')
    Stroke: number;

    circumference: number;

    constructor () { }

    ngOnInit ()
    {
        this.circumference = this.Radius * 2 * Math.PI;
        this.Value = this.Value === 0 ? 1 : this.Value;
    }
}
