import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { UploadService, UploadItem } from 'src/app/services/upload.service';

@Component({
  selector: 'app-upload-bar',
  templateUrl: './upload-bar.component.html',
  styleUrls: ['./upload-bar.component.scss']
})
export class UploadBarComponent implements AfterViewInit
{

  uploads: UploadItem[] = [];
  showUploadMenu: boolean = false;
  @HostListener('window:click', ['$event'])
  onClick (e)
  {
    if (this.showUploadMenu)
    {
      if (!$(e.target).hasClass('upload-expand-element'))
      {
        this.showUploadMenu = false;
      }
    }
  }
  constructor (private uploadService: UploadService) { }

  ngAfterViewInit ()
  {
    this.uploadService.currentUploads.subscribe((uploads: UploadItem[]) =>
    {
      setTimeout(() =>
      {
        this.uploads = uploads;
      }, 0);
    });
  }

  addItem (item: UploadItem)
  {
    this.uploadService.addItem(item);
  }

  removeItem (item: UploadItem)
  {
    this.uploadService.removeItem(item.id);
  }

  cancelUpload (item: UploadItem)
  {
    item.call.unsubscribe();
    this.removeItem(item);
  }
}
