import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ViewerApi } from '../shared/sdk';
import { ViewerService } from '../services/viewer.service';
import { GlobalEventService } from '../services/global-event.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit
{
    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;

    creds: FormGroup;
    passwordToken = new FormControl();
    constructor (private router: Router,
        private formBuilder: FormBuilder,
        private viewerApi: ViewerApi,
        private viewerService: ViewerService,
        private eventService: GlobalEventService) { }

    ngOnInit ()
    {
        this.creds = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });

        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof (isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });
    }

    ngOnDestroy ()
    {
        this.layoutSubscription.unsubscribe();
    }

    getToken ()
    {
        this.viewerApi.resetPassword({ email: this.creds.value.email }).subscribe((res) =>
        {
            this.viewerService.setEmailForPasswordReset(this.creds.value.email);
            this.router.navigate(['/login'], { queryParams: { returnUrl: 'change-password' } });
        });
    }

}
