/* tslint:disable */
import {
  ChatEntry,
  LogEvent
} from '../index';

declare var Object: any;
export interface ChatInterface {
  "LastEdit"?: number;
  "ProjectId": number;
  "id"?: number;
  "parentId"?: number;
  "parentType"?: string;
  parent?: any;
  messages?: ChatEntry[];
  events?: LogEvent[];
}

export class Chat implements ChatInterface {
  "LastEdit": number;
  "ProjectId": number;
  "id": number;
  "parentId": number;
  "parentType": string;
  parent: any;
  messages: ChatEntry[];
  events: LogEvent[];
  constructor(data?: ChatInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Chat`.
   */
  public static getModelName() {
    return "Chat";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Chat for dynamic purposes.
  **/
  public static factory(data: ChatInterface): Chat{
    return new Chat(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Chat',
      plural: 'Chats',
      path: 'Chats',
      idName: 'id',
      properties: {
        "LastEdit": {
          name: 'LastEdit',
          type: 'number',
          default: 0
        },
        "ProjectId": {
          name: 'ProjectId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "parentId": {
          name: 'parentId',
          type: 'number'
        },
        "parentType": {
          name: 'parentType',
          type: 'string'
        },
      },
      relations: {
        parent: {
          name: 'parent',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'parentId',
          keyTo: 'id'
        },
        messages: {
          name: 'messages',
          type: 'ChatEntry[]',
          model: 'ChatEntry',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'chatId'
        },
        events: {
          name: 'events',
          type: 'LogEvent[]',
          model: 'LogEvent',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'chatId'
        },
      }
    }
  }
}
