import { Component, AfterViewInit, EventEmitter, Output, HostListener, Input } from '@angular/core';
import { Group } from 'src/app/shared/sdk';
import {  ModelMapping, FileService } from 'src/app/services/file.service';
import { ViewerService } from 'src/app/services/viewer.service';
import { FolderService } from 'src/app/services/folder.service';
declare var HidePrivate: boolean;
declare var HideMailLinks: boolean;

@Component({
    selector: 'app-context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements AfterViewInit
{
    //Layout variables
    @Input('isMobile')
    isMobile: boolean = false;

    @Input('menuId')
    menuId: string;
    @Input('writeAccess')
    writeAccess: boolean;
    @Input('shareContextMenu')
    shareContextMenu: boolean = false;
    @Input('isAssembly')
    isAssembly: boolean = false;
    @Input('allowDownloadOnGroup')
    allowDownloadOnGroup: boolean = false;
    @Input('showDownload')
    showDownload: boolean = false;

    private wrapper: HTMLElement;
    isOpen: boolean = false;
    clients: ModelMapping[] = [];
    selectionSize: number = 0;
    folderSelected: boolean = false;
    assemblySelected: boolean = false;
    isPublic: boolean;
    isShare: boolean;
    isAdmin: boolean;
    ownerRights: boolean;
    downloadOnlySelected: boolean = false;
    downloadWhitelisted: boolean = false;
    hasClipboardData: boolean = false;

    //UI visibility
    hidePrivate: boolean = false;
    hideLinks: boolean = false;

    //Position
    private x: number;

    //Side panel
    showTargetLocations: boolean = false;
    targetType: number;
    sidePanelRight: boolean = true;

    @Output() emitter = new EventEmitter<any>();


    @HostListener('window:click', ['$event'])
    onClick (e)
    {
        this.hideContextMenu();
    }

    constructor (private viewerService: ViewerService,
        private folderService: FolderService,
        private fileService: FileService) { }


    ngAfterViewInit ()
    {
        this.isOpen = false;
        this.wrapper = document.getElementById(this.menuId);
        try
        {
            this.hidePrivate = HidePrivate;
        }
        catch(e){}
        try
        {
            this.hideLinks = HideMailLinks;
        }
        catch(e){}
    }

    showContextMenu (isPublic: boolean, isShare: boolean, x: number, y: number, selectionSize: number, folderSelected: boolean, assemblySelected: boolean = false, ownerRights: boolean, downloadOnlySelected: boolean, downloadWhitelisted: boolean)
    {
        this.showTargetLocations = false;
        this.selectionSize = selectionSize;
        this.isPublic = isPublic;
        this.isShare = isShare;
        this.ownerRights = ownerRights;
        this.downloadOnlySelected = downloadOnlySelected;
        this.downloadWhitelisted = downloadWhitelisted;
        //Test if clipboard holds data
        this.hasClipboardData = this.folderService.getClipboard(true).length !== 0 || this.fileService.getClipboard(true).length !== 0;
        this.folderSelected = folderSelected;
        this.assemblySelected = assemblySelected;
        this.viewerService.getMap().subscribe(res =>
        {
            this.clients = res;
        });
        //Sizing if non-mobile
        if (!this.isMobile)
        {
            this.x = x;
            this.wrapper.style.left = this.wrapper.id === 'browser2_ctx' ?
                (x - document.getElementById('browser2').getBoundingClientRect().left + 'px') : x + 'px';
            this.wrapper.style.top = this.wrapper.id === 'browser2_ctx' ?
                (y - 80) + 'px' : y + 'px';
            //Recalculate at window borders after rendering
            setTimeout(() =>
            {
                let bodyHeight = document.body.clientHeight;
                let menuHeight = this.wrapper.clientHeight;
                let bodyWidth = document.body.clientWidth;
                let menuWidth = this.wrapper.clientWidth;
                if (y + menuHeight > bodyHeight)
                {
                    let diff = bodyHeight - (y + menuHeight);
                    let top = parseInt($(this.wrapper).css('top'));
                    this.wrapper.style.top = top + diff - 15 + 'px';
                }
                if (x + menuWidth > bodyWidth)
                {
                    let diff = bodyWidth - (x + menuWidth);
                    let left = parseInt($(this.wrapper).css('left'));
                    this.wrapper.style.left = left + diff - 15 + 'px';
                };
            }, 0);
        }
        else
        {
            this.wrapper.style.left = '0';
            this.wrapper.style.top = '0';
        }
        this.isOpen = true;
    }

    showSidePanel (type: number, event?: MouseEvent)
    {
        if (event)
        {
            event.preventDefault();
            event.stopPropagation();
        }
        //Mobile upload
        if (type === -1)
        {
            this.showTargetLocations = false;
            this.targetType = -1;
            return;
        }
        //Download type
        else if(type === 3)
        {
            this.showTargetLocations = true;
            this.targetType = 3;
            return
        }
        this.showTargetLocations = true;
        this.targetType = type;
        if (!this.isMobile)
        {
            setTimeout(() =>
            {
                let className = 'side-panel-' + type;
                let panelElements = this.wrapper.getElementsByClassName(className)
                if (panelElements && panelElements.length > 0)
                {
                    let panelWidth = panelElements[0].clientWidth;
                    let bodyWidth = document.body.clientWidth;
                    let menuWidth = this.wrapper.clientWidth;
                    this.sidePanelRight = (this.x + menuWidth + panelWidth > bodyWidth) ? false : true;
                }
            }, 0);
        }
    }

    hideContextMenu ()
    {
        this.isOpen = false;
    }

    uploadFile ()
    {
        this.emitter.emit('StartUpload');
    }

    uploadAssemblyFolder ()
    {
        this.emitter.emit('UploadAssemblyFolder');
    }

    replaceFile ()
    {
        this.emitter.emit('ReplaceFile');
    }

    createFolder ()
    {
        this.emitter.emit('CreateFolder');
    }

    shareFile ()
    {
        this.emitter.emit('ShareFile');
    }

    remoteShareFile ()
    {
        this.emitter.emit('RemoteShare');
    }

    copyFile ()
    {
        this.emitter.emit('CopyFile');
    }

    pasteFile ()
    {
        this.emitter.emit('PasteFile');
    }

    cutFile ()
    {
        this.emitter.emit('CutFile');
    }

    deleteFile ()
    {
        this.emitter.emit('DeleteFile');
    }

    importInClient (client: ModelMapping)
    {
        this.emitter.emit({ Type: 'Import', Token: client.token });
    }

    openInClient (client: ModelMapping)
    {
        this.emitter.emit({ Type: 'OpenInClient', Token: client.token });
    }

    openInNewScene ()
    {
        this.emitter.emit('OpenInNewScene');
    }

    importInNewScene ()
    {
        this.emitter.emit('ImportInNewScene');
    }

    rename ()
    {
        this.emitter.emit('Rename');
    }

    showProperties ()
    {
        this.emitter.emit('ShowProperties');
    }

    compareFiles ()
    {
        this.emitter.emit('CompareFiles');
    }

    convertFiles ()
    {
        this.emitter.emit('ConvertFiles');
    }

    download ()
    {
        this.emitter.emit('Download');
    }

    downloadWithAttachments ()
    {
        this.emitter.emit('DownloadWithAttachments');
    }

    //Share methods
    removeShare ()
    {
        this.emitter.emit('RemoveShare');
    }

    removeAllShares ()
    {
        this.emitter.emit('RemoveAllSharesForUser')
    }

    activateMobileCrud ()
    {
        this.emitter.emit('StartCRUD');
    }

}
