import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform (value: any, args?: any): any
  {
    if (!value)
    {
      return '-';
    }
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    let i = Math.floor(Math.log(value) / Math.log(1024));
    if (i === 0)
    {
        return value + ' ' + sizes[i];
    }
    return (value / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  }
}
