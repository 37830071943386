/* tslint:disable */

declare var Object: any;
export interface ZipJobInterface {
  "FileMap"?: any;
  "ZipPath": string;
  "FolderName"?: string;
  "ViewerId"?: string;
  "JobId": string;
  "CallbackUrl"?: string;
  "InProgress"?: boolean;
  "id"?: number;
}

export class ZipJob implements ZipJobInterface {
  "FileMap": any;
  "ZipPath": string;
  "FolderName": string;
  "ViewerId": string;
  "JobId": string;
  "CallbackUrl": string;
  "InProgress": boolean;
  "id": number;
  constructor(data?: ZipJobInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ZipJob`.
   */
  public static getModelName() {
    return "ZipJob";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ZipJob for dynamic purposes.
  **/
  public static factory(data: ZipJobInterface): ZipJob{
    return new ZipJob(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ZipJob',
      plural: 'ZipJobs',
      path: 'ZipJobs',
      idName: 'id',
      properties: {
        "FileMap": {
          name: 'FileMap',
          type: 'any'
        },
        "ZipPath": {
          name: 'ZipPath',
          type: 'string'
        },
        "FolderName": {
          name: 'FolderName',
          type: 'string'
        },
        "ViewerId": {
          name: 'ViewerId',
          type: 'string'
        },
        "JobId": {
          name: 'JobId',
          type: 'string'
        },
        "CallbackUrl": {
          name: 'CallbackUrl',
          type: 'string'
        },
        "InProgress": {
          name: 'InProgress',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
