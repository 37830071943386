import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModelFileApi, ModelFile, GroupFolder, Group, SearchSettings, SearchSettingsInterface, Settings, ImageData, Attachment, ModelFileInterface } from '../shared/sdk';
import { Subscription } from 'rxjs';
import { ViewerService } from '../services/viewer.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileService } from '../services/file.service';
import { SettingsService } from '../services/settings.service';
import { ConfirmModalDialogComponent } from '../widgets/confirm-modal-dialog/confirm-modal-dialog.component';
import { ProgressService, PortalError } from '../services/progress.service';
import { GlobalEventService } from '../services/global-event.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
declare var VSShareBackendUrl: any;

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit
{
    @ViewChild(ConfirmModalDialogComponent)
    private confirmModal: ConfirmModalDialogComponent;

    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;

    //Search variables
    searchText = new FormControl();

    //iOs detection
    isiOs: boolean = false;

    //Viewer variables
    viewerId: number;
    settings: Settings;
    private searchSettingSubscription: Subscription;
    private settingsSubscription: Subscription;

    //Results
    files: ModelFile[] = [];
    folders: GroupFolder[] = [];
    groups: Group[] = [];
    attachments: Attachment[] = [];
    private searchResult;

    //Advanced search settings
    advancedSearchSettings: SearchSettingsInterface;
    showAdvancedSearch: boolean = false;
    //Types
    showGroups: boolean = true;
    showFolders: boolean = true;
    showFiles: boolean = true;
    //File attributes
    showNative: boolean = true;
    showConverted: boolean = true;
    showAttachments: boolean = false;
    //show2D: boolean = true;
    //show3D: boolean = true;
    //String attributes
    caseSensitive: boolean = false;
    exactMatch: boolean = false;
    regEx: boolean = false;
    //Images
    loadImages: boolean = false;

    //Sorting
    sortCat: string;
    sortAsc: boolean = true;

    //Sort display statets for angular build
    showNameSort: boolean = false;
    showPathSort: boolean = false;
    showNoteSort: boolean = false;
    showSizeSort: boolean = false;
    showDateSort: boolean = false;

    //Navigation
    private needSort: boolean = false;
    private isInit: boolean = false;

    constructor (private sanitizer: DomSanitizer,
        private viewerService: ViewerService,
        private fileApi: ModelFileApi,
        private fileService: FileService,
        private settingsService: SettingsService,
        private progressService: ProgressService,
        private eventService: GlobalEventService,
        private http: HttpClient,
        private route: ActivatedRoute)
    { }

    ngAfterViewInit ()
    {
        this.isiOs = /iPad|iPhone|iPod/.test(navigator.platform)
        || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        this.settingsSubscription = this.settingsService.settings.subscribe((settings: Settings) =>
        {
            this.settings = settings;
        });
        if (this.route.snapshot.queryParams['search'])
        {
            let urlSearchString = this.route.snapshot.queryParams['search'];
            try
            {
                urlSearchString = decodeURIComponent(escape(window.atob(urlSearchString)));
            }
            catch (e) { }
            this.settingsService.setSearchString(urlSearchString);
        }
        this.searchSettingSubscription = this.viewerService.currentSearchSettings.subscribe((settings: SearchSettings) =>
        {
            setTimeout(() =>
            {
                this.viewerId = settings.viewerId;
                this.advancedSearchSettings = settings;
                this.caseSensitive = settings.CaseSensitive;
                this.exactMatch = settings.ExactMatch;
                this.regEx = settings.RegEx;
                this.showAttachments = settings.SearchAttachments;
                //this.show2D = settings.Search2D;
                //this.show3D = settings.Search3D;
                this.showConverted = settings.SearchConverted;
                this.showFiles = settings.SearchFiles;
                this.showFolders = settings.SearchFolders;
                this.showGroups = settings.SearchGroups;
                this.showNative = settings.SearchNative;
                this.loadImages = settings.LoadImages;
            }, 0);
            if (this.settingsService.getSearchString() && this.settingsService.getSearchString().trim() !== '' && !this.isInit)
            {
                if (this.viewerId)
                {
                    this.isInit = true;
                    setTimeout(() =>
                    {
                        this.searchText.setValue(this.settingsService.getSearchString());
                        if (this.settingsService.getSortCategory())
                        {
                            this.needSort = true;
                            this.sortAsc = this.settingsService.getSortAsc();
                            this.sortCat = this.settingsService.getSortCategory();
                        }
                        this.startSearch();
                    }, 0);
                }
            }
        });
    }

    ngOnInit ()
    {
        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });
    }

    ngOnDestroy ()
    {
        this.layoutSubscription.unsubscribe();
        this.searchSettingSubscription.unsubscribe();
        this.settingsSubscription.unsubscribe();
    }

    resetSearchTextString ()
    {
        this.searchText.setValue('');
    }

    startSearch ()
    {
        this.isInit = true;
        this.progressService.startLoadingScreen('SEARCHING');
        this.groups = [];
        this.files = [];
        this.folders = [];
        this.attachments = [];
        if (this.regEx)
        {
            try
            {
                let regEx = new RegExp(this.searchText.value);
            }
            catch (e)
            {
                let error: PortalError = {
                    code: -1,
                    message: e.message,
                    name: 'RegularExpressionError'
                };
                this.progressService.addError(error);
                return;
            }
        }
        this.settingsService.setSearchString(this.searchText.value);
        //Set settings
        let newSettings: SearchSettingsInterface = {
            CaseSensitive: this.caseSensitive,
            ExactMatch: this.exactMatch,
            RegEx: this.regEx,
            SearchAttachments: this.showAttachments,
            /*Search2D: this.show2D,
            Search3D: this.show3D,*/
            SearchConverted: this.showConverted,
            SearchFiles: this.showFiles,
            SearchFolders: this.showFolders,
            SearchGroups: this.showGroups,
            SearchNative: this.showNative,
            LoadImages: this.loadImages
        };
        if (this.advancedSearchSettings !== newSettings)
        {
            this.viewerService.setSearchSettings(newSettings).subscribe((settings: SearchSettings) =>
            {
                this.viewerService.changeSearchSettings(settings);
                //Start search
                this.viewerService.search(this.searchText.value, false).subscribe(res =>
                {
                    this.progressService.endLoadingScreen();
                    if (res.SearchResult.Aborted)
                    {
                        this.confirmModal.setLock(true);
                        this.confirmModal.open('LONG_SEARCH', 'SEARCH_OVER_CAP', 'CONTINUE');
                    }
                    else
                    {
                        this.finishSearch(res);
                    }
                });
            });
        }
    }

    finishSearch (res)
    {
        if (this.needSort)
        {
            res.SearchResult.Groups = res.SearchResult.Groups.sort((g1, g2) =>
            {
                return this.stringSort(g1, g2);
            });
            res.SearchResult.Folders = res.SearchResult.Folders.sort((f1, f2) =>
            {
                return this.stringSort(f1, f2);
            });
            res.SearchResult.Files = res.SearchResult.Files.sort((f1, f2) =>
            {
                return this.stringSort(f1, f2);
            });
            res.SearchResult.Attachments = res.SearchResult.Attachments.sort((a1, a2) =>
            {
                return this.stringSort(a1, a2);
            });
        }
        res.SearchResult.Groups.forEach(g =>
        {
            setTimeout(() => { this.groups.push(g); }, 0);
        });
        res.SearchResult.Folders.forEach(f =>
        {
            setTimeout(() => { this.folders.push(f); }, 0);
        });
        let blacklistedFormats: string[] = (this.settings && this.settings.IgnoreOnConvert) ? this.settings.IgnoreOnConvert.toLowerCase().replace(/\s/g, "").split(',') : [];
        res.SearchResult.Files.forEach((f, index) =>
        {
            setTimeout(() =>
            {
                if (blacklistedFormats && blacklistedFormats.length > 0)
                {
                    let format = f.UID.toLowerCase().split('.').pop();
                    if (blacklistedFormats.indexOf(format) !== -1)
                    {
                        f['ForeignFormat'] = true;
                    }
                }
                this.files.push(f);
                if (index === res.SearchResult.Files.length - 1 && this.showFiles && this.files && this.loadImages)
                {
                    this.getImages();
                }
            }, 0);;
        });
        res.SearchResult.Attachments.forEach(a =>
        {
            setTimeout(() => { this.attachments.push(a); }, 0);
        });
    }

    getImages ()
    {
        this.files.forEach((file: ModelFile) =>
        {
            this.fileApi.getImageData(file.id, false).subscribe((imageData: ImageData) =>
            {
                if (imageData)
                {
                    file['Image'] = imageData.Image;
                }
            }, err =>
            {
                console.log(err)
            });
        });
    }

    sortTable (category: string)
    {
        this.sortAsc = this.sortCat === category ? !this.sortAsc : false;
        this.sortCat = category;
        this.settingsService.setSortCategory(this.sortCat);
        this.settingsService.setSortAsc(this.sortAsc);
        this.needSort = true;
        this.files = this.files.sort((file1: ModelFile, file2: ModelFile) =>
        {
            return this.stringSort(file1, file2);
        });

        this.folders = this.folders.sort((folder1: GroupFolder, folder2: GroupFolder) =>
        {
            return this.stringSort(folder1, folder2);
        });

        if (this.sortCat === 'Name')
        {
            this.groups = this.groups.sort((group1: Group, group2: Group) =>
            {
                return this.stringSort(group1, group2);
            });
        }
    }

    stringSort (obj1, obj2)
    {
        let val1 = isNaN(obj1[this.sortCat]) ? obj1[this.sortCat].toLowerCase() : parseFloat(obj1[this.sortCat]);
        let val2 = isNaN(obj2[this.sortCat]) ? obj2[this.sortCat].toLowerCase() : parseFloat(obj2[this.sortCat]);
        if (val1 > val2)
        {
            return this.sortAsc ? 1 : -1;
        }
        else
        {
            return this.sortAsc ? -1 : 1;
        }
    }

    //File utility methods
    openFile (file: ModelFile)
    {
        let map = this.fileService.getMap();
        if (this.settings.SingleSession && map.length > 0)
        {
            let token = this.fileService.getToken();
            this.viewerService.openInTab(token, file).subscribe();
        }
        else
        {
            this.viewerService.openFile(file).subscribe(token =>
            {
                this.fileService.addTab(file, token.token);
            });
        }
    }

    openTab (e: MouseEvent, file: ModelFile)
    {
        if (e && (e.which === 2 || e.button === 1))
        {
            let token = this.fileService.getToken();
            this.viewerService.openInTab(token, file).subscribe();
        }
    }

    mobileAttachmentOpen (attachment: Attachment)
    {
        if (attachment.Downloadable)
        {
            this.downloadAttachment(attachment);
        }
        else if(attachment.Viewable)
        {
            this.openInViewer(attachment);
        }
    }

    openInViewer (attachment: Attachment)
    {
        this.fileApi.findById(attachment.modelFileId).subscribe((file: ModelFile) =>
        {
            let fileObj: ModelFileInterface = {
                id: file.id,
                Container: "repo",
                IsNative: file.IsNative,
                Name: attachment.Name,
                Path: " ",
                UID: attachment.UID,
                childFileId: file.childFileId,
                childFileType: file.childFileType
            };
            this.viewerService.openFile(fileObj).subscribe(token =>
            {
                this.fileService.addTab(fileObj, token.token);
            });
        });
    }

    downloadAttachment (attachment: Attachment)
    {
        let headers = new HttpHeaders({
            "Authorization": this.viewerService.getAuthToken().id,
        });
        this.http.get(VSShareBackendUrl + '/api/Sources/repo/download/' + attachment.UID + '?isAttachment=true&attachmentId=' + attachment.id,
            { headers, responseType: 'blob' }).subscribe((res: any) =>
            {
                let link = document.createElement('a');
                link.href = URL.createObjectURL(res);
                link.download = attachment.Name;
                document.body.appendChild(link);
                link.click();
                link.remove();
                URL.revokeObjectURL(res);
            });
    }

    sanitizeBase64Image (imageSrc: string)
    {
        return imageSrc ? this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + imageSrc) : '';
    }

    OnConfirm (event)
    {
        if (event)
        {
            if (event === 'LockStep')
            {
                this.confirmModal.startLoading();
                this.viewerService.search(this.searchText.value, true).subscribe(res =>
                {
                    let groupText = res.SearchResult.Groups.length > 0 ? res.SearchResult.Groups.length + ' projects ' : '';
                    let folderText = res.SearchResult.Folders.length > 0 ? res.SearchResult.Folders.length + ' folders ' : '';
                    let fileText = res.SearchResult.Files.length > 0 ? res.SearchResult.Files.length + ' files ' : '';
                    let text = 'The search found ' + groupText + folderText + fileText + '.\n Are you sure to display them in the UI?'
                    this.searchResult = res;
                    this.confirmModal.setLock(false);
                    this.confirmModal.setNewText('Displaying the list might take...', text, 'Display');
                    this.confirmModal.stopLoading();
                });
            }
            else if(event)
            {
                setTimeout(() => { this.finishSearch(this.searchResult) },0 );
            }
        }
    }

}
