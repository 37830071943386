import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input() state: boolean;
  @Input() label: string;
  @Output() toggled = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit ()
  {
    
  }

  toggleState ()
  {
    this.state = !this.state;
    this.toggled.emit(this.state);
  }

  setState (state: boolean, emitEvent?: boolean)
  {
    this.state = state;
    if (emitEvent)
    {
      this.toggled.emit(this.state);
    }
  }

}
