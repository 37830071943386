import { Component, OnInit, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { RemoteShare } from 'src/app/shared/sdk';

@Component({
  selector: 'app-share-context-menu',
  templateUrl: './share-context-menu.component.html',
  styleUrls: ['./share-context-menu.component.scss']
})
export class ShareContextMenuComponent implements OnInit {
    //Layout variables
    @Input('isMobile')
    isMobile: boolean = false;

    selection: RemoteShare[] = [];
    private wrapper: HTMLElement;
    isOpen: boolean;
    //Position
    private x: number;

    @Output() emitter = new EventEmitter<any>();

    @HostListener('window:click', ['$event'])
    onClick (e)
    {
        this.hideContextMenu();
    }


    constructor () { }


    ngOnInit ()
    {
        this.isOpen = false;
        this.wrapper = document.getElementById('context-menu');
    }

    showContextMenu (event: MouseEvent, selection: RemoteShare[])
    {
        this.selection = selection;
        if (!this.isMobile)
        {
            this.wrapper.style.left = event.pageX + 'px';
            this.wrapper.style.top = event.pageY + 'px';
            this.x = event.pageX;
            //Recalculate at window borders after rendering
            setTimeout(() =>
            {
                let bodyHeight = document.body.clientHeight;
                let menuHeight = this.wrapper.clientHeight;
                let bodyWidth = document.body.clientWidth;
                let menuWidth = this.wrapper.clientWidth;
                if (event.pageY + menuHeight > bodyHeight)
                {
                    let diff = bodyHeight - (event.pageY + menuHeight);
                    let top = parseInt($(this.wrapper).css('top'));
                    this.wrapper.style.top = top + diff + 'px';
                }
                if (event.pageX + menuWidth > bodyWidth)
                {
                    let diff = bodyWidth - (event.pageX + menuWidth);
                    let left = parseInt($(this.wrapper).css('left'));
                    this.wrapper.style.left = left + diff + 5 + 'px';
                };
            }, 0);
        }
        this.isOpen = true;
    }

    hideContextMenu ()
    {
        this.isOpen = false;
    }

    note ()
    {
        this.emitter.emit('EditNote');
    }

    editShares ()
    {
        this.emitter.emit('EditShares');
    }

    deleteShares ()
    {
        this.emitter.emit('DeleteShares');
    }

    sendMails ()
    {
        this.emitter.emit('SendMails');
    }

}
