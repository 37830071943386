/* tslint:disable */

declare var Object: any;
export interface SearchSettingsInterface {
  "SearchGroups"?: boolean;
  "SearchFolders"?: boolean;
  "SearchFiles"?: boolean;
  "SearchNative"?: boolean;
  "SearchConverted"?: boolean;
  "Search2D"?: boolean;
  "Search3D"?: boolean;
  "CaseSensitive"?: boolean;
  "ExactMatch"?: boolean;
  "LoadImages"?: boolean;
  "RegEx"?: boolean;
  "SearchAttachments"?: boolean;
  "id"?: number;
  "viewerId"?: number;
}

export class SearchSettings implements SearchSettingsInterface {
  "SearchGroups": boolean;
  "SearchFolders": boolean;
  "SearchFiles": boolean;
  "SearchNative": boolean;
  "SearchConverted": boolean;
  "Search2D": boolean;
  "Search3D": boolean;
  "CaseSensitive": boolean;
  "ExactMatch": boolean;
  "LoadImages": boolean;
  "RegEx": boolean;
  "SearchAttachments": boolean;
  "id": number;
  "viewerId": number;
  constructor(data?: SearchSettingsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SearchSettings`.
   */
  public static getModelName() {
    return "SearchSettings";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SearchSettings for dynamic purposes.
  **/
  public static factory(data: SearchSettingsInterface): SearchSettings{
    return new SearchSettings(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SearchSettings',
      plural: 'SearchSettings',
      path: 'SearchSettings',
      idName: 'id',
      properties: {
        "SearchGroups": {
          name: 'SearchGroups',
          type: 'boolean',
          default: true
        },
        "SearchFolders": {
          name: 'SearchFolders',
          type: 'boolean',
          default: true
        },
        "SearchFiles": {
          name: 'SearchFiles',
          type: 'boolean',
          default: true
        },
        "SearchNative": {
          name: 'SearchNative',
          type: 'boolean',
          default: true
        },
        "SearchConverted": {
          name: 'SearchConverted',
          type: 'boolean',
          default: true
        },
        "Search2D": {
          name: 'Search2D',
          type: 'boolean',
          default: true
        },
        "Search3D": {
          name: 'Search3D',
          type: 'boolean',
          default: true
        },
        "CaseSensitive": {
          name: 'CaseSensitive',
          type: 'boolean',
          default: false
        },
        "ExactMatch": {
          name: 'ExactMatch',
          type: 'boolean',
          default: false
        },
        "LoadImages": {
          name: 'LoadImages',
          type: 'boolean',
          default: false
        },
        "RegEx": {
          name: 'RegEx',
          type: 'boolean',
          default: false
        },
        "SearchAttachments": {
          name: 'SearchAttachments',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "viewerId": {
          name: 'viewerId',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
