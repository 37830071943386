import { Component, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { TenantApi, Tenant, TenantViewerMapping, TenantViewerMappingApi, Viewer, TenantInterface } from '../shared/sdk';
import { FormControl } from '@angular/forms';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { ViewerService } from '../services/viewer.service';
import { TenantModalComponent } from './tenant-modal/tenant-modal.component';
import { GlobalEventService, VisKeyboardEvent } from '../services/global-event.service';
import { ConfirmModalDialogComponent } from '../widgets/confirm-modal-dialog/confirm-modal-dialog.component';
import { SimpleDialogComponent } from '../widgets/simple-dialog/simple-dialog.component';

@Component({
    selector: 'app-tenant-management',
    templateUrl: './tenant-management.component.html',
    styleUrls: ['./tenant-management.component.scss']
})
export class TenantManagementComponent implements AfterViewInit
{

    @ViewChild(TenantModalComponent)
    private tenantModal: TenantModalComponent;

    @ViewChild(ConfirmModalDialogComponent)
    private confirmModal: ConfirmModalDialogComponent;

    @ViewChild(SimpleDialogComponent)
    private simpleDialog: SimpleDialogComponent;

    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;
    selectedTenants: Tenant[] = [];
    private allSelected: boolean = false;

    //iOs detection
    isiOs: boolean = false;

    tenants: Tenant[] = [];
    tenantToDelete: Tenant;

    //Search
    searchInput = new FormControl();
    private searchString: string = '';

    //Sorting
    sortCat: string = 'ID';
    sortAsc: boolean = false;

    //Sort display statets for angular build
    showNameSort: boolean = false;
    showMemberSort: boolean = false;
    showSizeSort: boolean = false;
    showIdSort: boolean = false;

    //Viewer
    activeViewer: Viewer;
    private viewerSubscription: Subscription;

    private globalEventSubscription: Subscription;

    constructor (private tenantApi: TenantApi,
        private tenantMappingApi: TenantViewerMappingApi,
        private viewerService: ViewerService,
        private eventService: GlobalEventService) { }

    ngAfterViewInit ()
    {
        this.isiOs = /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        this.globalEventSubscription = this.eventService.keyboardEvent.subscribe((key: VisKeyboardEvent) =>
        {
            if (key === VisKeyboardEvent.ESC)
            {
                this.tenantModal.close(false);
            }
        });

        this.viewerSubscription = this.viewerService.currentViewer.subscribe((viewer: Viewer) =>
        {
            setTimeout(() =>
            {
                if (viewer.id && ((this.activeViewer && this.activeViewer.id !== viewer.id) || !this.activeViewer))
                {
                    this.buildList();
                }
                this.activeViewer = viewer;
            }, 0);
        });
        this.searchInput.valueChanges.subscribe(val =>
        {
            this.searchString = val;
            this.buildList();
        });

        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });
    }

    ngOnDestroy ()
    {
        this.viewerSubscription.unsubscribe();
        this.globalEventSubscription.unsubscribe();
        this.layoutSubscription.unsubscribe();
    }

    sortTable (category?: string)
    {
        if (category)
        {
            this.sortAsc = this.sortCat === category ? !this.sortAsc : true;
            this.sortCat = category;
        }
        this.tenants = this.tenants.sort((tenant1: Tenant, tenant2: Tenant) =>
        {
            let val1, val2;
            if (this.sortCat === 'Name')
            {
                val1 = tenant1[this.sortCat].toLowerCase();
                val2 = tenant2[this.sortCat].toLowerCase();
            }
            else
            {
                val1 = tenant1[this.sortCat];
                val2 = tenant2[this.sortCat];
            }
            if (val1 > val2)
            {
                return this.sortAsc ? 1 : -1;
            }
            else
            {
                return this.sortAsc ? -1 : 1;
            }
        });
    }

    buildList ()
    {
        this.selectedTenants = [];
        let filter = {};
        if (this.searchString && this.searchString !== null && null !== '')
        {
            filter = {
                where: {
                    Name: {
                        ilike: '%' + this.searchString + '%'
                    }
                }
            };
        }
        this.tenantApi.find(filter).subscribe((tenants: Tenant[]) =>
        {
            this.tenants = tenants;
            this.sortTable();
            this.tenants.forEach((t: Tenant) =>
            {
                let tenantFilter = {
                    where: {
                        tenantId: t.id
                    }
                };
                this.tenantMappingApi.find(tenantFilter).subscribe((map: TenantViewerMapping[]) =>
                {
                    t['Size'] = map.length;
                    t['Member'] = map.map((item: TenantViewerMapping) => { return item.viewerId }).indexOf(this.activeViewer.id) !== -1;
                });
            });
        });
    }

    resetSearchInput ()
    {
        this.searchInput.setValue('');
    }

    manageTenant (tenant: Tenant)
    {
        this.tenantModal.open(tenant);
    }

    deleteTenant (tenant: Tenant)
    {
        this.tenantToDelete = tenant;
        this.confirmModal.open("DELETE_USER_POOL", "USER_POOL_DELETION_CONFIRM", "Delete");
    }

    deleteTenants ()
    {
        this.confirmModal.open("DELETE_USER_POOLS", "USER_POOLS_DELETION_CONFIRM", "Delete");
    }

    createTenant ()
    {
        this.tenantModal.open();
    }

    createMobileTenant ()
    {
        this.simpleDialog.open('CREATE_USER_POOL', 'NAME', 'TenantCreation');
    }

    handleWrapperClick ()
    {
        this.selectedTenants = [];
    }

    selectTenant (tenant: Tenant, e?: MouseEvent)
    {
        if (e)
        {
            e.preventDefault();
            e.stopPropagation();
        }
        let index = this.selectedTenants.indexOf(tenant);
        if (index === -1)
        {
            this.selectedTenants.push(tenant);
        }
        else
        {
            this.selectedTenants.splice(index, 1);
        }
    }

    selectAll (e?: MouseEvent)
    {
        if (e)
        {
            e.preventDefault();
            e.stopPropagation();
        }
        if (!this.allSelected)
        {
            this.selectedTenants = this.tenants;
        }
        else
        {
            this.selectedTenants = [];
        }
        this.allSelected = !this.allSelected;
    }

    OnTenantEdit (event: boolean)
    {
        if (event)
        {
            this.buildList();
        }
    }

    OnConfirm (event: boolean)
    {
        if (event && !this.isMobile)
        {
            this.tenantApi.deleteById(this.tenantToDelete.id).subscribe(res =>
            {
                this.buildList();
            });
        }
        else if (event && this.isMobile)
        {
            let calls: Observable<any>[] = [];
            this.selectedTenants.forEach((tenant: Tenant) =>
            {
                calls.push(this.tenantApi.deleteById(tenant.id));
            });
            forkJoin(calls).subscribe(res =>
            {
                this.buildList();
            });
        }
    }

    OnDialogInput (event)
    {
        if (event.id === 'TenantCreation')
        {
            let name = event.value;
            let newTenant: TenantInterface = {
                Name: name
            };
            this.tenantApi.create(newTenant).subscribe((tenant: Tenant) =>
            {
                this.selectedTenants = [tenant];
                this.manageTenant(tenant);
            });
        }
    }
}
