/* tslint:disable */

declare var Object: any;
export interface SettingsInterface {
  "ServerUrl"?: string;
  "WebViewerBackendUrl"?: string;
  "WebViewerClientUrl"?: string;
  "WebViewerClientWebSocketUrl"?: string;
  "DriveName"?: string;
  "WVDriveName"?: string;
  "ConfirmationUrl"?: string;
  "ConfirmationRedirectUrl"?: string;
  "LDAPUrl"?: string;
  "LDAPBaseDN"?: string;
  "LDAPBindDN"?: string;
  "UseLDAP"?: boolean;
  "LDAPOnly"?: boolean;
  "AdminVerification"?: boolean;
  "AllowGuests"?: boolean;
  "RemotePublic"?: boolean;
  "SendingMail"?: string;
  "SendMailsOnConfirm"?: boolean;
  "ConfirmMailRecipients"?: string;
  "KASKey"?: string;
  "Convert"?: boolean;
  "KASTimeout"?: number;
  "DeleteNative"?: boolean;
  "SingleSession"?: boolean;
  "UseTenants"?: boolean;
  "UseAdvancedRegister"?: boolean;
  "AdvancedRegisterMail"?: string;
  "Color1"?: string;
  "Color2"?: string;
  "Color3"?: string;
  "Banner"?: string;
  "Logo"?: string;
  "InviteOnly"?: boolean;
  "AdminCreationOnly"?: boolean;
  "AdminVerificationLDAP"?: boolean;
  "AllowDownloads"?: boolean;
  "OnlyAdminProjects"?: boolean;
  "IgnoreOnConvert"?: string;
  "AllowIgnoredFileTypeDownload"?: string;
  "UserCount"?: number;
  "SessionsPerUser"?: number;
  "RemoteSessionCount"?: number;
  "LinkSessionCount"?: number;
  "AllowExternalUploads"?: boolean;
  "ExternalKey"?: string;
  "DeactivateOnTenantRemoval"?: boolean;
  "id"?: number;
}

export class Settings implements SettingsInterface {
  "ServerUrl": string;
  "WebViewerBackendUrl": string;
  "WebViewerClientUrl": string;
  "WebViewerClientWebSocketUrl": string;
  "DriveName": string;
  "WVDriveName": string;
  "ConfirmationUrl": string;
  "ConfirmationRedirectUrl": string;
  "LDAPUrl": string;
  "LDAPBaseDN": string;
  "LDAPBindDN": string;
  "UseLDAP": boolean;
  "LDAPOnly": boolean;
  "AdminVerification": boolean;
  "AllowGuests": boolean;
  "RemotePublic": boolean;
  "SendingMail": string;
  "SendMailsOnConfirm": boolean;
  "ConfirmMailRecipients": string;
  "KASKey": string;
  "Convert": boolean;
  "KASTimeout": number;
  "DeleteNative": boolean;
  "SingleSession": boolean;
  "UseTenants": boolean;
  "UseAdvancedRegister": boolean;
  "AdvancedRegisterMail": string;
  "Color1": string;
  "Color2": string;
  "Color3": string;
  "Banner": string;
  "Logo": string;
  "InviteOnly": boolean;
  "AdminCreationOnly": boolean;
  "AdminVerificationLDAP": boolean;
  "AllowDownloads": boolean;
  "OnlyAdminProjects": boolean;
  "IgnoreOnConvert": string;
  "AllowIgnoredFileTypeDownload": string;
  "UserCount": number;
  "SessionsPerUser": number;
  "RemoteSessionCount": number;
  "LinkSessionCount": number;
  "AllowExternalUploads": boolean;
  "ExternalKey": string;
  "DeactivateOnTenantRemoval": boolean;
  "id": number;
  constructor(data?: SettingsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Settings`.
   */
  public static getModelName() {
    return "Settings";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Settings for dynamic purposes.
  **/
  public static factory(data: SettingsInterface): Settings{
    return new Settings(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Settings',
      plural: 'Settings',
      path: 'Settings',
      idName: 'id',
      properties: {
        "ServerUrl": {
          name: 'ServerUrl',
          type: 'string',
          default: 'http://localhost:3000'
        },
        "WebViewerBackendUrl": {
          name: 'WebViewerBackendUrl',
          type: 'string',
          default: 'ws://localhost:9000/'
        },
        "WebViewerClientUrl": {
          name: 'WebViewerClientUrl',
          type: 'string',
          default: 'http://localhost/'
        },
        "WebViewerClientWebSocketUrl": {
          name: 'WebViewerClientWebSocketUrl',
          type: 'string',
          default: 'ws://localhost/ws'
        },
        "DriveName": {
          name: 'DriveName',
          type: 'string',
          default: './server/storage'
        },
        "WVDriveName": {
          name: 'WVDriveName',
          type: 'string',
          default: './server/storage'
        },
        "ConfirmationUrl": {
          name: 'ConfirmationUrl',
          type: 'string',
          default: 'http://localhost:3000'
        },
        "ConfirmationRedirectUrl": {
          name: 'ConfirmationRedirectUrl',
          type: 'string',
          default: 'http://kn-tthunig.kisters.de/'
        },
        "LDAPUrl": {
          name: 'LDAPUrl',
          type: 'string'
        },
        "LDAPBaseDN": {
          name: 'LDAPBaseDN',
          type: 'string',
          default: 'DN'
        },
        "LDAPBindDN": {
          name: 'LDAPBindDN',
          type: 'string',
          default: 'DN'
        },
        "UseLDAP": {
          name: 'UseLDAP',
          type: 'boolean',
          default: false
        },
        "LDAPOnly": {
          name: 'LDAPOnly',
          type: 'boolean',
          default: false
        },
        "AdminVerification": {
          name: 'AdminVerification',
          type: 'boolean',
          default: false
        },
        "AllowGuests": {
          name: 'AllowGuests',
          type: 'boolean',
          default: false
        },
        "RemotePublic": {
          name: 'RemotePublic',
          type: 'boolean',
          default: false
        },
        "SendingMail": {
          name: 'SendingMail',
          type: 'string',
          default: 'vis-portal@server.com'
        },
        "SendMailsOnConfirm": {
          name: 'SendMailsOnConfirm',
          type: 'boolean',
          default: true
        },
        "ConfirmMailRecipients": {
          name: 'ConfirmMailRecipients',
          type: 'string',
          default: 'vis-admin1@server.com, vis-admin2@server.com'
        },
        "KASKey": {
          name: 'KASKey',
          type: 'string',
          default: 'KAS-Key'
        },
        "Convert": {
          name: 'Convert',
          type: 'boolean',
          default: false
        },
        "KASTimeout": {
          name: 'KASTimeout',
          type: 'number',
          default: 600000
        },
        "DeleteNative": {
          name: 'DeleteNative',
          type: 'boolean',
          default: false
        },
        "SingleSession": {
          name: 'SingleSession',
          type: 'boolean',
          default: false
        },
        "UseTenants": {
          name: 'UseTenants',
          type: 'boolean',
          default: false
        },
        "UseAdvancedRegister": {
          name: 'UseAdvancedRegister',
          type: 'boolean',
          default: false
        },
        "AdvancedRegisterMail": {
          name: 'AdvancedRegisterMail',
          type: 'string',
          default: 'vis-portal@register.com'
        },
        "Color1": {
          name: 'Color1',
          type: 'string',
          default: '#d67c1c'
        },
        "Color2": {
          name: 'Color2',
          type: 'string',
          default: '#ffc662'
        },
        "Color3": {
          name: 'Color3',
          type: 'string',
          default: '#fcd797'
        },
        "Banner": {
          name: 'Banner',
          type: 'string',
          default: 'Kisters_banner.svg'
        },
        "Logo": {
          name: 'Logo',
          type: 'string',
          default: 'Kisters_logo.svg'
        },
        "InviteOnly": {
          name: 'InviteOnly',
          type: 'boolean',
          default: false
        },
        "AdminCreationOnly": {
          name: 'AdminCreationOnly',
          type: 'boolean',
          default: false
        },
        "AdminVerificationLDAP": {
          name: 'AdminVerificationLDAP',
          type: 'boolean',
          default: false
        },
        "AllowDownloads": {
          name: 'AllowDownloads',
          type: 'boolean',
          default: false
        },
        "OnlyAdminProjects": {
          name: 'OnlyAdminProjects',
          type: 'boolean',
          default: false
        },
        "IgnoreOnConvert": {
          name: 'IgnoreOnConvert',
          type: 'string',
          default: ''
        },
        "AllowIgnoredFileTypeDownload": {
          name: 'AllowIgnoredFileTypeDownload',
          type: 'string',
          default: ''
        },
        "UserCount": {
          name: 'UserCount',
          type: 'number',
          default: 0
        },
        "SessionsPerUser": {
          name: 'SessionsPerUser',
          type: 'number',
          default: 0
        },
        "RemoteSessionCount": {
          name: 'RemoteSessionCount',
          type: 'number',
          default: 0
        },
        "LinkSessionCount": {
          name: 'LinkSessionCount',
          type: 'number',
          default: 0
        },
        "AllowExternalUploads": {
          name: 'AllowExternalUploads',
          type: 'boolean',
          default: false
        },
        "ExternalKey": {
          name: 'ExternalKey',
          type: 'string',
          default: 'external-vis-key'
        },
        "DeactivateOnTenantRemoval": {
          name: 'DeactivateOnTenantRemoval',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
