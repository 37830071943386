/* tslint:disable */
import {
  Group,
  ModelFile,
  ViewerVerification,
  ConvJob,
  SearchSettings,
  ChatView
} from '../index';

declare var Object: any;
export interface ViewerInterface {
  "ViewMode"?: number;
  "ShowNative"?: boolean;
  "AdvancedNativeLoading"?: boolean;
  "IsDeleted"?: boolean;
  "CreationTime"?: number;
  "LastLogin"?: number;
  "HideNative"?: boolean;
  "HideNonNative"?: boolean;
  "HideFolders"?: boolean;
  "HideAssemblies"?: boolean;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: number;
  "viewerVerificationId"?: number;
  "password"?: string;
  accessTokens?: any[];
  ownedGroups?: Group[];
  groups?: Group[];
  sharedFiles?: ModelFile[];
  viewerVerifications?: ViewerVerification;
  convJobs?: ConvJob[];
  searchSettings?: SearchSettings;
  chatViews?: ChatView[];
}

export class Viewer implements ViewerInterface {
  "ViewMode": number;
  "ShowNative": boolean;
  "AdvancedNativeLoading": boolean;
  "IsDeleted": boolean;
  "CreationTime": number;
  "LastLogin": number;
  "HideNative": boolean;
  "HideNonNative": boolean;
  "HideFolders": boolean;
  "HideAssemblies": boolean;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": number;
  "viewerVerificationId": number;
  "password": string;
  accessTokens: any[];
  ownedGroups: Group[];
  groups: Group[];
  sharedFiles: ModelFile[];
  viewerVerifications: ViewerVerification;
  convJobs: ConvJob[];
  searchSettings: SearchSettings;
  chatViews: ChatView[];
  constructor(data?: ViewerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Viewer`.
   */
  public static getModelName() {
    return "Viewer";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Viewer for dynamic purposes.
  **/
  public static factory(data: ViewerInterface): Viewer{
    return new Viewer(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Viewer',
      plural: 'viewers',
      path: 'viewers',
      idName: 'id',
      properties: {
        "ViewMode": {
          name: 'ViewMode',
          type: 'number',
          default: 0
        },
        "ShowNative": {
          name: 'ShowNative',
          type: 'boolean',
          default: true
        },
        "AdvancedNativeLoading": {
          name: 'AdvancedNativeLoading',
          type: 'boolean',
          default: true
        },
        "IsDeleted": {
          name: 'IsDeleted',
          type: 'boolean',
          default: false
        },
        "CreationTime": {
          name: 'CreationTime',
          type: 'number'
        },
        "LastLogin": {
          name: 'LastLogin',
          type: 'number'
        },
        "HideNative": {
          name: 'HideNative',
          type: 'boolean',
          default: false
        },
        "HideNonNative": {
          name: 'HideNonNative',
          type: 'boolean',
          default: false
        },
        "HideFolders": {
          name: 'HideFolders',
          type: 'boolean',
          default: false
        },
        "HideAssemblies": {
          name: 'HideAssemblies',
          type: 'boolean',
          default: false
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "viewerVerificationId": {
          name: 'viewerVerificationId',
          type: 'number'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        ownedGroups: {
          name: 'ownedGroups',
          type: 'Group[]',
          model: 'Group',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'viewerId'
        },
        groups: {
          name: 'groups',
          type: 'Group[]',
          model: 'Group',
          relationType: 'hasMany',
          modelThrough: 'GroupViewerMapping',
          keyThrough: 'groupId',
          keyFrom: 'id',
          keyTo: 'groupId'
        },
        sharedFiles: {
          name: 'sharedFiles',
          type: 'ModelFile[]',
          model: 'ModelFile',
          relationType: 'hasMany',
          modelThrough: 'SharedFile',
          keyThrough: 'modelFileId',
          keyFrom: 'id',
          keyTo: 'viewerId'
        },
        viewerVerifications: {
          name: 'viewerVerifications',
          type: 'ViewerVerification',
          model: 'ViewerVerification',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'viewerId'
        },
        convJobs: {
          name: 'convJobs',
          type: 'ConvJob[]',
          model: 'ConvJob',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'viewerId'
        },
        searchSettings: {
          name: 'searchSettings',
          type: 'SearchSettings',
          model: 'SearchSettings',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'viewerId'
        },
        chatViews: {
          name: 'chatViews',
          type: 'ChatView[]',
          model: 'ChatView',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'viewerId'
        },
      }
    }
  }
}
