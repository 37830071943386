import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewerApi, Viewer } from '../shared/sdk';
import { Router, ActivatedRoute } from '@angular/router';
import { ProgressService, PortalError } from '../services/progress.service';
import { SwitchComponent } from '../widgets/switch/switch.component';
import { ViewerService } from '../services/viewer.service';

@Component({
    selector: 'app-advanced-register',
    templateUrl: './advanced-register.component.html',
    styleUrls: ['./advanced-register.component.scss']
})
export class AdvancedRegisterComponent implements OnInit
{

    mail: string;
    hasAgreed: boolean = false;
    newViewer: FormGroup;
    private tempUser;

    @ViewChild(SwitchComponent)
    private privacySwitch: SwitchComponent;

    constructor (private formBuilder: FormBuilder,
        private router: Router,
        private progressService: ProgressService,
        private viewerService: ViewerService,
        private viewerApi: ViewerApi)
    {
        this.newViewer = this.formBuilder.group({
            firstname: ['', [Validators.required]],
            lastname: ['', [Validators.required]],
            company: ['', [Validators.required]],
            country: ['', [Validators.required]],
            city: ['', [Validators.required]],
            zip: ['', [Validators.required]],
            street: ['', [Validators.required]],
            phone: ['', [Validators.required]],
        });
    }

    ngOnInit ()
    {
        this.tempUser = this.viewerService.getTempUser();
        setTimeout(() => { this.mail = this.tempUser.mail; }, 0);
    }

    register ()
    {
        this.viewerApi.create({ email: this.tempUser.mail.toLowerCase(), password: this.tempUser.password }).subscribe((viewer: Viewer) =>
        {
            this.viewerService.deleteTempUser();
            let user = {
                mail: viewer.email.toLowerCase(),
                firstname: this.newViewer.value.firstname,
                lastname: this.newViewer.value.lastname,
                company: this.newViewer.value.company,
                country: this.newViewer.value.country,
                city: this.newViewer.value.city,
                zip: this.newViewer.value.zip,
                street: this.newViewer.value.street,
                phone: this.newViewer.value.phone
            };
            this.viewerApi.sendRegisterData(user).subscribe();
            this.router.navigateByUrl('/login').then(() =>
            {
                let error: PortalError = {
                    code: 0,
                    message: 'VERIFY_MAIL_SEND',
                    name: 'VerificationWarning'
                }
                this.progressService.addError(error);
            });
        }, error =>
            {
                this.progressService.addError({ name: error.name, message: error.message, code: error.statusCode });
            });
    }

    OnPrivacyAgreement (state: boolean)
    {
        this.hasAgreed = state;
    }

}
