/* tslint:disable */

declare var Object: any;
export interface ChatFileViewInterface {
  "Version": number;
  "ViewData": string;
  "FileId"?: number;
  "id"?: number;
  "chatEntryId"?: number;
}

export class ChatFileView implements ChatFileViewInterface {
  "Version": number;
  "ViewData": string;
  "FileId": number;
  "id": number;
  "chatEntryId": number;
  constructor(data?: ChatFileViewInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ChatFileView`.
   */
  public static getModelName() {
    return "ChatFileView";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ChatFileView for dynamic purposes.
  **/
  public static factory(data: ChatFileViewInterface): ChatFileView{
    return new ChatFileView(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ChatFileView',
      plural: 'ChatFileViews',
      path: 'ChatFileViews',
      idName: 'id',
      properties: {
        "Version": {
          name: 'Version',
          type: 'number',
          default: 0
        },
        "ViewData": {
          name: 'ViewData',
          type: 'string'
        },
        "FileId": {
          name: 'FileId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "chatEntryId": {
          name: 'chatEntryId',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
