/* tslint:disable */
import {
  Viewer,
  ModelFile
} from '../index';

declare var Object: any;
export interface SharedFileInterface {
  "SharedFrom"?: number;
  "id"?: number;
  "viewerId"?: number;
  "modelFileId"?: number;
  viewer?: Viewer;
  modelFile?: ModelFile;
}

export class SharedFile implements SharedFileInterface {
  "SharedFrom": number;
  "id": number;
  "viewerId": number;
  "modelFileId": number;
  viewer: Viewer;
  modelFile: ModelFile;
  constructor(data?: SharedFileInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SharedFile`.
   */
  public static getModelName() {
    return "SharedFile";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SharedFile for dynamic purposes.
  **/
  public static factory(data: SharedFileInterface): SharedFile{
    return new SharedFile(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SharedFile',
      plural: 'SharedFiles',
      path: 'SharedFiles',
      idName: 'id',
      properties: {
        "SharedFrom": {
          name: 'SharedFrom',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "viewerId": {
          name: 'viewerId',
          type: 'number'
        },
        "modelFileId": {
          name: 'modelFileId',
          type: 'number'
        },
      },
      relations: {
        viewer: {
          name: 'viewer',
          type: 'Viewer',
          model: 'Viewer',
          relationType: 'belongsTo',
                  keyFrom: 'viewerId',
          keyTo: 'id'
        },
        modelFile: {
          name: 'modelFile',
          type: 'ModelFile',
          model: 'ModelFile',
          relationType: 'belongsTo',
                  keyFrom: 'modelFileId',
          keyTo: 'id'
        },
      }
    }
  }
}
