import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { RegisterComponent } from './register/register.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GroupComponent } from './group/group.component';
import { ShareComponent } from './share/share.component';
import { ShareWithComponent } from './share-with/share-with.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { RemoteShareComponent } from './remote-share/remote-share.component';
import { ShareListComponent } from './remote-share/share-list/share-list.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { SearchComponent } from './search/search.component';
import { ResendMailComponent } from './resend-mail/resend-mail.component';
import { AdvancedRegisterComponent } from './advanced-register/advanced-register.component';
import { TenantManagementComponent } from './tenant-management/tenant-management.component';
import { SyncListComponent } from './sync-list/sync-list.component';
import { ColorSelectionComponent } from './color-selection/color-selection.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { TermsComponent } from './terms/terms.component';
import { LoginOrRegisterComponent } from './login-or-register/login-or-register.component';

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'advanced-register', component: AdvancedRegisterComponent },
    { path: 'terms-and-conditions', component: TermsComponent },
    { path: 'register/:mail', component: RegisterComponent },
    { path: 'reset-password', component: PasswordResetComponent },
    { path: 'change-password', component: PasswordChangeComponent, canActivate: [AuthGuard] },
    { path: '', redirectTo: 'all', pathMatch: 'full', canActivate: [AuthGuard] },
    { path: ':category/folder/:rootFolderId', component: GalleryComponent, canActivate: [AuthGuard] },
    { path: 'all', component: GalleryComponent, canActivate: [AuthGuard] },
    { path: 'projects', component: GalleryComponent, canActivate: [AuthGuard] },
    { path: 'private', component: GalleryComponent, canActivate: [AuthGuard] },
    { path: ':category/project/:id', component: GroupComponent, canActivate: [AuthGuard] },
    { path: ':category/folder/:rootFolderId/project/:id', component: GroupComponent, canActivate: [AuthGuard] },
    { path: 'share/sharedWith', component: ShareWithComponent, canActivate: [AuthGuard] },
    { path: 'share/sharedFrom', component: ShareComponent, canActivate: [AuthGuard] },
    { path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuard] },
    { path: 'userpool-management', component: TenantManagementComponent, canActivate: [AuthGuard] },
    { path: 'file-link/:token', component: RemoteShareComponent },
    { path: 'file-links', component: ShareListComponent, canActivate: [AuthGuard] },
    { path: 'remote-public/:id', component: GroupComponent },
    { path: 'search/:key', component: SearchComponent, canActivate: [AuthGuard] },
    { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
    { path: 'sync-list', component: SyncListComponent, canActivate: [AuthGuard] },
    { path: 'color-theme', component: ColorSelectionComponent, canActivate: [AuthGuard] },
    { path: 'resend-mail', component: ResendMailComponent },
    { path: 'info', component: InfoPageComponent },
    { path: 'login-or-register', component: LoginOrRegisterComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
