import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, forkJoin } from "rxjs";
import { Viewer, ViewerApi, GroupViewerMappingApi, SharedFileApi, Settings, ViewerVerificationApi, ViewerVerification, SearchSettings, SearchSettingsInterface, TenantViewerMapping, TenantViewerMappingApi, TenantApi, GroupApi, GroupFolder, ChatView, ChatViewInterface, ModelFile } from "../shared/sdk";
import { FileService, ModelMapping } from "./file.service";
import { RoleMappingApi } from "../shared/sdk/services/custom/RoleMapping";
import { RoleMapping } from "../shared/sdk/models/RoleMapping";
import { SettingsService } from "./settings.service";

@Injectable()
export class ViewerService
{

    private viewerSource = new BehaviorSubject({});
    currentViewer = this.viewerSource.asObservable();

    private searchSettingsSource = new BehaviorSubject({});
    currentSearchSettings = this.searchSettingsSource.asObservable();

    //Global variables
    private authenticated: boolean = false;
    private loadedWithSettings: boolean = false;
    //Local variables
    private email: string = '';
    private settings: Settings;
    tempUser: object = undefined;

    constructor (private viewerApi: ViewerApi,
        private groupViewerApi: GroupViewerMappingApi,
        private groupApi: GroupApi,
        private fileService: FileService,
        private roleMappingApi: RoleMappingApi,
        private sharedFileApi: SharedFileApi,
        private settingsService: SettingsService,
        private verifyApi: ViewerVerificationApi,
        private tenantMappingApi: TenantViewerMappingApi,
        private tenantApi: TenantApi
    )
    {
        this.settingsService.settings.subscribe((settings: Settings) =>
        {
            let newSettings = this.settings !== settings;
            this.settings = settings;
            if (newSettings && this.settings.ServerUrl)
            {
                this.changeViewer(this.getViewer());
            }
        });
        this.authenticated = this.viewerApi.isAuthenticated();
    }

    changeViewer (viewer: Viewer)
    {
        let initViewer = this.getViewer();
        if ((viewer.id === undefined && initViewer.id) || viewer === initViewer && this.loadedWithSettings)
        {
            return;
        }
        if (this.settings.ServerUrl)
        {
            this.loadedWithSettings = true;
        }
        let filter = {
            where: {
                principalId: viewer.id
            }
        };
        //this.viewerSource.next(viewer);
        this.authenticated = this.viewerApi.isAuthenticated();
        if (!viewer['isRemote'])
        {
            if (!viewer.id || viewer.id === undefined)
            {
                return;
            }
            this.roleMappingApi.find(filter).subscribe((roleMappings: RoleMapping[]) =>
            {
                let isAdmin: boolean = false;
                let isManager: boolean = false;
                for (let i = 0; i < roleMappings.length; i++)
                {
                    if (roleMappings[i].roleId === 1)
                    {
                        isAdmin = true;
                        isManager = true;
                    }
                    else if (roleMappings[i].roleId === 3)
                    {
                        isManager = true;
                    }
                }
                //Is Admin
                if (isAdmin && !viewer['isAdmin'])
                {
                    viewer['isAdmin'] = true;
                    viewer['Verified'] = true;
                    this.viewerSource.next(viewer);
                    this.fileService.changeViewer(viewer, false);
                    if (viewer.id !== initViewer.id && viewer.id)
                    {
                        this.getSearchSettingsForUser(viewer.id);
                    }
                }
                else if (isManager && !viewer['isManager'])
                {
                    viewer['isManager'] = true;
                    viewer['Verified'] = true;
                    this.viewerSource.next(viewer);
                    this.fileService.changeViewer(viewer, false);
                    if (viewer.id !== initViewer.id && viewer.id)
                    {
                        this.getSearchSettingsForUser(viewer.id);
                    }
                }
                //No Admin and verification enabled
                else if (this.settings.AdminVerification && viewer && viewer.id && viewer.id !== 0)
                {
                    this.verifyApi.find({ where: { viewerId: viewer.id } }).subscribe((verification: ViewerVerification[]) =>
                    {
                        if (verification.length > 0)
                        {
                            viewer['Verified'] = verification[0].Verified;
                            this.viewerSource.next(viewer);
                            this.fileService.changeViewer(viewer, false);
                            if (viewer.id !== initViewer.id && viewer.id)
                            {
                                this.getSearchSettingsForUser(viewer.id);
                            }
                        }
                        else
                        {
                            this.viewerSource.next(viewer);
                            this.fileService.changeViewer(viewer, false);
                            if (viewer.id !== initViewer.id && viewer.id)
                            {
                                this.getSearchSettingsForUser(viewer.id);
                            }
                        }
                    });
                }
                //No Admin and verification disabled
                else
                {
                    viewer['Verified'] = true;
                    this.viewerSource.next(viewer);
                    this.fileService.changeViewer(viewer, false);
                    if (viewer.id !== initViewer.id && viewer.id)
                    {
                        this.getSearchSettingsForUser(viewer.id);
                    }
                }
            });
        }
        else
        {
            this.viewerSource.next(viewer);
            this.fileService.changeViewer(viewer, true);
        }
    }

    changeSearchSettings (searchSettings: SearchSettings)
    {
        let initSettings = this.getSearchSettings();
        if (!searchSettings.id && initSettings.id)
        {
            return;
        }
        this.searchSettingsSource.next(searchSettings);
    }

    setSearchSettings (searchSettings: SearchSettingsInterface)
    {
        let viewer = this.getViewer();
        return this.viewerApi.updateSearchSettings(viewer.id, searchSettings);
    }

    getSearchSettingsForUser (viewerId: number)
    {
        this.viewerApi.getSearchSettings(viewerId, false).subscribe((settings: SearchSettings) =>
        {
            this.changeSearchSettings(settings);
        },
            err =>
            {
                let viewer = this.getViewer();
                this.viewerApi.createSearchSettings(viewer.id).subscribe();
            });
    }

    getSearchSettings ()
    {
        let settings = this.searchSettingsSource.getValue() as SearchSettings;
        return settings;
    }

    isAuthenticated ()
    {
        return this.authenticated;
    }

    getAuthToken ()
    {
        return this.viewerApi.getCurrentToken();
    }

    setViewMode (viewModeEnum: number)
    {
        let prop = {
            ViewMode: viewModeEnum
        };
        let viewer = this.getViewer();
        return this.viewerApi.patchAttributes(viewer.id, prop);
    }


    setShowNative (showNativeState: boolean)
    {
        let prop = {
            ShowNative: showNativeState
        };
        let viewer = this.getViewer();
        return this.viewerApi.patchAttributes(viewer.id, prop);
    }

    setAdvancedNativeLoading (advancedNativeLoadingState: boolean)
    {
        let prop = {
            AdvancedNativeLoading: advancedNativeLoadingState
        };
        let viewer = this.getViewer();
        return this.viewerApi.patchAttributes(viewer.id, prop);
    }

    getSharedFiles (filter?)
    {
        let viewer = this.getViewer();
        return this.viewerApi.getSharedFiles(viewer.id, filter);
    }

    countSharedFiles (filter?)
    {
        let viewer = this.getViewer();
        return this.viewerApi.countSharedFiles(viewer.id, filter);
    }

    compareFiles (files)
    {
        let viewer = this.getViewer();
        return this.viewerApi.compareFiles(viewer.id, viewer, files);
    }

    openFile (file, isPreview: boolean = false, viewDataId: number = -1)
    {
        let viewer = this.getViewer();
        if (viewer.id === -1)
        {
            return this.viewerApi.remoteOpen(file);
        }
        else
        {
            return this.viewerApi.openFile(viewer.id, viewer, file, isPreview, viewDataId);
        }
    }

    openInTab (token: any, file)
    {
        let viewer = this.getViewer();
        this.fileService.updateMap(token, file.Name);
        this.fileService.getMap().forEach((item: ModelMapping) =>
        {
            if (item.token === token && item.tab)
            {
                try
                {
                    item.tab.focus();
                }
                catch (e) { }
            }
        });
        return this.viewerApi.openFileInClient(viewer.id, token, file);
    }

    importInTab (token: any, files: any[])
    {
        let viewer = this.getViewer();
        this.fileService.getMap().forEach((item: ModelMapping) =>
        {
            if (item.token === token && item.tab)
            {
                try
                {
                    item.tab.focus();
                }
                catch (e) { }
            }
        });
        return this.viewerApi.importFileInClient(viewer.id, token, files);
    }

    countUser (filter?)
    {
        return this.viewerApi.count(filter);
    }

    getAllUser (filter?)
    {
        return this.viewerApi.find(filter);
    }

    getViewerById (id: number)
    {
        return this.viewerApi.findById(id);
    }

    getUserForGroup (tenantId: number, isPublic: boolean, filter?)
    {
        let viewer = this.getViewer();
        if (this.settings.UseTenants && (!viewer['isAdmin'] || !isPublic))
        {
            let tenantFilter = {
                where: {
                    tenantId: tenantId
                }
            };
            if (!tenantId)
            {
                return new Observable<any>(observer =>
                {
                    observer.next([]);
                });
            }
            return new Observable<any>(observer =>
            {
                let calls: Observable<any>[] = [];
                this.tenantMappingApi.find(tenantFilter).subscribe((map: TenantViewerMapping[]) =>
                {
                    for (let i = 0; i < map.length; i++)
                    {
                        calls.push(this.viewerApi.findById(map[i].viewerId, filter));
                    }
                    if (calls.length === 0)
                    {
                        observer.next([]);
                    }
                    else
                    {
                        forkJoin(calls).subscribe(viewer =>
                        {
                            observer.next(viewer);
                        });
                    }
                });
            });
        }
        else
        {
            return this.viewerApi.find(filter);
        }
    }

    getTenants ()
    {
        return this.tenantApi.find();
    }

    exists (id: number)
    {
        return this.viewerApi.exists(id);
    }

    createGroup (group)
    {
        let viewer = this.getViewer();
        return this.viewerApi.createOwnedGroups(viewer.id, group);
    }

    leaveGroup (group)
    {
        let viewer = this.getViewer();
        return this.viewerApi.unlinkGroups(viewer.id, group.id);
    }

    getViewer ()
    {
        let viewer = this.viewerSource.getValue() as Viewer;
        return viewer;
    }

    getAdministrableGroups ()
    {
        let calls: Observable<any>[] = [];
        let viewer = this.getViewer();
        let ownedFilter = {
            where: {
                viewerId: viewer.id
            }
        };
        calls.push(this.groupApi.find(ownedFilter));
        let filter = {
            where: {
                viewerId: viewer.id,
                adminRights: true
            }
        };
        calls.push(this.groupViewerApi.find(filter));
        return calls;
    }

    getAccsessibleGroups ()
    {
        let calls: Observable<any>[] = [];
        let viewer = this.getViewer();
        let ownedFilter = {
            where: {
                viewerId: viewer.id
            }
        };
        calls.push(this.groupApi.find(ownedFilter));
        let filter = {
            where: {
                viewerId: viewer.id,
                writeAccess: true
            }
        };
        calls.push(this.groupViewerApi.find(filter));
        return calls;
    }

    getMap ()
    {
        let viewer = this.getViewer();
        return new Observable<ModelMapping[]>(observer =>
        {
            this.viewerApi.getSessionFromUser(viewer.id).subscribe(res =>
            {
                let oldMap = [...this.fileService.getMap()];
                this.fileService.clearMap();
                for (let i = 0; i < res.sessions.length; i++)
                {
                    let session = res.sessions[i];
                    let index = oldMap.map((item: ModelMapping) => { return item.token }).indexOf(session.token);
                    if (index !== -1 && oldMap[index].tab)
                    {
                        session.tab = oldMap[index].tab;
                    }
                    this.fileService.addEntry(session);
                }
                observer.next(this.fileService.getMap());
            });
        });
    }

    countDir (pathObj)
    {
        let viewer = this.getViewer();
        return this.viewerApi.countDirectory(viewer.id, pathObj);
    }

    importDir (groupId: number, dirSize: number, path: string)
    {
        let viewer = this.getViewer();
        return this.viewerApi.uploadDirectory(viewer.id, { groupId: groupId, size: dirSize, dir: path });
    }

    getSharedWith (sharedWithId?: number)
    {
        let viewer = this.getViewer();
        let where = sharedWithId ? { where: { SharedFrom: viewer.id, viewerId: sharedWithId } } : { where: { SharedFrom: viewer.id } };
        return this.sharedFileApi.find(where);
    }

    getPagedSharedWith (filter?)
    {
        let viewer = this.getViewer();
        return this.sharedFileApi.find(filter);
    }

    countSharedWith (filter?)
    {
        let viewer = this.getViewer();
        return this.sharedFileApi.count(filter);
    }

    getShareFrom ()
    {
        let viewer = this.getViewer();
        return this.sharedFileApi.find({ where: { viewerId: viewer.id } });
    }

    search (searchString: string, enforceSearch: boolean)
    {
        let viewer = this.getViewer();
        return this.viewerApi.searchByName(viewer.id, { SearchString: searchString, EnforceSearch: enforceSearch });
    }

    getBanners ()
    {
        let viewer = this.getViewer();
        return this.viewerApi.getBanners(viewer.id);
    }

    getLogos ()
    {
        let viewer = this.getViewer();
        return this.viewerApi.getLogos(viewer.id);
    }

    setBanner (banner: string)
    {
        let viewer = this.getViewer();
        return this.viewerApi.setBanner(viewer.id, banner);
    }

    setLogo (logo: string)
    {
        let viewer = this.getViewer();
        return this.viewerApi.setLogo(viewer.id, logo);
    }

    setAttribute (obj)
    {
        let viewer = this.getViewer();
        return this.viewerApi.patchAttributes(viewer.id, obj);
    }

    getChatView (chatId: number)
    {
        let viewer = this.getViewer();
        return this.viewerApi.getChatViews(viewer.id, { where: { ChatId: chatId } });
    }

    createChatView (chatId: number, timestamp: number)
    {
        let viewer = this.getViewer();
        let chatView: ChatViewInterface = {
            ChatId: chatId,
            Bookmarked: false,
            LastViewed: timestamp,
        };
        return this.viewerApi.createChatViews(viewer.id, chatView);
    }

    updateChatViewReadState (chatViewId: number, timestamp: number)
    {
        let viewer = this.getViewer();
        return this.viewerApi.updateByIdChatViews(viewer.id, chatViewId, { LastViewed: timestamp });
    }

    //Local methods
    setEmailForPasswordReset (email: string)
    {
        this.email = email;
    }

    getEmailForPasswordReset ()
    {
        return this.email;
    }

    saveTempUser (mail: string, pw: string)
    {
        this.tempUser = {
            mail: mail,
            password: pw
        };
    }

    getTempUser ()
    {
        return this.tempUser;
    }

    deleteTempUser ()
    {
        this.tempUser = undefined;
    }

    downloadFileWithAttachments (file: ModelFile, projectId: number, secret: string)
    {
        return this.viewerApi.downloadFileWithAttachments({ id: file.id, projectId: projectId, secret: secret});
    }

    downloadAssembly (assembly: GroupFolder, projectId: number, secret: string, includeAttachments: boolean = false)
    {
        return this.viewerApi.downloadAssembly({ id: assembly.id, projectId: projectId, secret: secret, includeAttachments: includeAttachments });
    }

    downloadFolder (folder: GroupFolder, projectId: number, secret: string, includeAttachments: boolean = false)
    {
        return this.viewerApi.downloadFolder({ id: folder.id, projectId: projectId, secret: secret, includeAttachments: includeAttachments });
    }

    downloadProject (projectId: number, secret: string, includeAttachments: boolean = false)
    {
        return this.viewerApi.downloadProject({ projectId: projectId, secret: secret, includeAttachments: includeAttachments });
    }

    //Admin methods
    grantManagerRights(userId: number)
    {
        let viewer = this.getViewer();
        return this.viewerApi.grantManagerRights(viewer.id, { userId: userId });
    }

    revokeManagerRights(userId: number)
    {
        let viewer = this.getViewer();
        return this.viewerApi.revokeManagerRights(viewer.id, { userId: userId });
    }

    verifyViewer (userId: number)
    {
        let viewer = this.getViewer();
        return this.viewerApi.unlockViewer(viewer.id, { userId: userId });
    }

    unverifyViewer (userId: number)
    {
        let viewer = this.getViewer();
        return this.viewerApi.lockViewer(viewer.id, { userId: userId });
    }

    deleteViewer (userId: number)
    {
        let viewer = this.getViewer();
        return this.viewerApi.deleteViewer(viewer.id, { userId: userId });
    }

    restoreViewer (userId: number)
    {
        let viewer = this.getViewer();
        return this.viewerApi.restoreViewer(viewer.id, { userId: userId });
    }

    removeViewer (userId: number)
    {
        return this.viewerApi.deleteById(userId);
    }

    confirmMail (userId: number)
    {
        let viewer = this.getViewer();
        return this.viewerApi.adminConfirm(viewer.id, { userId: userId });
    }

    createAccount (mail: string)
    {
        return this.viewerApi.createViewer({ Email: mail });
    }

}
