/* tslint:disable */
import {
  Group
} from '../index';

declare var Object: any;
export interface GroupSyncInterface {
  "Path": string;
  "LastSync"?: number;
  "Running"?: boolean;
  "Backup"?: boolean;
  "Automate"?: boolean;
  "id"?: number;
  "groupId"?: number;
  group?: Group;
}

export class GroupSync implements GroupSyncInterface {
  "Path": string;
  "LastSync": number;
  "Running": boolean;
  "Backup": boolean;
  "Automate": boolean;
  "id": number;
  "groupId": number;
  group: Group;
  constructor(data?: GroupSyncInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GroupSync`.
   */
  public static getModelName() {
    return "GroupSync";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GroupSync for dynamic purposes.
  **/
  public static factory(data: GroupSyncInterface): GroupSync{
    return new GroupSync(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GroupSync',
      plural: 'GroupSyncs',
      path: 'GroupSyncs',
      idName: 'id',
      properties: {
        "Path": {
          name: 'Path',
          type: 'string',
          default: 'c://'
        },
        "LastSync": {
          name: 'LastSync',
          type: 'number'
        },
        "Running": {
          name: 'Running',
          type: 'boolean',
          default: false
        },
        "Backup": {
          name: 'Backup',
          type: 'boolean',
          default: false
        },
        "Automate": {
          name: 'Automate',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "groupId": {
          name: 'groupId',
          type: 'number'
        },
      },
      relations: {
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'groupId',
          keyTo: 'id'
        },
      }
    }
  }
}
