import { Directive, OnDestroy, Input, ElementRef, HostListener } from "@angular/core";
import { TooltipService } from "./tooltip.service";

@Directive({ selector: '[tooltip]' })
export class TooltipDirective implements OnDestroy
{

    @Input('tooltipTitle')
    tooltipTitle: string = '';
    @Input('delay')
    delay: number = 0;

    private id: number;
    updateCall: any;

    @HostListener('mouseenter', ['$event'])
    onMouseEnter (e: MouseEvent): void
    {
        let isiOs = /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        if (isiOs || !this.tooltipTitle)
        {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        this.destroy();
        this.id = Math.random();
        this.updateCall = setTimeout(() =>
        {
                this.tooltipService.components.push({
                    id: this.id,
                    ref: this.element,
                    title: this.tooltipTitle
                });
        }, this.delay);
    }

    @HostListener('mouseleave', ['$event'])
    onMouseLeave (e: MouseEvent): void
    {
        let isiOs = /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        if (isiOs)
        {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        this.destroy();
    }

    constructor (private tooltipService: TooltipService, private element: ElementRef) { }

    ngOnDestroy (): void
    {
        this.destroy();
    }

    destroy (): void
    {
        clearTimeout(this.updateCall);
        const idx = this.tooltipService.components.findIndex((t) =>
        {
            return t.id === this.id;
        });
        this.tooltipService.components.splice(idx, 1);
    }

}