/* tslint:disable */

declare var Object: any;
export interface RemoteShareInterface {
  "Email"?: string;
  "From"?: number;
  "Until"?: number;
  "Comment"?: string;
  "Token"?: string;
  "Limit"?: number;
  "FileId": number;
  "ViewCount"?: number;
  "ViewerId"?: number;
  "id"?: number;
}

export class RemoteShare implements RemoteShareInterface {
  "Email": string;
  "From": number;
  "Until": number;
  "Comment": string;
  "Token": string;
  "Limit": number;
  "FileId": number;
  "ViewCount": number;
  "ViewerId": number;
  "id": number;
  constructor(data?: RemoteShareInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RemoteShare`.
   */
  public static getModelName() {
    return "RemoteShare";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RemoteShare for dynamic purposes.
  **/
  public static factory(data: RemoteShareInterface): RemoteShare{
    return new RemoteShare(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RemoteShare',
      plural: 'RemoteShares',
      path: 'RemoteShares',
      idName: 'id',
      properties: {
        "Email": {
          name: 'Email',
          type: 'string'
        },
        "From": {
          name: 'From',
          type: 'number',
          default: 0
        },
        "Until": {
          name: 'Until',
          type: 'number',
          default: 0
        },
        "Comment": {
          name: 'Comment',
          type: 'string',
          default: ''
        },
        "Token": {
          name: 'Token',
          type: 'string'
        },
        "Limit": {
          name: 'Limit',
          type: 'number',
          default: 0
        },
        "FileId": {
          name: 'FileId',
          type: 'number'
        },
        "ViewCount": {
          name: 'ViewCount',
          type: 'number',
          default: 0
        },
        "ViewerId": {
          name: 'ViewerId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
