// errors-handler.ts
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PortalError, ProgressService } from '../services/progress.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler
{
    constructor (
        private errorService: ProgressService
    ) { }

    handleError (error: Error)
    {
        this.errorService.endLoadingScreen();
        if (error instanceof HttpErrorResponse)
        {
            if (error.error instanceof Blob)
            {
                const reader = new FileReader();
                reader.addEventListener('loadend', (e) =>
                {
                    let target = e.target as EventTarget;
                    let err = JSON.parse(target['result']).error;
                    let visError: PortalError = {
                        code: err.statusCode,
                        message: err.message,
                        name: err.name
                    };
                   this.errorService.addError(visError);
                });
                reader.readAsText(error.error);
            }
            else
            {
                let visError: PortalError = {
                    code: error.status,
                    message: error.message,
                    name: error.name
                };
                this.errorService.addError(visError);
            }
        }
        else if (error['statusCode'])
        {
            let visError: PortalError = {
                code: error['statusCode'],
                message: error.message,
                name: 'Server Error'
            };
            this.errorService.addError(visError);
        }
        else
        {
            console.error(error)
        }
    }
}