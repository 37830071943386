import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface PortalError
{
    message: string,
    name: string,
    code: number
}
export interface BlockerContext
{
    isLoading: boolean,
    message?: string
}

/*
    Toat types:
        0: mail send
        1: deletion
        2: creation

*/
export interface PortalToast
{
    message: string,
    type: number
}

@Injectable()
export class ProgressService
{


    private toastSource = new BehaviorSubject({});
    toast = this.toastSource.asObservable();

    private errorSource = new BehaviorSubject({});
    error = this.errorSource.asObservable();
    
    private blockerSource = new BehaviorSubject({});
    loading = this.blockerSource.asObservable();

    private imageSource= new BehaviorSubject({});
    image = this.imageSource.asObservable();

  constructor () { }

    addError (error: PortalError)
    {
        this.errorSource.next(error);
    }

    hideError ()
    {
        this.errorSource.next(false);
    }

    startLoadingScreen (message?: string)
    {
        let startObj: BlockerContext = {
            isLoading: true,
            message: message
        };
        this.blockerSource.next(startObj);
    }

    endLoadingScreen ()
    {
        let endObj: BlockerContext = {
            isLoading: false,
            message: undefined
        };
        this.blockerSource.next(endObj);
    }

    startImageOverlay (image: string)
    {
        this.imageSource.next({Image: image});
    }

    endImageOverlay ()
    {
        this.imageSource.next({Image: false});
    }

    showToast (toast: PortalToast)
    {
        this.toastSource.next(toast);
        setTimeout(() =>
        {
            this.toastSource.next(false);
        }, 1500);
    }


}
