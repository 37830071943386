import { Component, OnInit } from '@angular/core';
import { Settings } from 'src/app/shared/sdk';
import { SettingsService } from 'src/app/services/settings.service';
import { Subscription } from 'rxjs';

declare var VSWebClient: any;
declare var $;
declare var WebViewerClientUrlOverride;
@Component({
    selector: 'app-embedded-viewer',
    templateUrl: './embedded-viewer.component.html',
    styleUrls: ['./embedded-viewer.component.scss']
})
export class EmbeddedViewerComponent implements OnInit
{

    private canvasWrapper: HTMLElement;
    private client = new VSWebClient();
    loading: boolean;
    private token: string;
    //Settings
    private settings: Settings;
    private settingsSubscription: Subscription;
    constructor (private settingsService: SettingsService) { }

    ngOnInit ()
    {
        this.canvasWrapper = document.getElementById('wv-canvas');
        this.loading = false;
        window.onresize = e =>
        {
            this.client.resize(this.canvasWrapper.clientWidth, this.canvasWrapper.clientHeight);
            setTimeout(e =>
            {
                this.client.resize(this.canvasWrapper.clientWidth, this.canvasWrapper.clientHeight);
            }, 100);
        };
        this.settingsSubscription = this.settingsService.settings.subscribe((settings: Settings) =>
        {
            this.settings = settings;
        })
    }

    ngOnDestroy ()
    {
        this.settingsSubscription.unsubscribe();
    }

    openConnection (token)
    {
        let url = this.settings.WebViewerClientWebSocketUrl;
        try
        {
            if (WebViewerClientUrlOverride && WebViewerClientUrlOverride !== '' && WebViewerClientUrlOverride !== null)
            {
                url = WebViewerClientUrlOverride
                //Replace http/https
                if (WebViewerClientUrlOverride.indexOf('http') !== -1)
                {
                    url = url.replace('http', 'ws');
                }
            }
        }
        catch (e) { }
        this.client.initialize(this.canvasWrapper, url);

        this.loading = true;
        this.client.once('initialized', () =>
        {
            this.client.setDefaultMouse(1);
            this.token = token.token;
            this.client.SessionToken(token.token);
            this.openFileHandler();
        });
    }

    openFileHandler ()
    {
        this.loading = true;
        this.client.once('OpenFile', res =>
        {
            this.loading = false;
            this.client.resize(this.canvasWrapper.clientWidth, this.canvasWrapper.clientHeight);
        });
    }

    getToken ()
    {
        return this.token;
    }

}
