import { Component, OnInit, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { Group, RootFolder, Viewer } from 'src/app/shared/sdk';
import { ViewerService } from 'src/app/services/viewer.service';
declare var OnlyAdminProjectTypeChanges: boolean;

@Component({
    selector: 'app-gallery-context-menu',
    templateUrl: './gallery-context-menu.component.html',
    styleUrls: ['./gallery-context-menu.component.scss']
})
export class GalleryContextMenuComponent implements OnInit
{
    //Layout variables
    @Input('isMobile')
    isMobile: boolean = false;

    @Input('adminGroups')
    private adminGroups: Group[];

    @Input('ownedFolders')
    private ownedFolders: RootFolder[];

    @Input('showDownload')
    showDownload: boolean = false;

    @Input('onlyAdminCreation')
    onlyAdminCreation: boolean = false;

    @Input('rootFolderId')
    rootFolderId: number;

    private contextMenuStyle: number = 0;
    writeAccess: boolean = false;
    private wrapper: HTMLElement;
    group: Group;
    isPublic: boolean = false;
    hideLeave: boolean = false;
    isOpen: boolean;
    multipleGroups: boolean = false;
    canDissolve: boolean = false;
    viewer: Viewer;
    inFolder: boolean = false;

    //Side panel
    showProjectTypes: boolean = false;
    targetType: number;
    sidePanelRight: boolean = true;

    //Position
    private x: number;

    //UI visibility
    hideProjectTypes: boolean = false;

    @Output() emitter = new EventEmitter<any>();

    @HostListener('window:click', ['$event'])
    onClick (e)
    {
        this.hideContextMenu();
    }


    constructor (private viewerService: ViewerService) { }


    ngOnInit ()
    {
        this.isOpen = false;
        this.wrapper = document.getElementById('context-menu');
    }

    showContextMenu ( selection: any[], hideLeave: boolean, inFolder: boolean, event?: MouseEvent)
    {
        try
        {
            this.hideProjectTypes = OnlyAdminProjectTypeChanges;
        }
        catch (e) { }
        this.viewer = this.viewerService.getViewer();
        this.multipleGroups = false;
        this.isPublic = false;
        this.canDissolve = false;
        this.inFolder = inFolder;
        this.hideLeave = hideLeave;
        if (selection.length === 0)
        {
            this.contextMenuStyle = 0;
        }
        else if (selection.length === 1)
        {
            this.writeAccess = selection[0].hasWriteAccess || selection[0].isAdmin;
            //Only folder selected
            if (selection[0].IsPrivate === undefined)
            {
                if (this.ownedFolders.map(item => { return item.id }).indexOf(selection[0].id) !== -1)
                {
                    this.canDissolve = true;
                }
                this.contextMenuStyle = 0;
            }
            else
            {
                this.isPublic = selection[0].IsPublic;
                this.group = selection[0];
                if (this.group && this.adminGroups.map(item => { return item.id }).indexOf(this.group.id) !== -1)
                {
                    //Administrable group
                    this.contextMenuStyle = 1;
                }
                else if (selection[0].IsPrivate)
                {
                    //Private group (no actions)
                    this.contextMenuStyle = 0;
                }
                else
                {
                    //Non-administrable group
                    this.contextMenuStyle = 2;
                }
            }
        }
        else if (selection.length > 1)
        {
            this.multipleGroups = true;
            //Check for admin-access for whole selection
            let hasAllAccess = true;
            let canDissolveAll = true;
            for (let i = 0; i < selection.length; i++)
            {
                if (this.adminGroups.map(item => { return item.id }).indexOf(selection[i].id) === -1 && selection[i].IsPrivate !== undefined)
                {
                    hasAllAccess = false;
                }
            }
            for (let i = 0; i < selection.length; i++)
            {
                if (this.ownedFolders.map(item => { return item.id }).indexOf(selection[i].id) === -1 && selection[i].IsPrivate === undefined)
                {
                    canDissolveAll = false;
                }
            }
            this.canDissolve = canDissolveAll;
            if (hasAllAccess)
            {
                this.contextMenuStyle = 1;
            }
            else
            {
                this.contextMenuStyle = 2;
            }
        }
        if (event)
        {
            this.wrapper.style.left = event.pageX + 'px';
            this.wrapper.style.top = event.pageY + 'px';
            this.x = event.pageX;
            this.isOpen = true;
            //Recalculate at window borders after rendering
            setTimeout(() =>
            {
                let bodyHeight = document.body.clientHeight;
                let menuHeight = this.wrapper.clientHeight;
                let bodyWidth = document.body.clientWidth;
                let menuWidth = this.wrapper.clientWidth;
                if (event.pageY + menuHeight > bodyHeight)
                {
                    let diff = bodyHeight - (event.pageY + menuHeight);
                    let top = parseInt($(this.wrapper).css('top'));
                    this.wrapper.style.top = top + diff + 'px';
                }
                if (event.pageX + menuWidth > bodyWidth)
                {
                    let diff = bodyWidth - (event.pageX + menuWidth);
                    let left = parseInt($(this.wrapper).css('left'));
                    this.wrapper.style.left = left + diff + 5 + 'px';
                };
            }, 0);
        }
        else
        {
            this.isOpen = true;
        }
    }

    showSidePanel (type: number, event?: MouseEvent)
    {
        if (event)
        {
            event.preventDefault();
            event.stopPropagation();
        }
        //Mobile  project type
        if (type === -1)
        {
            this.showProjectTypes = false
            this.targetType = -1;
            return;
        }
        //Download type
        else if (type === 3)
        {
            this.showProjectTypes = true;
            this.targetType = 3;
            return
        }
        this.showProjectTypes = true;
        this.targetType = type;
        if (!this.isMobile)
        {
            setTimeout(() =>
            {
                let className = 'side-panel-' + type;
                let panelElements = this.wrapper.getElementsByClassName(className)
                if (panelElements && panelElements.length > 0)
                {
                    let panelWidth = panelElements[0].clientWidth;
                    let bodyWidth = document.body.clientWidth;
                    let menuWidth = this.wrapper.clientWidth;
                    this.sidePanelRight = (this.x + menuWidth + panelWidth > bodyWidth) ? false : true;
                }
            }, 0);
        }
    }

    hideContextMenu ()
    {
        this.isOpen = false;
    }

    manageGroup ()
    {
        this.emitter.emit('ManageGroup');
    }

    addGroup ()
    {
        this.emitter.emit('AddGroup');
    }

    duplicateProject()
    {
        this.emitter.emit('DuplicateProject');
    }

    addMobileGroup ()
    {
        this.emitter.emit('AddMobileGroup');
    }

    deleteGroup ()
    {
        this.emitter.emit('DeleteGroup');
    }

    leaveGroup ()
    {
        this.emitter.emit('LeaveGroup');
    }

    dissolveFolder ()
    {
        this.emitter.emit('DissolveFolder');
    }

    renameFolder ()
    {
        this.emitter.emit('RenameFolder');
    }

    removeFromFolder ()
    {
        this.emitter.emit('RemoveFromFolder');
    }

    createFolder ()
    {
        this.emitter.emit('CreateFolder');
    }

    inviteRemote ()
    {
        this.emitter.emit('InviteRemote');
    }

    manageAdmins ()
    {
        this.emitter.emit('ManageAdmins');
    }

    setPublic ()
    {
        this.emitter.emit('SetPublic');
    }

    setShowcase ()
    {
        this.emitter.emit('SetShowcase');
    }

    setRestricted ()
    {
        this.emitter.emit('SetRestricted');
    }

    download ()
    {
        this.emitter.emit('Download');
    }

    downloadWithAttachments ()
    {
        this.emitter.emit('DownloadWithAttachments');
    }
}
