import { Component } from "@angular/core";
import { TooltipService } from "./tooltip.service";

@Component({
  selector: 'tooltip-container',
  templateUrl: './container.component.html',
  styleUrls: ['./tooltip.scss']
})
export class TooltipContainerComponent
{
  components = [];

  constructor (private tooltipService: TooltipService)
  {
    this.components = tooltipService.components;
  }

}