/* tslint:disable */
import {
  Viewer
} from '../index';

declare var Object: any;
export interface ConvJobInterface {
  "ServiceId": string;
  "FileIn": string;
  "FileOut": string;
  "IsConvJob": boolean;
  "FileId"?: number;
  "ConvFileId"?: number;
  "IsAssemblyConv"?: boolean;
  "id"?: number;
  "viewerId"?: number;
  viewer?: Viewer;
}

export class ConvJob implements ConvJobInterface {
  "ServiceId": string;
  "FileIn": string;
  "FileOut": string;
  "IsConvJob": boolean;
  "FileId": number;
  "ConvFileId": number;
  "IsAssemblyConv": boolean;
  "id": number;
  "viewerId": number;
  viewer: Viewer;
  constructor(data?: ConvJobInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ConvJob`.
   */
  public static getModelName() {
    return "ConvJob";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ConvJob for dynamic purposes.
  **/
  public static factory(data: ConvJobInterface): ConvJob{
    return new ConvJob(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ConvJob',
      plural: 'ConvJobs',
      path: 'ConvJobs',
      idName: 'id',
      properties: {
        "ServiceId": {
          name: 'ServiceId',
          type: 'string',
          default: 'null'
        },
        "FileIn": {
          name: 'FileIn',
          type: 'string'
        },
        "FileOut": {
          name: 'FileOut',
          type: 'string'
        },
        "IsConvJob": {
          name: 'IsConvJob',
          type: 'boolean',
          default: false
        },
        "FileId": {
          name: 'FileId',
          type: 'number',
          default: -1
        },
        "ConvFileId": {
          name: 'ConvFileId',
          type: 'number',
          default: -1
        },
        "IsAssemblyConv": {
          name: 'IsAssemblyConv',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "viewerId": {
          name: 'viewerId',
          type: 'number'
        },
      },
      relations: {
        viewer: {
          name: 'viewer',
          type: 'Viewer',
          model: 'Viewer',
          relationType: 'belongsTo',
                  keyFrom: 'viewerId',
          keyTo: 'id'
        },
      }
    }
  }
}
