/* tslint:disable */

declare var Object: any;
export interface ImageDataInterface {
  "Image"?: string;
  "id"?: number;
}

export class ImageData implements ImageDataInterface {
  "Image": string;
  "id": number;
  constructor(data?: ImageDataInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ImageData`.
   */
  public static getModelName() {
    return "ImageData";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ImageData for dynamic purposes.
  **/
  public static factory(data: ImageDataInterface): ImageData{
    return new ImageData(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ImageData',
      plural: 'ImageData',
      path: 'ImageData',
      idName: 'id',
      properties: {
        "Image": {
          name: 'Image',
          type: 'string',
          default: ''
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
