import { Component, ViewChild, HostListener } from '@angular/core';
import { LoopBackConfig, ViewerApi, Viewer, Settings } from './shared/sdk/index';
import { ViewerService } from './services/viewer.service';
import { Router } from '@angular/router';
import { PortalError, ProgressService, PortalToast, BlockerContext } from './services/progress.service';
import { SettingsService } from './services/settings.service';
import { FormControl } from '@angular/forms';
import { GlobalEventService } from './services/global-event.service';
import { SimpleDialogComponent } from './widgets/simple-dialog/simple-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { trigger, transition, style, animate } from '@angular/animations';
declare var VSShareBackendUrl: any;
declare var HidePrivate: boolean;
declare var HideMailLinks: boolean;
declare var HidePasswordReset: boolean;

export interface LangObj
{
    key: string,
    name: string
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger(
          'appMenuDisplay',
          [
            transition(
              ':enter',
              [
                style({ width: '0%' }),
                animate('0.5s ease-out',
                        style({ width: '80%' }))
              ]
            ),
            transition(
              ':leave',
              [
                style({ width: '80%' }),
                animate('0.5s ease-in',
                        style({ width: '0%' }))
              ]
            )
          ]
        )
      ]
})

export class AppComponent
{
    @ViewChild(SimpleDialogComponent)
    private confirmModal: SimpleDialogComponent;


    //Layout variable
    isMobile: boolean = false;
    mobileMenuOpen: boolean = false;
    mobileNav: string;

    //Banner & logo
    banner: string = './assets/banner.svg';
    logo: string = './assets/logo.svg';
    showHeaderIconText: boolean = true;
    showHeaderMail: boolean = true;

    private title = 'portal-client';
    activeViewer: any;
    //Error handling
    private error: PortalError;
    showErrorMessage: boolean = false;
    //Loading handling
    isLoading: boolean = false;
    blockerMessage: string;
    //Toast handling
    private toast: PortalToast;
    showToastMessage: boolean = false;
    //Image overlay handling
    overlayedImage: string;
    showImage: boolean = false;

    showUserMenu: boolean = false;
    showAdminMenu: boolean = false;
    showLanguageMenu: boolean = false;

    hasPublicGroups: boolean = false;
    hasTeamGroups: boolean = false;

    remoteUser: boolean = false;

    //Languages
    languages: LangObj[] = [
        {
            key: 'en',
            name: 'English'
        },
        {
            key: 'de',
            name: 'Deutsch'
        }
    ];
    usedLang: LangObj;

    //UI visibility
    hidePrivate: boolean = false;
    hideLinks: boolean = false;
    hidePasswordReset: boolean = false;

    //Group import
    private dirSize: number = 0;
    private path: string;

    //Group category state variables
    groupCategory: string = "";

    //Search
    searchText = new FormControl();


    //Settings
    private settings: Settings;

    @HostListener('window:keyup', ['$event'])
    onKeyUp (e: KeyboardEvent)
    {
        //Emit ESC
        if (e.keyCode === 27)
        {
            this.eventService.emitEvent(0);
            this.showErrorMessage = false;
            this.progressService.endImageOverlay();
        }
    }

    @HostListener('window:mousedown', ['$event'])
    onMouseDown (e: MouseEvent)
    {
        if (this.showAdminMenu)
        {
            setTimeout(() => { this.showAdminMenu = false }, 300);
        }
        if (this.showUserMenu)
        {
            setTimeout(() => { this.showUserMenu = false }, 300);
        }
        if (this.showLanguageMenu)
        {
            setTimeout(() => { this.showLanguageMenu = false }, 300);
        }
    }

    constructor (private viewerApi: ViewerApi,
        private viewerService: ViewerService,
        private progressService: ProgressService,
        private router: Router,
        private settingsService: SettingsService,
        private eventService: GlobalEventService,
        private translationService: TranslateService
    )
    {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translationService.setDefaultLang('en');
        let browserLang = this.translationService.getBrowserLang();
        let sessionLang = localStorage.getItem('VisShareLang');
        let usedLangKey = sessionLang ? sessionLang : browserLang;
        let usedLang = this.languages.filter((lang: LangObj) =>
        {
            return lang.key === usedLangKey;
        });
        this.usedLang = (usedLang && usedLang.length > 0) ? usedLang[0] : { key: 'en', name: 'English' };
        this.translationService.use(this.usedLang.key);
        try
        {
            this.hidePrivate = HidePrivate;
        }
        catch (e) { }
        try
        {
            this.hideLinks = HideMailLinks;
        }
        catch (e) { }
        try
        {
            this.hidePasswordReset = HidePasswordReset;
        }
        catch(e){}
        LoopBackConfig.setBaseURL(VSShareBackendUrl);
        LoopBackConfig.setApiVersion('api');
        LoopBackConfig.whereOnUrl();
        //Test if user is logged in
        this.viewerApi.getCurrent().subscribe(user =>
        {
            this.activeViewer = user;
            this.viewerService.getSearchSettingsForUser(user.id);
            this.settingsService.startSettingsSubscription();
            this.viewerService.changeViewer(user);
        }, err =>
            {
            });

        this.settingsService.settings.subscribe((settings: Settings) =>
        {
            this.settings = settings;
        });

        this.viewerService.currentViewer.subscribe((viewer: Viewer) =>
        {
            if (viewer['isRemote'])
            {
                this.remoteUser = true;
            }
            else
            {
                if (!this.activeViewer || this.activeViewer.id !== viewer.id)
                {
                    this.activeViewer = viewer;
                }
                this.remoteUser = false;
            }
        });

        this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
            }
        });

        this.progressService.error.subscribe(error =>
        {
            if (!error)
            {
                this.showErrorMessage = false;
                return;
            }
            let err = error as PortalError;
            if (err.name)
            {
               // this.zone.run(() =>
              //  {
                    this.error = err;
                    this.showErrorMessage = true;
               // });
            }
        });

        this.progressService.toast.subscribe((toast: PortalToast) =>
        {
            if (!toast)
            {
                this.showToastMessage = false;
                return;
            }
            let msg = toast as PortalToast;
            if (msg.message)
            {
                this.toast = msg;
                this.showToastMessage = true;
            }
        });

        this.progressService.loading.subscribe((loadObj: BlockerContext) =>
        {
            this.isLoading = loadObj.isLoading;
            this.blockerMessage = loadObj.message;
        });

        this.progressService.image.subscribe(image =>
        {
            if (!image || !image['Image'])
            {
                this.showImage = false;
                return;
            }
            else if(image['Image'])
            {
                this.overlayedImage = image['Image'];
                this.showImage = true;
            }
        });

        this.showHeaderMail = window.document.body.clientWidth > 800;
        this.showHeaderIconText = window.document.body.clientWidth > 500;
        window.onresize = e =>
        {
            this.showHeaderMail = window.document.body.clientWidth > 800;
            this.showHeaderIconText = window.document.body.clientWidth > 500;
        }

        //Highlight header section
        this.router.events.subscribe((event) =>
        {
            this.mobileMenuOpen = false;
            if (event['url'] && event['url'].indexOf('/') !== -1)
            {
                let startRoute = event['url'].split('/')[1];
                switch (startRoute)
                {
                    case 'private':
                        this.groupCategory = 'personal';
                        break;
                    case 'projects':
                        this.groupCategory = 'projects';
                        break;
                    case 'search':
                        this.groupCategory = 'search';
                        break;
                    default:
                        this.groupCategory = 'all';
                        break;
                }
                if (this.isMobile)
                {
                    this.mobileNav = event['url'] === '/' ? 'all' : startRoute;
                }
            }
        });

    }

    ngOnInit ()
    {
        let storedLayoutPref = localStorage.getItem('VisShareLayoutMobile');
        let hasMouse = storedLayoutPref !== null && storedLayoutPref !== '' ? storedLayoutPref === 'false' : matchMedia('(pointer:fine)').matches;
        this.eventService.changeLayout(!hasMouse);
    }

    closeImageOverlay (e: MouseEvent)
    {
        e.preventDefault();
        e.stopPropagation();
        this.progressService.endImageOverlay();
    }

    setLanguage (lang: LangObj)
    {
        this.translationService.use(lang.key).subscribe(res =>
        {
            this.usedLang = lang;
            localStorage.setItem('VisShareLang', lang.key);
        });
    }

    changeLayout ()
    {
        localStorage.setItem('VisShareLayoutMobile', !this.isMobile ? 'true' : 'false');
        this.eventService.changeLayout(!this.isMobile);
    }

    closeMobileMenu (e: MouseEvent)
    {
        e.preventDefault();
        e.stopPropagation();
        this.mobileMenuOpen = false;
    }

    startDeletion ()
    {
        this.confirmModal.open('ACCOUNT_DELETION', 'ACCOUNT_DELETION_CONFIRMATION', 'DeleteAccount');
    }

    OnDeletionConfirm (event: any)
    {
        if (event && event.id === 'DeleteAccount' && this.activeViewer && this.activeViewer.email)
        {
            this.viewerApi.login({ email: this.activeViewer.email, password: event.value }).subscribe(res =>
            {
                this.viewerApi.deleteById(this.activeViewer.id).subscribe(res =>
                {
                    this.activeViewer = {};
                    this.router.navigateByUrl('login');
                });
            }, err =>
                {
                    let error: PortalError = {
                        code: 401,
                        message: 'WRONG_PASSWORD',
                        name: 'PasswordConfirmationFailed'
                    };
                    this.progressService.addError(error);
                });
        }
    }

    logOut ()
    {
        this.viewerApi.logout();
        this.activeViewer = {};
    }

    resetSearchTextString ()
    {
        this.searchText.setValue('');
    }

    startSearch ()
    {
        this.router.navigate(['search', this.searchText.value]);
    }

}
