/* tslint:disable */

declare var Object: any;
export interface AttachmentInterface {
  "Name": string;
  "UID": string;
  "CreationDate"?: number;
  "Size"?: number;
  "Comment"?: string;
  "CreatorMail"?: string;
  "Viewable"?: boolean;
  "Downloadable"?: boolean;
  "id"?: number;
  "modelFileId"?: number;
}

export class Attachment implements AttachmentInterface {
  "Name": string;
  "UID": string;
  "CreationDate": number;
  "Size": number;
  "Comment": string;
  "CreatorMail": string;
  "Viewable": boolean;
  "Downloadable": boolean;
  "id": number;
  "modelFileId": number;
  constructor(data?: AttachmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Attachment`.
   */
  public static getModelName() {
    return "Attachment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Attachment for dynamic purposes.
  **/
  public static factory(data: AttachmentInterface): Attachment{
    return new Attachment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Attachment',
      plural: 'Attachments',
      path: 'Attachments',
      idName: 'id',
      properties: {
        "Name": {
          name: 'Name',
          type: 'string'
        },
        "UID": {
          name: 'UID',
          type: 'string'
        },
        "CreationDate": {
          name: 'CreationDate',
          type: 'number'
        },
        "Size": {
          name: 'Size',
          type: 'number'
        },
        "Comment": {
          name: 'Comment',
          type: 'string'
        },
        "CreatorMail": {
          name: 'CreatorMail',
          type: 'string'
        },
        "Viewable": {
          name: 'Viewable',
          type: 'boolean',
          default: false
        },
        "Downloadable": {
          name: 'Downloadable',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "modelFileId": {
          name: 'modelFileId',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
