import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { ModelFile, Viewer, SharedFileApi, SharedFile } from '../shared/sdk';
import { ViewerService } from '../services/viewer.service';
import { ActivatedRoute } from '@angular/router';
import { BrowserComponent } from '../group/browser/browser.component';
import { Subscription } from 'rxjs';
import { ShareBreadCrumb } from '../group/browser/browser-breadcrumb/browser-breadcrumb.component';
import { GlobalEventService } from '../services/global-event.service';

export interface SharedFolder
{
    name: string,
    id: number
};

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss']
})

export class ShareComponent implements AfterViewInit
{
    @ViewChild(BrowserComponent)
    private fileBrowser: BrowserComponent;

    //Layout variables
    private layoutSubscription: Subscription;
    isMobile: boolean = false;

    activeViewer: Viewer

    folders: SharedFolder[] = [];
    files: ModelFile[] = [];
    viewMode: number;

    //Pagination
    private fileCount: number;
    private page: number = 1;
    pageSize: number = 75;

    //Sort defaults
    private sortCat: string = 'Name';
    private sortAsc: boolean = true;

    private owner: Viewer;
    private viewerSubscription: Subscription;

    constructor (
        private route: ActivatedRoute,
        private viewerService: ViewerService,
        private eventService: GlobalEventService) { }

    ngAfterViewInit ()
    {
        this.viewerSubscription = this.viewerService.currentViewer.subscribe((activeViewer: Viewer) =>
        {
            if (activeViewer.id)
            {
                setTimeout(() => { this.activeViewer = activeViewer; }, 0);
                setTimeout(() => { this.viewMode = activeViewer.ViewMode; });
                setTimeout(() => { this.fileBrowser.setViewer(activeViewer) }, 0);
                setTimeout(() =>
                {
                    this.route.params.subscribe(params =>
                    {
                        this.files = [];
                        this.folders = [];
                        if (params.owner)
                        {
                            this.fileBrowser.setShareFolders([]);
                            this.viewerService.getViewerById(params.owner).subscribe((viewer: Viewer) =>
                            {
                                this.fileBrowser.resetShareCrumb();
                                this.owner = viewer;
                                let crumb: ShareBreadCrumb = {
                                    name: 'SHARED_WITH_ME',
                                    link: '/share/sharedFrom'
                                }
                                this.fileBrowser.addShareCrumb(crumb);
                                crumb = {
                                    name: viewer.email,
                                    link: '/share/sharedFrom/;owner=' + viewer.id
                                }
                                this.fileBrowser.addShareCrumb(crumb);
                                this.getFiles();
                            });
                        }
                        else
                        {
                            this.fileBrowser.resetShareCrumb();
                            let crumb: ShareBreadCrumb = {
                                name: 'SHARED_WITH_ME',
                                link: '/share/sharedFrom'
                            }
                            this.fileBrowser.addShareCrumb(crumb);
                            this.getFolder();
                        }
                    });
                });
            }
        });
    }

    ngOnInit ()
    {
        this.layoutSubscription = this.eventService.layoutChange.subscribe((isMobile: boolean) =>
        {
            if (typeof(isMobile) === 'boolean')
            {
                this.isMobile = isMobile;
                this.pageSize = isMobile ? 20 : 75;
            }
        });
    }

    getFolder (searchString?: string)
    {
        this.folders = [];
        this.fileBrowser.setShareFolders([]);
        if (this.activeViewer && this.activeViewer.id)
        {
            this.viewerService.getShareFrom().subscribe((shares: SharedFile[]) =>
            {
                this.fileBrowser.setFiles([]);
                let folders = [];
                for (let i = 0; i < shares.length; i++)
                {
                    let sharedFolder: SharedFolder = {
                        id: shares[i].SharedFrom,
                        name: ''
                    };
                    if (this.folders.map((folder: SharedFolder) => { return folder.id; }).indexOf(shares[i].SharedFrom) === -1)
                    {
                        this.folders.push(sharedFolder);
                        this.viewerService.getViewerById(shares[i].SharedFrom).subscribe((viewer: Viewer) =>
                        {
                            sharedFolder.name = viewer.email;
                            if ((!searchString || viewer.email.indexOf(searchString) !== -1) &&
                                folders.map((folder: SharedFolder) => { return folder.id; }).indexOf(viewer.id) === -1)
                            {
                                folders.push(sharedFolder);
                                this.fileBrowser.setShareFolders(folders);
                            }
                        });
                    }
                }
            });
        }
    }

    ngOnDestroy ()
    {
        this.viewerSubscription.unsubscribe();
        this.layoutSubscription.unsubscribe();
    }

    getFiles (searchString?: string, sortCat?: string, sortAsc?: boolean)
    {
        let countFilter = {
            viewerId: this.owner.id
        };
        if (searchString)
        {
            countFilter['Name'] = {
                ilike: '%' + searchString + '%'
            }
        }
        this.viewerService.countSharedFiles(countFilter).subscribe(count =>
        {
            this.fileCount = count.count;
            this.fileBrowser.setFileCount(this.fileCount);
            let filter = {};
            filter['where'] = countFilter;
            filter['limit'] = this.pageSize;
            filter['skip'] = (this.page - 1) * this.pageSize;
            let asc: boolean = (sortAsc !== undefined) ? sortAsc : this.sortAsc;
            let cat = (sortCat ? sortCat : this.sortCat);
            filter['order'] = cat + ' ' + (asc ? 'ASC' : 'DESC');
            this.viewerService.getSharedFiles(filter).subscribe((files: ModelFile[]) =>
            {
                this.files = files;
                this.fileBrowser.setFiles(this.files);
            });
        });
    }

    setPage (page: number, searchString?: string, sortCat?: string, sortAsc?: boolean)
    {
        let maxPage = Math.ceil(this.fileCount / this.pageSize);
        if ((page > maxPage || page < 1) && maxPage !== 0)
        {
            return;
        }
        else
        {
            this.page = page;
            this.fileBrowser.setPage(page);
            this.getFiles(searchString, sortCat, sortAsc);
        }
    }

    setViewMode (viewMode)
    {
        this.viewerService.setViewMode(viewMode).subscribe((viewer) =>
        {
            this.viewerService.changeViewer(viewer);
        });
    }

    OnBrowserEmit (event)
    {
        if (event.Page)
        {
            if (this.folders.length === 0)
            {
                this.setPage(event.Page, event.SearchText, event.SortCat, event.SortAsc);
            }
            else
            {
                this.getFolder(event.SearchText);
            }
        }
    }
}
